import React from "react";
import { Wrapper, StyledButton, Title } from "./used-block-styles";
import artist1 from "../../../../assets/images/for-artists/artist_1.webp";
import artist2 from "../../../../assets/images/for-artists/artist_2.webp";
import artist3 from "../../../../assets/images/for-artists/artist_3.webp";
import artist4 from "../../../../assets/images/for-artists/artist_4.webp";
import artist5 from "../../../../assets/images/for-artists/artist_5.webp";
import artist6 from "../../../../assets/images/for-artists/artist_6.webp";
import artist7 from "../../../../assets/images/for-artists/artist_7.webp";
import artist8 from "../../../../assets/images/for-artists/artist_8.webp";
import artist9 from "../../../../assets/images/for-artists/artist_9.webp";
import { Slide } from "./slide";
import { AdaptiveText, Carousel } from "../../../../components";
import { TitleDivider } from "../../../../components/v2/title-divider";
import { useNavigate } from "react-router-dom";

export const artistsData = [
  {
    id: 1,
    image: artist9,
    name: "Peter Van den Berg",
    description:
      "Being an independent stylist renting a chair is a very safe and risk-free way for me to work right now. I know that both me and my clients will feel comfortable because all the salons on VERVVEY have been pre-screened. Very positive experience and much safer than I expected.",
  },
  {
    id: 2,
    image: artist5,
    name: "Jolanda Westwood",
    description:
      "I often don’t need a full day as I’m not having that many clients yet. What I like is flexibility to book. I can book a few hours here and there to make sure I’m making money while I’m there and not wasting any money with downtime. It’s been great to keep my schedule really lean.",
  },
  {
    id: 3,
    image: artist8,
    name: "Laurent Maitre ",
    description:
      "You can be a hair entrepreneur without having to put up investments of building your own salon. What is more exciting, you can enjoy variety of salon vibes and meeting new people, when you are renting chair with VERVVEY.",
  },
  {
    id: 4,
    image: artist4,
    name: "Polina Kalinina",
    description:
      "When I got independent, I was expecting to lose some clients, but I was wrong. My clients were excited to follow me, and often we are ending up meeting in a salon much closer to them. I find my clients are much more engaged with me now.",
  },
  {
    id: 5,
    image: artist6,
    name: "Erik  Scholte",
    description:
      "I’ve gone back and forward between different options over my career. I worked in commission-based salons, I rented a chair in salons, I owned salons, I worked as a freelancer visiting clients’ homes. One thing I like is change. It’s definitely not for everyone, but there is so much power you get from being independent.",
  },
  {
    id: 6,
    image: artist3,
    name: "Isabella Girardello",
    description:
      "It’s been great to become independent both financially and personally. I used VERVVEY profile page as my demo website to share with the clients and spread the word. It helped to build new clientele.",
  },
  {
    id: 7,
    image: artist2,
    name: "Dior Diop",
    description:
      "Chair rental experience has never been easier and safer. You can rent a chair quickly, easily, contract-free, no deposits, and have a customer-support by your side if you need it.",
  },
  {
    id: 8,
    image: artist7,
    name: "Marnix Bessem",
    description:
      "Being independent as a stylist you are so much more powerful putting a client in a chair. Renting a chair can be daunting, but with VERVVEY I was able to sign up immediately and find the salon that matches my vibe, and now I’m meeting my clients in the comfort of the salon. ",
  },
  {
    id: 9,
    image: artist1,
    name: "Marjolein Visser",
    description:
      "When you have freedom and independence, you get to do things the way you want to and rediscover the pleasure of being an artist. And when you keep 100% of every euro you earn, you can make the same by seeing less clients. When I became independent, I re-found myself. I dropped down my clients, I dropped down my time, I dropped down my place.",
  },
];

const UsedBlock = () => {
  const navigate = useNavigate();

  const joinNow = () => {
    navigate("register_modal");
  };

  return (
    <Wrapper>
      <Title>
        <AdaptiveText
          xl={{ size: 44, height: 56 }}
          xs={{ size: 30, height: 38 }}
          sm={{ size: 30, height: 38 }}
          weight={600}
          fontFamily="Greenwich SemBd"
          align="center"
          uppercase
        >
          Used by thousand of artists & salons
        </AdaptiveText>
      </Title>
      <TitleDivider />
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 20 }}
        weight={100}
        fontFamily={"Greenwich ExtLt"}
        align="center"
      >
        Become one of them!
      </AdaptiveText>
      <Carousel
        autoplay
        showSlides={{ xs: 1, md: 2, lg: 3 }}
        bigNavigation
        withDots
        slides={artistsData.map((item) => (
          <Slide item={item} />
        ))}
      ></Carousel>
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={joinNow}
        disableRipple
      >
        JOIN NOW
      </StyledButton>
    </Wrapper>
  );
};

export default UsedBlock;
