import { CreateModeProfile } from "./Stepper/CreateModeProfile/CreateModeProfile";
import useFetchServicesData from "./useFetchServicesData";
import useFetchInterestsData from "./useFetchInterestsData";
import { UpdateModeProfile } from "./UpdateModeProfile/UpdateModeProfile";
import { useParams } from "react-router-dom";
import { ROLES } from "../../../constants/common.consts";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

export const EditProfile = () => {
  const { auth } = useSelector((state: AppState) => state);
  const { userId } = useParams();

  useFetchServicesData();
  useFetchInterestsData();

  if (userId && auth?.role === ROLES.SUPER_ADMIN) {
    return <UpdateModeProfile />;
  }

  if (!auth?.name) {
    return <CreateModeProfile />;
  }

  return <UpdateModeProfile />;
};
