import classes from "../salonInformation/salonInformation.module.scss";
import { useEffect, useState } from "react";
import { CustomInput } from "../../../../../components/customInput/customInput";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import { CustomPhoneInput } from "../../../../../components/phoneInput/phoneInput";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../constants/common.consts";
import setSalonFormDataToStorage from "../../Stepper/services/setSalonFormDataToStorage";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { getFormattedSalonFormDataForBEUpdateMode } from "../../../../../utils/utils";
import { updateSalonFormData } from "../../../../../services/salonsApi";
import { ExplanationIcon } from "@components";
import { Button } from "@mui/material";
import { NextButton } from "../../../../profile/EditProfileForm/Pages/YourPortfolio/yourPortfolio-styles";
import { useFetchSalonInformationOptions } from "./hooks/useFetchSalonInformationOptions";
import { getCityList } from "../../../../../services/api";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const DEFAULT_ERROR_STATE = {
  salonName: "",
  citySelect: "",
  addressStreet: "",
  addressNumber: "",
  addressPostalCode: "",
  salonPhoneNumber: "",
  website: "",
  socialNetwork: "",
  artist: "",
  hearAbout: "",
  accountNumber: "",
};

const isValidURL = (url) => {
  const res = url.match(/^(http(s)?:\/\/.)/g);

  return res !== null;
};

export const SalonInformation = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const [error, setError] = useState(null);
  const [cityList, setCityList] = useState([]);

  const { isLoading, frontOfHouseOptions, howToFoundOptions } =
    useFetchSalonInformationOptions();

  const { salonFormData, setSalonFormData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { salonId } = useParams();

  const handleInputChange = (_, e) => {
    const { name, value } = e.target;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    getCityList().then((cities) => {
      setCityList(
        cities.map((city) => {
          return { value: city, label: city };
        })
      );
    });
  }, []);

  const handleBankingInputChange = (_, e) => {
    const { name, value } = e.target;

    setSalonFormData({
      ...salonFormData,
      banking: {
        ...salonFormData.banking,
        [name]: value,
      },
    });
  };

  const handleAddressInputChange = (_, e) => {
    const { name, value } = e.target;

    setSalonFormData({
      ...salonFormData,
      address: {
        ...salonFormData.address,
        [name]: value,
      },
    });
  };

  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const data = await updateSalonFormData(
        getFormattedSalonFormDataForBEUpdateMode(salonFormData, salonId, auth),
        salonId
      );

      navigate(`/salon_profile/${salonId}`);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [salonFormData]);

  const isFieldsValid = () => {
    let isError = false;

    if (!salonFormData.salonName) {
      setErrs((prevState) => {
        return { ...prevState, salonName: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.citySelect) {
      setErrs((prevState) => {
        return { ...prevState, citySelect: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.address.street) {
      setErrs((prevState) => {
        return { ...prevState, addressStreet: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.address.number) {
      setErrs((prevState) => {
        return { ...prevState, addressNumber: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.address.postCode) {
      setErrs((prevState) => {
        return { ...prevState, addressPostalCode: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.salonPhoneNumber) {
      setErrs((prevState) => {
        return { ...prevState, salonPhoneNumber: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.website) {
      setErrs((prevState) => {
        return { ...prevState, website: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.socialNetwork) {
      setErrs((prevState) => {
        return { ...prevState, socialNetwork: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.artist) {
      setErrs((prevState) => {
        return { ...prevState, artist: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.hearAbout) {
      setErrs((prevState) => {
        return { ...prevState, hearAbout: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (
      salonFormData.banking.accountNumber &&
      salonFormData.banking.accountNumber?.length !== 18
    ) {
      setErrs((prevState) => {
        return { ...prevState, accountNumber: "IBAN is not correct" };
      });

      isError = true;
    }

    if (
      !salonFormData?.socialNetwork ||
      !isValidURL(salonFormData.socialNetwork)
    ) {
      setErrs((prevState) => {
        return {
          ...prevState,
          socialNetwork: "url is not valid",
        };
      });

      isError = true;
    }

    return !isError;
  };

  const submitCreateMode = async () => {
    setSalonFormDataToStorage(salonFormData);

    navigate(location, {
      state: {
        activeStep: 1,
      },
    });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!salonId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedSalonUrl"));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div>
      <h2 className={`page_main_title`}>Salon information</h2>
      <h3 className={`page_blue_subtitle`}>
        This data is required to create and verify the salon profile
      </h3>
      <form onSubmit={handleSubmit} noValidate>
        <div className={`form_group`}>
          <div className={`inputs_group`}>
            <CustomInput
              text={"Salon name"}
              name={"salonName"}
              type={"text"}
              value={salonFormData.salonName}
              onChange={handleInputChange}
              require
              err={errs.salonName}
              placeholder="Beauty by Roos"
              info="Please enter the name as it appears on your storefront, website, etc"
            />
          </div>
          <div className={`inputs_group xs-column`}>
            <MySelect
              name="citySelect"
              text="City"
              value={salonFormData.citySelect}
              options={cityList}
              onChange={handleSelectInputChange}
              placeholder="Select one option"
              require
              info="Select the city where the salon is located"
              err={errs.citySelect}
            />
            <CustomPhoneInput
              text={"Salon phone number"}
              value={salonFormData.salonPhoneNumber}
              onChange={(value) =>
                setSalonFormData({ ...salonFormData, salonPhoneNumber: value })
              }
              require
              err={errs.salonPhoneNumber}
            />
          </div>
          <div>
            <div className={classes.salon_address_wrapper}>
              <h3>Salon address</h3>

              <ExplanationIcon
                text={"Add full address, where the salon is located"}
              />
              <div className={classes.req_sign}>*</div>
            </div>
            <div className={`inputs_group ${classes.address_inputs_wrapper}`}>
              <div className={classes.long_input}>
                <CustomInput
                  text={"Street"}
                  name={"street"}
                  type={"text"}
                  value={salonFormData.address.street}
                  onChange={handleAddressInputChange}
                  require
                  err={errs.addressStreet}
                  placeholder="Nieuwezijds Voorburgwal"
                />
              </div>

              <div className={`half-width ${classes.short_input}`}>
                <CustomInput
                  text={"Number"}
                  name={"number"}
                  type={"text"}
                  value={salonFormData.address.number}
                  onChange={handleAddressInputChange}
                  require
                  err={errs.addressNumber}
                  placeholder="72 A"
                />
              </div>
              <div className={`half-width ${classes.short_input}`}>
                <CustomInput
                  text={"Postal code"}
                  name={"postCode"}
                  type={"text"}
                  value={salonFormData.address.postCode}
                  onChange={handleAddressInputChange}
                  require
                  err={errs.addressPostalCode}
                  placeholder="1012"
                />
              </div>
            </div>
          </div>

          <div className={`inputs_group`}>
            <CustomInput
              text={"Salon website "}
              name={"website"}
              type={"text"}
              value={salonFormData.website}
              onChange={handleInputChange}
              require
              err={errs.website}
              placeholder="https://www.hairsalons.com"
              info="We need it for verification of your salon profile"
            />
            <CustomInput
              text={"Salon profile on Facebook or Instagram"}
              name={"socialNetwork"}
              type={"text"}
              value={salonFormData.socialNetwork}
              onChange={handleInputChange}
              require
              err={errs.socialNetwork}
              placeholder="https://www.instagram.com/vervvey/"
              info="We would love to follow you! Please add the link to your most actiive social media profile"
            />
          </div>
          <div className={`inputs_group`}>
            <MySelect
              name="artist"
              text="Front of House for VERVVEY artists"
              value={salonFormData?.artist?.value}
              options={frontOfHouseOptions}
              onChange={(value, actionMeta, newValue) =>
                setSalonFormData({
                  ...salonFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder="Select one option"
              require
              info="Specify who will welcome VERVVEY artists in the salon & do the welcome tour for their first visit"
              err={errs.artist}
            />
            <MySelect
              name="hearAbout"
              text="How did you hear about VERVVEY?"
              value={salonFormData?.hearAbout?.value}
              options={howToFoundOptions}
              onChange={(value, actionMeta, newValue) =>
                setSalonFormData({
                  ...salonFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder="Select one option"
              require
              err={errs.hearAbout}
            />
          </div>
        </div>
        <div className={classes.bottom_text_wrapper}>
          <h3 className={`page_subtitle ${classes.bottom_text}`}>
            Salon banking details for payments from VERVVEY
          </h3>
          <h3 className={`page_blue_subtitle`}>
            Banking details you provide will be used for payments and are
            securely stored for internal reference only
          </h3>
        </div>
        <div className={`form_group`}>
          <div className={`inputs_group`}>
            <CustomInput
              text={"Beneficiary name"}
              name={"benefitName"}
              type={"text"}
              value={salonFormData.banking.benefitName}
              onChange={handleBankingInputChange}
              placeholder="Example"
              info="Please enter beneficiary name as it appears on your business account"
            />
            <CustomInput
              text={"Account number (IBAN)"}
              name={"accountNumber"}
              type={"text"}
              value={salonFormData.banking.accountNumber}
              onChange={handleBankingInputChange}
              err={errs.accountNumber}
              placeholder="NL99 BANK 0123 4567 89"
              info="Please enter your IBAN"
              maxLength={18}
            />
          </div>
        </div>
        <div className={"btn_bottom_wrapper"}>
          {!salonId ? (
            <>
              <NextButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </NextButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
