import React from "react";
import { ReactComponent as HairStyleIcon } from "../../../../assets/icons/hairstylist.svg";
import { ReactComponent as BarberIcon } from "../../../../assets/icons/barber.svg";
import { ReactComponent as NailTechnicianIcon } from "../../../../assets/icons/nail_technician.svg";
import { ReactComponent as EstheticianIcon } from "../../../../assets/icons/esthetician.svg";
import { ReactComponent as MakeupArtistIcon } from "../../../../assets/icons/makeup_artist.svg";
import { ReactComponent as MassageTherapistIcon } from "../../../../assets/icons/massage_therapist.svg";
import { ReactComponent as TattooArtistIcon } from "../../../../assets/icons/tattooArtist.svg";
import { ReactComponent as PemanentMakeup } from "../../../../assets/icons/perm-make-up.svg";
import { Title, Wrapper, Card } from "./carousel-block-styles";
import { AdaptiveText, Carousel } from "../../../../components";
import { TitleDivider } from "../../../../components/v2/title-divider";
import { colors } from "../../../../styles";

let cards = [
  {
    id: 1,
    icon: <HairStyleIcon />,
    label: "Hairstylist",
  },
  {
    id: 2,
    icon: <BarberIcon />,
    label: "Barbers",
  },
  {
    id: 3,
    icon: <NailTechnicianIcon />,
    label: "Nail technician",
  },
  {
    id: 4,
    icon: <EstheticianIcon />,
    label: "Esthetician",
  },
  {
    id: 5,
    icon: <MakeupArtistIcon />,
    label: "Make-up Artist",
  },
  {
    id: 6,
    icon: <MassageTherapistIcon />,
    label: "Massage Therapist",
  },
  {
    id: 7,
    icon: <TattooArtistIcon />,
    label: "Tattoo Artist",
  },
  {
    id: 8,
    icon: <PemanentMakeup />,
    label: "Permanent Make-up Artist",
  },
];

const CarouselBlock = () => {
  return (
    <Wrapper>
      <Title>
        <AdaptiveText
          xl={{ size: 44, height: 56 }}
          xs={{ size: 30, height: 38 }}
          sm={{ size: 30, height: 38 }}
          weight={600}
          fontFamily="Greenwich SemBd"
          align="center"
          uppercase
        >
          Who is VERVVEY for?
        </AdaptiveText>
      </Title>
      <TitleDivider bg={colors.blu4} />

      <Carousel
        showSlides={{ xs: 3, lg: 5 }}
        bigNavigation
        slides={cards.map((card) => (
          <Card key={card.id}>
            {card.icon}
            <AdaptiveText
              xl={{ size: 25, height: 28 }}
              sm={{ size: 16, height: 18 }}
              xs={{ size: 16, height: 18 }}
              fontFamily="Greenwich"
              align="center"
            >
              {card.label}
            </AdaptiveText>
          </Card>
        ))}
      ></Carousel>
    </Wrapper>
  );
};

export default CarouselBlock;
