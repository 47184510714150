import { Record } from "../record";
import {
  Wrapper,
  DetailBlock,
  DetailMain,
  DetailRow,
  Status,
  DetailRecord,
  SelectWrapper,
} from "./payout-details-styles";
import { AdaptiveText, DayStatusSelect } from "../../../../components";
import { colors } from "../../../../styles";

const PayoutDetails = ({ data, statuses }) => {
  return (
    <Wrapper>
      <DetailBlock>
        <DetailMain>
          <DetailRecord>
            <AdaptiveText
              xl={{ size: 14, height: 16 }}
              xs={{ size: 14, height: 16 }}
              fontFamily="Greenwich"
              as="span"
              color={colors.grey2}
            >
              Beneficiary name:
            </AdaptiveText>
            <AdaptiveText
              xl={{ size: 14, height: 16 }}
              xs={{ size: 14, height: 16 }}
              fontFamily="Greenwich"
              weight={500}
              as="span"
            >
              {data?.salonName}
            </AdaptiveText>
          </DetailRecord>
          <DetailRecord>
            <AdaptiveText
              xl={{ size: 14, height: 16 }}
              xs={{ size: 14, height: 16 }}
              fontFamily="Greenwich"
              as="span"
              color={colors.grey2}
            >
              Bank account number (IBAN):
            </AdaptiveText>
            <AdaptiveText
              xl={{ size: 14, height: 16 }}
              xs={{ size: 14, height: 16 }}
              fontFamily="Greenwich"
              weight={500}
              as="span"
            >
              {data?.iban}
            </AdaptiveText>
          </DetailRecord>
        </DetailMain>
        {data?.schedules?.map((item) => (
          <DetailRow key={item.id}>
            <Record w={100} label="Date to pay for:" value={item?.rentDate} />
            <Record
              label="Price per day:(excl. fee)"
              value={`€ ${Math.floor(item?.priceVat.toFixed(2) * 90) / 100}`}
            />
            <Status>
              <SelectWrapper>
                <DayStatusSelect
                  defaultValue={item?.status}
                  statuses={statuses}
                  data={item}
                />
              </SelectWrapper>
            </Status>
          </DetailRow>
        ))}
      </DetailBlock>
    </Wrapper>
  );
};

export default PayoutDetails;
