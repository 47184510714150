import styled from "@emotion/styled";
import { alpha } from "@mui/material";
import { colors } from "../../../../../styles";

const Wrapper = styled.div`
  width: 100%;
  padding: 24px 16px;
  background-color: #eef5ff;
`;

const DetailBlock = styled.div`
  border-radius: 20px;
  border: 2px solid ${colors.white};
`;

const DetailMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 24px;
  background-color: ${alpha(colors.white, 0.3)};
`;

const DetailRow = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Status = styled.div`
  width: 200px;
`;

export { Wrapper, DetailBlock, DetailMain, DetailRow, Status };
