import { FC, useState } from "react";
import { StyledLink, Wrapper } from "./read-more-styles";
import { AdaptiveText } from "../../../../../components";

const ReadMore: FC<any> = ({ text, maxLength = 90, className = "" }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Wrapper>
      <AdaptiveText
        xl={{ size: 18, height: 21 }}
        xs={{ size: 18, height: 21 }}
        weight={300}
        fontFamily="Greenwich"
      />
      {isReadMore ? `${text.slice(0, maxLength)}...` : text}
      <StyledLink onClick={toggleReadMore}>
        {isReadMore ? "See more" : "Read less"}
      </StyledLink>
    </Wrapper>
  );
};

export default ReadMore;
