import { Link } from "react-router-dom";
import headerImage from "../../assets/images/main-page-header-image.webp";
import "./404.scss";

function NotFoundPage() {
  return (
    <div className="not_found_page">
      <div className="header_image_block">
        <div className="row_container">
          <div className="text_column">
            <h1 className="title">404</h1>
            <h2 className="better_experience_text">
              OOPS! THE PAGE IS NOT FOUND
            </h2>
          </div>
          <div className="image_column">
            <img src={headerImage} alt="header" />
          </div>
        </div>
        <div className="polygon"></div>
      </div>
      <div className="main_body">
        <div className="text">
          GO TO <Link to="/">MAIN PAGE</Link> OR FIND YOUR ARTIST
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
