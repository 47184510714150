import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { alpha } from "@mui/material";
import { AdaptiveText } from "@components";

const Wrapper = styled.div`
  margin: 100px 0;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("60 0")};
  }
`;

const Divider = styled.div`
  margin: 20px 0 60px;
  width: 100%;
  height: 3px;
  background-color: ${alpha(colors.blu4, 0.3)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("12 0 32")};
    height: ${vw(3)};
  }
`;

const Row = styled.div`
  display: flex;
  gap: 90px;
  justify-content: center;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(30)};
  }
`;

const Day = styled(AdaptiveText)`
  width: 140px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(120)};
  }
`;

const Hours = styled(AdaptiveText)`
  width: 160px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(160)};
  }
`;

export { Wrapper, Divider, Row, Day, Hours };
