import { useAuth } from "../contexts/AuthContext";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { ROLES } from "../constants/common.consts";
import { useSelector } from "react-redux";
import { AppState } from "../redux/reducers/rootReducer";

export function SuperAdminRequireAuth({ children }) {
  const { auth } = useSelector((state: AppState) => state);
  const location = useLocation();

  if (auth?.role !== ROLES.SUPER_ADMIN) {
    return <Navigate to="/" replace state={{ path: location.pathname }} />;
  }

  return children;
}
