import { FormControl, MenuItem } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { StyledSelect, LabelContainer } from "./status-select-styles";
import { updateBookingRequest } from "../../../../services/api";
import { StatusChange } from "../../../../modals";
import WithDialog from "../../../../contexts/dialog/WithDialog";

const StatusSelect: FC<any> = ({
  defaultValue,
  data,
  statuses,
  openDialog,
  closeDialog,
  placeholder,
}) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const accept = async (e) => {
    setValue(e.target.value);

    updateBookingRequest({
      rentId: data?.id,
      status: e.target.value,
    });
  };

  const handleChange = (e) => {
    openDialog({
      component: (
        <StatusChange
          close={closeDialog}
          okCallback={() => {
            accept(e);
            closeDialog();
          }}
        />
      ),
      mw: 716,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  useEffect(() => {
    if (defaultValue?.length) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormControl fullWidth>
      {placeholder && (
        <LabelContainer
          onClick={() => {
            inputRef.current?.click();
            setOpen(true);
          }}
        >
          {placeholder}
        </LabelContainer>
      )}
      <StyledSelect
        value={statuses?.length ? value : ""}
        label="Status"
        onChange={handleChange}
        ref={inputRef}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
      >
        {statuses?.map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default WithDialog(StatusSelect);
