import classes from "./customCheckboxButton.module.scss";

type PropsType = {
  label: string;
  value: boolean;
  id: number;
  onChange: (value: boolean, id: number) => void;
  disabled?: boolean;
};

export const CustomCheckboxButton = ({
  label,
  value,
  onChange,
  id,
  disabled,
}: PropsType) => {
  const onChangeHandler = (e) => {
    if (disabled) return;

    onChange(!value, id);
  };

  return (
    <div
      onClick={onChangeHandler}
      className={`${classes.custom_checkbox_wrapper} ${
        disabled && classes.disabled
      }`}
    >
      <input type="checkbox" checked={value} readOnly />
      <div className={classes.inner_checkbox}>{label}</div>
    </div>
  );
};
