import { boolean, number, object, string } from "yup";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../../constants/common.consts";

export const validationSchema = object({
  workstationId: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
  count: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
  minDuration: string().default("day"),
  wAvailId: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
  pricePerDay: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
  pricePerHour: number().default(0),
  additionCondition: string(),
  instantBooking: boolean().default(false),
  lang: string().default("EN"),
});

export const getValidation = (workstation) => ({
  validationSchema,
  defaultValues: workstation
    ? {
        workstationId: workstation.workstationId,
        count: workstation.count,
        minDuration: "day",
        wAvailId: workstation.availability.wAvailId,
        pricePerDay: workstation.pricePerDay,
        pricePerHour: workstation.pricePerHour,
        additionCondition: workstation.additionCondition,
        instantBooking: workstation.instantBooking,
        lang: "EN",
      }
    : validationSchema.cast({}),
});
