import { useController, useFormContext } from "react-hook-form";
import {
  ErrorMessage,
  Label,
  LabelContainer,
  StyledInputLabel,
  StyledTextField,
} from "./currency-input-styles";
import { LabelTooltip } from "../label-tooltip";
import { FormControl } from "@mui/material";
import React, { FC } from "react";
import { get } from "lodash-es";

const CurrencyInput: FC<any> = ({
  label,
  placeholder,
  required,
  defaultValue,
  name,
  tooltip,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const error = get(errors, fieldName)?.message;

  const custoChange = (e) => {
    if (!e.target.value) {
      onChange(e);
    } else if (!/^(?![.0]*$)\d+(?:\.{0,1})(?:\d{1,2})?$/.test(e.target.value)) {
      return;
    } else {
      onChange(e);
    }
  };

  return (
    <FormControl fullWidth>
      {label && (
        <LabelContainer>
          <StyledInputLabel>
            <Label shrink htmlFor={name} error={!!error}>
              {label}
            </Label>
            {tooltip && <LabelTooltip tooltip={tooltip} />}
          </StyledInputLabel>
          {required && "*"}
        </LabelContainer>
      )}
      <StyledTextField
        id={name}
        value={value}
        placeholder={placeholder}
        {...rest}
        onChange={custoChange}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormControl>
  );
};

export default CurrencyInput;
