import React, { ChangeEvent } from "react";
import "./customCheckbox.scss";

type PropsType = {
  name?: string;
  children?: string;
  value?: any;
  callback?: (id: any, value: any, e: any) => void;
  id?: any;
  checked?: boolean;
  disabled?: boolean;
};

export default function CustomCheckbox({
  children = "",
  name,
  value,
  callback,
  checked,
  id,
  ...restProps
}: PropsType) {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    callback(id, e.currentTarget.checked, e);
  };

  return (
    <>
      <div className="vv_checkbox">
        <input
          id={id}
          name={name}
          type="checkbox"
          value={value}
          onChange={onChangeHandler}
          checked={checked}
          {...restProps}
        />
        <label htmlFor={id}>{children}</label>
      </div>
    </>
  );
}
