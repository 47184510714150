import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { PropTypes } from "./duck/types";
import { MySelect } from "../../components/mySelect/mySelect";

const Select: React.FC<PropTypes> = ({
  name,
  label,
  placeholder,
  tooltip,
  required,
  defaultValue,
  options,
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  return (
    <MySelect
      name={fieldName}
      text={label}
      placeholder={placeholder}
      info={tooltip}
      require={required}
      onChange={onChange}
      options={options}
      value={value}
      err={errors[fieldName]?.message.toString()}
    />
  );
};

export default Select;
