import React, { FC } from "react";

import { HeaderWrappper } from "./form-header-styles";
import { AdaptiveText } from "../../../components";
import { colors } from "../../../styles";

interface PropTypes {
  title: string;
  subtitle: string;
}

const FormHeader: FC<PropTypes> = ({ title, subtitle }) => {
  return (
    <HeaderWrappper>
      <AdaptiveText
        xl={{ size: 36, height: 46 }}
        xs={{ size: 28, height: 39 }}
        fontFamily="Greenwich SemBd"
        weight={600}
      >
        {title}
      </AdaptiveText>
      <AdaptiveText
        xl={{ size: 20, height: 23 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={700}
        color={colors.blu4}
      >
        {subtitle}
      </AdaptiveText>
    </HeaderWrappper>
  );
};

export default FormHeader;
