import React from "react";
import leftArrowIcon from "../../../assets/icons/arrow_left.svg";
import rightArrowRight from "../../../assets/icons/arrow_right.svg";
import "./arrowControl.scss";

const DEFAULT_DIRECTION = "left";

type PropsType = {
  handleClick?: any;
  direction?: any;
  className?: string;
};

export default function ArrowControl({
  handleClick = () => {},
  direction = DEFAULT_DIRECTION,
  className = "",
}: PropsType) {
  const arrowIcon =
    direction === DEFAULT_DIRECTION ? leftArrowIcon : rightArrowRight;

  return (
    <button
      className={`vv_arrow_btn ${direction} ${className}`}
      onClick={handleClick}
    >
      <img src={arrowIcon} alt="icon_arrow" />
    </button>
  );
}
