import React from "react";
import { Wrapper, Content, Subtitle, StyledButton } from "./final-block-styles";
import { Typography } from "@mui/material";
import { colors } from "../../../../styles";
import { AdaptiveText } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const FinalBlock = ({ showPopup }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  const becomeHost = (e) => {
    if (auth?.role) {
      e.preventDefault();

      showPopup();
    } else {
      navigate(`/register_modal?mode=${ROLES.SALON_ADMIN}`);
    }
  };

  return (
    <Wrapper>
      <Content>
        <Typography variant="h2" color={colors.white} align="center">
          Try hosting on VERVVEY
        </Typography>
        <Subtitle>
          <AdaptiveText
            xl={{ size: 38, height: 38 }}
            xs={{ size: 28, height: 36 }}
            weight={500}
            color={colors.white}
            align="center"
            fontFamily="Greenwich"
          >
            Join us. We’ll help you every step of the way.
          </AdaptiveText>
        </Subtitle>
        <StyledButton
          disableTouchRipple
          disableFocusRipple
          onClick={becomeHost}
          variant="contained"
        >
          Let’s go!
        </StyledButton>
      </Content>
    </Wrapper>
  );
};

export default FinalBlock;
