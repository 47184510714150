import {
  Wrapper,
  Number,
  NumberContainer,
  ControlButton,
} from "./count-select-styles";
import { AdaptiveText } from "../../../../../../components";
import { colors } from "../../../../../../styles";
import { Stack } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

const CountSelect = ({
  close,
  date,
  onCheck,
  onClear,
  schedule,
  defaultCount,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const available = schedule?.find(
      (day) => day.scheduleDay === date.toString()
    )?.availableCount;

    if (available) {
      setCount(available);
    } else {
      setCount(defaultCount);
    }
  }, []);

  const setDate = (num) => () => {
    setCount(num);
  };

  const submitCount = () => {
    onCheck(date, count);
    close();
  };

  const clearDate = () => {
    onClear(date);
    close();
  };

  return (
    <Wrapper>
      <AdaptiveText
        xl={{ size: 18, height: 21 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={700}
      >
        Selected date:
      </AdaptiveText>
      <AdaptiveText
        xl={{ size: 18, height: 21 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={700}
        color={colors.blu4}
      >
        {moment(date.toString()).format("DD/MM/YYYY")}
      </AdaptiveText>
      <AdaptiveText
        xl={{ size: 18, height: 21 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={700}
      >
        Select number of workstations
      </AdaptiveText>
      <NumberContainer>
        {Array.from(Array(10).keys()).map((key) => (
          <Number
            disabled={
              schedule?.find((day) => day.scheduleDay === date.toString())
                ?.currentCount >
                key + 1 || key + 1 > defaultCount
            }
            key={key + 1}
            disableFocusRipple
            disableTouchRipple
            onClick={setDate(key + 1)}
            active={key === count - 1}
          >
            {key + 1}
          </Number>
        ))}
      </NumberContainer>
      <Stack flexDirection="row" justifyContent="flex-end">
        <ControlButton
          variant="text"
          disableFocusRipple
          disableTouchRipple
          onClick={clearDate}
        >
          Clear
        </ControlButton>
        <ControlButton
          variant="text"
          disableFocusRipple
          disableTouchRipple
          pink
          onClick={submitCount}
        >
          Ok
        </ControlButton>
      </Stack>
    </Wrapper>
  );
};

export default CountSelect;
