import React from "react";
import { Wrapper, Content, Subtitle, StyledButton } from "./grant-block-styles";
import { AdaptiveText } from "../../../../components";
import { colors } from "../../../../styles";
import { AlreadyLogged } from "../../../../modals";
import WithDialog from "../../../../contexts/dialog/WithDialog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const GrantBlock = ({ openDialog, closeDialog }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  function applyForGrant() {
      navigate(`/grants_for_beauty_artists`);
  }

  return (
    <Wrapper>
      <Content>
        <Subtitle>
          <AdaptiveText
            xl={{ size: 38, height: 38 }}
            xs={{ size: 28, height: 36 }}
            weight={500}
            color={colors.white}
            align="center"
            fontFamily="Greenwich"
          >
            APPLY FOR THE TALENT GRANT FOR BEAUTY ARTISTS
            <br/><br/>
          </AdaptiveText>
        </Subtitle>
        <StyledButton variant="contained" onClick={applyForGrant} disableRipple>
          Apply for grant
        </StyledButton>
      </Content>
    </Wrapper>
  );
};

export default WithDialog(GrantBlock);
