import React, { useCallback, useEffect, useState } from "react";

import {
  Wrapper,
  WorkStation,
  PricingBlock,
  LabelContainer,
  StyledInputLabel,
  DeleteButton,
  Append,
} from "./workstation-rentals-styles";
import { FormHeader } from "../shared";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import { Button, Grid, InputAdornment } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { array, boolean, number, object, string } from "yup";
import { map } from "lodash-es";
import { ReactComponent as EuroIcon } from "../../../../../../assets/icons/euro-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/icons/delete-icon.svg";

import {
  DropDown,
  Radiogroup,
  Textarea,
} from "../../../../../../v2/components";
import { colors, vw } from "../../../../../../styles";
import { LabelTooltip } from "../../../../../../v2/components/label-tooltip";
import { AdaptiveText } from "../../../../../../components";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../../constants/common.consts";
import {
  createSalonFormData,
  getAllWorkstationAvailabilityOptions,
  getAllWorkstationOptions,
  updateSalonFormData,
} from "../../../../../../services/salonsApi";
import { BackButton } from "../../../../../../components/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFormattedSalonFormDataForBackendCreateMode,
  getFormattedSalonFormDataForBEUpdateMode,
} from "../../../../../../utils/utils";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { CurrencyInput } from "../../../../../../v2/components/currency-input";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import { Dispatch } from "redux";
import { AuthActions } from "../../../../../../redux/actions/authActions";

const mockCount = Array.from({ length: 10 }, (v, k) => {
  return { value: k + 1, label: `${k + 1}` };
});

const rentalDurationValues = [
  { label: "Hourly", value: "hourly", disabled: true },
  {
    label: "Full Day only (8hrs)",
    value: "day",
    disabled: false,
  },
];

const WorkstationRentals = () => {
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();
  const [workstations, setworkstations] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const { salonId } = useParams();
  const { salonFormData } = useAuth();

  useEffect(() => {
    getAllWorkstationOptions().then((data) => {
      setworkstations(
        data.map((workstation) => {
          return {
            value: workstation.workstationId,
            label: workstation.name,
          };
        })
      );
    });

    getAllWorkstationAvailabilityOptions().then((data) => {
      setAvailabilities(
        data.map((availability) => {
          return {
            value: availability.wAvailId,
            label: availability.description,
          };
        })
      );
    });
  }, []);

  const validationSchema = object({
    workstations: array().of(
      object().shape({
        workstationId: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
        count: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
        minDuration: string().default("day"),
        wAvailId: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
        pricePerDay: string().required(DEFAULT_ERROR_TEXT_MESSAGE),
        pricePerHour: number().default(0),
        additionCondition: string(),
        instantBooking: boolean().default(false),
        lang: string().default("EN"),
      })
    ),
  });

  const getDefaultValues = (data) => {
    return data.workstations.map((item) => {
      return {
        workstationId: item.workstationsTypes.workstationId,
        count: item.workstationsNumber,
        minDuration: "day",
        wAvailId: item.productAvailability.wAvailId,
        pricePerDay: item.pricePerDay,
        additionCondition: item.additionCondition,
      };
    });
  };

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: salonId
      ? { workstations: getDefaultValues(salonFormData) }
      : {
          workstations: [
            {
              workstationId: "",
              count: "",
              minDuration: "day",
              wAvailId: "",
              pricePerDay: "",
              additionCondition: "",
            },
          ],
        },
    mode: "onChange",
  });

  const filterWorkstationOptions = (id) => {
    const selectedWorkStations = map(
      formProviderProps.getValues()?.workstations?.filter((item, idx) => {
        return idx !== id;
      }),
      "workstationId"
    );

    return workstations.filter(
      (workstation) => !selectedWorkStations.includes(workstation.value)
    );
  };

  const { fields, append, remove } = useFieldArray({
    control: formProviderProps.control,
    name: "workstations",
  });

  const onAppend = () => {
    formProviderProps.trigger().then((isValid) => {
      if (isValid) {
        append({ minDuration: "day" });
      }
    });
  };

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedSalonUrl"));
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitUpdateMode = async (values) => {
    const salonData = JSON.parse(JSON.stringify(salonFormData));
    salonData.workstations = values.workstations;

    const data = await updateSalonFormData(
      getFormattedSalonFormDataForBEUpdateMode(salonData, salonId, auth),
      salonId
    );

    if (data.salon) {
      navigate(`/salon_profile/${salonId}`);
    }
  };

  const submitCreateMode = async (values) => {
    const salonData = JSON.parse(JSON.stringify(salonFormData));
    salonData.workstations = values.workstations;

    const data = await createSalonFormData(
      getFormattedSalonFormDataForBackendCreateMode(salonData, auth)
    );

    if (data.salon) {
      const currentUser = {
        ...auth,
        hostUserId: data.salon.hostUserId,
        salonId: data.salon.salonId,
      };

      navigate(`/edit_salons/workstations_schedule/${data.salon.salonId}`, {
        state: { completeCreate: true },
      });

      localStorage.setItem("user", JSON.stringify(currentUser));
      authDispatch({ type: "SET_USER", payload: currentUser });
    }
  };

  const onSubmit = (values) => {
    if (!salonId) {
      submitCreateMode(values);
    } else {
      submitUpdateMode(values);
    }
  };

  const registerField = (name) => {
    const { ref: inputRef, ...inputProps } = formProviderProps.register(name);

    return { inputRef, ...inputProps };
  };

  return (
    <FormProvider {...formProviderProps}>
      <Wrapper as="form" onSubmit={formProviderProps.handleSubmit(onSubmit)}>
        <FormHeader
          title="workstations rentals, terms & pricing"
          subtitle="Specify which workstations are available for artists to rent and share your terms & conditions"
        />
        {fields.map((item, index) => {
          return (
            <WorkStation key={item.id}>
              <Grid container spacing={{ xs: vw(40), sm: 5 }}>
                <Grid item container spacing={{ xs: vw(12), sm: 1.5 }}>
                  <Grid item xs={10} sm={12} lg={6}>
                    <DropDown
                      {...registerField(`workstations.${index}.workstationId`)}
                      options={filterWorkstationOptions(index)}
                      placeholder="Select one option"
                      label="Types of workstations to rent"
                      tooltip="Select the workstation you are willing to rent to beauty artists for coworking opportunities"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DropDown
                      {...registerField(`workstations.${index}.count`)}
                      options={mockCount}
                      placeholder="Choose from 1 to 10"
                      label="Number of workstations of this type"
                      tooltip="Specify the number of workstations of this type available for rent in the salon"
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Radiogroup
                    {...registerField(`workstations.${index}.minDuration`)}
                    label="Minimum rental duration"
                    tooltip="We always recommend to rent hourly, this provides you and the artist with more flexibility"
                    options={rentalDurationValues}
                    required
                  />
                </Grid>
                <Grid item container spacing={{ xs: vw(24), sm: 3 }}>
                  <Grid item xs={12}>
                    <PricingBlock>
                      <LabelContainer>
                        <StyledInputLabel>
                          <AdaptiveText
                            xl={{ size: 20, height: 24 }}
                            xs={{ size: 20, height: 24 }}
                            weight={700}
                            fontFamily="Greenwich"
                          >
                            Pricing, € (excl. VAT)
                          </AdaptiveText>
                          <LabelTooltip tooltip="Define your full day rent, the price is excluding VAT" />
                        </StyledInputLabel>
                        <span>*</span>
                      </LabelContainer>
                      <AdaptiveText
                        xl={{ size: 18, height: 21 }}
                        xs={{ size: 18, height: 21 }}
                        fontFamily="Greenwich"
                      >
                        Pricing covers rental of workstation & usage of
                        professional equipment by beauty artist. We charge small
                        service fee to help operate the VERVVEY platform. For
                        every booking, we charge hosts a 10% service fee. If the
                        rate in the salon profile is €10, the host will receive
                        €9 for that booking
                      </AdaptiveText>
                    </PricingBlock>
                  </Grid>
                  <Grid item container spacing={{ xs: vw(12), sm: 1.5 }}>
                    <Grid item xs={12} lg={6}>
                      <CurrencyInput
                        {...registerField(`workstations.${index}.pricePerDay`)}
                        placeholder="72"
                        label="Full Day rate"
                        tooltip="Define your hourly rate and/or full day rent as applicable"
                        required
                        type="string"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EuroIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/*<Textfield*/}
                      {/*  {...registerField(`workstations.${index}.pricePerDay`)}*/}
                      {/*  placeholder="72"*/}
                      {/*  label="Full Day rate"*/}
                      {/*  tooltip="Define your hourly rate and/or full day rent as applicable"*/}
                      {/*  required*/}
                      {/*  type="string"*/}
                      {/*  InputProps={{*/}
                      {/*    startAdornment: (*/}
                      {/*      <InputAdornment position="start">*/}
                      {/*        <EuroIcon />*/}
                      {/*      </InputAdornment>*/}
                      {/*    ),*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <DropDown
                      {...registerField(`workstations.${index}.wAvailId`)}
                      options={availabilities}
                      placeholder="Select one option"
                      label="Availability of products for booking artists"
                      tooltip="Whether artists can use salon products to deliver their services, e.g. colour, nail polish, etc"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textarea
                      {...registerField(
                        `workstations.${index}.additionCondition`
                      )}
                      label="Specify what is included in the rental fee"
                      tooltip="Additional terms & conditions of rental"
                      multiline
                      minRows={6}
                      limit={440}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {fields?.length > 1 && (
                <DeleteButton
                  disableRipple
                  aria-label="delete"
                  color="primary"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon />
                </DeleteButton>
              )}
            </WorkStation>
          );
        })}

        <Append onClick={onAppend}>
          <AdaptiveText
            xl={{ size: 28, height: 28 }}
            xs={{ size: 28, height: 28 }}
            fontFamily="Greenwich"
            weight={500}
            color={colors.pink4}
          >
            +
          </AdaptiveText>
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
            weight={700}
            color={colors.pink4}
          >
            <u>Add types of workstations to rent</u>
          </AdaptiveText>
        </Append>

        <div className={"btn_bottom_wrapper"}>
          {!salonId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <Button
                type="submit"
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </Wrapper>
    </FormProvider>
  );
};

export default WorkstationRentals;
