import React from "react";
import { Wrapper, Content, Block } from "./fees-block-styles";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as Percent } from "../../../../assets/icons/percent.svg";
import { ReactComponent as Coins } from "../../../../assets/icons/coin.svg";
import { AdaptiveText } from "../../../../components";
import { colors } from "../../../../styles";

const FeesBlock = () => {
  return (
    <Wrapper>
      <Content>
        <Typography variant="h2" color={colors.white} align="center">
          We charge small service fee to help
          <br />
          operate the VERVVEY platform
        </Typography>
        <Grid container spacing={3.5}>
          <Grid item xs={12} lg={6}>
            <Block>
              <Percent />
              <AdaptiveText
                xl={{ size: 28, height: 36 }}
                xs={{ size: 24, height: 30 }}
                weight={500}
                color={colors.white}
                align="center"
              >
                For every booking, we charge
                <br />
                hosts a 10% service fee
              </AdaptiveText>
            </Block>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Block>
              <Coins />
              <AdaptiveText
                xl={{ size: 28, height: 36 }}
                xs={{ size: 24, height: 30 }}
                weight={500}
                color={colors.white}
                align="center"
              >
                For example, if the rate in the salon profile is €10, the host
                will receive €9 for that booking
              </AdaptiveText>
            </Block>
          </Grid>
        </Grid>
      </Content>
    </Wrapper>
  );
};

export default FeesBlock;
