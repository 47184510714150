import { TableWrapper } from "./payouts-styles";

import React, { useEffect, useState } from "react";
import { PayoutDetails } from "../payout-details";
import { Table } from "../../../../components/v2/table";
import {
  geBookingDayStatuses,
  getPayments,
  getRefunds,
} from "../../../../services/api";
import { ActionComponent } from "../refunds/action-component";

const getColumns = (expand, expanded) => [
  {
    width: "740px",
    name: "Salon name",
    data: "salonName",
  },
  {
    width: "664px",
    name: "Total sum to transfer",
    data: "price",
    formatter: (item, data) => {
      const sumPrices = data?.schedules?.reduce((acc, item) => {
        return acc + Math.floor(item.priceVat * 90) / 100;
      }, 0);
      return `€ ${sumPrices || 0}`;
    },
    withoutBorder: true,
  },
  {
    width: "76px",
    data: "action-component",
    getComponent: (item) => {
      return (
        <ActionComponent
          requestId={item?.id}
          expand={expand}
          expanded={expanded}
        />
      );
    },
  },
];

const Payouts = () => {
  const [expanded, setExpanded] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    geBookingDayStatuses().then((data) => {
      setStatuses(data);
    });
  }, []);

  const expand = (item) => {
    if (expanded.includes(item)) {
      setExpanded(expanded.filter((el) => el !== item));
    } else {
      setExpanded([...expanded, item]);
    }
  };

  return (
    <TableWrapper>
      <Table
        desktopOnly
        withBorders
        columns={getColumns(expand, expanded)}
        api={getPayments}
        minWidth="1440px"
        initialExpand={setExpanded}
        expanded={expanded}
        detailed
        detailComponent={(detailData) => (
          <PayoutDetails data={detailData} statuses={statuses} />
        )}
      />
    </TableWrapper>
  );
};

export default Payouts;
