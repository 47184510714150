import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { vw } from "../../../../../styles";

const NextButton = styled(Button)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(210)};
  }
`;

export { NextButton };
