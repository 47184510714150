import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { colors, vw } from "../../styles";

interface Props {
  mw?: number;
}

const StyledDialog = styled(Dialog)<Props>`
  & .MuiPaper-root {
    max-width: ${(props) => (props.mw ? props.mw : 596)}px;
    background-color: ${colors.blu1};
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: ${vw(20)};
    }
  }
`;

const StyledDialogContent = styled.div`
  width: 100%;
  padding: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(12)};
  }
`;

export { StyledDialogContent, StyledDialog };
