import { ChangeEvent, useState } from "react";
import classes from "./customInput.module.scss";
import { ExplanationIcon } from "@components";

type PropsType = {
  text: string;
  name: string;
  type: string;
  value?: string;
  onChange?: (value: string, e: any) => void;
  placeholder?: string;
  className?: string;
  require?: boolean;
  err?: string;
  info?: string;
  defaultValue?: string;
  disabled?: boolean;
  maxLength?: any;
  pattern?: string;
};
export const CustomInput = ({
  text,
  name,
  type,
  value = "",
  onChange = () => {},
  placeholder,
  className,
  require,
  err,
  info,
  ...restProps
}: PropsType) => {
  const [focus, setFocus] = useState(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value, e);
  };

  const setLabelActive = () => setFocus(true);
  const setLabelInactive = () => setFocus(false);

  const borderColor = err
    ? "1px solid #E23600"
    : focus
    ? "1px solid #4a83da"
    : "none";

  const labelStyle = err ? "#E23600" : "#121212";

  return (
    <div className={classes.custom_input_wrapper} id={"div_"+name}>
      <div
        className={`${classes.input_group} ${className}`}
        onClick={setLabelActive}
        onBlur={setLabelInactive}
      >
        <label htmlFor={name} style={{ color: labelStyle }}>
          <div className={classes.text_icon_wrapper}>
            {text} {info !== "" && info && <ExplanationIcon text={info} />}
          </div>
          {require && <span className="req_sign">*</span>}
        </label>
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder && placeholder}
          required={require}
          style={{ border: borderColor }}
          {...restProps}
        />
      </div>
      {err && <div className={classes.err_text}>{err}</div>}
    </div>
  );
};
