import React from "react";
import logoIconBlack from "../../../assets/icons/vervvey_logo_dark.svg";
import classes from "./notificationHeader.module.scss";

const NotificationHeader = () => {
  return (
    <header className={classes.notification_header}>
      <img src={logoIconBlack} alt={"logo"} />
    </header>
  );
};

export default NotificationHeader;
