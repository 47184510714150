import classes from "./ProfessionalProfileInfo.module.scss";
import { CustomInput } from "../../../../../components/customInput/customInput";
import { useCallback, useEffect, useState } from "react";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import { CustomTextarea } from "../../../../../components/customTextarea/customTextarea";
import { MultiSelect } from "../../../../../components/multiSelect/multiSelect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { languages } from "../../../../../utils/mocks";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  getAllVibes,
  updateMasterProfileData,
} from "../../../../../services/masterApi";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import {
  getFormattedMasterProfileForBackend,
} from "../../../../../utils/utils";

import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../constants/common.consts";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import UploadProfilePhoto from "./components/UploadProfilePhoto";
import { Button, Grid } from "@mui/material";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import { getCityList } from "../../../../../services/api";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const experienceOptions = [
  { value: "1", label: "0-1 year experience" },
  { value: "2", label: "1-2 years experience" },
  { value: "5", label: "3-6 years experience" },
  { value: "9", label: "6-10 years experience" },
  { value: "10", label: "10+ years experience" },
];

const getMappedVibesOptions = (vibes) => {
  const _vibesOptions = vibes.map((vibe) => {
    return { vibeId: vibe.vibeId, value: vibe.name, label: vibe.name };
  });

  return _vibesOptions;
};

const formattedLanguagesOptions = languages.map((language) => {
  return { value: language[1], label: language[0] };
});

const DEFAULT_ERROR_STATE = {
  nickname: "",
  resume: "",
  citySelect: "",
  experienceSelect: "",
  vibeSelect: "",
  languagesMultiSelect: "",
  profilePhoto: "",
};

export const ProfessionalProfileInfo = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const [vibeOptions, setVibeOptions] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    getCityList().then((cities) => {
      setCityList(
        cities.map((city) => {
          return { value: city, label: city };
        })
      );
    });
  }, []);

  const handleInputChange = (_, e) => {
    const { name, value } = e.target;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  const submitCreateMode = async () => {
    setFormDataToStorage(masterProfileFormData);

    navigate(location, {
      state: {
        activeStep: 1,
      },
    });
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const updatedMasterProfile = await updateMasterProfileData(
        getFormattedMasterProfileForBackend(
          masterProfileFormData,
          userId,
          auth
        ),
        userId
      );

      navigate(`/profile/${userId}`);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [masterProfileFormData]);

  const isFieldsValid = () => {
    let isError = false;

    if (!masterProfileFormData?.nickname) {
      setErrs((prevState) => {
        return {
          ...prevState,
          nickname: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });
      isError = true;
    }

    if (!masterProfileFormData?.resume) {
      setErrs((prevState) => {
        return {
          ...prevState,
          resume: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData.languagesMultiSelect?.length) {
      setErrs((prevState) => {
        return {
          ...prevState,
          languagesMultiSelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData?.citySelect) {
      setErrs((prevState) => {
        return {
          ...prevState,
          citySelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData?.vibeSelect?.value) {
      setErrs((prevState) => {
        return {
          ...prevState,
          vibeSelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData.experienceSelect) {
      setErrs((prevState) => {
        return {
          ...prevState,
          experienceSelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData?.profilePhoto?.imageUrl) {
      setErrs((prevState) => {
        return {
          ...prevState,
          profilePhoto: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }


    return !isError;
  };

  useEffect(() => {

    for (const [key, value] of Object.entries(errs)) {
      if (value != DEFAULT_ERROR_STATE[key]) {
        console.log(key);
        const errorElement = document.querySelector("[id=div_"+key+"]");
        console.log(errorElement);
        if (errorElement) {
          errorElement.scrollIntoView({behavior: 'smooth'})
          return;
        }
      }
    }

  }, [errs]);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) {
      return;
    }

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  useEffect(() => {
    const fetchVibesDataOptions = async () => {
      try {
        setIsLoading(true);

        const vibes = await getAllVibes();

        setVibeOptions(getMappedVibesOptions(vibes));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchVibesDataOptions();
  }, []);

  useEffect(() => {
    // create mode
    if (!userId) {
      setFormDataToStorage(masterProfileFormData);
    }
  }, [masterProfileFormData]);

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div className={classes.personal_info_wrapper}>
      <h2 className={classes.page_title}>Professional profile</h2>
      <h3 className={classes.title}>
        Tell the world a bit about yourself: this helps the hosts and your
        clients to get to know you
      </h3>
      <form className={classes.form_wrapper} onSubmit={handleSubmit} noValidate>
        <div className={classes.top_input_wrapper}>
          <CustomInput
            text={"Which name do you want to use on the platform?"}
            name={"nickname"}
            type={"text"}
            value={masterProfileFormData.nickname}
            onChange={handleInputChange}
            className={classes.input_style}
            err={errs.nickname}
            require
            placeholder={"Eva Stark"}
            info={
              "You decide which name is displayed on the platform: name and/or surname or the name your clients know you best"
            }
          />
          <CustomTextarea
            text={
              "Give a brief summary about your services, your experience and your background"
            }
            name={"resume"}
            value={masterProfileFormData.resume}
            onChange={handleInputChange}
            err={errs.resume}
            require
            placeholder="My motivation come from Italy, one of the country's pioneer in the beauty industry. My style is based on Tony & Guy and Vidal Sassoon hairdresser's. The education I have is from Florida and the experience. I gain come from Italy in the past 5 years.
                My approach to the customers come always by open mindset and collaboration on finding the best way on having a personalized haircut"
          />
          <div className={classes.select_group}>
            <MySelect
              name="citySelect"
              text="In which city do you work most often?"
              value={masterProfileFormData.citySelect}
              options={cityList}
              onChange={handleSelectInputChange}
              placeholder="Select one option"
              require
              err={errs.citySelect}
            />
            <MySelect
              name="experienceSelect"
              text="How many years of experience do you have?"
              value={masterProfileFormData.experienceSelect}
              options={experienceOptions}
              onChange={handleSelectInputChange}
              placeholder="Select one option"
              require
              err={errs.experienceSelect}
            />
          </div>
          <div className={classes.select_group}>
            <MultiSelect
              name="languagesMultiSelect"
              div_name="languagesMultiSelect"
              text="Which language(s) do you speak?"
              options={formattedLanguagesOptions}
              value={masterProfileFormData.languagesMultiSelect}
              onChange={handleSelectInputChange}
              require
              placeholder="Select one or more options"
              err={errs.languagesMultiSelect}
            />
            <MySelect
              name="vibeSelect"
              text="What's your vibe?"
              value={masterProfileFormData?.vibeSelect?.value}
              options={vibeOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder="Select one option"
              info="This will help to find the match with the hosts with similar vibe"
              require
              err={errs.vibeSelect}
            />
          </div>
          <UploadProfilePhoto setErrs={setErrs} errs={errs} />
        </div>

        <div className={classes.btn_bottom_wrapper}>
          {!userId ? (
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <BackButton backNavigationCallback={onBack} />
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit">
                  Next
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="flex-end"
              spacing={2}
              flexDirection="row"
              flexWrap="nowrap"
            >
              <Grid item>
                <Button onClick={onCancelHandler} variant="outlined">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </form>
    </div>
  );
};
