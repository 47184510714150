import React from "react";
import { TabIndex, StyledNavLink, IconWrapper } from "./nav-tab-styles";
import { Text } from "../../../../components/text";

interface PropTypes {
  index: number;
  to: string;
  title: string;
  icon?: any;
}

const NavTab: React.FC<PropTypes> = ({ index, to, title, icon }) => {
  const Icon = icon;
  return (
    <StyledNavLink to={to}>
      <TabIndex>
        {icon ? (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        ) : (
          <Text xs={{ size: 16, height: 16 }} sm={{ size: 16, height: 16 }}>
            {index}
          </Text>
        )}
      </TabIndex>
      <Text
        xs={{ size: 18, height: 18 }}
        sm={{ size: 18, height: 18 }}
        textAlign="left"
        fontFamily="Greenwich"
      >
        {title}
      </Text>
    </StyledNavLink>
  );
};

export { NavTab };
