import React from "react";
import { Button } from "react-bootstrap";
import "./customButton.scss";

// TODO: Replace all bootstrap buttons with CustomButton
export default function CustomButton(props) {
  return (
    <Button className="vv_button" {...props}>
      {props.children}
    </Button>
  );
}
