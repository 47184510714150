import styled from "@emotion/styled";
import { vw } from "../../../../../styles";

const SlideWrapper = styled.div`
  padding: 0 27px;
`;

const Image = styled.img`
  margin-bottom: 20px;
  width: 100%;
  max-width: 408px;
  height: 248px;
  border-radius: 20px;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(20)};
    height: ${vw(268)};
    border-radius: ${vw(20)};
  }
`;

export { SlideWrapper, Image };
