import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { vw } from "../../styles";
import { TabIndex } from "../profile/components/nav-tab/nav-tab-styles";
import { Text } from "../../components/text";
import {
  TabLabel,
  StyledTab,
  Wrapper,
  TabsWrapper,
  NavigationTabs,
} from "./booking-styles";
import { Details, Selection } from "./components";
import { useNavigate, useParams } from "react-router-dom";
import { getSalonProfileData } from "../../services/salonsApi";

const tabs = [
  {
    idx: 1,
    title: "Time and venue selection",
  },
  {
    idx: 2,
    title: "Booking details confirmation",
  },
];

const Booking = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"));
  const [tabValue, setTabValue] = useState(1);
  const [salonData, setSalonData] = useState(null);
  const { salonId } = useParams();
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState<Record<string, any>>({});

  const updateData = (data) => {
    setBookingData(data);
  };

  useEffect(() => {
    if (!salonId) {
      navigate("/salon_catalog");
    } else {
      getSalonProfileData(salonId)
        .then(({ salon }) => {
          setSalonData(salon);
        })
        .catch(() => {
          navigate("/salon_catalog");
        });
    }
  }, []);

  const handleChangeTab = (e, value) => {
    setTabValue(value);
  };

  const setTab = (val) => {
    setTabValue(val);
  };

  return (
    <Wrapper>
      <Grid
        container
        direction={isDesktop ? "column" : "row"}
        spacing={{ xs: vw(42), sm: 8 }}
        wrap="nowrap"
      >
        <TabsWrapper item>
          <NavigationTabs
            value={tabValue}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            {tabs.map((tab) => {
              return (
                <StyledTab
                  disableTouchRipple
                  disableFocusRipple
                  value={tab.idx}
                  key={tab.idx}
                  disabled={tab.idx === 2 && !bookingData?.days?.length}
                  label={
                    <TabLabel active={tabValue === tab.idx}>
                      <TabIndex>
                        <Text
                          xs={{ size: 16, height: 16 }}
                          sm={{ size: 16, height: 16 }}
                        >
                          {tab.idx}
                        </Text>
                      </TabIndex>
                      <Text
                        xs={{ size: 18, height: 18 }}
                        sm={{ size: 18, height: 18 }}
                        textAlign="left"
                        fontFamily="Greenwich"
                      >
                        {tab.title}
                      </Text>
                    </TabLabel>
                  }
                />
              );
            })}
          </NavigationTabs>
        </TabsWrapper>
        <Grid item xs={12}>
          {tabValue === 1 && (
            <Selection
              setTab={setTab}
              salonData={salonData}
              updateData={updateData}
              bookingData={bookingData}
            />
          )}
          {tabValue === 2 && (
            <Details
              setTab={setTab}
              reset={() => {
                setBookingData({});
              }}
              salonData={salonData}
              bookingData={bookingData}
            />
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Booking;
