import React, { FC, useRef } from "react";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import {
  CalendarSelect,
  ExpandIcon,
  InputWrapper,
  PrevButton,
  Row,
  SelectWrapper,
  StyledDatePicker,
  StyledFormControl,
  StyledIconButton,
} from "./table-datepicker-styles";
import { IconButton } from "@mui/material";
import moment from "moment";
import { MdOutlineClose } from "react-icons/md";

const TableDatepicker: FC<any> = ({
  startDate,
  setStartDate,
  placeHolder,
  withReset,
}) => {
  const years = range(2020, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const inputRef = useRef(null);

  const onChangeHandler = (val) => {
    setStartDate(val);
  };

  return (
    <StyledFormControl fullWidth>
      <InputWrapper>
        <StyledDatePicker
          dateFormat="dd/MM/yyyy"
          placeholderText={placeHolder}
          ref={inputRef}
          value={startDate ? new Date(startDate) : null}
          selected={startDate ? new Date(startDate) : null}
          onChange={onChangeHandler}
          autoComplete="off"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Row>
              <PrevButton
                aria-label="prev"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                disableRipple
              >
                <ExpandIcon />
              </PrevButton>
              <SelectWrapper>
                <CalendarSelect
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) => {
                    changeMonth(months.indexOf(value));
                    setStartDate(
                      moment(date).set(
                        "month",
                        months.findIndex((item) => item === value)
                      )
                    );
                  }}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CalendarSelect>
              </SelectWrapper>
              <SelectWrapper>
                <CalendarSelect
                  value={getYear(date)}
                  onChange={({ target: { value } }) => {
                    changeYear(value);
                    setStartDate(moment(date).set("year", +value));
                  }}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CalendarSelect>
              </SelectWrapper>
              <IconButton
                aria-label="prev"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                disableRipple
              >
                <ExpandIcon />
              </IconButton>
            </Row>
          )}
        />
        {withReset && startDate && (
          <StyledIconButton
            disableRipple
            onClick={() => {
              setStartDate(null);
            }}
          >
            <MdOutlineClose />
          </StyledIconButton>
        )}
      </InputWrapper>
    </StyledFormControl>
  );
};

export default TableDatepicker;
