import { AdaptiveText } from "../../components";
import React, { useEffect } from "react";
import { Wrapper, ButtonContainer, IconBlock } from "./canceled-payment-styles";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Ban } from "../../assets/icons/ban.svg";

const CanceledPayment = ({ okCallback }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.delete("payment");
    setSearchParams(searchParams);
  }, []);

  return (
    <Wrapper flexDirection="column">
      <IconBlock>
        <Ban />
      </IconBlock>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={600}
        fontFamily="Greenwich SemBd"
        align="center"
      >
        Payment system has rejected your
        <br /> payment. Please contact us at <br />
        <a href="mailto:hello@vervvey.com">hello@vervvey.com</a> or&nbsp;
        <a href="tel:+31 20 890 5527">+31 20 890 5527</a>
      </AdaptiveText>

      <ButtonContainer>
        <Button
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
          OK
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default CanceledPayment;
