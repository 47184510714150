import React, { useRef, useState } from "react";
import TableHead from "@mui/material/TableHead";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { Box, TableCell, TableRow, TableSortLabel } from "@mui/material";
import { CustomDropdown } from "../dropdown/customDropdown";
import arrow_list from "./../../../../assets/icons/arrow_list_down.svg";
import classes from "./TableHeadContent.module.scss";
import { visuallyHidden } from "@mui/utils";
import { Data } from "../../CustomTable";

const ArrowSortIcon = ({ statusListShow }) => {
  return (
    <img
      src={arrow_list}
      alt={"show"}
      style={{ transform: statusListShow && "rotate(180deg)" }}
    />
  );
};

export const TableHeadContent = ({
  order,
  orderBy,
  onRequestSort,
  statusSort,
  setStatusSort,
  columnCells,
  setPage,
}: any) => {
  const [statusListShow, setStatusListShow] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setStatusListShow(false));

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ borderBottom: "2px solid #e6f0ff" }}>
        {columnCells.map((headCell) => {
          if (headCell.id === "status") {
            return (
              <TableCell
                ref={ref}
                key={headCell.id}
                className={classes.status_wrapper}
                sx={{
                  width: headCell.width,
                  color: "#8A8383",
                  fontSize: "14px",
                  fontFamily: "Greenwich",
                  fontStyle: "normal",
                }}
              >
                <div onClick={() => setStatusListShow(!statusListShow)}>
                  <span>Status</span>
                  <ArrowSortIcon statusListShow={statusListShow} />
                </div>
                {statusListShow && (
                  <CustomDropdown
                    statusSort={statusSort}
                    setStatusSort={setStatusSort}
                    setPage={setPage}
                  />
                )}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                width: headCell.width,
                color: "#8A8383",
                fontSize: "14px",
                fontFamily: "Greenwich",
                fontStyle: "normal",
              }}
            >
              {headCell.isSortingActive ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  )}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
