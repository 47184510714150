import styled from "@emotion/styled";
import { Select } from "@mui/material";
import { colors, vw } from "../../../styles";

interface Props {
  error?: boolean;
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(20)};
    line-height: ${vw(20)};
  }
`;

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    border-radius: 8px;
    font-family: Greenwich, serif;
    font-size: 20px;
    line-height: 20px;
    height: 60px;
    color: ${colors.accent};

    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: ${vw(8)};
      font-size: ${vw(20)};
      line-height: ${vw(20)};
      height: ${vw(60)};

      & textarea {
        min-height: ${vw(20)};
      }

      & input {
        height: ${vw(20)};
      }
    }

    svg {
      position: absolute;
      top: 14px;
      width: 32px;
      height: 32px;

      path {
        fill: ${colors.pink4};
      }

      ${(props) => props.theme.breakpoints.down("sm")} {
        top: ${vw(14)};
        width: ${vw(32)};
        height: ${vw(32)};
      }
    }

    fieldset {
      top: 0;
      border: none;

      legend {
        display: none;
      }
    }

    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.blu4};
      }
    }
  }
`;

const StyledInputLabel = styled.div<Props>`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Greenwich, serif;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => (props.error ? colors.red : colors.accent)};
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(8)};
    gap: ${vw(10)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
  font-family: Greenwich, serif;
  color: ${colors.red};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(4)};
    font-size: ${vw(14)};
    line-height: ${vw(16)};
  }
`;

export { StyledSelect, LabelContainer, StyledInputLabel, ErrorMessage };
