import styled from "@emotion/styled";

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  width: ${(props) => (props.width ? `${props.width}px` : "260px")};
  min-width: 260px;
`;

const Title = styled.div<any>`
  width: ${(props) => (props.width ? `${props.width}px` : "160px")};
`;

export { Wrapper, Title };
