import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { PropTypes } from "./duck/types";
import { CustomInput } from "../../components/customInput/customInput";

const TextInput: React.FC<PropTypes> = ({
  name,
  label,
  placeholder,
  tooltip,
  required,
  type,
  defaultValue,
  isDisabled,
  height,
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  return (
    <CustomInput
      name={fieldName}
      text={label}
      type={type}
      value={value || ""}
      onChange={onChange}
      require={required}
      disabled={isDisabled}
      placeholder={placeholder}
      info={tooltip}
      err={errors[fieldName]?.message.toString()}
    />
  );
};

export default TextInput;
