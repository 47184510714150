import React from "react";
import { Wrapper, Title } from "./questions-block-styles";
import { Link, Typography } from "@mui/material";
import { colors } from "../../../../styles";
import { Question } from "../../../../components";

const questions = [
  {
    headerText: "Who can apply for VERVVEY Talent Grant?",
    title: (
        <Typography>
            The Talent Program offers individual grants to talented and ambitious beauty artists: hairstylists, barbers, nail technicians, estheticians, make-up artists, massage therapists, tattoo artists.
        </Typography>
    ),
  },
  {
    headerText: "Can recently graduated beauty artists apply?",
    title: (
      <Typography>
          The Talent Program has fits to various phases in beauty artist’s professional careers, it’s great for artists who have recently graduated and for more experienced artists, who have already conducted several years of professional experience and are looking for more independence. There is no min requirement to the number of years of working experience.
      </Typography>
    ),
  },
  {
      headerText: "What is the application deadline for VERVVEY Talent Grant?",
      title: (
            <Typography>
                Applications can be submitted at any time of the year for the next calendar year, using VERVVEY online registration system.<br/><br/>
                You must first create an account and make your online profile in order to submit your application.
            </Typography>
        ),
    },
    {
        headerText: "What is the process of assessing grant applications?",
        title: (
            <Typography>
                All applications are first checked by VERVVEY team to ensure they are complete. If so, the application is then submitted to the committee that advises on awarding VERVVEY Talent Program grants.
                <br/><br/>
                Because the total amount available is limited, it may unfortunately not be possible for all positive advisories to be honoured. If this is the case, we will ask the advisory committee to consider the applications against one another and indicate an order of priority. Here, we will take into account Diversity & Inclusion in order to ensure a representative and multifaceted range of beauty artists awarded.
                <br/><br/>
                All awarded artists will be published online with assessments and advisory texts following the decisions.
            </Typography>
        ),
    },
    {
        headerText: "Any other questions not covered here?",
        title: (
            <Typography>
                We’d love to hear from you. Reach us at <a href="mailto:lana@vervvey.com">lana@vervvey.com</a>
            </Typography>
        ),
    },

];

const QuestionsBlock = () => {
  const [expanded, setExpanded] = React.useState<number | null>(null);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    };

  return (
    <Wrapper>
      <Title>
        <Typography variant="h2" textAlign="center">
          Questions about our TALENT PROGRAM?
        </Typography>
      </Title>

      {questions.map((question, idx) => (
        <Question
          key={question.headerText}
          title={question.headerText}
          answer={question.title}
          expanded={expanded === idx}
          handleChange={handleChange(idx)}
        />
      ))}
    </Wrapper>
  );
};

export default QuestionsBlock;
