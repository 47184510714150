import classes from "./salonConcepts.module.scss";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import { ExplanationIcon } from "@components";
import { MultiSelect } from "../../../../../components/multiSelect/multiSelect";
import { languages } from "../../../../../utils/mocks";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import { CustomTextarea } from "../../../../../components/customTextarea/customTextarea";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import {
  DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
  DEFAULT_ERROR_TEXT_MESSAGE,
  DEFAULT_FILE_SIZE_LIMIT_KB,
} from "../../../../../constants/common.consts";
import setSalonFormDataToStorage from "../../Stepper/services/setSalonFormDataToStorage";
import default_upload_photo from "../../../../../assets/images/default_upload_photo.webp";
import UploadMainPhoto from "./components/UploadMainPhoto";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import {
  addSalonImage,
  addSalonImageCreateMode,
  deleteSalonImage,
  updateSalonFormData,
} from "../../../../../services/salonsApi";
import { getFormattedSalonFormDataForBEUpdateMode } from "../../../../../utils/utils";
import { Button } from "@mui/material";
import { useFetchSalonConceptOptions } from "./hooks/useFetchSalonConceptOptions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const formattedLanguagesOptions = languages.map((language) => {
  return { value: language[1], label: language[0] };
});

const MAX_GALLERY_PORTFOLIO_IMAGES = 9;
const MIN_PORTFOLIO_IMAGES = 1;

const DEFAULT_ERROR_STATE = {
  vibe: "",
  languagesMultiSelect: "",
  salonDescription: "",
  services: "",
  teamwork: "",
  branding: "",
  mainPhoto: "",
  galleryPhoto: "",
  workImage: "",
};

export const SalonConcept = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const [error, setError] = useState(null);
  const {
    isLoading,
    brandOptions,
    senseOfTeamworkOptions,
    vibeOptions,
    servicesOptions,
  } = useFetchSalonConceptOptions();

  const { salonFormData, setSalonFormData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { salonId } = useParams();

  const addFileImage = async (fileImage) => {
    try {
      const _formData = new FormData();

      // Update the formData object
      _formData.append("photo", fileImage, fileImage.name);
      _formData.append("type", "SALON-GALLERY-PHOTO");
      _formData.append("lang", "EN");

      let _galleryWorks = [];

      // create mode
      if (!auth?.salonId) {
        const responseImageData = await addSalonImageCreateMode(_formData);

        _galleryWorks = [
          ...salonFormData.galleryWorks,
          {
            imageId: responseImageData.imageId,
            imageUrl: responseImageData.imageUrl,
            ...responseImageData,
          },
        ];

        setSalonFormDataToStorage({
          ...salonFormData,
          galleryWorks: _galleryWorks,
        });
      }

      // update mode
      if (salonId) {
        const responseImageData = await addSalonImage(_formData, salonId);

        _galleryWorks = [
          ...salonFormData.galleryWorks,
          {
            imageId: responseImageData.imageId,
            imageUrl: responseImageData.imageUrl,
            ...responseImageData,
          },
        ];
      }

      setSalonFormData({
        ...salonFormData,
        galleryWorks: _galleryWorks,
      });

      setErrs((prevState) => {
        return {
          ...prevState,
          workImage: "",
        };
      });
    } catch (error) {
      setError(error);
    }
  };

  const onUploadImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const fileImage = e.target.files[0];

      const fileSize = fileImage.size;
      const fileSizeInKB = fileSize / 1024; // this would be in kilobytes defaults to bytes

      if (fileSizeInKB < DEFAULT_FILE_SIZE_LIMIT_KB) {
        addFileImage(fileImage);
      } else {
        setErrs((prevState) => {
          return {
            ...prevState,
            workImage: DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
          };
        });
      }
    }
  };

  const deleteImageUpdateMode = async (imageId) => {
    try {
      setError(null);

      const deletedImageData = await deleteSalonImage(salonId, imageId);
    } catch (error) {
      setError(error);
    }
  };

  const deleteImageCreateMode = async (imageId) => {
    try {
      setError(null);

      const deletedImageData = await deleteImageCreateMode(imageId);
    } catch (error) {
      setError(error);
    }
  };

  const deletePhotoHandler = async (imageId: number) => {
    // create mode
    if (!auth?.salonId) {
      const deleteImageFromStorage = () => {
        setSalonFormDataToStorage({
          ...salonFormData,
          galleryWorks: salonFormData.galleryWorks.filter(
            (el) => el.imageId !== imageId
          ),
        });
      };

      deleteImageFromStorage();

      deleteImageUpdateMode(imageId);
    }

    setSalonFormData({
      ...salonFormData,
      galleryWorks: salonFormData.galleryWorks.filter(
        (el) => el.imageId !== imageId
      ),
    });

    if (salonId) {
      deleteImageUpdateMode(imageId);
    }
  };

  const handleInputChange = (_, e) => {
    const { name, value } = e.target;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };
  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [salonFormData]);

  const isFieldsValid = () => {
    let isError = false;

    if (!salonFormData.vibe) {
      setErrs((prevState) => {
        return { ...prevState, vibe: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.languagesMultiSelect?.length) {
      setErrs((prevState) => {
        return {
          ...prevState,
          languagesMultiSelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!salonFormData.salonDescription) {
      setErrs((prevState) => {
        return { ...prevState, salonDescription: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.services?.length) {
      setErrs((prevState) => {
        return { ...prevState, services: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.teamwork?.value) {
      setErrs((prevState) => {
        return { ...prevState, teamwork: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData.branding) {
      setErrs((prevState) => {
        return { ...prevState, branding: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData?.mainPhoto?.imageUrl) {
      setErrs((prevState) => {
        return { ...prevState, mainPhoto: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!salonFormData?.galleryWorks?.length) {
      setErrs((prevState) => {
        return { ...prevState, galleryPhoto: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    return !isError;
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const data = await updateSalonFormData(
        getFormattedSalonFormDataForBEUpdateMode(salonFormData, salonId, auth),
        salonId
      );

      navigate(`/salon_profile/${salonId}`);
    } catch (error) {
      setError(error);
    }
  };

  const submitCreateMode = async () => {
    setSalonFormDataToStorage(salonFormData);

    navigate(location, {
      state: {
        activeStep: 3,
      },
    });
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!salonId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }
  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedSalonUrl"));
  };

  return (
    <div>
      <h2 className={`page_main_title`}>Salon concept</h2>
      <h3 className={`page_blue_subtitle`}>
        Tell the community a bit about your salon: this helps the beauty artists
        to get to know your concept better
      </h3>
      <form onSubmit={handleSubmit} noValidate>
        <div className={classes.images_wrapper}>
          <div className={classes.images_header}>
            <div className={classes.title}>Salon photos</div>
            <div>
              <ExplanationIcon text="Create here a diverse portfolio of up to 9 photos of your salon, min 3 required, including workstation(s), welcome area and anything else, that makes your place special. The best listings have photos of the following areas:  Storefront, Beverage Station, Color Area, Dryer Area, Chair or Suite, Dryer Station, Break Room, Shampoo Area, Waiting Area, Bathroom. Professional photos are not required, but do help your listing." />
            </div>
            <div className={classes.req_sign}>*</div>
          </div>
          <div className={classes.flex_images_wrapper}>
            <UploadMainPhoto setErrs={setErrs} errs={errs.mainPhoto} />

            <div className={classes.photo_gallery_wrapper}>
              <div className={classes.gallery_title}>Photo gallery:</div>
              <div className={classes.gallery_cards}>
                {salonFormData.galleryWorks?.map((el) => {
                  return (
                    <div key={el.imageId} className={classes.photo_wrapper}>
                      <img src={el.imageUrl} alt={"vv-photo"} />
                      <button
                        type="button"
                        onClick={() => deletePhotoHandler(el.imageId)}
                      >
                        delete photo
                      </button>
                    </div>
                  );
                })}
                {salonFormData?.galleryWorks?.length <
                  MAX_GALLERY_PORTFOLIO_IMAGES && (
                  <div className={classes.upload_wrapper}>
                    <div className={classes.camera_wrapper}>
                      <img src={default_upload_photo} alt={"vv_file"} />
                    </div>
                    <div className={classes.upload_btn_wrapper}>
                      <label className={classes.upload_label}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={onUploadImageHandler}
                          onClick={(event: any) => {
                            event.target.value = null;
                          }}
                        />
                        Upload photo
                      </label>
                    </div>
                  </div>
                )}
              </div>
              {errs.galleryPhoto && (
                <div className={classes.gallery_photo_err}>
                  {errs.galleryPhoto}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`form_group`}>
          <div className={`inputs_group`}>
            <MySelect
              name="vibe"
              text="Salon vibe"
              value={salonFormData?.vibe?.value}
              options={vibeOptions}
              onChange={(value, actionMeta, newValue) =>
                setSalonFormData({
                  ...salonFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder="Select one option"
              require
              info="This will help to find the match with the beauty artists with similar vibe"
              err={errs.vibe}
            />
            <MultiSelect
              name="languagesMultiSelect"
              text="Salon language(s)"
              options={formattedLanguagesOptions}
              value={salonFormData.languagesMultiSelect}
              onChange={handleSelectInputChange}
              require
              info={
                "Which language(s) are most spoken in the salon on daily basis?"
              }
              placeholder="Select one or more options"
              err={errs.languagesMultiSelect}
            />
          </div>
          <div className={`inputs_group`}>
            <CustomTextarea
              text={"Salon profile description"}
              name={"salonDescription"}
              value={salonFormData.salonDescription}
              onChange={handleInputChange}
              require
              maxLength={750}
              info={
                "Provide salon description with the summary of your principles and background for the beauty artists to understand better your concept. Be specific and entice professionals to book your listing: how many stations, the ambience, how many chairs in the reception area, how is the lighting, etc."
              }
              placeholder="Opened in 2004, Salon has become a neighborhood pillar and a destination for those in pursuit of modern functional hair.  Located in the heart of Williamsburg, Salon is home to a collective of talented, trained, and educated individuals by way of industry heavyweights including Bumble and Bumble, Vidal Sassoon, Paul Mitchell, and Garren. Functionality and a client’s individual style is never compromised.  Genuine communication combined with refined skills, techniques, and cultivated aesthetics turn out looks with taste and precision. Salon is a curated and welcoming ensemble of down-to-earth progressives and visionaries, but at heart…we’re all just hair nerds!"
              err={errs.salonDescription}
            />
          </div>
          <div className={`inputs_group`}>
            <MultiSelect
              name="services"
              text="Salon services"
              value={salonFormData?.services}
              options={servicesOptions}
              onChange={(value, actionMeta) =>
                setSalonFormData({
                  ...salonFormData,
                  [actionMeta.name]: value,
                })
              }
              require
              info={"Specify which service(s) you provide in the salon"}
              placeholder="Select one or more options"
              err={errs.services}
            />
          </div>
          <div className={`inputs_group`}>
            <MySelect
              name="teamwork"
              text="Sense of teamwork"
              value={salonFormData?.teamwork?.value}
              options={senseOfTeamworkOptions}
              onChange={(value, actionMeta, newValue) =>
                setSalonFormData({
                  ...salonFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder="Select one option"
              require
              info="How strong is your expectation for the sense of the teamwork in the salon?"
              err={errs.teamwork}
            />
          </div>
          <div className={`inputs_group`}>
            <MySelect
              name="branding"
              text="Salon branding "
              value={salonFormData?.branding}
              options={brandOptions}
              onChange={(value, actionMeta) =>
                setSalonFormData({
                  ...salonFormData,
                  [actionMeta.name]: value,
                })
              }
              require
              info={
                "Specify if there is any specific branding collaboration in the salon"
              }
              placeholder="Select one option"
              err={errs.branding}
            />
          </div>
        </div>
        <div className={"btn_bottom_wrapper"}>
          {!salonId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
