import styled from "@emotion/styled";
import { vw } from "../../../../../../styles";
import { Button, Grid } from "@mui/material";

const Row = styled(Grid)`
  max-width: 504px;
`;

const FormButton = styled(Button)`
  width: 311px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 50%;
  }
`;

const ButtonsWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: ${vw(40)};
    margin-bottom: ${vw(20)};
    gap: ${vw(16)};
  }
`;

export { FormButton, ButtonsWrapper, Row };
