import { Grid, Tab, useMediaQuery, useTheme } from "@mui/material";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import React, { useEffect, useMemo, useState } from "react";
import { getSalonProfileData } from "../../../../services/salonsApi";
import { getFormattedSalonFormData } from "../../../../utils/utils";
import CustomSpinner from "../../../../components/spinner/customSpinner";
import {
  EditSalonWrapper,
  NavigationTabs,
  TabsWrapper,
} from "./edit-schedules-styles";
import { ROLES } from "../../../../constants/common.consts";
import { NavTab } from "../../../profile/components/nav-tab";
import { Divider } from "../../../profile/EditProfileForm/UpdateModeProfile/EditProfile-styles";
import { ReactComponent as Password } from "../../../../assets/icons/password.svg";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { vw } from "../../../../styles";
import { Payouts, Refunds } from "../../../accounting/components";
import {
  Places,
  Schedule,
} from "../../../../v2/pages/workstations-management/pages";

const tabs = [
  {
    to: "workstations_rentals",
    title: "Workstations rentals, terms & pricing",
  },
  {
    to: "workstations_schedule",
    title: "Availability schedule",
  },
];

const EditSchedules = () => {
  const { auth } = useSelector((state: AppState) => state);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();
  const { setSalonFormData } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(location.pathname.split("/").pop());
  const handleChangeTab = (e, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salonProfileFormData = await getSalonProfileData(auth.salonId);

        setSalonFormData(getFormattedSalonFormData(salonProfileFormData));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <CustomSpinner />;
  }

  return (
    <EditSalonWrapper>
      <Grid
        container
        direction={isDesktop ? "column" : "row"}
        spacing={{ xs: vw(42), sm: 7 }}
        wrap="nowrap"
      >
        <Grid item>
          <TabsWrapper>
            <NavigationTabs
              value={tabValue}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {tabs.map((tab, idx) => {
                if (
                  auth?.role !== ROLES.SUPER_ADMIN &&
                  tab.to === "salon_administrator"
                ) {
                  return null;
                }

                return (
                  <Tab
                    disableTouchRipple
                    disableFocusRipple
                    value={tab.to}
                    key={tab.to}
                    label={
                      <NavTab to={tab.to} title={tab.title} index={idx + 1} />
                    }
                  />
                );
              })}
            </NavigationTabs>
          </TabsWrapper>
        </Grid>
        <Grid item xs={12}>
          <Routes>
            <Route path="workstations_rentals" element={<Places />} />
            <Route path="workstations_schedule" element={<Schedule />} />
          </Routes>
        </Grid>
      </Grid>
    </EditSalonWrapper>
  );
};

export default EditSchedules;
