import styled from "@emotion/styled";
import { alpha } from "@mui/material";
import bg from "../../../../assets/images/for_salon/salons_bg.webp";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  margin-left: -80px;
  width: calc(100% + 160px);
  background: linear-gradient(
      0deg,
      ${alpha(colors.accent, 0.5)},
      ${alpha(colors.accent, 0.5)}
    ),
    url(${bg});
  background-size: cover;

  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-left: -60px;
    width: calc(100% + 120px);
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-left: -36px;
    width: calc(100% + 72px);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: ${vw(-20)};
    width: calc(100% + ${vw(40)});
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 84px 80px;
  gap: 60px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 84px 60px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 60px 36px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("60 20")};
    gap: ${vw(32)};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: 274px;
  padding: 0 30px;

  background-color: ${alpha(colors.white, 0.22)};
  border-radius: 12px;
  border: 1px solid ${colors.white};

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(254)};
    gap: ${vw(16)};
    padding: ${vw("0 20")};
  }

  & svg {
    width: 70px;
    height: 70px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(70)};
      height: ${vw(70)};
    }
  }
`;

export { Wrapper, Content, Block };
