import styled from "@emotion/styled";
import { alpha, Button } from "@mui/material";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  width: 100%;
  height: 587px;

  ${(props) => props.theme.breakpoints.down("md")} {
    height: 750px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(630)};
  }

  &:before {
    content: "";
    z-index: -999;
    position: absolute;
    left: 0;
    width: 100%;
    height: 587px;
    background: linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%);

    ${(props) => props.theme.breakpoints.down("md")} {
      height: 750px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      height: ${vw(630)};
    }
  }

  &:after {
    content: "";
    z-index: -998;
    position: absolute;
    left: 0;
    top: 90px;
    width: 100%;
    height: 587px;
    background: linear-gradient(
      114deg,
      transparent 0%,
      transparent 82%,
      ${alpha("#5553a3", 0.5)} 82%,
      ${alpha("#5553a3", 0.5)} 100%
    );

    ${(props) => props.theme.breakpoints.down("lg")} {
      background: linear-gradient(
        108deg,
        transparent 0%,
        transparent 84%,
        ${alpha("#5553a3", 0.5)} 84%,
        ${alpha("#5553a3", 0.5)} 100%
      );
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      height: 750px;
      top: 70px;

      background: linear-gradient(
        114deg,
        transparent 0%,
        transparent 64%,
        ${alpha("#5553a3", 0.5)} 64%,
        ${alpha("#5553a3", 0.5)} 100%
      );
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      top: ${vw(70)};
      height: ${vw(630)};
    }
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 42px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    gap: 24px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(24)};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 735px;
  margin-top: 95px;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    margin-top: 30px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-right: 0;
    margin-top: ${vw(30)};
  }
`;

const HeaderImages = styled.div`
  margin-top: 95px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 48px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(48)};
  }
`;

const StyledButton = styled(Button)`
  width: 336px;
  margin-top: 44px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 24px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-top: ${vw(24)};
  }
`;

const TopImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
  margin-bottom: 14px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    gap: 8px;
    margin-bottom: 8px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(8)};
    margin-bottom: ${vw(8)};
  }
`;

interface BlockProps {
  color: string;
  hidden?: boolean;
}

const Block = styled.div<BlockProps>`
  display: block;
  border-radius: 8px;
  width: 68px;
  height: 68px;
  background-color: ${(props) => props.color};

  ${(props) => props.theme.breakpoints.down("lg")} {
    display: ${(props) => (props.hidden ? "none" : "block")};
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 48px;
    height: 48px;
    display: block;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(48)};
    height: ${vw(48)};
    border-radius: ${vw(8)};
  }
`;

const BottomImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    gap: 8px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(8)};
  }
`;

const LittleImage = styled.img`
  border-radius: 8px;
  width: 68px;
  height: 68px;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 48px;
    height: 48px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(48)};
    height: ${vw(48)};
    border-radius: ${vw(8)};
  }
`;

const BigImage = styled.img`
  width: 232px;
  height: 232px;
  border-radius: 12px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 160px;
    height: 160px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 120px;
    height: 120px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(160)};
    height: ${vw(160)};
    border-radius: ${vw(12)};
  }
`;

export {
  Wrapper,
  HeaderWrapper,
  TitleWrapper,
  HeaderImages,
  StyledButton,
  TopImages,
  Block,
  BottomImages,
  BigImage,
  LittleImage,
};
