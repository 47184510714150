import { Record } from "../record";
import {
  Wrapper,
  DetailBlock,
  DetailMain,
  DetailRow,
  Status,
  SelectWrapper,
  CommentWrapper,
} from "./details-styles";
import { CommentInput } from "../comment-input";
import { DayStatusSelect } from "../../../../components";

const Details = ({ data, statuses }) => {
  return (
    <Wrapper>
      <DetailBlock>
        <DetailMain>
          <Record
            w={100}
            totalW={320}
            label="Payment ID:"
            value={data?.paymentId}
          />
          <Record
            label="Total sum paid (incl. fee):"
            value={`€ ${data?.priceVat || 0}`}
          />
          <CommentWrapper>
            <CommentInput defaultValue={data?.comment || ""} data={data} />
          </CommentWrapper>
        </DetailMain>
        {data?.schedules?.map((item) => (
          <DetailRow key={item.id}>
            <Record w={100} label="Booking date:" value={item?.rentDate} />
            <Record
              label="Paid per day (incl. fee):"
              value={`€ ${item?.priceVat || 0}`}
            />
            <Status>
              <SelectWrapper>
                <DayStatusSelect
                  defaultValue={item?.status}
                  statuses={statuses}
                  data={item}
                />
              </SelectWrapper>
            </Status>
          </DetailRow>
        ))}
      </DetailBlock>
    </Wrapper>
  );
};

export default Details;
