import styled from "@emotion/styled";
import { alpha, Button } from "@mui/material";
import bg from "../../../../assets/images/grant-for-BEAUTY-ARTISTS.webp";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  margin-left: -80px;
  width: calc(100% + 160px);
  background: linear-gradient(
      0deg,
      ${alpha(colors.accent, 0.5)},
      ${alpha(colors.accent, 0.5)}
    ),
    url(${bg});
  background-size: cover;

  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-left: -60px;
    width: calc(100% + 120px);
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-left: -36px;
    width: calc(100% + 72px);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: ${vw(-20)};
    width: calc(100% + ${vw(40)});
  }
`;

const Content = styled.div`
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 80px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 0 60px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 0 36px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 20")};
  }
`;

const Subtitle = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  margin-top: 60px;
  width: 414px;
`;

export { Wrapper, Content, Subtitle, StyledButton };
