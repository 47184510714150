import { Button, Stack } from "@mui/material";
import { FormHeader } from "../../../../components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import {
  deleteSalonWorkstation,
  getAllWorkstationAvailabilityOptions,
  getAllWorkstationOptions,
  getSalonsWorkstations,
} from "../../../../../services/salonsApi";
import {
  WorkStation,
  WorkStationInfo,
  WorkstationControls,
  WorkStationRow,
  WorkStationTitle,
  ActionButton,
  Append,
} from "./places-styles";
import { AdaptiveText } from "../../../../../components";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon.svg";
import { colors } from "../../../../../styles";
import WithDialog from "../../../../../contexts/dialog/WithDialog";
import { WorkplaceForm } from "./workplace-form";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ButtonBlock } from "../schedule/shedule-styles";
import { DeleteReject } from "@modals";

const Places = ({ openDialog, closeDialog }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSelector((state: AppState) => state);
  const { salonId } = useParams();
  const { salonFormData, setSalonFormData } = useAuth();

  const [loading, setLoading] = useState(true);
  const [workStations, setWorkStations] = useState([]);
  const [workStationTypes, setWorkstationTypes] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);

  const isEditSalon = location.pathname.includes("edit_salons");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          workStationsResponse,
          availabilityOptionsResponse,
          workstationOptionsResponse,
        ] = await Promise.all([
          getSalonsWorkstations(auth?.salonId || salonId),
          getAllWorkstationAvailabilityOptions(),
          getAllWorkstationOptions(),
        ]);

        if (workStationsResponse) {
          setWorkStations(workStationsResponse);
        }

        if (availabilityOptionsResponse) {
          setAvailabilities(
            availabilityOptionsResponse.map((availability) => {
              return {
                value: availability.wAvailId,
                label: availability.description,
              };
            })
          );
        }
        if (workstationOptionsResponse) {
          setWorkstationTypes(
            workstationOptionsResponse.map((workstation) => {
              return {
                value: workstation.workstationId,
                label: workstation.name,
              };
            })
          );
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onChangeSalon = () => {
    setLoading(true);

    getSalonsWorkstations(auth?.salonId || salonId).then((data) => {
      const salonData = JSON.parse(JSON.stringify(salonFormData));
      salonData.workstations = data;

      setSalonFormData(salonData);

      setWorkStations(data);
      setLoading(false);
    });
  };

  const onAppend = () => {
    openDialog({
      component: (
        <WorkplaceForm
          close={closeDialog}
          onAdd={onChangeSalon}
          salonId={auth?.salonId || salonId}
          availabilities={availabilities}
          workStations={workStations}
          workStationTypes={workStationTypes}
        />
      ),
      mw: 1100,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  const onEdit = (workstation) => () => {
    openDialog({
      component: (
        <WorkplaceForm
          close={closeDialog}
          onAdd={onChangeSalon}
          salonId={auth?.salonId || salonId}
          workstation={workstation}
          availabilities={availabilities}
          workStations={workStations}
          workStationTypes={workStationTypes}
        />
      ),
      mw: 1100,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  const deleteWorkStation = (relationId) => () => {
    deleteSalonWorkstation(relationId)
      .then(() => {
        onChangeSalon();
      })
      .catch((err) => {
        if (
          err.message.includes("Workstation is presented in future calendar")
        ) {
          openDialog({
            component: <DeleteReject okCallback={closeDialog} />,
            mw: 596,
            cancelCallback: () => {
              closeDialog();
            },
          });
        }
      });
  };

  return (
    <Stack flexDirection="column">
      <FormHeader
        title="Workstations rentals, terms & pricing"
        subtitle="Specify which workstations are available for artists to rent and share your terms & conditions"
      />
      {workStations.map((workStation) => (
        <WorkStation key={workStation.workstationId}>
          <WorkStationInfo>
            <WorkStationRow>
              <WorkStationTitle>
                <AdaptiveText
                  xl={{ size: 20, height: 24 }}
                  xs={{ size: 20, height: 24 }}
                  fontFamily="Greenwich"
                  as="span"
                >
                  Workplace type
                </AdaptiveText>
              </WorkStationTitle>
              <AdaptiveText
                xl={{ size: 20, height: 24 }}
                xs={{ size: 20, height: 24 }}
                fontFamily="Greenwich"
                weight={700}
                as="span"
              >
                {workStation.name}
              </AdaptiveText>
            </WorkStationRow>
            <WorkStationRow>
              <WorkStationTitle>
                <AdaptiveText
                  xl={{ size: 20, height: 24 }}
                  xs={{ size: 20, height: 24 }}
                  fontFamily="Greenwich"
                  as="span"
                >
                  Number of stations
                </AdaptiveText>
              </WorkStationTitle>
              <AdaptiveText
                xl={{ size: 20, height: 24 }}
                xs={{ size: 20, height: 24 }}
                fontFamily="Greenwich"
                weight={700}
                as="span"
              >
                {workStation.count}
              </AdaptiveText>
            </WorkStationRow>
            <WorkStationRow>
              <WorkStationTitle>
                <AdaptiveText
                  xl={{ size: 20, height: 24 }}
                  xs={{ size: 20, height: 24 }}
                  fontFamily="Greenwich"
                  as="span"
                >
                  Rental duration
                </AdaptiveText>
              </WorkStationTitle>
              <AdaptiveText
                xl={{ size: 20, height: 24 }}
                xs={{ size: 20, height: 24 }}
                fontFamily="Greenwich"
                weight={700}
                as="span"
              >
                Full Day
              </AdaptiveText>
            </WorkStationRow>
            <WorkStationRow>
              <WorkStationTitle>
                <AdaptiveText
                  xl={{ size: 20, height: 24 }}
                  xs={{ size: 20, height: 24 }}
                  fontFamily="Greenwich"
                  as="span"
                >
                  Price
                </AdaptiveText>
              </WorkStationTitle>
              <AdaptiveText
                xl={{ size: 20, height: 24 }}
                xs={{ size: 20, height: 24 }}
                fontFamily="Greenwich"
                weight={700}
                as="span"
              >
                € {workStation.pricePerDay}
              </AdaptiveText>
            </WorkStationRow>
            <WorkStationRow>
              <WorkStationTitle>
                <AdaptiveText
                    xl={{ size: 20, height: 24 }}
                    xs={{ size: 20, height: 24 }}
                    fontFamily="Greenwich"
                    as="span"
                >
                  Additional terms
                </AdaptiveText>
              </WorkStationTitle>
              <AdaptiveText
                  xl={{ size: 16, height: 24 }}
                  xs={{ size: 14, height: 24 }}
                  fontFamily="Greenwich"
                  weight={400}
                  as="span"
              >
                {workStation.additionCondition}
              </AdaptiveText>
            </WorkStationRow>
          </WorkStationInfo>
          <WorkstationControls>
            <ActionButton
              disableRipple
              aria-label="edit"
              color="primary"
              onClick={onEdit(workStation)}
            >
              <EditIcon fontSize="24px" />
            </ActionButton>
            {workStations?.length > 1 && (
              <ActionButton
                disableRipple
                aria-label="delete"
                color="primary"
                onClick={deleteWorkStation(workStation.relationId)}
              >
                <DeleteIcon />
              </ActionButton>
            )}
          </WorkstationControls>
        </WorkStation>
      ))}
      <Append onClick={onAppend}>
        <AdaptiveText
          xl={{ size: 28, height: 28 }}
          xs={{ size: 28, height: 28 }}
          fontFamily="Greenwich"
          weight={500}
          color={colors.pink4}
        >
          +
        </AdaptiveText>
        <AdaptiveText
          xl={{ size: 18, height: 21 }}
          xs={{ size: 18, height: 21 }}
          fontFamily="Greenwich"
          weight={700}
          color={colors.pink4}
        >
          <u>Add types of workstations to rent</u>
        </AdaptiveText>
      </Append>
      <ButtonBlock>
        <Button
          variant="outlined"
          disableTouchRipple
          disableFocusRipple
          onClick={() => {
            navigate(-1);
          }}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          disableRipple
          onClick={() => {
            navigate(
              isEditSalon
                ? `/edit_salons/workstations_schedule/${
                    salonId || auth.salonId
                  }`
                : "/schedules/workstations_schedule"
            );
          }}
        >
          Submit
        </Button>
      </ButtonBlock>
    </Stack>
  );
};

export default WithDialog(Places);
