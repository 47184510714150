import styled from "@emotion/styled";
import { colors, vw } from "../../../styles";
import { Button } from "@mui/material";

const Wrapper = styled.div`
  padding: 4px;
  background-color: ${colors.white};
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(4)};
    border-radius: ${vw(8)};
    gap: ${vw(4)};
  }
`;

const ToggleButton = styled(Button)<any>`
  font-size: 18px;
  line-height: 21px;
  font-family: Greenwich, serif;
  text-transform: none;
  height: 52px;
  background-color: ${(props) => (props.checked ? colors.pink4 : colors.white)};
  color: ${(props) => (props.checked ? colors.white : colors.pink4)};

  &:hover {
    background-color: ${(props) =>
      props.checked ? colors.pink3 : colors.pink1};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(18)};
    line-height: ${vw(21)};
    height: ${vw(52)};
  }
`;

export { Wrapper, ToggleButton };
