import React, { ChangeEvent, useState } from "react";
import classes from "./UploadProfilePhoto.module.scss";
import default_upload_photo from "../../../../../../assets/images/default_upload_photo.webp";
import { useAuth } from "../../../../../../contexts/AuthContext";
import {
  DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
  DEFAULT_FILE_SIZE_LIMIT_KB,
} from "../../../../../../constants/common.consts";
import setFormDataToStorage from "../../../Stepper/services/setFormDataToStorage";
import {
  addWorkOrProfileImage,
  deleteImage,
} from "../../../../../../services/masterApi";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";

export default function UploadProfilePhoto({ setErrs, errs }) {
  const { auth } = useSelector((state: AppState) => state);
  const [error, setError] = useState(null);
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();

  const deletePhotoHandler = async (imageId: number) => {
    // create mode
    if (!auth?.name) {
      const deleteImageFromStorage = () => {
        setFormDataToStorage({
          ...masterProfileFormData,
          profilePhoto: { ...masterProfileFormData.profilePhoto, imageUrl: "" },
        });
      };

      deleteImageFromStorage();
    }

    setMasterProfileFormData({
      ...masterProfileFormData,
      profilePhoto: { ...masterProfileFormData.profilePhoto, imageUrl: "" },
    });

    // update mode
    try {
      setError("");

      const deletedImageData = await deleteImage(auth.userId, imageId);
    } catch {
      setError("Failed to delete image");
    }
  };

  const addFileImage = async (fileImage) => {
    try {
      const _formData = new FormData();

      // Update the formData object
      _formData.append("photo", fileImage, fileImage.name);
      _formData.append("type", "profile-photo");
      _formData.append("lang", "EN");

      const responseImageData = await addWorkOrProfileImage(
        _formData,
        auth.userId
      );

      setMasterProfileFormData({
        ...masterProfileFormData,
        profilePhoto: responseImageData,
      });
    } catch (error) {
      alert(error);
    }
  };

  const onUploadImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const fileImage = e.target.files[0];

      const fileSize = fileImage.size;
      const fileSizeInKB = fileSize / 1024; // this would be in kilobytes defaults to bytes

      if (fileSizeInKB < DEFAULT_FILE_SIZE_LIMIT_KB) {
        addFileImage(fileImage);
      } else {
        setErrs((prevState) => {
          return {
            ...prevState,
            profilePhoto: DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
          };
        });
      }
    }
  };

  return (
    <div className={classes.upload_wrapper}>
      <div className={classes.upload_title}>
        Please upload your profile photo <span className="req_sign">*</span>
      </div>

      <div className={classes.camera_wrapper}>
        <img
          src={
            masterProfileFormData?.profilePhoto?.imageUrl ||
            default_upload_photo
          }
          alt="profile"
        />
      </div>
      <div className={classes.upload_btn_wrapper}>
        {masterProfileFormData?.profilePhoto?.imageUrl ? (
          <button
            type="button"
            onClick={() =>
              deletePhotoHandler(masterProfileFormData?.profilePhoto?.imageId)
            }
          >
            delete photo
          </button>
        ) : (
          <label className={classes.upload_label}>
            <input
              type="file"
              accept="image/*"
              onChange={onUploadImageHandler}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
            Upload photo
          </label>
        )}
        {errs.profilePhoto && (
          <div className={classes.err_text}>{errs.profilePhoto}</div>
        )}
      </div>
    </div>
  );
}
