import React from "react";
import { TabIndex, StyledProgressItem } from "./progress-tab-styles";
import { Text } from "../../../../components/text";
import Check from "@mui/icons-material/Check";

const ProgressTab = ({ index, title, active }) => {
  return (
    <StyledProgressItem
      $disabled={index > active}
      $active={index === active}
      $passed={index < active}
    >
      <TabIndex>
        {index < active ? (
          <Check />
        ) : (
          <Text xs={{ size: 16, height: 16 }} sm={{ size: 16, height: 16 }}>
            {index + 1}
          </Text>
        )}
      </TabIndex>
      <Text
        xs={{ size: 18, height: 18 }}
        sm={{ size: 18, height: 18 }}
        fontWeight="bold"
        textAlign="left"
        fontFamily="Greenwich"
      >
        {title}
      </Text>
    </StyledProgressItem>
  );
};

export { ProgressTab };
