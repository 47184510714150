import React, { FC } from "react";
import { FormControl } from "@mui/material";
import { get } from "lodash-es";
import {
  LabelContainer,
  StyledInputLabel,
  StyledTextField,
  ErrorMessage,
  Label,
} from "./textfield-styles";
import { useController, useFormContext } from "react-hook-form";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { LabelTooltip, RequiredLabel } from "../../v2/components";

interface IProps extends BaseTextFieldProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  name: string;
  tooltip?: string;
  className?: string;
  height?: number;
}

const TextField: FC<IProps> = ({
  label,
  placeholder,
  required,
  defaultValue,
  name,
  tooltip,
  className,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const error = get(errors, fieldName)?.message;

  return (
    <FormControl fullWidth className={className}>
      {label && (
        <LabelContainer>
          <StyledInputLabel>
            <Label shrink htmlFor={name} error={!!error}>
              {label}
            </Label>
            {tooltip && <LabelTooltip tooltip={tooltip} />}
          </StyledInputLabel>
          {required && <RequiredLabel />}
        </LabelContainer>
      )}
      <StyledTextField
        error={!!error}
        id={name}
        value={value}
        type="text"
        placeholder={placeholder}
        {...rest}
        onChange={onChange}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormControl>
  );
};
export default TextField;
