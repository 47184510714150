import classes from "./YourPortfolio.module.scss";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import React, { ChangeEvent, useCallback, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  addWorkOrProfileImage,
  deleteImage,
} from "../../../../../services/masterApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ExplanationIcon } from "@components";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import {
  DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
  DEFAULT_FILE_SIZE_LIMIT_KB,
} from "../../../../../constants/common.consts";
import default_upload_photo from "../../../../../assets/images/default_upload_photo.webp";
import { Text } from "../../../../../components/text";
import { Button } from "@mui/material";
import { NextButton } from "./yourPortfolio-styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const INITIAL_VALUES_PORTFOLIO = {
  works: [
    {
      imageId: "1cddea39-a938-4349-892d-1704545470f3",
      imageUrl:
        "https://pbs.twimg.com/profile_images/1455185376876826625/s1AjSxph_400x400.jpg",
    },
  ],
};

const MAX_PORTFOLIO_IMAGES = 6;
const MIN_PORTFOLIO_IMAGES = 1;

const DEFAULT_ERROR_STATE = {
  workImage: "",
};

export const YourPortfolio = () => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formError, setFormError] = useState(null);
  const { userId } = useParams();
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);

  const deletePhotoHandler = async (imageId: number) => {
    // create mode
    if (!userId) {
      const deleteImageFromStorage = () => {
        setFormDataToStorage({
          ...masterProfileFormData,
          works: masterProfileFormData.works.filter(
            (el) => el.imageId !== imageId
          ),
        });
      };

      deleteImageFromStorage();
    }

    setMasterProfileFormData({
      ...masterProfileFormData,
      works: masterProfileFormData.works.filter((el) => el.imageId !== imageId),
    });

    // update mode
    try {
      setError(null);

      const deletedImageData = await deleteImage(userId, imageId);
    } catch (error) {
      setError(error);
    }
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitCreateMode = async () => {
    navigate(location, {
      state: {
        activeStep: 3,
      },
    });
  };

  const submitUpdateMode = async () => {
    navigate(`/profile/${userId}`);
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (
      masterProfileFormData?.works?.length < MIN_PORTFOLIO_IMAGES ||
      masterProfileFormData?.works?.length > MAX_PORTFOLIO_IMAGES
    ) {
      setFormError("Please upload your portfolio photo");
      return;
    }

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const addFileImage = async (fileImage) => {
    try {
      setError(null);
      setFormError(null);

      const _formData = new FormData();

      // Update the formData object
      _formData.append("photo", fileImage, fileImage.name);
      _formData.append("type", "work-photo");

      let _works = [];

      // create mode
      if (!userId) {
        const responseImageData = await addWorkOrProfileImage(
          _formData,
          auth.userId
        );

        _works = [
          ...masterProfileFormData.works,
          {
            imageId: responseImageData.imageId,
            imageUrl: responseImageData.imageUrl,
          },
        ];

        setFormDataToStorage({
          ...masterProfileFormData,
          works: _works,
        });
      }

      // update mode
      if (userId) {
        const responseImageData = await addWorkOrProfileImage(
          _formData,
          userId
        );

        _works = [
          ...masterProfileFormData.works,
          {
            imageId: responseImageData.imageId,
            imageUrl: responseImageData.imageUrl,
          },
        ];
      }

      setMasterProfileFormData({
        ...masterProfileFormData,
        works: _works,
      });

      setErrs((prevState) => {
        return {
          ...prevState,
          workImage: "",
        };
      });
    } catch (error) {
      setError(error);
    }
  };

  const onUploadImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const fileImage = e.target.files[0];

      const fileSize = fileImage.size;
      const fileSizeInKB = fileSize / 1024; // this would be in kilobytes defaults to bytes

      if (fileSizeInKB < DEFAULT_FILE_SIZE_LIMIT_KB) {
        addFileImage(fileImage);
      } else {
        setErrs((prevState) => {
          return {
            ...prevState,
            workImage: DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
          };
        });
      }
    }
  };

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  return (
    <div>
      <Text
        xs={{ size: 28, height: 40 }}
        sm={{ size: 36, height: 46 }}
        fontWeight="bold"
      >
        Your portfolio
      </Text>
      <h3 className={classes.title}>
        Please share some examples of your work to be showcased on your profile
        <ExplanationIcon
          text={
            "Please create here a diverse portfolio of up to 6 photos, min 3 recommended"
          }
        />
        <div className={classes.req_sign}>*</div>
      </h3>

      <form className={classes.form_wrapper} onSubmit={handleSubmit}>
        <div className={classes.photos_wrapper}>
          {masterProfileFormData?.works?.map((el) => {
            return (
              <div key={el.imageId} className={classes.photo_wrapper}>
                <img src={el.imageUrl} alt={"vv-photo"} />
                <button
                  type="button"
                  onClick={() => deletePhotoHandler(el.imageId)}
                >
                  delete photo
                </button>
              </div>
            );
          })}
          {masterProfileFormData?.works?.length < MAX_PORTFOLIO_IMAGES && (
            <div className={classes.upload_wrapper}>
              <div className={classes.camera_wrapper}>
                <img src={default_upload_photo} alt={"vv_file"} />
              </div>
              <div className={classes.upload_btn_wrapper}>
                <label className={classes.upload_label}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onUploadImageHandler}
                    onClick={(event: any) => {
                      event.target.value = null;
                    }}
                  />
                  Upload photo
                </label>
              </div>
              {errs.workImage && (
                <div className={classes.err_text}>{errs.workImage}</div>
              )}
            </div>
          )}
        </div>
        <div className={classes.form_error}>
          {formError && <div className={classes.err_text}>{formError}</div>}
        </div>
        <div className={classes.btn_bottom_wrapper}>
          {!userId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <NextButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </NextButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
