import React from "react";
import classes from "./notification2.page.module.scss";
import NotificationHeader from "./components/notificationHeader";
import NotificationFooter from "./components/notificationFooter";

const Notification2Page = () => {
  return (
    <div className={classes.notification_wrapper}>
      <NotificationHeader />
      <div className={classes.body}>
        <div className={classes.welcome_text}>
          <div>Dear (имя),</div>
          <div>
            Thank you for submitting your profile for verification. We sincerely
            appreciate your interest in collaborating with VERVVEY.
          </div>
        </div>
        <div className={classes.complete_profile_wrapper}>
          <div className={classes.title}>
            <div>
              In order to be verified as an active member of VERVVEY beauty
              coworking community please complete the onboarding training
              course.
            </div>
            <div>
              It won’t take longer than 20-30 min to complete and will help to
              deliver better customer experience together.
            </div>
          </div>
          <button className={classes.complete_btn}>
            <div>Deliver better</div>
            <div>customer experienсe</div>
          </button>
        </div>
        <div className={classes.best_process_text}>
          <div>
            We are giving our best to process all applications within the next
            working day.
          </div>
          <div>Thank you for your patience!</div>
        </div>
        <div className={classes.with_love}>
          <div>
            <div>Sincerely,</div>
            <div>The VERVVEY Team</div>
            <div>welcome@vervvey.com</div>
          </div>
        </div>
      </div>
      <NotificationFooter />
    </div>
  );
};

export default Notification2Page;
