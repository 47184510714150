import classes from "./BeautyArtists.module.scss";
import CustomButton from "../../components/customButton/customButton";
import { useNavigate } from "react-router-dom";
import ImageSlider from "../home/components/imageSlider/imageSlider";
import ArtistsSlide from "../home/components/artistsSlider/artistsSlider";
import CustomSwiper from "../../components/CustomSwiper/CustomSwiper";
import { CustomSlider } from "./components/CustomSider/CustomSlider";
import { RandomFaceImage } from "./components/RandomFaceImage";
import artist1 from "../../assets/images/artist_1.webp";
import artist2 from "../../assets/images/artist_2.webp";
import artist3 from "../../assets/images/artist_3.webp";
import artist4 from "../../assets/images/artist_4.webp";
import artist5 from "../../assets/images/artist_5.webp";
import artist6 from "../../assets/images/artist_6.webp";
import artist7 from "../../assets/images/artist_7.webp";
import artist8 from "../../assets/images/artist_8.webp";
import artist9 from "../../assets/images/artist_9.webp";
import ReactGA from 'react-ga4'
import {Helmet} from "react-helmet";
import React from "react";
import {artistsData} from "../../constants/common.consts";


export const BeautyArtists = () => {
  const navigate = useNavigate();

  const handleJoinNowBtn = () => {
    ReactGA.event({category: 'engagement', action: 'join_now_pressed', label: 'master'});
    navigate("/register_modal");
  };

  return (
      <><Helmet>
          <title>Meet your clients on your terms | Vervvey</title>
          <meta name="description"
              content="Meet your clients on your terms. We’ll take care of the rest. Join the biggest beauty coworking community of salon hosts and freelance beauty artists."/>
        </Helmet>
        <div className={classes.beautyArtists_wrapper}>
          <div className={classes.top_section_wrapper}>
            <div className={classes.titles_wrapper}>
              <div className={classes.top_section_title}>
                Meet your clients
                <br/> on your terms.
                <br/>
                We’ll take care of the rest
              </div>
              <div className={classes.top_section_sub_title}>
                Join the biggest beauty coworking community delivering better
                customer experience together
              </div>
              <CustomButton variant="primary" onClick={handleJoinNowBtn}>
                JOIN NOW
              </CustomButton>
            </div>
            <div className={classes.top_section_image_wrapper}>
              <div className={classes.colored_blocks_wrapper}>
                <div></div>
                <div></div>
              </div>
              <RandomFaceImage/>
            </div>
          </div>
          <div className={classes.who_vervvey_wrapper}>
            <ImageSlider className={classes.who_is_vv_for}/>
            <div className={classes.who_vervvey_text}>
              Not sure whether there is something for you? Create your VERVVEY
              profile, so we can guide you further. Registration is always free!
            </div>
          </div>
          <div className={classes.how_vv_works}>
            <CustomSlider/>
          </div>
          <div className={classes.used_by_wrapper}>
            <div className={classes.used_by_title}>
              Used by thousand of artists & salons
            </div>
            <div className={classes.divider}></div>
            <CustomSwiper
                showSlides={{xs: 1, md: 2, lg: 3}}
                bigNavigation
                slides={artistsData.map((item) => (
                    <ArtistsSlide item={item}/>
                ))}/>
          </div>
          <div className={classes.btn_wrapper}>
            <CustomButton
                className={classes.btn}
                variant="secondary"
                onClick={handleJoinNowBtn}
            >
              JOIN NOW
            </CustomButton>
          </div>
        </div>
      </>
  );
};
