import styled from "@emotion/styled";
import { colors, vw } from "../../../../../styles";
import { Calendar } from "react-multi-date-picker";
import { alpha, Button, Tab, Tabs } from "@mui/material";
import isPropValid from "@emotion/is-prop-valid";

const TabWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  border-bottom: 2px solid ${colors.white};

  & div {
    margin-bottom: -2px;
  }
`;

const BulkOpenButton = styled(Button)<any>`
  width: 300px;
  font-size: 16px;
  height: 40px;
  margin-bottom: 10px;
  
  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: 16px !important;
  }
`;

const CalendarBlock = styled.div`
  min-height: 482px;
  margin-top: 42px;
  margin-bottom: 24px;
  padding: 40px 0;
  border-radius: 8px;
  background-color: ${colors.white};

  ${(props) => props.theme.breakpoints.down("md")} {
    min-height: ${vw(400)};
    margin-top: ${vw(42)};
    margin-bottom: ${vw(24)};
    padding: ${vw(40)} 0;
    border-radius: ${vw(8)};
  }
`;

const ModifyControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0 40px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0 ${vw(40)};
    margin-bottom: ${vw(32)};
  }

  & button {
    width: 200px;
    height: 52px;
    font-size: 20px;
    line-height: 23px;
    text-transform: none;
    font-family: Greenwich, serif;

    ${(props) => props.theme.breakpoints.down("sm")} {
      height: ${vw(52)};
      font-size: ${vw(20)};
      line-height: ${vw(23)};
    }
  }
`;

const StyledCalendar = styled(Calendar)<any>`
  margin-bottom: 20px;
  &.bookingCalendar {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 100%;
    padding: 0 20px;
    position: relative;

    ${(props) => props.theme.breakpoints.down("md")} {
      padding: 0;
    }

    .rmdp-top-class {
      width: 100%;

      .rmdp-calendar {
        width: 100%;

        ${(props) => props.theme.breakpoints.down("sm")} {
          padding: 0 ${vw(40)};
        }

        .rmdp-header {
          height: 32px;

          ${(props) => props.theme.breakpoints.down("sm")} {
            height: ${vw(32)};
          }

          div {
            position: inherit !important;
          }
        }
      }
    }

    .rmdp-header-values {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      font-family: "Greenwich", sans-serif;

      ${(props) => props.theme.breakpoints.down("sm")} {
        font-size: ${vw(18)};
        line-height: ${vw(21)};
      }
    }

    .rmdp-day-picker {
      justify-content: space-around;

      & > div {
        width: auto;

        ${(props) => props.theme.breakpoints.down("sm")} {
          width: 100%;
        }
      }
    }

    .rmdp-arrow-container {
      z-index: 2;
      width: 60px;
      height: 60px;

      ${(props) => props.theme.breakpoints.down("sm")} {
        width: ${vw(40)};
        height: ${vw(40)};
      }

      i {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
      }

      &:hover:not(.disabled) {
        background-color: ${colors.white};
        box-shadow: none;
      }

      &.rmdp-right:after {
        top: 12px;
        left: 24px;
        border: 18px solid transparent;
        border-left: 18px solid ${colors.pink4};

        ${(props) => props.theme.breakpoints.down("sm")} {
          top: ${vw(8)};
          left: ${vw(16)};

          border-width: ${vw(12)};
        }
      }

      &.rmdp-left:after {
        top: 12px;
        right: 24px;
        border: 18px solid transparent;
        border-right: 18px solid ${colors.pink4};

        ${(props) => props.theme.breakpoints.down("sm")} {
          top: ${vw(8)};
          right: ${vw(16)};

          border-width: ${vw(12)};
        }
      }

      &.disabled {
        &.rmdp-left:after {
          border-right: 18px solid ${colors.grey};

          ${(props) => props.theme.breakpoints.down("sm")} {
            border-width: ${vw(12)};
          }
        }

        &.rmdp-right:after {
          border-left: 18px solid ${colors.grey};

          ${(props) => props.theme.breakpoints.down("sm")} {
            border-width: ${vw(12)};
          }
        }
      }
    }

    .rmdp-week-day {
      width: 38px;
      height: 38px;
      margin: 4px 6px;
      font-size: 18px;
      line-height: 21px;
      font-family: "Greenwich", sans-serif;
      font-weight: 700;
      color: ${colors.blu2};

      ${(props) => props.theme.breakpoints.down("sm")} {
        width: ${vw(38)};
        height: ${vw(38)};
        margin: ${vw(4)} ${vw(6)};

        font-size: ${vw(18)};
        line-height: ${vw(21)};
      }
    }

    .rmdp-day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      margin: 4px 6px;
      font-size: 18px;
      line-height: 21px;
      font-family: "Greenwich", sans-serif;
      font-weight: 700;
      color: ${colors.grey};

      &:hover {
        background-color: transparent;
      }

      ${(props) => props.theme.breakpoints.down("sm")} {
        width: ${vw(38)};
        height: ${vw(38)};
        margin: ${vw(4)} ${vw(6)};

        font-size: ${vw(18)};
        line-height: ${vw(21)};
      }

      & span {
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        width: 38px;
        height: 38px;
        font-size: 18px;
        line-height: 21px;

        &:hover {
          background-color: transparent;
        }

        &.today {
          color: ${colors.pink4};
        }

        ${(props) => props.theme.breakpoints.down("sm")} {
          width: ${vw(38)};
          height: ${vw(38)};
          font-size: ${vw(18)};
          line-height: ${vw(21)};
        }
      }
    }
  }
`;

interface DayButtonProps {
  checked: boolean;
  booked: boolean;
}

const DayButton = styled(Button, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "booked",
})<DayButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 38px;
  height: 38px;
  min-width: 38px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  font-family: "Greenwich", sans-serif;
  background-color: ${(props) => {
    if (props.booked) {
      return colors.pink4;
    }

    return "transparent";
  }};
  color: ${(props) => {
    if (props.booked) {
      return colors.white;
    }

    if (props.checked) {
      return colors.accent;
    }

    return colors.grey3;
  }};
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => {
    if (props.booked) {
      return colors.pink4;
    }

    if (props.checked) {
      return colors.pink4;
    }

    return "transparent";
  }};

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0;
    width: ${vw(38)};
    height: ${vw(38)};
    min-width: ${vw(38)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
    border-width: ${vw(2)};
  }

  &:hover {
    background-color: ${(props) => {
      if (props.booked) {
        return colors.blu4;
      }

      return colors.blu5;
    }};
    color: ${(props) => {
      if (props.booked) {
        return colors.white;
      }

      return colors.accent;
    }};
    border-color: ${(props) => {
      if (props.booked) {
        return colors.blu4;
      }

      if (props.checked) {
        return colors.blu4;
      }

      return "transparent";
    }};
  }
`;

const WorkstationInfo = styled.div`
  width: 60px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(60)};
  }
`;

const WorkstationTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  line-height: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    gap: ${vw(8)};
    font-size: ${vw(14)};
    line-height: ${vw(16)};
  }
`;

const StyledTab = styled(Tab)`
  font-weight: 700;
  color: ${alpha(colors.accent, 0.3)};
  text-transform: none;
  font-size: 20px;
  line-height: 24px;
  font-family: Greenwich, sans-serif;

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: ${vw(20)};
    line-height: ${vw(24)};
  }

  &.Mui-selected {
    color: ${colors.accent};
  }
`;

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${colors.pink4};
  }
`;

const ButtonBlock = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }

  & button {
    max-width: 307px;
  }
`;

const CancelButton = styled(Button)`
  color: ${colors.pink4};
  text-decoration: underline;

  &:hover {
    color: ${colors.pink4};
    text-decoration: underline;
    background-color: ${alpha(colors.pink1, 0.3)};
  }
`;

const SaveBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding: 0 40px;

  & button {
    max-width: 200px;
    height: 52px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    padding: 0 ${vw(40)};

    & button {
      max-width: ${vw(200)};
      height: ${vw(52)};
    }
  }
`;

export {
  TabWrapper,
  CalendarBlock,
  ModifyControls,
  StyledCalendar,
  DayButton,
  WorkstationInfo,
  WorkstationTooltip,
  StyledTab,
  StyledTabs,
  ButtonBlock,
  CancelButton,
  SaveBlock,
  BulkOpenButton,
};
