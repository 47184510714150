import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { colors, vw } from "../../../styles";

const StyledChip = styled(Chip)`
  position: absolute;
  height: 54px;
  border-radius: 20px;
  top: 10px;
  right: 10px;
  background-color: ${colors.pink4};
  padding: 15px 27px;
  color: ${colors.white};

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(54)};
    border-radius: ${vw(20)};
    top: ${vw(10)};
    right: ${vw(10)};
    padding: ${vw("15 27")};
  }

  & .MuiChip-label {
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 23px;
    font-family: Greenwich;
    font-weight: 700;

    ${(props) => props.theme.breakpoints.down("sm")} {
      font-size: ${vw(20)};
      line-height: ${vw(23)};
    }
  }
`;

const EmptyResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 100px 0;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(40)};
    margin: ${vw("100 0")};
  }

  button {
    font-size: 24px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      font-size: ${vw(24)};
    }
  }
`;

export { StyledChip, EmptyResultWrapper };
