import { bool, object, string } from "yup";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../constants/common.consts";

export const validationSchema = object({
  eligible: bool().default(true).test("isTrue", DEFAULT_ERROR_TEXT_MESSAGE, (value) => value),
  eligibilityId: string().required("Please confirm that you are eligible to work in the Netherlands").default(""),
  withoutKvk: bool().default(true),
  insured: string().default(""),
  mobilePhone: string().default(""),
  kvkNumber: string()
    .test("length", "Must be exactly 8 characters", (val, form) => {
      return val.toString()?.length === 0 || val.toString()?.length === 8;
    })

    .nullable()
    .default(""),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({}),
});
