import React from "react";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import { Wrapper, ExpandIcon } from "./question-styles";

const Question = ({ title, answer, expanded, handleChange }) => {
  return (
    <Wrapper expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandIcon />}>{title}</AccordionSummary>
      <AccordionDetails>{answer}</AccordionDetails>
    </Wrapper>
  );
};

export default Question;
