import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledSelect } from "./day-status-select-styles";
import { updateBookingDay, updateBookingRequest } from "../../services/api";
import { colors } from "../../styles";
import { StatusChange } from "../../modals";
import WithDialog from "../../contexts/dialog/WithDialog";

const DayStatusSelect = ({
  defaultValue,
  data,
  statuses,
  openDialog,
  closeDialog,
}) => {
  const [value, setValue] = useState("");

  const accept = async (e) => {
    setValue(e.target.value);

    updateBookingDay(data?.id, e.target.value);
  };

  const handleChange = (e) => {
    openDialog({
      component: (
        <StatusChange
          close={closeDialog}
          okCallback={() => {
            accept(e);
            closeDialog();
          }}
        />
      ),
      mw: 716,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  useEffect(() => {
    if (defaultValue?.length) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <StyledSelect
      value={statuses?.length ? value : ""}
      label="Status"
      onChange={handleChange}
      renderValue={
        value?.length
          ? null
          : () => <p style={{ color: colors.grey }}>Status</p>
      }
    >
      {statuses?.map((status) => (
        <MenuItem key={status} value={status}>
          {status}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default WithDialog(DayStatusSelect);
