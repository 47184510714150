import React, { useEffect, useState } from "react";
import {
  geBookingDayStatuses,
  getBookingStatuses,
  getUsers,
  getWorkstationBookings,
  updateBookingRequest,
} from "../../../../services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Wrapper,
  RequestDetails,
  Row,
  RowTitle,
  RequestInfo,
  RequestFields,
  Divider,
  BookingDetails,
  Title,
  ContactCard,
  CardHeader,
  CardBody,
  StyledLink,
  ButtonRow,
  RouterLink,
  DetailRow,
  Status,
} from "./book-info-styles";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./duck/utils";
import { AdaptiveText, DayStatusSelect } from "../../../../components";
import moment from "moment";
import { Text } from "../../../../components/text";
import { colors } from "../../../../styles";
import { Button, Stack } from "@mui/material";
import { getSalonProfileData } from "../../../../services/salonsApi";
import { getMasterProfileData } from "../../../../services/masterApi";
import { Textarea } from "../../../../v2/components";
import { Spinner } from "../../../../components/v2/spinner";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow_left_pink.svg";
import { StatusSelect } from "../status-select";
import { ManagerSelect } from "../manager-select";
import { Record } from "../../../accounting/components/record";

const BookInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [salonData, setSalon] = useState(null);
  const [masterData, setMaster] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [dayStatuses, setDayStatuses] = useState([]);

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  useEffect(() => {
    getWorkstationBookings({
      page: 0,
      pageSize: 1,
      id: id,
    }).then(async ({ page }) => {
      if (page?.[0]) {
        formProviderProps.reset(page?.[0]);

        setData(page[0]);
        const [salon, master, admins] = await Promise.all([
          getSalonProfileData(page[0].salonId),
          getMasterProfileData(page[0].masterId),
          getUsers({
            page: 0,
            pageSize: 10,
            filterBy: "role",
            filterValue: "admin",
          }),
        ]);
        if (salon) {
          setSalon(salon);
        }

        if (master) {
          setMaster(master);
        }

        if (admins) {
          setAdmins(
            admins.page.map((admin) => {
              return { value: admin.userId, label: admin.name };
            })
          );
        }

        setLoading(false);
      }
    });
    getBookingStatuses().then((data) => {
      setStatuses(data);
    });
    geBookingDayStatuses().then((data) => {
      setDayStatuses(data);
    });
  }, []);

  const onSubmit = () => {
    updateBookingRequest({
      rentId: data?.id,
      ...formProviderProps.getValues(),
    });
  };

  const onCancelHandler = () => {
    navigate(-1);
  };

  return (
    <Container>
      <RouterLink onClick={onCancelHandler}>
        <ArrowLeft />
        back to list
      </RouterLink>
      {loading ? (
        <Spinner />
      ) : (
        <FormProvider {...formProviderProps}>
          <Wrapper
            onSubmit={formProviderProps.handleSubmit(onSubmit)}
            noValidate
          >
            <RequestDetails>
              <RequestInfo>
                <Row>
                  <RowTitle>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                    >
                      Request ID
                    </AdaptiveText>
                  </RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 23 }}
                    xs={{ size: 20, height: 23 }}
                    weight={700}
                  >
                    {data?.id}
                  </AdaptiveText>
                </Row>
                <Row>
                  <RowTitle>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                    >
                      Request time
                    </AdaptiveText>
                  </RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 23 }}
                    xs={{ size: 20, height: 23 }}
                    weight={700}
                  >
                    {moment(data?.createDate).format("HH:mm DD/MM/YYYY")}
                  </AdaptiveText>
                </Row>
                <Row>
                  <RowTitle>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                    >
                      Artist's nickname
                    </AdaptiveText>
                  </RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 23 }}
                    xs={{ size: 20, height: 23 }}
                    weight={700}
                  >
                    <Link to={`/profile/${data?.masterId}`}>
                      <Text
                        xs={{ size: 20, height: 20 }}
                        sm={{ size: 20, height: 20 }}
                        fontWeight={700}
                        fontFamily="Greenwich"
                        color={colors.pink4}
                      >
                        {data?.masterName}
                      </Text>
                    </Link>
                  </AdaptiveText>
                </Row>
                <Row>
                  <RowTitle>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                    >
                      Salon name
                    </AdaptiveText>
                  </RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 23 }}
                    xs={{ size: 20, height: 23 }}
                    weight={700}
                  >
                    <Link to={`/salon_profile/${data?.salonId}`}>
                      <Text
                        xs={{ size: 20, height: 20 }}
                        sm={{ size: 20, height: 20 }}
                        fontFamily="Greenwich"
                        fontWeight={700}
                        color={colors.pink4}
                      >
                        {data?.salonName}
                      </Text>
                    </Link>
                  </AdaptiveText>
                </Row>
              </RequestInfo>
              <RequestFields>
                <StatusSelect
                  placeholder="Booking status"
                  defaultValue={data.status}
                  data={data}
                  statuses={statuses}
                />
                <ManagerSelect
                  placeholder="Manager"
                  defaultValue={data.managerId}
                  admins={admins}
                  data={data}
                />
              </RequestFields>
            </RequestDetails>
            <Divider />
            <BookingDetails>
              <Row>
                <RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 20 }}
                    xs={{ size: 20, height: 20 }}
                    color={colors.blu4}
                    weight={700}
                  >
                    Request details
                  </AdaptiveText>
                </RowTitle>
              </Row>
              <Row>
                <RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 20 }}
                    xs={{ size: 20, height: 20 }}
                  >
                    Workplace type
                  </AdaptiveText>
                </RowTitle>
                <AdaptiveText
                  fontFamily="Greenwich"
                  xl={{ size: 20, height: 23 }}
                  xs={{ size: 20, height: 23 }}
                  weight={700}
                >
                  {data?.workstationType}
                </AdaptiveText>
              </Row>
              <Row>
                <RowTitle>
                  <AdaptiveText
                    fontFamily="Greenwich"
                    xl={{ size: 20, height: 20 }}
                    xs={{ size: 20, height: 20 }}
                  >
                    Total price to be paid, incl. VAT
                  </AdaptiveText>
                </RowTitle>
                <AdaptiveText
                  fontFamily="Greenwich"
                  xl={{ size: 20, height: 23 }}
                  xs={{ size: 20, height: 23 }}
                  weight={700}
                >
                  € {data?.priceVat}
                </AdaptiveText>
              </Row>
              <Row>
                <Stack flexDirection="column">
                  {data?.schedules?.map((item) => (
                    <DetailRow key={item.id}>
                      <Record
                        w={100}
                        label="Booking date:"
                        value={moment(item?.rentDate).format("YYYY-MM-DD")}
                      />
                      <Status>
                        <DayStatusSelect
                          defaultValue={item?.status}
                          statuses={dayStatuses}
                          data={item}
                        />
                      </Status>
                    </DetailRow>
                  ))}
                </Stack>
              </Row>
              <Title>
                <AdaptiveText
                  fontFamily="Greenwich"
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 20, height: 20 }}
                  color={colors.blu4}
                  weight={700}
                >
                  Title
                </AdaptiveText>
              </Title>
              <Stack flexDirection="row" gap="24px">
                <ContactCard>
                  <CardHeader>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 23 }}
                      xs={{ size: 20, height: 23 }}
                      weight={700}
                    >
                      Artist
                    </AdaptiveText>
                  </CardHeader>
                  <CardBody>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                    >
                      {masterData?.master?.city}
                    </AdaptiveText>
                    <StyledLink href={`mailto:${masterData?.user?.email}`}>
                      {masterData?.master?.email}
                    </StyledLink>
                    {masterData?.user?.mobilePhone && (
                      <AdaptiveText
                        fontFamily="Greenwich"
                        xl={{ size: 20, height: 20 }}
                        xs={{ size: 20, height: 20 }}
                      >
                        +{masterData?.user?.mobilePhone}
                      </AdaptiveText>
                    )}
                    <StyledLink
                      href={masterData?.user?.socialProfile}
                      target="_blank"
                    >
                      {masterData?.user?.socialProfile}
                    </StyledLink>
                  </CardBody>
                </ContactCard>

                <ContactCard>
                  <CardHeader>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 23 }}
                      xs={{ size: 20, height: 23 }}
                      weight={700}
                      align="center"
                    >
                      Salon
                    </AdaptiveText>
                  </CardHeader>
                  <CardBody>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                    >
                      {salonData?.salon?.city}
                    </AdaptiveText>
                    <StyledLink href={`mailto:${salonData?.host?.email}`}>
                      {salonData?.host?.email}
                    </StyledLink>
                    {salonData?.salon?.phone && (
                      <AdaptiveText
                        fontFamily="Greenwich"
                        xl={{ size: 20, height: 20 }}
                        xs={{ size: 20, height: 20 }}
                      >
                        +{salonData?.salon?.phone}
                      </AdaptiveText>
                    )}
                    <StyledLink href={salonData?.salon?.site} target="_blank">
                      {salonData?.salon?.site}
                    </StyledLink>
                    <StyledLink
                      href={salonData?.salon?.socialProfile}
                      target="_blank"
                    >
                      {salonData?.salon?.socialProfile}
                    </StyledLink>
                    <AdaptiveText
                      fontFamily="Greenwich"
                      xl={{ size: 20, height: 20 }}
                      xs={{ size: 20, height: 20 }}
                      capitalize
                    >
                      {salonData?.salon?.frontOfHouse?.name}
                    </AdaptiveText>
                  </CardBody>
                </ContactCard>
              </Stack>
              <Title>
                <AdaptiveText
                  fontFamily="Greenwich"
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 20, height: 20 }}
                  color={colors.blu4}
                  weight={700}
                >
                  Comment
                </AdaptiveText>
              </Title>
              <Textarea name="comment" multiline minRows={3} />
              <ButtonRow>
                <Button
                  variant="outlined"
                  disableTouchRipple
                  disableFocusRipple
                  onClick={onCancelHandler}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disableTouchRipple
                  disableFocusRipple
                >
                  Submit
                </Button>
              </ButtonRow>
            </BookingDetails>
          </Wrapper>
        </FormProvider>
      )}
    </Container>
  );
};

export default BookInfo;
