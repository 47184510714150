import React, { useEffect, useState } from "react";
import { getAllBios } from "../../../../../../services/masterApi";
import { CATEGORY_NAMES } from "../PersonalProfile.consts";

const getMappedBiosOptionsByCategoryName = (bios, categoryName) => {
  const _bios = bios
    .filter((bio) => {
      return bio.category === categoryName;
    })
    .map((bio) => {
      return { value: bio.bioId, label: bio.name };
    });

  return _bios;
};

export const useFetchBiosData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [starSignsOptions, setStarSignsOptions] = useState([]);
  const [maritalOptions, setMaritalOptions] = useState([]);
  const [covidStatusOptions, setCovidStatusOptions] = useState([]);
  const [smokeOptions, setSmokeOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [haveKidsOptions, setHaveKidsOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [descriptionOptions, setDescriptionOptions] = useState([]);
  const [describeYourselfOptions, setDescribeYourselfOptions] = useState([]);

  useEffect(() => {
    const fetchBiosData = async () => {
      try {
        setIsLoading(true);

        const _bios = await getAllBios();

        setStarSignsOptions(
          getMappedBiosOptionsByCategoryName(_bios, CATEGORY_NAMES.STAR_SIGN)
        );

        setMaritalOptions(
          getMappedBiosOptionsByCategoryName(
            _bios,
            CATEGORY_NAMES.MARITAL_SITUATION
          )
        );

        setCovidStatusOptions(
          getMappedBiosOptionsByCategoryName(_bios, CATEGORY_NAMES.COVID_STATUS)
        );

        setSmokeOptions(
          getMappedBiosOptionsByCategoryName(_bios, CATEGORY_NAMES.SMOKE)
        );

        setReligionOptions(
          getMappedBiosOptionsByCategoryName(_bios, CATEGORY_NAMES.RELIGION)
        );

        setHaveKidsOptions(
          getMappedBiosOptionsByCategoryName(_bios, CATEGORY_NAMES.KIDS)
        );

        setEducationOptions(
          getMappedBiosOptionsByCategoryName(_bios, CATEGORY_NAMES.EDUCATION)
        );

        setDescriptionOptions(
          getMappedBiosOptionsByCategoryName(
            _bios,
            CATEGORY_NAMES.DESCRIBES_BEST
          )
        );

        setDescribeYourselfOptions(
          getMappedBiosOptionsByCategoryName(
            _bios,
            CATEGORY_NAMES.DESCRIBE_YOURSELF
          )
        );

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchBiosData();
  }, []);

  return {
    isLoading,
    starSignsOptions,
    maritalOptions,
    covidStatusOptions,
    smokeOptions,
    religionOptions,
    haveKidsOptions,
    educationOptions,
    descriptionOptions,
    describeYourselfOptions,
  };
};
