import styled from "@emotion/styled";
import { colors, vw } from "../../../../../../styles";

const Wrapper = styled.form`
  padding: 20px;
`;

const StyledInputLabel = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Greenwich, serif;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(8)};
    gap: ${vw(10)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const PricingBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(9)};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 18px;

  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(18)};
    font-size: ${vw(20)};
    line-height: ${vw(20)};
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;

  & button {
    width: 240px;
  }
`;

export { Wrapper, StyledInputLabel, PricingBlock, LabelContainer, ButtonBlock };
