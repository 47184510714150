import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import LogOutModal from "../../../../pages/home/components/logoutModal/logoutModal";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

export const HostNavMobileList = () => {
  const { auth } = useSelector((state: AppState) => state);
  const location = useLocation();
  const isHostInfoFilled = !!(auth.name && auth.surname);

  const setSalonUrlToLocalStorage = () => {
    localStorage.setItem("memorizedSalonUrl", location.pathname);
  };

  const setHostUrlToLocalStorage = () => {
    localStorage.setItem("memorizedHostUrl", location.pathname);
  };

  return (
    <>
      {auth.salonId && (
        <Link to={`salon_profile/${auth.salonId}`}>
          <NavDropdown.Item as="button">Salon profile</NavDropdown.Item>
        </Link>
      )}

      {auth?.role ? (
        <>
          <Link
            to={
              !isHostInfoFilled
                ? "host_information"
                : auth.salonId
                ? `edit_salons/salon_information/${auth.salonId}`
                : "edit_salons"
            }
            state={{ from: location.pathname }}
            onClick={setSalonUrlToLocalStorage}
          >
            <NavDropdown.Item as="button">Edit profile</NavDropdown.Item>
          </Link>
          {isHostInfoFilled && (
            <Link
              to="host_information"
              state={{ from: location.pathname }}
              onClick={setHostUrlToLocalStorage}
            >
              <NavDropdown.Item as="button">Edit host info</NavDropdown.Item>
            </Link>
          )}
          {auth.salonId && (
            <Link to={`schedules/workstations_rentals`}>
              <NavDropdown.Item as="button">Edit schedule</NavDropdown.Item>
            </Link>
          )}
          {/*<Link to={`salon_catalog`}>*/}
          {/*  <NavDropdown.Item as="button">View salons</NavDropdown.Item>*/}
          {/*</Link>*/}
          {auth.salonId && (
            <Link to={`bookings_list`}>
              <NavDropdown.Item as="button">Bookings</NavDropdown.Item>
            </Link>
          )}
          <NavDropdown.Item as="button">
            <LogOutModal />
          </NavDropdown.Item>
        </>
      ) : (
        <Link to="login_modal">
          <NavDropdown.Item as="button">Log in</NavDropdown.Item>
        </Link>
      )}
    </>
  );
};
