import { useEffect, useState } from "react";
import { getMasterList } from "../../../../../services/salonsApi";
import {
  CustomTable,
  Data,
  Order,
} from "../../../../../components/CustomTable/CustomTable";
import classes from "./MasterList.module.scss";
import { useSearchParams } from "react-router-dom";

const getFormattedTableList = (list) => {
  const _list = list.map((item) => {
    return {
      ...item,
      id: item.userId,
      name: item?.nickname,
      phone: item?.mobilePhone,
      email: item.email,
      status: item.status,
    };
  });

  return _list;
};

const columnCells: any[] = [
  {
    id: "createDate",
    label: "Registration time",
    width: 154,
    isSortingActive: true,
  },
  {
    id: "id",
    label: "ID",
    width: 294,
    isSortingActive: true,
  },
  {
    id: "name",
    label: "Nickname",
    width: 180,
    isSortingActive: true,
    previewUrl: "/profile",
  },
  {
    id: "email",
    label: "E-mail",
    width: 250,
    isSortingActive: true,
  },
  {
    id: "phone",
    label: "Mobile number",
    width: 160,
    isSortingActive: true,
  },
  {
    id: "status",
    label: "Status",
    width: 124,
  },
  {
    id: "action",
    label: "",
    width: 118,
    previewUrl: "/profile",
    editUrl: "/profile_edit/professional_profile",
    memorizedUrl: "memorizedProfileUrl",
  },
];

const COLUMNS_SORTING_FIELDS = {
  id: "userId",
  name: "nickname",
  email: "email",
  phone: "mobilePhone",
  createDate: "createDate",
};

export const MasterList = () => {
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [salonList, setSalonList] = useState([]);
  const [statusSort, setStatusSort] = useState(
    searchParams.get("status") || "All"
  );
  const [order, setOrder] = useState<Order>(
    (searchParams.get("order") as Order) || "desc"
  );
  const [orderBy, setOrderBy] = useState<keyof Data>(
    (searchParams.get("sortBy") as keyof Data) || "createDate"
  );
  const [page, setPage] = useState(Number(searchParams.get("page")) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(searchParams.get("pageSize")) || 25
  );
  const [itemsCount, setItemsCount] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let salonList;

        salonList = await getMasterList({
          page,
          pageSize: rowsPerPage,
          status: statusSort === "All" ? "" : statusSort,
          order,
          sortBy: COLUMNS_SORTING_FIELDS[orderBy],
        });

        setSalonList(getFormattedTableList(salonList.page));
        setItemsCount(salonList.itemsCount);

        setSearchParams({
          page: `${page}`,
          pageSize: `${rowsPerPage}`,
          status: statusSort === "All" ? "" : statusSort,
          order,
          sortBy: orderBy,
        });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchData();
  }, [order, orderBy, page, rowsPerPage, statusSort]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={classes.masterList_wrapper}>
      <h2 className={classes.page_main_title}>Artists list</h2>
      <div className={classes.table_wrapper}>
        <CustomTable
          isLoading={isLoading}
          tableList={salonList}
          page={page}
          statusSort={statusSort}
          setStatusSort={setStatusSort}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          itemsCount={itemsCount}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          columnCells={columnCells}
        />
      </div>
    </div>
  );
};
