import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import classes from "./CreateModeSalon.module.scss";
import { SalonInformation } from "../../pages/salonInformation/salonInformation";
import { WorkingHours } from "../../pages/workingHours/workingHours";
import { SalonConcept } from "../../pages/salonConcept/salonConcept";
import { AmenitiesExpectations } from "../../pages/amenitiesExpectations/amenitiesExpectations";
import clearFormData from "../../../../profile/EditProfileForm/Stepper/services/clearFormData";
import { vw } from "../../../../../styles";
import {
  CreateProfileWrapper,
  ProgressTabs,
  TabsWrapper,
} from "./CreateModeSalon-styles";
import { Grid, Tab, useMediaQuery, useTheme } from "@mui/material";
import { ProgressTab } from "../../../../profile/components/progress-tab";
import { WorkstationRentals } from "../../pages/v2/workstation-rentals";
import { Schedule } from "../../../../../v2/pages/workstations-management/pages";

const TABS = [
  {
    component: SalonInformation,
    title: "Salon Information",
    value: 0,
  },
  {
    component: WorkingHours,
    title: "Working Hours",
    value: 1,
  },
  {
    component: SalonConcept,
    title: "Salon Concept",
    value: 2,
  },
  {
    component: AmenitiesExpectations,
    title: "Salon amenities & coworking expectations",
    value: 3,
  },
  {
    component: WorkstationRentals,
    title: "Workstations rentals, terms & pricing",
    value: 4,
  },
  {
    component: Schedule,
    title: "Availability schedule",
    value: 5,
  },
];

export const CreateModeSalon = () => {
  const location = useLocation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const activeStep = (location.state as any)?.activeStep || 0;

  const tab = TABS[activeStep];

  useEffect(() => {
    return () => {
      clearFormData();
    };
  }, []);

  return (
    <CreateProfileWrapper>
      <Grid
        container
        direction={isTablet ? "column" : "row"}
        spacing={{ xs: vw(42), sm: 7 }}
        wrap="nowrap"
      >
        <Grid item>
          <TabsWrapper>
            <ProgressTabs
              value={activeStep}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {TABS.map(({ title, value }, idx) => {
                return (
                  <Tab
                    disableTouchRipple
                    disableFocusRipple
                    value={value}
                    key={value}
                    label={
                      <ProgressTab
                        title={title}
                        index={idx}
                        active={activeStep}
                      />
                    }
                  />
                );
              })}
            </ProgressTabs>
          </TabsWrapper>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.forms_wrapper}>
            {tab && <tab.component />}
          </div>
        </Grid>
      </Grid>
    </CreateProfileWrapper>
  );
};
