import React from "react";
import classes from "./notificationFooter.module.scss";
import linkedinIcon from "../../../assets/icons/Icon_Linkedin.svg";
import instagramIcon from "../../../assets/icons/Icon_Insta.svg";
import facebookIcon from "../../../assets/icons/Icon_Facebook.svg";

function NotificationFooter() {
  return (
    <footer className={classes.notification_footer}>
      <div className={classes.title}> Follow us:</div>
      <div className={classes.social_links_wrapper}>
        <div>
          <a href="https://www.linkedin.com/company/vervvey" target="_blank">
            <img
              src={linkedinIcon}
              alt="link"
              className={classes.social_link}
            />
          </a>
        </div>
        <div>
          <a href="https://instagram.com/vervvey" target="_blank">
            <img
              src={instagramIcon}
              alt="link"
              className={classes.social_link}
            />
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/vervvey.beauty" target="_blank">
            <img
              src={facebookIcon}
              alt="link"
              className={classes.social_link}
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default NotificationFooter;
