import {StyledButton, Title, Wrapper} from "./featured-block-styles";
import React, {useEffect, useState} from "react";
import {Slide} from "./slide";
import {AdaptiveText, Carousel} from "../../../../components";
import {TitleDivider} from "../../../../components/v2/title-divider";
import {colors} from "../../../../styles";
import {getSalonList} from "@services/salonsApi";
import {Link} from "react-router-dom";

const shuffled = (list) =>
    [...list].sort(() => 0.5 - Math.random()).slice(0, 9);

const FeaturedBlock = () => {
    const [salonList, setSalonList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let salonList = await getSalonList({
                page: 0,
                pageSize: 100,
                city: "",
                workstationType: "",
                status: "active",
                showInFeaturedSalons: true,
            });

            setSalonList(shuffled(salonList.page));
        };

        fetchData();
    }, []);

    return (
        <Wrapper>
            <Title>
                <AdaptiveText
                    xl={{size: 44, height: 56}}
                    xs={{size: 30, height: 38}}
                    sm={{size: 30, height: 38}}
                    weight={600}
                    fontFamily="Greenwich SemBd"
                    align="center"
                    uppercase
                >
                    our featured salons
                </AdaptiveText>
            </Title>
            <TitleDivider bg={colors.blu4}/>
            <AdaptiveText
                xl={{size: 26, height: 33}}
                xs={{size: 18, height: 20}}
                weight={100}
                fontFamily={"Greenwich ExtLt"}
                align="center"
            >
                Choose a salon that suits your vibe
            </AdaptiveText>
            <Carousel
                autoplay
                showSlides={{xs: 1, md: 2, lg: 2, xl: 3}}
                bigNavigation
                withDots
                slides={salonList.map((item) => (
                    <Slide item={item}/>
                ))}
            ></Carousel>
            <Link to={`/salon_catalog`}>
                <StyledButton
                    variant="contained"
                    color="secondary"
                    disableRipple
                >
                    find your salon
                </StyledButton>
            </Link>
        </Wrapper>
    );
};

export default FeaturedBlock;
