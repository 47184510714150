import classes from "./ProfessionalTrainings.module.scss";
import { CustomInput } from "../../../../../components/customInput/customInput";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addCertificateImage,
  deleteImage,
  updateMasterProfileData,
} from "../../../../../services/masterApi";
import { BackButton } from "@components/BackButton/BackButton";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import { getFormattedMasterProfileForBackend } from "../../../../../utils/utils";
import { ExplanationIcon } from "@components";
import delete_icon from "../../../../../assets/icons/delete.svg";
import default_upload_photo from "../../../../../assets/images/default_upload_photo.webp";
import {
  DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
  DEFAULT_ERROR_TEXT_MESSAGE,
  DEFAULT_FILE_SIZE_LIMIT_KB,
} from "../../../../../constants/common.consts";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { NextButton } from "./professionalTrainings-styles";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const INITIAL_VALUES = {
  certificates: [
    {
      imageId: "3a2d629c-ea15-43bc-9673-a80f92f1cb2a",
      imageUrl:
        "https://pbs.twimg.com/profile_images/1455185376876826625/s1AjSxph_400x400.jpg",
      organizationName: "Design academy",
    },
  ],
};

const MAX_DIPLOMAS_NUMBER = 6;

const DEFAULT_ERROR_STATE = {
  certificatePhotoDescription: "",
  certificatePhoto: "",
};

const disabledStyle = {
  pointerEvents: "none" as any,
  opacity: 0.65,
};

export const ProfessionalTrainings = () => {
  const { auth } = useSelector((state: AppState) => state);
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [certificatePhotoDescription, setPhotoDescription] = useState("");
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [certificatePhotoDescription]);

  const onChangeCertificatesHandler = (value: string, imageId: number) => {
    const _certificates = masterProfileFormData.certificates.map((el) =>
      el.imageId === imageId ? { ...el, organizationName: value } : el
    );

    setMasterProfileFormData({
      ...masterProfileFormData,
      certificates: _certificates,
    });
  };

  const deleteCertificateHandler = async (imageId: number) => {
    const addDeleteTypeToDeletedCertificates = () => {
      return masterProfileFormData.certificates.map((el) => {
        if (el.imageId === imageId) return { ...el, operation: "DELETE" };

        return el;
      });
    };

    // create mode
    if (!userId) {
      const deleteImageFromStorage = async () => {
        const deletedImageData = await deleteImage(auth.userId, imageId);

        setFormDataToStorage({
          ...masterProfileFormData,
          certificates: addDeleteTypeToDeletedCertificates(),
        });
      };

      deleteImageFromStorage();
    }

    setMasterProfileFormData((prevState) => {
      return {
        ...prevState,
        certificates: addDeleteTypeToDeletedCertificates(),
      };
    });

    // update mode
    if (userId) {
      try {
        setError(null);

        const updatedMasterProfile = await updateMasterProfileData(
          getFormattedMasterProfileForBackend(
            {
              ...masterProfileFormData,
              certificates: addDeleteTypeToDeletedCertificates(),
            },
            userId,
            auth
          ),
          userId
        );
      } catch (error) {
        setError(error);
      }
    }
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitCreateMode = async () => {
    setFormDataToStorage(masterProfileFormData);

    navigate(location, {
      state: {
        activeStep: 4,
      },
    });
  };

  const isFieldsValid = () => {
    if (!certificatePhotoDescription) {
      setErrs((prevState) => {
        return {
          ...prevState,
          certificatePhotoDescription: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      return false;
    }

    return true;
  };

  const resetOrganizationField = () => {
    setPhotoDescription("");
  };

  const addFileImage = async (fileImage) => {
    try {
      setIsLoading(true);
      setError(null);

      const _formData = new FormData();

      // Update the formData object
      _formData.append("certificate", fileImage, fileImage.name);
      _formData.append("organizationName", certificatePhotoDescription);
      _formData.append("type", "certificate");
      _formData.append("lang", "EN");

      let _certificates = [];

      // create mode
      if (!userId) {
        const responseImageData = await addCertificateImage(
          _formData,
          auth.userId
        );

        _certificates = [
          {
            imageId: responseImageData.imageId,
            imageUrl: responseImageData.imageUrl,
            organizationName: responseImageData.organizationName,
          },
          ...masterProfileFormData.certificates,
        ];
      }

      // update mode
      if (userId) {
        const responseImageData = await addCertificateImage(_formData, userId);

        _certificates = [
          {
            certificateId: responseImageData.certificateId,
            imageId: responseImageData.imageId,
            imageUrl: responseImageData.imageUrl,
            organizationName: responseImageData.organizationName,
          },
          ...masterProfileFormData.certificates,
        ];
      }

      setFormDataToStorage({
        ...masterProfileFormData,
        certificates: _certificates,
      });

      setMasterProfileFormData({
        ...masterProfileFormData,
        certificates: _certificates,
      });

      resetOrganizationField();

      setErrs((prevState) => {
        return {
          ...prevState,
          certificatePhoto: "",
        };
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const onUploadImageHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    imageId
  ) => {
    if (!isFieldsValid()) return;

    if (e.target.files && e.target.files?.length > 0) {
      const fileImage = e.target.files[0];

      const fileSize = fileImage.size;
      const fileSizeInKB = fileSize / 1024; // this would be in kilobytes defaults to bytes

      if (fileSizeInKB < DEFAULT_FILE_SIZE_LIMIT_KB) {
        addFileImage(fileImage);
      } else {
        setErrs((prevState) => {
          return {
            ...prevState,
            certificatePhoto: DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
          };
        });
      }
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  const submitUpdateMode = async () => {
    navigate(`/profile/${userId}`);
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  return (
    <div className={classes.trained_with_wrapper}>
      <h2 className={classes.page_title}>Professional trainings</h2>
      <h3 className={classes.title}>
        Share your professional background and training history here
      </h3>
      <form className={classes.flex_wrapper} onSubmit={handleSubmit}>
        <div className={classes.diplomas_wrapper}>
          {masterProfileFormData.certificates?.filter(
            (certificate) => certificate.operation !== "DELETE"
          )?.length < MAX_DIPLOMAS_NUMBER && (
            <div className={classes.certificate_card_upload}>
              <CustomInput
                className={classes.organization_name_input}
                text={"Name of the school or training provider "}
                name={"organizationName"}
                type={"text"}
                value={certificatePhotoDescription}
                onChange={setPhotoDescription}
                placeholder={"Beauty School name"}
                err={errs.certificatePhotoDescription}
              />
              <div>
                <div className={classes.certificate_upload_title}>
                  Upload your diploma{" "}
                  <ExplanationIcon text={"You can upload up to 6 diplomas"} />
                </div>
                <div className={classes.upload_wrapper}>
                  <div className={classes.camera_wrapper}>
                    <img src={default_upload_photo} alt={"vv_file"} />
                  </div>
                  <div className={classes.upload_btn_wrapper}>
                    <label
                      style={
                        !certificatePhotoDescription ? disabledStyle : null
                      }
                      className={classes.upload_label}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => onUploadImageHandler(e, 1)}
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                      />
                      Upload photo
                    </label>
                  </div>
                  {errs.certificatePhoto && (
                    <div className={classes.err_text}>
                      {errs.certificatePhoto}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/*filter by DELETE need for BE DELETE LOGIC*/}
          {masterProfileFormData?.certificates
            ?.filter((certificate) => certificate.operation !== "DELETE")
            .map((el) => {
              return (
                <div className={classes.certificate_card} key={el.imageId}>
                  <div className={classes.delete_btn_wrapper}>
                    <button
                      type="button"
                      className={classes.delete_btn}
                      onClick={() => deleteCertificateHandler(el.imageId)}
                    >
                      <img src={delete_icon} alt="delete" />
                    </button>
                  </div>

                  <div key={el.imageId} className={classes.item_wrapper}>
                    <div className={classes.upload_wrapper}>
                      <div className={classes.camera_wrapper}>
                        <img src={el.imageUrl} alt={"vv_file"} />
                      </div>
                    </div>
                    <CustomInput
                      text={"Name of the school or training provider "}
                      name={"photo"}
                      type={"text"}
                      value={el.organizationName}
                      onChange={(value) =>
                        onChangeCertificatesHandler(value, el.imageId)
                      }
                      disabled
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.btn_bottom_wrapper}>
          {!userId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <NextButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </NextButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
