import { object, string } from "yup";

export const validationSchema = object({
  comment: string().default(""),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({}),
});
