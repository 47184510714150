import classes from "./amentitiesExpectations.module.scss";
import { ExplanationIcon } from "@components";
import { useCallback, useEffect, useState } from "react";
import { CustomCheckboxButton } from "../../../../../components/customCheckboxButton/customCheckboxButton";
import { CustomTextarea } from "../../../../../components/customTextarea/customTextarea";
import CustomCheckbox from "../../../../../components/checkbox/customCheckbox";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../constants/common.consts";
import setSalonFormDataToStorage from "../../Stepper/services/setSalonFormDataToStorage";
import { updateSalonFormData } from "../../../../../services/salonsApi";
import { getFormattedSalonFormDataForBEUpdateMode } from "../../../../../utils/utils";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const DEFAULT_ERROR_STATE = {
  amenitiesDescription: "",
  specificInformation: "",
  checkboxesAmenitiesExpectations: "",
  coworkingCollaborationCheckbox: "",
};

export const AmenitiesExpectations = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [error, setError] = useState(null);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);

  const { salonFormData, setSalonFormData } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const { salonId } = useParams();

  const onCheckboxChange = (value: boolean, id: number) => {
    const _checkboxesOutSideWork =
      salonFormData.checkboxesAmenitiesExpectations.map((el) =>
        el.amenityId === id ? { ...el, checked: value } : el
      );

    setSalonFormData({
      ...salonFormData,
      checkboxesAmenitiesExpectations: _checkboxesOutSideWork,
    });
  };

  const onCheckboxHandler = (id: number, value: boolean, e: any) => {
    const { name } = e.target;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };

  const onSpecificInformationCheckboxHandler = (
    id: number,
    value: boolean,
    e: any
  ) => {
    const { name } = e.target;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
      specificInformation: "",
    });
  };

  const handleInputChange = (_, e) => {
    const { name, value } = e.target;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [salonFormData]);

  const isFieldsValid = () => {
    let isError = false;

    if (
      !salonFormData.specificInformation &&
      !salonFormData.specificInformationCheckbox
    ) {
      setErrs((prevState) => {
        return {
          ...prevState,
          specificInformation: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!salonFormData.coworkingCollaborationCheckbox) {
      setErrs((prevState) => {
        return {
          ...prevState,
          coworkingCollaborationCheckbox: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    const isAllCheckboxesEmpty = () => {
      const isEmpty = salonFormData.checkboxesAmenitiesExpectations
        .flat()
        .some((item) => item.checked);

      return !isEmpty;
    };

    if (isAllCheckboxesEmpty()) {
      setErrs((prevState) => {
        return {
          ...prevState,
          checkboxesAmenitiesExpectations: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    return !isError;
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const data = await updateSalonFormData(
        getFormattedSalonFormDataForBEUpdateMode(salonFormData, salonId, auth),
        salonId
      );

      navigate(`/salon_profile/${salonId}`);
    } catch (error) {
      setError(error);
    }
  };

  const submitCreateMode = async () => {
    setSalonFormDataToStorage(salonFormData);

    navigate(location, {
      state: {
        activeStep: 4,
      },
    });
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!salonId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedSalonUrl"));
  };

  useEffect(() => {
    setSalonFormData({
      ...salonFormData,
      specificInformationCheckbox: !salonFormData.specificInformation,
    });
  }, []);

  return (
    <div>
      <h2 className={`page_main_title`}>
        Salon amenities & coworking expectations
      </h2>
      <h3 className={`page_blue_subtitle`}>
        Specify which salon amenities are available for artists and their
        customers
      </h3>
      <form onSubmit={handleSubmit} noValidate>
        <div className={classes.checkboxes_wrapper}>
          <div className={classes.checkboxes_title}>
            <p>Salon amenities</p>
            <ExplanationIcon
              text={
                "Select all amenities available for artists and their clients during their time at your salon"
              }
            />
            <div className={classes.req_sign}>*</div>
          </div>
          <div className={classes.checkboxes}>
            {salonFormData?.checkboxesAmenitiesExpectations?.map((el) => {
              return (
                <div key={el.amenityId}>
                  <CustomCheckboxButton
                    label={el.label}
                    value={el.checked}
                    id={el.amenityId}
                    onChange={onCheckboxChange}
                  />
                </div>
              );
            })}
          </div>
          {errs.checkboxesAmenitiesExpectations && (
            <div className={classes.err_text}>
              {errs.checkboxesAmenitiesExpectations}
            </div>
          )}
        </div>
        <div className={`inputs_group`}>
          <CustomTextarea
            text={
              "Provide any additional information regarding amentities availability"
            }
            name={"amenitiesDescription"}
            value={salonFormData.amenitiesDescription}
            onChange={handleInputChange}
            height={124}
            info={
              "Specify any additional information regarding amenities availability, you can also add any amenities that are not on the list above"
            }
          />
        </div>
        <div className={classes.coworking_collaboration_wrapper}>
          <div className={classes.coworking_collaboration_title}>
            <p>Coworking collaboration expectations </p>
            <ExplanationIcon
              text={
                "Please confirm that you are able to meet these expectations as a host"
              }
            />
            <div className={classes.req_sign}>*</div>
          </div>
          <div className={classes.coworking_collaboration_text}>
            As a minimum courtesy VERVVEY hosts provide electrical outlets,
            trash receptacle, towel service and access to backbar
            (shampoo/conditioner only) for visiting beauty artist
          </div>
          <div className={classes.coworking_collaboration_checkbox}>
            <CustomCheckbox
              name="coworkingCollaborationCheckbox"
              children={"We are able to meet these expectations as a host"}
              value={salonFormData?.coworkingCollaborationCheckbox}
              callback={(_, value, e) => onCheckboxHandler(_, value, e)}
              id={"coworkingCollaborationCheckbox"}
              checked={salonFormData?.coworkingCollaborationCheckbox}
            />
          </div>
          {errs.coworkingCollaborationCheckbox && (
            <div className={classes.err_text}>
              {errs.coworkingCollaborationCheckbox}
            </div>
          )}
        </div>

        <div className={`inputs_group`}>
          <CustomTextarea
            className={classes.text_area}
            text={
              "Specific requirements for visiting artists to secure smooth coworking"
            }
            name={"specificInformation"}
            value={salonFormData.specificInformation}
            onChange={handleInputChange}
            require={!salonFormData.specificInformationCheckbox}
            err={errs.specificInformation}
            height={86}
            placeholder={
              "Stylists typically wear all black. No children or extra guests. Please clean up after yourself and your clients at the end \n" +
              "of the day. Thank you!"
            }
            info={
              "Make sure you detail here any specific requirements for visiting artists, incl. dress code, vaccination, etc. Otherwise no specific terms & conditions for coworking are expected."
            }
            disabled={salonFormData.specificInformationCheckbox}
          />
        </div>
        <div className={classes.specific_information_checkbox}>
          <CustomCheckbox
            name="specificInformationCheckbox"
            children={
              "We don't have any specific requirements for booking artists"
            }
            value={salonFormData?.specificInformationCheckbox}
            callback={(_, value, e) =>
              onSpecificInformationCheckboxHandler(_, value, e)
            }
            id={"specificInformationCheckbox"}
            checked={salonFormData?.specificInformationCheckbox}
          />
        </div>
        <div className={"btn_bottom_wrapper"}>
          {!salonId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
