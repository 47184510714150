import { AdaptiveText } from "@components";
import React, { useEffect } from "react";
import { Wrapper, ButtonContainer, IconBlock } from "./success-apply-grant-styles";
import { Button } from "@mui/material";
import { ReactComponent as Allow } from "../../assets/icons/allow.svg";

const SuccessGrantApply = ({ okCallback }) => {
  return (
    <Wrapper flexDirection="column">
      <IconBlock>
        <Allow />
      </IconBlock>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={600}
        fontFamily="Greenwich SemBd"
        align="center"
      >
          Your application is successfully submitted. We admire your intention to develop with VERVVEY talent grant! We will contact you shortly to schedule a 15 min online interview to discuss your background and motivation.
      </AdaptiveText>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={600}
        fontFamily="Greenwich SemBd"
        align="center"
      >
          In the meantime you are all set up to explore coworking with the most interesting salons in the city! Request pre-approval at as many VERVVEY coworking spaces, as you wish, to start building your professional network.
      </AdaptiveText>

      <ButtonContainer>
        <Button
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
            FIND YOUR SALON
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default SuccessGrantApply;
