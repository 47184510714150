import React from "react";
import {
  Wrapper,
  Card,
  Divider,
  StepRow,
  StepNum,
  StepDescription,
  StyledButton,
  TextPaddingBox,
  BottomRow,
  BottomBtnBlock,
} from "./steps-block-styles";
import { Grid } from "@mui/material";
import { AdaptiveText, Carousel } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";
import classes from "../../GrantsForBeautyArtists.module.scss";

const steps = [
  {
    num: 1,
    title: "Make online application",
    text: `Applications are made through the site. To submit your application, you must first create an account and complete your online profile – it only takes 15 minutes.`,
    description: `Please ensure that you provide complete and accurate information in your profile, it helps us to get to know you better!`,
  },
  {
    num: 2,
    title: "Share your motivation",
    text: `We will contact you, using the contact details provided on your profile, to schedule a 15 min online interview to discuss your background and motivation.`,
    description: `Once your application is complete, please allow for a processing period of 5 working days.`,
  },
  {
    num: 3,
    title: "Receive Decision",
    text: `All applications are submitted to the committee that advises on awarding VERVVEY Talent grants.`,
    description: `Those who are awarded will receive an award letter within 5 working days and will be able to start coworking using the grant funding.`,
  },
];

export const StepsBlock = () => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  function handleJoinNowBtn() {
    navigate("/register_modal");
  }

  return (
    <Wrapper>
      <Card>
        <TextPaddingBox>
          <AdaptiveText
            xl={{ size: 44, height: 56 }}
            xs={{ size: 30, height: 38 }}
            weight={400}
            uppercase
            align="center"
          >
            How to get a grant from Vervvey
          </AdaptiveText>
        </TextPaddingBox>
        <Divider />
        <TextPaddingBox>
          <AdaptiveText
            xl={{ size: 26, height: 33 }}
            xs={{ size: 18, height: 20 }}
            weight={300}
          >
            Get financed in 3 easy steps:
          </AdaptiveText>
        </TextPaddingBox>
        <Carousel
          showSlides={{ xs: 1 }}
          bigNavigation
          slides={steps.map((step) => (
            <Grid container>
              <StepRow item container wrap="nowrap">
                <Grid item>
                  <StepNum
                    xl={{ size: 270, height: 270 }}
                    sm={{ size: 120, height: 120 }}
                    xs={{ size: 180, height: 180 }}
                    weight={600}
                  >
                    {step.num}
                  </StepNum>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <AdaptiveText
                        xl={{ size: 48, height: 61 }}
                        sm={{ size: 22, height: 28 }}
                        xs={{ size: 22, height: 28 }}
                        weight={700}
                        uppercase
                      >
                        {step.title}
                      </AdaptiveText>
                      <StepDescription
                        xl={{ size: 24, height: 28 }}
                        sm={{ size: 18, height: 21 }}
                        xs={{ size: 18, height: 21 }}
                        weight={300}
                      >
                        {step.text}
                      </StepDescription>
                    </Grid>
                    <Grid
                      item
                      container
                      wrap="nowrap"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      gap="36px"
                      sx={{ display: { xs: "none", xl: "flex" } }}
                    >
                      <Grid item>
                        <AdaptiveText
                          xl={{ size: 20, height: 23 }}
                          sm={{ size: 14, height: 16 }}
                          xs={{ size: 14, height: 16 }}
                          weight={300}
                        >
                          {step.description}
                        </AdaptiveText>
                      </Grid>
                      {/*<Grid sx={{ display: { xs: "none", xl: "flex" } }}>*/}
                      {/*  <StyledButton*/}
                      {/*    disableTouchRipple*/}
                      {/*    disableFocusRipple*/}
                      {/*    onClick={handleJoinNowBtn}*/}
                      {/*    variant="contained"*/}
                      {/*  >*/}
                      {/*    Apply now*/}
                      {/*  </StyledButton>*/}
                      {/*</Grid>*/}
                    </Grid>
                  </Grid>
                </Grid>
              </StepRow>

              <BottomRow
                item
                xs={12}
                sx={{ display: { xs: "flex", xl: "none" } }}
              >
                <AdaptiveText
                  xl={{ size: 20, height: 23 }}
                  sm={{ size: 14, height: 16 }}
                  xs={{ size: 14, height: 16 }}
                  weight={300}
                >
                  {step.description}
                </AdaptiveText>
              </BottomRow>
            </Grid>
          ))}
        ></Carousel>
        {/*<BottomBtnBlock>*/}
        {/*  <StyledButton*/}
        {/*    disableTouchRipple*/}
        {/*    disableFocusRipple*/}
        {/*    onClick={handleJoinNowBtn}*/}
        {/*    variant="contained"*/}
        {/*  >*/}
        {/*    Apply now*/}
        {/*  </StyledButton>*/}
        {/*</BottomBtnBlock>*/}
      </Card>
    </Wrapper>
  );
};
