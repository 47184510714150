import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { alpha } from "@mui/material";

const Wrapper = styled.div`
  width: 100%;
  margin: 100px 0;
  background-color: ${colors.blu1};
  border-radius: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("60 0")};
    border-radius: ${vw(20)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 0;

  span {
    padding: 0 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("60 0")};

    span {
      padding: ${vw("0 20")};
    }
  }
`;

const Divider = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 3px;
  background-color: ${alpha(colors.pink4, 0.3)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(20)};
    height: ${vw(3)};
  }
`;

const Amentities = styled.div`
  width: 100%;
  margin: 60px 0;
  padding: 0 48px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("32 0 48")};
    padding: ${vw("0 16")};
  }
`;

const Amentity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  span {
    padding: 0;
  }

  img {
    width: 22px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};

    img {
      width: ${vw(22)};
    }
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0 48px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 16")};
    gap: ${vw(16)};
  }
`;

export { Wrapper, Container, Divider, Amentities, Amentity, AdditionalInfo };
