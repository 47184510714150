import Select, { components } from "react-select";
import classes from "./mySelect.module.scss";
import arrow_icon from "../../assets/icons/Vector.svg";
import { ExplanationIcon } from "@components";
import { useMediaQuery, useTheme } from "@mui/material";
import { vw, vw1440 } from "../../styles";
import { RequireSign } from "../RequireSign/RequireSign";

type PropsType = {
  text?: string;
  options: any;
  value: string;
  name?: string;
  onChange: (value: string, actionMeta?: any, newValue?: any) => void;
  isMulti?: boolean;
  require?: boolean;
  info?: string;
  placeholder?: string;
  maxMenuHeight?: number;
  isDisabled?: boolean;
  err?: string;
  isClearable?: boolean;
  variant?: "default" | "pink";
};

export const MySelect = ({
  text = "",
  options,
  value,
  name,
  onChange,
  require,
  info,
  err = "",
  variant = "default",
  ...restProps
}: PropsType) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onChangeHandler = (newValue: any, actionMeta) => {
    onChange(newValue?.value || newValue, actionMeta, newValue);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className={classes.img_wrapper}>
          <img
            src={arrow_icon}
            alt="vv-icon"
            style={{ transform: props.isFocused ? "rotate(180deg)" : null }}
          />
        </div>
      </components.DropdownIndicator>
    );
  };

  let borderColor = err ? "1px solid #E23600" : "none";
  let textColor = "#8A8383";

  if (variant === "pink") {
    borderColor = "1px solid #EB1864 !important";
    textColor = "#EB1864";
  }

  const customStyles = {
    control: (provided, state) =>
      isMobile
        ? {
            ...provided,
            display: "flex",
            alignItems: "flex-start",
            background: "#fff",
            minHeight: vw(60),
            height: vw(60),
            border: borderColor,
            borderRadius: vw(8),
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: vw(20),
            color: textColor,
          }
        : {
            ...provided,
            background: "#fff",
            minHeight: "60px",
            height: "60px",
            border: borderColor,
            borderRadius: "8px",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "20",
            color: textColor,
          },

    focus: (provided, state) => ({
      ...provided,
      border: "1px solid #639FF9",
    }),

    menu: (provided, state) => ({
      ...provided,
      marginTop: "0px",
      backgroundColor: "#fff",
      zIndex: 333,
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "::-webkit-scrollbar-track": {
        paddingRight: "0px",
        marginRight: "0px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#E6F0FF",
        borderRadius: "8px",
      },
    }),

    option: (provided, state) =>
      isMobile
        ? {
            ...provided,
            backgroundColor: "#fff",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw(20),
            lineHeight: vw(24),
            color: "#121212",
          }
        : {
            ...provided,
            backgroundColor: "#fff",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            color: "#121212",
          },

    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) =>
      isMobile
        ? {
            ...provided,
            height: vw(60),
            marginRight: vw(10),
          }
        : {
            ...provided,
            height: "60px",
            marginRight: "10px",
          },
    singleValue: (provided, state) =>
      isMobile
        ? {
            ...provided,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw(20),
          }
        : {
            ...provided,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
          },
    valueContainer: (provided, state) =>
      isMobile
        ? {
            ...provided,
            margin: 0,
            minHeight: vw(60),
            height: vw(60),
            fontSize: vw(20),
            lineHeight: vw(20),
          }
        : {
            ...provided,
          },
    placeholder: (defaultStyles) =>
      isMobile
        ? {
            ...defaultStyles,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: vw(20),
            lineHeight: vw(20),
            color: variant === "pink" ? "#EB1864" : "#BABABA",
          }
        : {
            ...defaultStyles,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            color: variant === "pink" ? "#EB1864" : "#BABABA",
          },
  };

  const labelStyle = err ? "#E23600" : "#121212";

  return (
    <div className={classes.my_select_wrapper} id={"div_"+name}>
      {text && (
        <label className={classes.select_label} style={{ color: labelStyle }}>
          <div className={classes.text_icon_wrapper}>
            {text} {info && <ExplanationIcon text={info} />}
          </div>
          {require && <RequireSign />}
        </label>
      )}
      <Select
        value={options.filter((elValue) => elValue.value === value)}
        options={options}
        name={name}
        onChange={onChangeHandler}
        components={{
          DropdownIndicator,
        }}
        styles={customStyles}
        className={classes.values}
        menuPortalTarget={document.body}
        {...restProps}
      />
      {err && <div className={classes.err_text}>{err}</div>}
    </div>
  );
};
