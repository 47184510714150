import React from "react";
import { AdaptiveText } from "../../../../components";
import { Wrapper, Divider, Specific } from "./salon-booking-styles";
import { colors } from "../../../../styles";

const SalonBooking = ({ salonProfile }) => {
  return (
    <Wrapper>
      <Divider />
      <AdaptiveText
        xl={{ size: 24, height: 28 }}
        xs={{ size: 20, height: 24 }}
        fontFamily="Greenwich"
        weight={700}
        color={colors.pink4}
      >
          Before you book: our ground rules for coworking
      </AdaptiveText>
      <Specific
        xl={{ size: 24, height: 28 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={400}
      >
        {salonProfile?.specificCoworkingReq}
      </Specific>
    </Wrapper>
  );
};

export default SalonBooking;
