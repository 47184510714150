import { Record } from "../../../../accounting/components/record";
import {
  Wrapper,
  DetailBlock,
  DetailMain,
  DetailRow,
  Status,
} from "./details-styles";
import { CommentInput, StatusSelect } from "../../../components";
import moment from "moment";
import { DayStatusSelect } from "../../../../../components";

const Details = ({ detailData, statuses }) => {
  return (
    <Wrapper>
      <DetailBlock>
        <DetailMain>
          <CommentInput
            defaultValue={detailData?.comment || ""}
            data={detailData}
          />
        </DetailMain>
        {detailData?.schedules?.map((item) => (
          <DetailRow key={item.id}>
            <Record
              w={100}
              label="Booking date:"
              value={moment(item?.rentDate).format("YYYY-MM-DD")}
            />
            <Status>
              <DayStatusSelect
                defaultValue={item?.status}
                statuses={statuses}
                data={item}
              />
            </Status>
          </DetailRow>
        ))}
      </DetailBlock>
    </Wrapper>
  );
};

export default Details;
