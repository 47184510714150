import { Wrapper, Title } from "./record-styles";
import { AdaptiveText } from "../../../../components";
import { colors } from "../../../../styles";
import { FC } from "react";

interface IProps {
  label?: string;
  value?: string;
  w?: number;
  totalW?: number;
}

const Record: FC<IProps> = ({ label, value, w, totalW }) => {
  return (
    <Wrapper width={totalW}>
      <Title width={w}>
        <AdaptiveText
          xl={{ size: 14, height: 16 }}
          xs={{ size: 14, height: 16 }}
          fontFamily="Greenwich"
          as="span"
          color={colors.grey2}
        >
          {label}
        </AdaptiveText>
      </Title>
      <AdaptiveText
        xl={{ size: 14, height: 16 }}
        xs={{ size: 14, height: 16 }}
        fontFamily="Greenwich"
        weight={500}
        as="span"
      >
        {value}
      </AdaptiveText>
    </Wrapper>
  );
};

export default Record;
