import { AdaptiveText } from "../../components";
import React from "react";
import { StyledButton, Wrapper, ButtonContainer } from "./status-change-styles";

const StatusChange = ({ close, okCallback }) => {
  return (
    <Wrapper flexDirection="column">
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        uppercase
        variant="body1"
      >
        Are you sure you want to change status?
      </AdaptiveText>
      <ButtonContainer>
        <StyledButton
          variant="outlined"
          disableTouchRipple
          disableFocusRipple
          onClick={close}
        >
          No
        </StyledButton>
        <StyledButton
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
          Yes
        </StyledButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default StatusChange;
