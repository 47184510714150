import { ReactComponent as TooltipIcon } from "../../../assets/icons/tooltip-icon.svg";

import { StyledTooltip, IconContainer } from "./label-tooltip-styles";

const LabelTooltip = ({ tooltip }) => {
  return (
    <StyledTooltip
      title={tooltip}
      placement="right-end"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconContainer>
        <TooltipIcon />
      </IconContainer>
    </StyledTooltip>
  );
};

export default LabelTooltip;
