import React from "react";
// import { useGetPokemonByNameQuery } from "../services/pokemonApi";

function About() {
  // const { data, error, isLoading } = useGetPokemonByNameQuery("regirock");

  return (
    <div>
      <h1>This is the about page</h1>
    </div>
  );
}

export default About;
