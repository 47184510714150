import styled from "@emotion/styled";
import { Select } from "@mui/material";
import { colors, vw } from "../../../../styles";

const StyledSelect = styled(Select)`
  width: 100%;

  &.MuiOutlinedInput-root {
    border-radius: 8px;
    font-family: Greenwich, serif;
    font-size: 20px;
    line-height: 20px;
    height: 50px;
    color: ${colors.accent};

    & .MuiSelect-select {
      padding: 15px 16px;
      background-color: ${colors.blu1};
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: ${vw(8)};
      font-size: ${vw(20)};
      line-height: ${vw(20)};
      height: ${vw(50)};

      & .MuiSelect-select {
        padding: ${vw(15)} ${vw(16)};
      }

      & textarea {
        min-height: ${vw(20)};
      }

      & input {
        height: ${vw(20)};
      }
    }

    svg {
      position: absolute;
      top: 9px;
      width: 32px;
      height: 32px;

      path {
        fill: ${colors.pink4};
      }

      ${(props) => props.theme.breakpoints.down("sm")} {
        top: ${vw(9)};
        width: ${vw(32)};
        height: ${vw(32)};
      }
    }

    fieldset {
      top: 0;
      border: none;

      legend {
        display: none;
      }
    }

    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.blu4};
      }
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;

  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(8)};
    font-size: ${vw(20)};
    line-height: ${vw(20)};
  }
`;

export { StyledSelect, LabelContainer };
