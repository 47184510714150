import React from "react";
import { Wrapper, StyledButton, TextSlide } from "./stories-block-styles";
import { AdaptiveText, Carousel } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const testimonials = [
  `«Hosting my salon to freelance beauty artists allowed me to become an entrepreneur and laid
  down a path to financial freedom.»`,
  `«VERVVEY has allowed me to create side income from the unused chairs doing what I
  love — taking care of my clients’ hair.»`,
  `«It’s costly to run a salon, and this is a really good way to lower my overheads.»`,
  `«If you’ve got available salon space, you’d be crazy not to use the service. As a bonus
  you enjoy meeting new talented artists, while renting out with VERVVEY!»`,
  `«I usually have at least one empty chair, so it’s nice to be able to fill the space with a
  trusted stylist that I don't have to spend hours interviewing!»`,
  `«VERVVEY help hair salons monetise vacant space inviting freelance artists to
  cowork, I’m happy I was among the first to experience this new ways of working.»`,
  `«VERVVEY does all the work for me. I don’t have to put ads out or look for renters. I
  just need to list my space and then beauty artists show interest.»`,
];

const StoriesBlock = ({ showPopup }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  const becomeHost = (e) => {
    if (auth?.role) {
      e.preventDefault();

      showPopup();
    } else {
      navigate(`/register_modal?mode=${ROLES.SALON_ADMIN}`);
    }
  };

  return (
    <Wrapper>
      <Carousel
        autoplay
        showSlides={{ xs: 1 }}
        slides={testimonials.map((item) => (
          <TextSlide>
            <AdaptiveText
              xl={{ size: 24, height: 28 }}
              xs={{ size: 20, height: 23 }}
              weight={300}
              align="center"
            >
              {item}
            </AdaptiveText>
          </TextSlide>
        ))}
      ></Carousel>
      <StyledButton
        disableTouchRipple
        disableFocusRipple
        onClick={becomeHost}
        variant="contained"
        color="secondary"
      >
        Try hosting
      </StyledButton>
    </Wrapper>
  );
};

export default StoriesBlock;
