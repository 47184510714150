import styled from "@emotion/styled";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 100px;

  & .swiper {
    margin: 64px 80px 0;

    & .carousel-prev {
      left: -38px;
    }
    & .carousel-next {
      right: -38px;
    }

    ${(props) => props.theme.breakpoints.down("lg")} {
      margin: 64px 40px 0;

      & .carousel-prev {
        left: 0;
      }
      & .carousel-next {
        right: 0;
      }
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      margin: ${vw("32 40 0")};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(60)};
  }
`;

const Title = styled.div`
  width: auto;
  padding: 0 79px 12px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 30px 12px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 30 12")};
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & svg {
    width: 84px;
    height: 84px;

    ${(props) => props.theme.breakpoints.down("md")} {
      width: 62px;
      height: 62px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};

    & svg {
      width: ${vw(62)};
      height: ${vw(62)};
    }
  }
`;

export { Wrapper, Title, Card };
