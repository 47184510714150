import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .swiper {
    margin: 32px 10px 50px;

    & .carousel-prev {
      left: -38px;
    }

    & .carousel-next {
      right: -38px;
    }

    ${(props) => props.theme.breakpoints.down("lg")} {
      & .carousel-prev {
        left: 0;
      }

      & .carousel-next {
        right: 0;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("32 10")};
    padding-bottom: ${vw(60)};
  }
`;

const Title = styled.div`
  margin-top: 100px;
  width: auto;
  padding: 0 79px 12px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 30px 12px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 30 12")};
    margin-top: ${vw(60)};
  }
`;

const StyledButton = styled(Button)`
  width: 404px;
  margin-top: 94px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-top: ${vw(60)};
  }
`;

export { Wrapper, StyledButton, Title };
