import React, { useEffect, useState } from "react";
import {
  getAllVibes,
  getAllServices,
} from "../../../../../../services/masterApi";
import {
  getAllBrandOptions,
  getAllSenseOfTeamworkOptions,
} from "../../../../../../services/salonsApi";

const getMappedVibesOptions = (options) => {
  const _vibesOptions = options.map((option) => {
    return {
      vibeId: option.vibeId,
      value: option.name,
      label: option.name,
    };
  });

  return _vibesOptions;
};

const getMappedBrandOptions = (options) => {
  const _options = options.map((option) => {
    return { brandId: option.brandId, value: option.name, label: option.name };
  });

  return _options;
};

const getMappedSenseOfTeamworkOptions = (options) => {
  const _options = options.map((option) => {
    return { sotId: option.sotId, value: option.name, label: option.name };
  });

  return _options;
};

const getMappedServicesOptions = (options) => {
  const _options = options.map((option) => {
    return {
      serviceId: option.serviceId,
      value: option.name,
      label: option.name,
    };
  });

  return _options;
};

export const useFetchSalonConceptOptions = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [senseOfTeamworkOptions, setSenseOfTeamworkOptions] = useState([]);
  const [vibeOptions, setVibeOptions] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        setIsLoading(true);

        const vibes = await getAllVibes();

        setVibeOptions(getMappedVibesOptions(vibes));

        const servicesOptions = await getAllServices();

        setServicesOptions(getMappedServicesOptions(servicesOptions));

        const brandOptions = await getAllBrandOptions();

        setBrandOptions(getMappedBrandOptions(brandOptions));

        const senseOfTeamworkOptions = await getAllSenseOfTeamworkOptions();

        setSenseOfTeamworkOptions(
          getMappedSenseOfTeamworkOptions(senseOfTeamworkOptions)
        );

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchDataOptions();
  }, []);

  return {
    isLoading,
    brandOptions,
    senseOfTeamworkOptions,
    vibeOptions,
    servicesOptions,
  };
};
