import styled from "@emotion/styled";
import { colors } from "../../../../styles";
import { alpha, Link } from "@mui/material";
import { Link as RLink } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px 80px 100px;
  gap: 40px;
`;

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
`;

const RequestDetails = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  display: flex;
`;

const RowTitle = styled.div`
  display: flex;
  padding-right: 40px;
  width: 260px;
`;

const RequestInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 65%;
`;

const BookingDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RequestFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 35%;

  & .MuiOutlinedInput-root {
    width: 274px;

    .MuiSelect-select {
      background-color: ${colors.white} !important;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: white;
  margin-top: 42px;
  margin-bottom: 40px;
`;

const Title = styled.div`
  margin-top: 16px;
`;

const ContactCard = styled.div`
  width: calc(50% - 12px);
  border: 2px solid ${colors.white};
  border-radius: 20px;
`;

const CardHeader = styled.div`
  padding: 19px;
  background-color: ${alpha(colors.white, 0.3)};
  border-radius: 18px 18px 0 0;
`;

const CardBody = styled.div`
  height: 320px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledLink = styled(Link)`
  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.pink4};
  font-weight: 400;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;

  & button {
    width: 163px;
    height: 52px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    text-transform: none;
  }
`;

const RouterLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.pink4};
  font-family: Greenwich, serif;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;

  & svg {
    width: 24px;
    height: 24px;
  }

  & svg path {
    transition: stroke 0.3s;
  }

  &:hover {
    color: ${colors.pink2};

    & svg path {
      stroke: ${colors.pink2};
    }
  }
`;

const DetailRow = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Status = styled.div`
  width: 200px;
`;

export {
  Container,
  Wrapper,
  RequestDetails,
  Row,
  RowTitle,
  RequestInfo,
  RequestFields,
  Divider,
  BookingDetails,
  Title,
  ContactCard,
  CardHeader,
  CardBody,
  StyledLink,
  ButtonRow,
  RouterLink,
  DetailRow,
  Status,
};
