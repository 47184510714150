import styled from "@emotion/styled";
import { colors } from "../../../styles";

const Wrapper = styled.div`
  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 78px 80px 100px;
  gap: 40px;
`;

export { Wrapper, Container };
