import { FormControl, MenuItem } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { StyledSelect } from "./manager-select-styles";
import { updateBookingRequest } from "../../../../services/api";
import { LabelContainer } from "../status-select/status-select-styles";

const ManagerSelect: FC<any> = ({
  defaultValue,
  admins,
  data,
  placeholder,
}) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setValue(e.target.value);

    updateBookingRequest({
      rentId: data?.id,
      managerId: e.target.value,
    });
  };

  useEffect(() => {
    if (defaultValue?.length) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <FormControl fullWidth>
      {placeholder && (
        <LabelContainer
          onClick={() => {
            inputRef.current?.click();
            setOpen(true);
          }}
        >
          {placeholder}
        </LabelContainer>
      )}
      <StyledSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={admins?.length ? value : ""}
        label="Age"
        onChange={handleChange}
      >
        {admins?.map((admin) => (
          <MenuItem key={admin.value} value={admin.value}>
            {admin.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default ManagerSelect;
