import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getValidation } from "./duck/utils";
import { FormProvider, useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from "../../services/authApi";
import { PasswordInput, TextField } from "../../form-units";
import { AuthActions } from "../../redux/actions/authActions";
import ReactGA from 'react-ga4'

import {
  Wrapper,
  PaddedBox,
  LoginBlock,
  StyledLink,
  FieldsWrapper,
  ToggleWrapper,
} from "./register-styles";
import { AdaptiveText } from "../../components";
import { colors } from "../../styles";
import { ROLES } from "../../constants/common.consts";
import { Toggle } from "../../components/v2";
import { Button } from "@mui/material";

const Register = ({ close }) => {
  const [searchParams] = useSearchParams();
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(ROLES.MASTER);
  const navigate = useNavigate();
  const { validationSchema, defaultValues } = getValidation();
  const mode = searchParams.get("mode");

  useEffect(() => {
    if (mode === ROLES.SALON_ADMIN) {
      setRole(ROLES.SALON_ADMIN);
    }
  }, []);

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const pass = formProviderProps.watch(["password", "confirmPassword"]);

  useEffect(() => {
    if (formProviderProps.formState.isSubmitted) {
      formProviderProps.trigger();
    }
  }, [pass]);

  const login = () => {
    close();
    navigate("/login_modal");
  };

  async function handleSubmit() {
    try {
      setLoading(true);
      const { email, password } = formProviderProps.getValues();

      const registerData = await authService.register({
        email,
        password,
        role,
        ...(role === ROLES.SALON_ADMIN && { hostRole: "Salon Administrator" }),
      });

      authDispatch({ type: "SET_USER", payload: registerData });

      if (registerData.token) {
        localStorage.setItem("user", JSON.stringify(registerData));
      }

      if (role === ROLES.MASTER) {
        ReactGA.event({category: 'engagement', action: 'sign_up', label: 'master'});
        navigate("/eligibility_check");
      } else {
        ReactGA.event({category: 'engagement', action: 'sign_up', label: 'salon'});
        navigate("/host_information");
      }

      close();

      return;
    } catch {
      setError("Failed to register user");
    }

    setLoading(false);
  }

  return (
    <Wrapper>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        fontFamily="Greenwich"
        uppercase
      >
        Identify yourself:
      </AdaptiveText>
      <ToggleWrapper>
        <Toggle
          firstLabel="Beauty Artist"
          secondLabel="Salon"
          checked={role}
          firstValue={ROLES.MASTER}
          secondValue={ROLES.SALON_ADMIN}
          onChange={setRole}
        />
      </ToggleWrapper>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        fontFamily="Greenwich"
        uppercase
      >
        Let's get started
      </AdaptiveText>
      <PaddedBox>
        <AdaptiveText
          xl={{ size: 16, height: 19 }}
          xs={{ size: 12, height: 12 }}
          fontFamily="Greenwich"
        >
          Sign up for free to create your profile page, access your bookings and
          have customized experience
        </AdaptiveText>
      </PaddedBox>

      {error && <Alert variant="danger">{error}</Alert>}
      <FormProvider {...formProviderProps}>
        <form
          onSubmit={formProviderProps.handleSubmit(handleSubmit)}
          noValidate
        >
          <FieldsWrapper>
            <TextField
              name="email"
              type="email"
              required
              label="E-mail address"
              placeholder="example@mail.com"
              size="small"
            />
            <PasswordInput
              name="password"
              label="Password"
              placeholder="Password should be at least 8 characters"
              required
              size="small"
            />
            <PasswordInput
              name="confirmPassword"
              label="Confirm password"
              placeholder="Passwords must match"
              required
              size="small"
            />
          </FieldsWrapper>

          <AdaptiveText
            xl={{ size: 16, height: 18 }}
            xs={{ size: 16, height: 18 }}
            fontFamily="Greenwich"
            color={colors.grey2}
          >
            By creating or logging into an account you are agreeing with our{" "}
            <StyledLink> Terms of Use</StyledLink> and&nbsp;
            <StyledLink> Privacy Policy</StyledLink>
          </AdaptiveText>

          <LoginBlock>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              disableRipple
            >
              {role === ROLES.MASTER
                ? "Sign up as a beauty artist"
                : "Sign up as a salon"}
            </Button>
          </LoginBlock>
        </form>
      </FormProvider>
      <AdaptiveText
        xl={{ size: 16, height: 18 }}
        xs={{ size: 16, height: 18 }}
        fontFamily="Greenwich"
      >
        Already have an account?&nbsp;
        <StyledLink onClick={login}>Log in</StyledLink>
      </AdaptiveText>
    </Wrapper>
  );
};

export default Register;
