import { Button, Grid, InputAdornment } from "@mui/material";
import { vw } from "../../../../../../styles";
import { DropDown, Radiogroup, Textarea } from "../../../../../components";
import {
  Wrapper,
  LabelContainer,
  PricingBlock,
  StyledInputLabel,
  ButtonBlock,
} from "./workplace-form-styles";
import { AdaptiveText } from "../../../../../../components";
import { LabelTooltip } from "../../../../../components/label-tooltip";
import { CurrencyInput } from "../../../../../components/currency-input";
import { ReactComponent as EuroIcon } from "../../../../../../assets/icons/euro-icon.svg";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { getValidation } from "./utils";
import { map } from "lodash-es";
import {
  addSalonWorkstation,
  updateSalonWorkstation,
} from "../../../../../../services/salonsApi";

const mockCount = Array.from({ length: 10 }, (v, k) => {
  return { value: k + 1, label: `${k + 1}` };
});

const rentalDurationValues = [
  { label: "Hourly", value: "hourly", disabled: true },
  {
    label: "Full Day only (8hrs)",
    value: "day",
    disabled: false,
  },
];

interface WorkPlaceFormProps {
  close: () => void;
  availabilities: any;
  workStations: any;
  workStationTypes: Record<string, string>[];
  workstation?: Record<string, any>;
  salonId: string;
  onAdd: () => void;
}

const WorkplaceForm: React.FC<WorkPlaceFormProps> = ({
  close,
  availabilities,
  workStations,
  workStationTypes,
  workstation,
  salonId,
  onAdd,
}) => {
  const { validationSchema, defaultValues } = getValidation(workstation);

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const filterWorkstationOptions = () => {
    const selectedWorkStations = map(workStations, "workstationId");

    return workStationTypes.filter(
      (workstation) => !selectedWorkStations.includes(workstation.value)
    );
  };

  const onSubmit = () => {
    const values = formProviderProps.getValues();

    if (workstation?.relationId) {
      updateSalonWorkstation(workstation.relationId, {
        salonId: salonId,
        ...values,
      }).then(() => {
        onAdd();
        close();
      });
    } else {
      addSalonWorkstation({
        salonId: salonId,
        ...values,
      }).then(() => {
        onAdd();
        close();
      });
    }
  };

  return (
    <FormProvider {...formProviderProps}>
      <Wrapper onSubmit={formProviderProps.handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={{ xs: vw(40), sm: 5 }}>
          <Grid item container spacing={{ xs: vw(12), sm: 1.5 }}>
            <Grid item xs={10} sm={12} lg={6}>
              <DropDown
                name="workstationId"
                options={
                  workstation ? workStationTypes : filterWorkstationOptions()
                }
                placeholder="Select one option"
                label="Types of workstations to rent"
                tooltip="Select the workstation you are willing to rent to beauty artists for coworking opportunities"
                required
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DropDown
                name="count"
                options={mockCount}
                placeholder="Choose from 1 to 10"
                label="Number of workstations of this type"
                tooltip="Specify the number of workstations of this type available for rent in the salon"
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Radiogroup
              name="minDuration"
              label="Minimum rental duration"
              tooltip="We always recommend to rent hourly, this provides you and the artist with more flexibility"
              options={rentalDurationValues}
              required
            />
          </Grid>
          <Grid item container spacing={{ xs: vw(24), sm: 3 }}>
            <Grid item xs={12}>
              <PricingBlock>
                <LabelContainer>
                  <StyledInputLabel>
                    <AdaptiveText
                      xl={{ size: 20, height: 24 }}
                      xs={{ size: 20, height: 24 }}
                      weight={700}
                      fontFamily="Greenwich"
                    >
                      Pricing, € (excl. VAT)
                    </AdaptiveText>
                    <LabelTooltip tooltip="Define your full day rent, the price is excluding VAT" />
                  </StyledInputLabel>
                  <span>*</span>
                </LabelContainer>
                <AdaptiveText
                  xl={{ size: 18, height: 21 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                >
                  Pricing covers rental of workstation & usage of professional
                  equipment by beauty artist. We charge small service fee to
                  help operate the VERVVEY platform. For every booking, we
                  charge hosts a 10% service fee. If the rate in the salon
                  profile is €10, the host will receive €9 for that booking
                </AdaptiveText>
              </PricingBlock>
            </Grid>
            <Grid item container spacing={{ xs: vw(12), sm: 1.5 }}>
              <Grid item xs={12} lg={6}>
                <CurrencyInput
                  name="pricePerDay"
                  placeholder="72"
                  label="Full Day rate"
                  tooltip="Define your hourly rate and/or full day rent as applicable"
                  required
                  type="string"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EuroIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DropDown
                name="wAvailId"
                options={availabilities}
                placeholder="Select one option"
                label="Availability of products for booking artists"
                tooltip="Whether artists can use salon products to deliver their services, e.g. colour, nail polish, etc"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea
                name="additionCondition"
                label="Specify what is included in the rental fee"
                tooltip="Additional terms & conditions of rental"
                multiline
                minRows={6}
                limit={440}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonBlock>
                <Button
                  type="button"
                  variant="outlined"
                  disableTouchRipple
                  disableFocusRipple
                  onClick={close}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disableTouchRipple
                  disableFocusRipple
                >
                  Submit
                </Button>
              </ButtonBlock>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </FormProvider>
  );
};

export default WorkplaceForm;
