import styled from "@emotion/styled";

const FilterWrapper = styled.div`
  min-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 70px;
`;

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > div {
    max-width: 100%;
  }
`;

export { FilterWrapper, Block };
