import classes from "./BackButton.module.scss";
import arrow_icon from "../../assets/icons/arrow_left_pink.svg";
import CustomButton from "../customButton/customButton";

export const BackButton = ({ backNavigationCallback }: any) => {
  return (
    <div className={classes.wrapper}>
      <CustomButton
        className={classes.custom_btn}
        variant="primary-outline"
        onClick={backNavigationCallback}
      >
        <img src={arrow_icon} alt="previous" />
      </CustomButton>
    </div>
  );
};
