import styled from "@emotion/styled";
import { InputLabel, TextField } from "@mui/material";
import { colors, vw } from "../../../styles";

interface Props {
  error?: boolean;
}

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(20)};
    line-height: ${vw(20)};
  }
`;

const StyledInputLabel = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Greenwich, serif;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.accent};
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(8)};
    gap: ${vw(10)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const Label = styled(InputLabel)<Props>`
  margin-bottom: 0;
  color: ${(props) => (props.error ? colors.red : colors.accent)};
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding: 20px 16px;
    border-radius: 8px;
    font-family: "Greenwich", serif;
    font-size: 20px;
    line-height: 20px;
    min-height: 60px;
    color: ${colors.accent};
    background-color: ${colors.white};

    & fieldset {
      top: 0;
      border: none;

      & legend {
        display: none;
      }
    }

    & textarea {
      min-height: 20px;
    }

    & input {
      padding: 0;
      height: 20px;
    }

    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.blu4};
      }
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding: ${vw(20)} ${vw(16)};
      border-radius: ${vw(8)};
      font-size: ${vw(20)};
      line-height: ${vw(20)};
      min-height: ${vw(60)};

      & textarea {
        min-height: ${vw(20)};
      }

      & input {
        height: ${vw(20)};
      }
    }
  }
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
  font-family: Greenwich, serif;
  color: ${colors.red};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(4)};
    font-size: ${vw(14)};
    line-height: ${vw(16)};
  }
`;

export {
  LabelContainer,
  StyledInputLabel,
  StyledTextField,
  ErrorMessage,
  Label,
};
