import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AdaptiveText, Carousel } from "../../../../components";
import {
  Wrapper,
  Title,
  Advantages,
  AdvantageWrapper,
  AdvantageIcon,
  StyledButton,
} from "./why-block-styles";

import { ReactComponent as Advantage1 } from "../../../../assets/icons/advantage_1.svg";
import { ReactComponent as Advantage2 } from "../../../../assets/icons/advantage_2.svg";
import { ReactComponent as Advantage3 } from "../../../../assets/icons/advantage_3.svg";
import { ReactComponent as Advantage4 } from "../../../../assets/icons/advantage_4.svg";
import { ReactComponent as Advantage5 } from "../../../../assets/icons/advantage_5.svg";
import { ReactComponent as Advantage6 } from "../../../../assets/icons/advantage_6.svg";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const advantagesData = [
  {
    icon: Advantage1,
    title: "Keep the right vibe",
    description:
      "Choose to cowork with beauty artists who fit your salon’s culture",
  },
  {
    icon: Advantage2,
    title: "Make More Money",
    description: "Make extra income renting unused chairs burden-free",
  },
  {
    icon: Advantage3,
    title: "Join the Community",
    description:
      "Easily connect with like-minded beauty artists to share your salon space so both can thrive",
  },
  {
    icon: Advantage4,
    title: "You’re in control",
    description:
      "You control the availability, price and space rules directly through VERVVEY",
  },
  {
    icon: Advantage5,
    title: "Grow your business",
    description:
      "We get your salon in front of thousands of freelance beauty artists",
  },
  {
    icon: Advantage6,
    title: "No hidden fees",
    description:
      "VERVVEY charges you nothing to list your space and be part of this community.\nPay as little as 10% commission on every rental.",
  },
];

const WhyBlock = ({ showPopup }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  const becomeHost = (e) => {
    if (auth?.role) {
      e.preventDefault();

      showPopup();
    } else {
      navigate(`/register_modal?mode=${ROLES.SALON_ADMIN}`);
    }
  };

  return (
    <Wrapper>
      <Title>
        <Typography variant="h2" textAlign="center">
          Why host with VERVVEY?
        </Typography>
      </Title>
      <Typography variant="subtitle1" textAlign="center">
        More income. More fun. More control
      </Typography>
      <Advantages>
        <Grid
          container
          spacing={3.5}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {advantagesData.map((item) => {
            return (
              <Grid item xs={12} md={6} xl={4} key={item.title}>
                <AdvantageWrapper>
                  <AdvantageIcon>
                    {React.createElement(item.icon)}
                  </AdvantageIcon>
                  <Typography variant="h3" textAlign="center">
                    {item.title}
                  </Typography>
                  <AdaptiveText
                    xl={{ size: 18, height: 21 }}
                    xs={{ size: 18, height: 21 }}
                    weight={300}
                    variant="body1"
                    align="center"
                  >
                    {item.description}
                  </AdaptiveText>
                </AdvantageWrapper>
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Carousel
            autoplay
            showSlides={{ xs: 1 }}
            slides={advantagesData.map((item) => {
              return (
                <AdvantageWrapper>
                  <AdvantageIcon>
                    {React.createElement(item.icon)}
                  </AdvantageIcon>
                  <Typography variant="h3" textAlign="center">
                    {item.title}
                  </Typography>
                  <AdaptiveText
                    xl={{ size: 18, height: 21 }}
                    xs={{ size: 18, height: 21 }}
                    weight={300}
                    variant="body1"
                    align="center"
                  >
                    {item.description}
                  </AdaptiveText>
                </AdvantageWrapper>
              );
            })}
          ></Carousel>
        </Box>
      </Advantages>

      <Typography variant="h4">
        You choose who to work with and on what terms
      </Typography>
      <StyledButton
        disableTouchRipple
        disableFocusRipple
        onClick={becomeHost}
        variant="contained"
        color="secondary"
      >
        Become a host
      </StyledButton>
    </Wrapper>
  );
};

export default WhyBlock;
