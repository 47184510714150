import React, { useEffect, useState } from "react";
import "./CustomRadioGroup.scss";
import classes from "./customRadioGroup.module.scss";
import { Radio } from "@mui/material";

export default function CustomRadioGroup({
  value,
  values,
  onChange,
  children,
  ...restProps
}: any) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  const controlProps = (item: string) => ({
    checked: value === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  return (
    <div className={classes.radio_group_wrapper}>
      {values.map((el) => {
        return (
          <div key={el.value} className={classes.group_wrapper}>
            <Radio
              {...controlProps(el.value)}
              sx={{
                color: "#EB1864",
                "&.Mui-checked": {
                  color: "#EB1864",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 15,
                },
              }}
              disabled={el.disabled}
            />

            <div
              className={classes.text_wrapper}
              style={{ color: el.disabled ? "#BABABA" : "#121212" }}
            >
              {el.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}
