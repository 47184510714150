import styled from "@emotion/styled";
import { AdaptiveText } from "../../../../components";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin-bottom: 0px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(0)};
  }
`;

const Name = styled(AdaptiveText)`
  color: ${colors.blu4};

  ${(props) => props.theme.breakpoints.down("lg")} {
    color: ${colors.pink4};
  }
`;

const Data = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    gap: ${vw(16)};
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export { Wrapper, Name, Data, Row };
