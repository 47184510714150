import axios from "axios";

export const BASE_API_URL = (process.env.REACT_APP_ENV === "prod") ? "https://be.vervvey.com/api" :
    (process.env.REACT_APP_ENV === "stage")  ? "https://stgbe.vervvey.com/api" :
        (process.env.REACT_APP_ENV === "dev")  ? "http://localhost:8080/api":
            "http://localhost:8080/api";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403 || error.response.status === 403) {
      localStorage.removeItem("user");
      window.location.href = "/login_modal";
    }

    return Promise.reject(
      new Error(error?.response?.data?.message || error?.response?.message)
    );
  }
);

export function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user?.token) {
    return {
      Authorization: "Bearer " + user.token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}

const register = async ({
  email,
  password,
  role = "master",
  status = "approvement",
  ...rest
}) => {
  try {
    const response = await axios.post(`${BASE_API_URL}/user/signup`, {
      email,
      password,
      role,
      status,
      ...rest,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const login = async (email, password) => {
  const response = await axios.put(`${BASE_API_URL}/user/login`, {
    email,
    password,
  });

  return response.data;
};

const forgotPassword = async (email) => {
  const response = await axios.post(`${BASE_API_URL}/user/password/forgot`, {
    email,
  });

  return response.data;
};

const resetPassword = async (body) => {
  const response = await axios.put(
    `${BASE_API_URL}/user/password/forgot`,
    body
  );

  return response.data;
};

const updateUser = async (currentUser) => {
  const response = await axios.put(
    `${BASE_API_URL}/user/${currentUser.userId}`,
    {
      userId: currentUser.userId,
      name: currentUser.name,
      surname: currentUser.surname,
      email: currentUser.email,
      mobilePhone: currentUser.mobilePhone,
      hostRole: currentUser.hostRole,
      lang: "EN",
    },
    { headers: authHeader() }
  );

  if (response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

const logout = async (userId) => {
  const response = await axios.put(
    `${BASE_API_URL}/user/${userId}/logout`,
    {},
    { headers: authHeader() }
  );

  localStorage.removeItem("user");
};

export async function loginUserWithGoogle(response: any) {
  const res = await axios.post(
    "http://localhost:1337/api/v1/auth/google",
    {
      token: response.tokenId,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const data = await res.data;

  if (data.name) {
    localStorage.setItem("token", JSON.stringify(data));
    console.log("ResetPassword successful");

    return data;
  } else {
    console.log("Please check your username and password");
  }
}

export const authService = {
  register,
  login,
  logout,
  updateUser,
  forgotPassword,
  resetPassword
};
