import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { alpha } from "@mui/material";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Divider = styled.div`
  margin: 20px 0 60px;
  width: 100%;
  height: 3px;
  background-color: ${alpha(colors.blu4, 0.3)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("12 0 32")};
    height: ${vw(3)};
  }
`;

const Slider = styled.div`
  margin: 100px 0 40px;
  width: 100%;

  & button {
    background-color: transparent;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("48 0 60")};

    & .swiper {
      margin: ${vw("0 30")};
    }
  }
`;

export { Wrapper, Divider, Slider };
