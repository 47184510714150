import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import About from "./pages/about";
import Contact from "./pages/contact";
import Profile from "./pages/profile/profile";
import NotFoundPage from "./pages/404/404";
import { DefaultLayout } from "@components/layout/layout";
import "./app.scss";
import LogInModal from "./pages/home/components/logInModal/loginModal";
import RegisterModal from "./pages/home/components/registerModal/registerModal";
import ForgotPasswordModal from "./pages/home/components/forgotPasswordModal/forgotPasswordModal";
import { EditProfile } from "./pages/profile/EditProfileForm/EditProfile";
import { PersonalDetailsInfo } from "./pages/profile/EditProfileForm/Pages/PersonalDetailsInfo/PersonalDetailsInfo";
import { ProfessionalProfileInfo } from "./pages/profile/EditProfileForm/Pages/ProfessionalProfileInfo/ProfessionalProfileInfo";
import { YourPortfolio } from "./pages/profile/EditProfileForm/Pages/YourPortfolio/YourPortfolio";
import { PersonalProfileInfo } from "./pages/profile/EditProfileForm/Pages/PersonalProfileInfo/PersonalProfileInfo";
import { ProfessionalTrainings } from "./pages/profile/EditProfileForm/Pages/ProfessionalTrainings/ProfessionalTrainings";
import { ServicesYouOffer } from "./pages/profile/EditProfileForm/Pages/ServicesYouOffer/ServicesYouOffer";
import EditSalons from "./pages/salons/EditSalonsForm/EditSalons";
import { HostInformation } from "./pages/salons/EditSalonsForm/pages/hostInformation/hostInformation";
import { SalonInformation } from "./pages/salons/EditSalonsForm/pages/salonInformation/salonInformation";
import { SalonConcept } from "./pages/salons/EditSalonsForm/pages/salonConcept/salonConcept";
import { AmenitiesExpectations } from "./pages/salons/EditSalonsForm/pages/amenitiesExpectations/amenitiesExpectations";
import { WorkingHours } from "./pages/salons/EditSalonsForm/pages/workingHours/workingHours";
import Notification1Page from "./pages/notifications/notification1.page";
import Notification2Page from "./pages/notifications/notification2.page";
import Notification3Page from "./pages/notifications/notification3.page";
import Notification4Page from "./pages/notifications/notification4.page";
import { SalonCatalog } from "./pages/salons/SalonCatalog/SalonCatalog";
import { SalonAdministratorForm } from "./pages/salons/EditSalonsForm/pages/SalonAdministratorForm/SalonAdministratorForm";
import { SalonList } from "./pages/salons/EditSalonsForm/pages/SalonList/SalonList";
import { ProfileAdministratorForm } from "./pages/profile/EditProfileForm/Pages/ProfileAdministratorForm/ProfileAdministratorForm";
import { MasterList } from "./pages/profile/EditProfileForm/Pages/MasterList/MasterList";
import EligibilityCheck from "./pages/eligibility-check/eligibility-check";
import { SuperAdminRequireAuth } from "./Routes/SuperAdminRequireAuth";
import { ChangePasswordForm } from "./pages/profile/EditProfileForm/Pages/ChangePasswordForm/ChangePasswordForm";
import { BeautyArtists } from "./pages/BeautyArtists/BeautyArtists";
import { ForSalons } from "./pages/for-salons";
import { SalonProfile } from "./pages/salon-profile";
import { GrantsForBeautyArtists } from "./pages/GrantsForBeautyArtists";
import { Booking } from "./pages/booking";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { AuthActions } from "@redux/actions/authActions";
import {
  BookingList,
  MasterBookingList,
  SalonBookingList,
} from "./pages/booking-list";
import { AppState } from "@redux/reducers/rootReducer";
import { Places, Schedule } from "./v2/pages/workstations-management/pages";
import { MainPage } from "./pages/main-page";
import { Reports } from "./pages/reports";
import {GA_MEASUREMENT_ID, ROLES} from "./constants/common.consts";
import { Accounting } from "./pages/accounting";
import { EditSchedules } from "./pages/salons/EditSalonsForm/edit-schedules";
import { BookInfo } from "./pages/booking-list/components/book-info";
import { ResetPassword } from "./modals";
import ReactGA from 'react-ga4'

// const Home = React.lazy(() => import("./pages/home/home"));
//const Salons = React.lazy(() => import("./pages/salons/salons"));
const WorkstationManagement = React.lazy(
  () => import("./v2/pages/workstations-management/workstation-management")
);

ReactGA.initialize(GA_MEASUREMENT_ID);

function usePageViews() {
  let location = useLocation();

  useEffect(
      () => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
      },
      [location]
  )
 }

function App() {
  const { auth } = useSelector((state: AppState) => state);
  const authDispatch = useDispatch<Dispatch<AuthActions>>();

  usePageViews()

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("user")) || null;

    if (auth) {
      authDispatch({ type: "SET_USER", payload: auth });
    }
  }, []);

  return (
    <div className="app">
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<MainPage />}>
            <Route path="login_modal" element={<LogInModal />} />
            <Route path="register_modal" element={<RegisterModal />} />
            <Route
              path="forgot_password_modal"
              element={<ForgotPasswordModal />}
            />
            <Route path="reset_password_modal" element={<ResetPassword />} />
          </Route>
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="eligibility_check" element={<EligibilityCheck />} />
          <Route path="profile/:userId" element={<Profile />} />
          {/*TODO: Need to refactor*/}
          <Route path="profile_edit" element={<EditProfile />}>
            <Route index element={<ProfessionalProfileInfo />} />
            <Route
              path="professional_profile/:userId"
              element={<ProfessionalProfileInfo />}
            />
            <Route
              path="services_you_offer/:userId"
              element={<ServicesYouOffer />}
            />
            <Route path="work/:userId" element={<YourPortfolio />} />
            <Route
              path="trained_with/:userId"
              element={<ProfessionalTrainings />}
            />
            <Route
              path="personal_profile/:userId"
              element={<PersonalProfileInfo />}
            />
            <Route
              path="personal_details/:userId"
              element={<PersonalDetailsInfo />}
            />
            <Route
              path="personal_administrator/:userId"
              element={<ProfileAdministratorForm />}
            />
            <Route
              path="change_password/:userId"
              element={<ChangePasswordForm />}
            />
          </Route>
          <Route path="Notification1Page" element={<Notification1Page />} />
          <Route path="Notification2Page" element={<Notification2Page />} />
          <Route path="Notification3Page" element={<Notification3Page />} />
          <Route path="Notification4Page" element={<Notification4Page />} />
          <Route path="host_information" element={<HostInformation />} />
          <Route path="edit_salons" element={<EditSalons />}>
            <Route index element={<SalonInformation />} />
            <Route
              path="salon_information/:salonId"
              element={<SalonInformation />}
            />
            <Route path="working_hours/:salonId" element={<WorkingHours />} />
            <Route path="salon_concept/:salonId" element={<SalonConcept />} />
            <Route
              path="amenities_expectations/:salonId"
              element={<AmenitiesExpectations />}
            />
            <Route path="workstations_rentals/:salonId" element={<Places />} />
            <Route
              path="workstations_schedule/:salonId"
              element={<Schedule />}
            />
            <Route
              path="salon_administrator/:salonId"
              element={<SalonAdministratorForm />}
            />
            <Route
              path="change_password/:salonId"
              element={<ChangePasswordForm />}
            />
          </Route>
          <Route
            path="salon_list"
            element={
              <SuperAdminRequireAuth>
                <SalonList />
              </SuperAdminRequireAuth>
            }
          />
          <Route
            path="master_list"
            element={
              <SuperAdminRequireAuth>
                <MasterList />
              </SuperAdminRequireAuth>
            }
          />
          <Route path="salon_profile/:salonId" element={<SalonProfile />} />

          <Route path="/salon_catalog" element={<SalonCatalog />} />
          <Route path="/grants_for_beauty_artists" element={<GrantsForBeautyArtists />} />
          <Route path="/beauty_artists" element={<BeautyArtists />} />
          <Route path="/for-salons" element={<ForSalons />} />
          <Route path="/booking/:salonId" element={<Booking />} />
          {auth?.role === ROLES.SUPER_ADMIN && (
            <>
              <Route path="/bookings_list" element={<BookingList />} />
              <Route path="/bookings_list/:id" element={<BookInfo />} />
              <Route path="/accounting/*" element={<Accounting />} />
            </>
          )}
          {auth?.role === ROLES.SALON_ADMIN && (
            <>
              <Route path="/bookings_list/*" element={<SalonBookingList />} />
              <Route path="/schedules/*" element={<EditSchedules />} />
            </>
          )}
          {auth?.role === ROLES.MASTER && (
            <Route path="/bookings_list/*" element={<MasterBookingList />} />
          )}
          <Route path="/reports" element={<Reports />} />
          <Route
            path="/workstation_management/*"
            element={<WorkstationManagement />}
          />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>

      {/*<CookieConsentModal />*/}
      {/* <CookieConsent
          style={{
            backgroundColor: "rgba(18, 18, 18, 0.7)",
            width: "65%",
            left: "50%",
            transform: "translateX(-50%)",
            alignItems: "center",
          }}
          location={"bottom"}
          buttonWrapperClasses="buttonWrapperClasses"
          buttonClasses="btn btn-primary"
          buttonStyle={{
            borderRadius: "10px",
            color: "white",
            backgroundColor: "#EB1864",
            height: "62px",
            padding: "10px 16px",
            fontSize: "20px",
          }}
          containerClasses="containerClasses"
          contentStyle={{ margin: "0px", padding: "20px 0px 20px 24px" }}
          contentClasses="contentClasses"
          debug={true}
          buttonText="I AGREE"
          overlay
        >
          We use cookies and similar tools according to our Privacy Policy in
          order to improve and customize your browsing experience and for
          analytics and metrics in order to improve our services. By clicking,
          navigating the site, or scrolling you agree to allow our collection of
          information through cookies
        </CookieConsent> */}
    </div>
  );
}

export default App;
