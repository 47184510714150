import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ProfessionalProfileInfo } from "../../Pages/ProfessionalProfileInfo/ProfessionalProfileInfo";
import { ServicesYouOffer } from "../../Pages/ServicesYouOffer/ServicesYouOffer";
import { YourPortfolio } from "../../Pages/YourPortfolio/YourPortfolio";
import { ProfessionalTrainings } from "../../Pages/ProfessionalTrainings/ProfessionalTrainings";
import { PersonalProfileInfo } from "../../Pages/PersonalProfileInfo/PersonalProfileInfo";
import { PersonalDetailsInfo } from "../../Pages/PersonalDetailsInfo/PersonalDetailsInfo";
import classes from "./CreateModeProfile.module.scss";
import { StepIconProps } from "@mui/material/StepIcon";
import Check from "@mui/icons-material/Check";
import { vw } from "../../../../../styles";
import { Tab, useMediaQuery, useTheme } from "@mui/material";
import { Grid } from "@mui/material";
import {
  CreateProfileWrapper,
  TabsWrapper,
  ProgressTabs,
} from "./CreateModeProfile-styles";
import { ProgressTab } from "../../../components/progress-tab";

const TABS = [
  {
    component: ProfessionalProfileInfo,
    title: "Professional profile",
    value: 0,
  },
  {
    component: ServicesYouOffer,
    title: "Services you offer",
    value: 1,
  },
  {
    component: YourPortfolio,
    title: "Your portfolio",
    value: 2,
  },
  {
    component: ProfessionalTrainings,
    title: "Professional trainings",
    value: 3,
  },
  {
    component: PersonalProfileInfo,
    title: "Personal profile",
    value: 4,
  },
  {
    component: PersonalDetailsInfo,
    title: "Personal details",
    value: 5,
  },
];

export function CustomStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;

  if (active) {
    return (
      <div className={`${classes.number_icon} ${classes.number_icon__active}`}>
        {icon}
      </div>
    );
  }

  if (completed) {
    return (
      <div className={`${classes.number_icon} ${classes.number_icon__active}`}>
        <Check />
      </div>
    );
  }

  return <div className={classes.number_icon}>{icon}</div>;
}

export const CreateModeProfile = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();

  const activeStep = (location.state as any)?.activeStep || 0;

  const tab = TABS[activeStep];

  useEffect(() => {
    window.scrollTo(0, 0);

    window.history.scrollRestoration = "manual";
  }, [activeStep]);

  useEffect(() => {
    return () => {
      //clearFormData();
    };
  }, []);

  return (
    <CreateProfileWrapper>
      <Grid
        container
        direction={isTablet ? "column" : "row"}
        spacing={{ xs: vw(42), sm: 7 }}
        wrap="nowrap"
      >
        <Grid item>
          <TabsWrapper>
            <ProgressTabs
              value={activeStep}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {TABS.map(({ title, value }, idx) => {
                return (
                  <Tab
                    disableTouchRipple
                    disableFocusRipple
                    value={value}
                    key={value}
                    label={
                      <ProgressTab
                        title={title}
                        index={idx}
                        active={activeStep}
                      />
                    }
                  />
                );
              })}
            </ProgressTabs>
          </TabsWrapper>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.forms_wrapper}>
            {tab && <tab.component />}
          </div>
        </Grid>
      </Grid>
    </CreateProfileWrapper>
  );
};
