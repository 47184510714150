import styled from "@emotion/styled";
import { colors, vw } from "../../../styles";
import { alpha } from "@mui/material";

const Wrapper = styled.div`
  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 78px 80px 100px;
  gap: 40px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 50px 30px 100px;
    gap: ${vw(40)};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("40 20 100")};
    gap: ${vw(40)};
  }
`;

const BookInfo = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 20px;
  border: 2px solid ${colors.white};
  background-color: ${alpha(colors.white, 0.3)};

  & svg {
    margin-bottom: 3px;
    margin-right: 4px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("40 16")};
    gap: ${vw(16)};
    border-radius: ${vw(20)};
    border-width: ${vw(2)};

    & svg {
      margin-bottom: ${vw(3)};
      margin-right: ${vw(4)};
    }
  }
`;

const TableFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }
`;

export { Wrapper, Container, BookInfo, TableFilter };
