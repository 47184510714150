import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { vw } from "../../styles";

const StyledText = styled(Typography)`
  font-size: ${(props) => props.sm.size}px;
  line-height: ${(props) => props.sm.height}px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${(props) => vw(props.xs.size)};
    line-height: ${(props) => vw(props.xs.height)};
  }
`;

export { StyledText };
