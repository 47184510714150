import { Stack } from "@mui/material";
import { colors } from "../../../../styles";
import { SortButton } from "../table-styles";
import React from "react";

import { Wrapper, Row, Title, Data } from "./mobile-styles";
import { ReactComponent as ArrowSorting } from "../../../../assets/icons/arrow-sorting.svg";

const Mobile = ({
  data,
  checkHighlightRow,
  columns,
  reload,
  sortBy,
  order,
  changeSorting,
}) => {
  return (
    <div>
      {data.map((item: any, itemIdx) => (
        <Wrapper key={data?.id || data?.wsRentId || "0"}>
          {columns.map((column: any, idx) => (
            <Row
              key={column.data}
              isFirst={idx === 0}
              isFirstItem={itemIdx === 0}
              isLast={idx === columns?.length - 1}
              isLastItem={itemIdx === data?.length - 1}
              {...(checkHighlightRow && checkHighlightRow(item, data)
                ? { style: { backgroundColor: colors.blu6 } }
                : {})}
            >
              <Title>
                {column.data === "action-component" ? null : column.sortable ? (
                  <Stack
                    flexDirection="row"
                    flexWrap="nowrap"
                    alignItems="center"
                  >
                    <span>{column.name}</span>
                    <SortButton
                      disableTouchRipple
                      disableFocusRipple
                      active={column.data === sortBy}
                      order={order}
                      onClick={changeSorting(column.data)}
                    >
                      <ArrowSorting />
                    </SortButton>
                  </Stack>
                ) : (
                  <span>{column.name}</span>
                )}
              </Title>
              <Data>
                {column.data === "action-component" ? (
                  <div>{column.getComponent(item)}</div>
                ) : (
                  <div>
                    {typeof column.formatter === "function"
                      ? column.formatter(item[column.data], item, reload)
                      : item[column.data]}
                  </div>
                )}
              </Data>
            </Row>
          ))}
        </Wrapper>
      ))}
    </div>
  );
};

export default Mobile;
