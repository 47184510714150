import React from "react";
import { StyledText } from "./adaptive-text-styles";

interface TextProps {
  children?: React.ReactNode;
  xl: Record<string, number>;
  md?: Record<string, number>;
  sm?: Record<string, number>;
  xs: Record<string, number>;
  color?: string;
  fontFamily?: string;
  decoration?: string;
  uppercase?: boolean;
  capitalize?: boolean;
  align?: "right" | "left" | "inherit" | "center" | "justify" | undefined;
  greenwich?: boolean;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700;
  variant?: any;
  as?: string;
}

const AdaptiveText: React.FC<TextProps> = ({
  children,
  uppercase,
  capitalize,
  decoration,
  as,
  ...rest
}) => {
  return (
    <StyledText
      {...rest}
      textTransform={
        uppercase ? "uppercase" : capitalize ? "capitalize" : "none"
      }
      component={as || "p"}
      decoration={decoration}
    >
      {children}
    </StyledText>
  );
};

export { AdaptiveText };
