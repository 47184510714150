import React from "react";
import { Wrapper, Content, Subtitle, StyledButton } from "./final-block-styles";
import { AdaptiveText } from "../../../../components";
import { colors } from "../../../../styles";
import { AlreadyLogged } from "../../../../modals";
import WithDialog from "../../../../contexts/dialog/WithDialog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const FinalBlock = ({ openDialog, closeDialog }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  function listYourSpace() {
    if (auth?.role) {
      openDialog({
        component: <AlreadyLogged close={closeDialog} />,
        cancelCallback: () => {
          closeDialog();
        },
      });
    } else {
      navigate(`/register_modal?mode=${ROLES.SALON_ADMIN}`);
    }
  }

  return (
    <Wrapper>
      <Content>
        <Subtitle>
          <AdaptiveText
            xl={{ size: 38, height: 38 }}
            xs={{ size: 28, height: 36 }}
            weight={500}
            color={colors.white}
            align="center"
            fontFamily="Greenwich"
          >
            Become a VERVVEY Host Salon
          </AdaptiveText>
        </Subtitle>
        <StyledButton variant="contained" onClick={listYourSpace} disableRipple>
          LIST YOUR SPACE
        </StyledButton>
      </Content>
    </Wrapper>
  );
};

export default WithDialog(FinalBlock);
