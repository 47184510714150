import styled from "@emotion/styled";
import { colors, vw } from "../../../../../../styles";
import { alpha, IconButton } from "@mui/material";

const Wrapper = styled.div``;

const WorkStation = styled.form`
  position: relative;
  width: 100%;
  margin-top: 24px;
  padding: 30px 70px 60px 40px;
  border: 2px solid ${colors.white};
  border-radius: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    padding: ${vw(30)} ${vw(16)};
    border-radius: ${vw(20)};
  }
`;

const PricingBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(9)};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 18px;

  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(18)};
    font-size: ${vw(20)};
    line-height: ${vw(20)};
  }
`;

const StyledInputLabel = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Greenwich, serif;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(8)};
    gap: ${vw(10)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;

  &:hover {
    background-color: ${alpha(colors.white, 0.5)};
  }

  & svg {
    width: 28px;
    height: 28px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    top: ${vw(8)};
    right: ${vw(8)};

    & svg {
      width: ${vw(28)};
      height: ${vw(28)};
    }
  }
`;

const Append = styled.div`
  margin-top: 24px;
  margin-bottom: 95px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    opacity: 0.8;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    margin-bottom: ${vw(95)};
    gap: ${vw(8)};
  }
`;

export {
  Wrapper,
  WorkStation,
  PricingBlock,
  LabelContainer,
  StyledInputLabel,
  DeleteButton,
  Append,
};
