import classes from "./customTextarea.module.scss";
import { ChangeEvent } from "react";
import { ExplanationIcon } from "@components";

type PropsType = {
  text: string;
  name: string;
  value: string;
  onChange: (value: string, e: any) => void;
  require?: boolean;
  err?: string;
  info?: string;
  maxLength?: number;
  placeholder?: string;
  height?: number;
  className?: string;
  isLimitCounterActive?: boolean;
  disabled?: boolean;
};

export const CustomTextarea = ({
  text,
  name,
  value = "",
  onChange,
  require,
  err,
  info,
  maxLength = 440,
  height = 168,
  className,
  isLimitCounterActive = true,
  ...restProps
}: PropsType) => {
  const onChangeTAHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.currentTarget.value, e);
  };

  const color = err ? "#E23600" : "#121212";
  const errBorder = err ? "1px solid #E23600" : "none";

  return (
    <div className={`${classes.custom_text_area_wrapper} ${className}`} id={"div_"+name}>
      <label htmlFor="addInfo" style={{ color: color }}>
        <div className={classes.text_icon_wrapper}>
          {text} {info !== "" && info && <ExplanationIcon text={info} />}
        </div>
        {require && <span className="req_sign">*</span>}
      </label>
      <div className={classes.textarea_wrapper}>
        <textarea
          name={name}
          value={value}
          onChange={onChangeTAHandler}
          required={require}
          maxLength={maxLength}
          style={{ height: height + "px", border: errBorder }}
          {...restProps}
        />
        {isLimitCounterActive && (
          <div className={classes.limit_counter}>
            {value?.length} / {maxLength}
          </div>
        )}
      </div>
      {err && <div className={classes.err_text}>{err}</div>}
    </div>
  );
};
