import { useCallback, useEffect, useState } from "react";
import CustomCheckbox from "../../../../../components/checkbox/customCheckbox";
import { MySelect } from "@components/mySelect/mySelect";
import classes from "./ServicesYouOffer.module.scss";
import { ExplanationIcon } from "@components";
import { CustomPhoneInput } from "@components/phoneInput/phoneInput";
import { CustomInput } from "@components/customInput/customInput";
import { BackButton } from "@components/BackButton/BackButton";
import { useAuth } from "@contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import { updateMasterProfileData } from "../../../../../services/masterApi";
import { getFormattedMasterProfileForBackend } from "../../../../../utils/utils";
import crossIcon from "../../../../../assets/icons/cross.svg";
import { RadioButtonsGroup } from "./RadioButtonsGroup";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../constants/common.consts";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const MAX_SUB_SERVICE_NUMBER = 10;

const DEFAULT_ERROR_STATE = {
  services: "",
  contactNumberForBooking: "",
  linkToYourSocialMedia: "",
};

const isValidURL = (url) => {
  const res = url.match(/^(http(s)?:\/\/.)/g);

  return res !== null;
};

export const ServicesYouOffer = () => {
  const { auth } = useSelector((state: AppState) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const [currentSelectValue, setCurrentSelectValue] = useState(null);
  const [subServices, setSubServices] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading] = useState(false);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const { userId } = useParams();

  const onCheckboxHandler = (serviceId, subServiceId, value) => {
    const _services = masterProfileFormData.services.map((el) => {
      if (el.serviceId !== serviceId) return el;

      const _checkboxArr = el.subServices.map((el) => {
        if (el.subServiceId !== subServiceId) return el;

        return { ...el, value };
      });

      return {
        ...el,
        subServices: _checkboxArr,
      };
    });

    setMasterProfileFormData((prevState) => {
      return { ...prevState, services: _services };
    });
  };

  useEffect(() => {
    if (!currentSelectValue) {
      setCurrentSelectValue(masterProfileFormData?.services[0]?.value);
    }
  }, [masterProfileFormData.services]);

  useEffect(() => {
    const _subServices = masterProfileFormData.services
      .map((service) => {
        return service.subServices;
      })
      .flat(1)
      .filter((service) => {
        return service.value;
      });

    setSubServices(_subServices);
  }, [masterProfileFormData.services]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleInputChange = (_, e) => {
    const { name, value } = e.target;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  const handleRadioButtonsChange = (event) => {
    setMasterProfileFormData({
      ...masterProfileFormData,
      radioBookingDirectlyWithYou: event.target.value,
    });

    if (event.target.value === "No") {
      setMasterProfileFormData({
        ...masterProfileFormData,
        radioBookingDirectlyWithYou: event.target.value,
        contactNumberForBooking: "",
        linkToYourSocialMedia: "",
      });
    }
  };

  const getCurrentService = () => {
    return masterProfileFormData.services?.find((service) => {
      return service.value === currentSelectValue;
    });
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [masterProfileFormData]);

  const isFieldsValid = () => {
    let isError = false;

    const isAllSubServicesEmpty = () => {
      const subServices = masterProfileFormData.services.map((service) => {
        return service.subServices;
      });

      const isEmpty = subServices.flat().some((subService) => subService.value);

      return !isEmpty;
    };

    if (isAllSubServicesEmpty()) {
      setErrs((prevState) => {
        return {
          ...prevState,
          services: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (masterProfileFormData.radioBookingDirectlyWithYou === "Yes") {
      if (
        !masterProfileFormData?.contactNumberForBooking &&
        !masterProfileFormData?.linkToYourSocialMedia
      ) {
        setErrs((prevState) => {
          return {
            ...prevState,
            contactNumberForBooking: DEFAULT_ERROR_TEXT_MESSAGE,
          };
        });

        isError = true;
      }

      if (
        !masterProfileFormData?.linkToYourSocialMedia &&
        !masterProfileFormData?.contactNumberForBooking
      ) {
        setErrs((prevState) => {
          return {
            ...prevState,
            linkToYourSocialMedia: DEFAULT_ERROR_TEXT_MESSAGE,
          };
        });

        isError = true;
      }

      if (
        !masterProfileFormData?.contactNumberForBooking &&
        !isValidURL(masterProfileFormData.linkToYourSocialMedia)
      ) {
        setErrs((prevState) => {
          return {
            ...prevState,
            linkToYourSocialMedia: "url is not valid",
          };
        });

        isError = true;
      }
    }

    return !isError;
  };

  const submitCreateMode = async () => {
    setFormDataToStorage(masterProfileFormData);

    navigate(location, {
      state: {
        activeStep: 2,
      },
    });
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const updatedMasterProfile = await updateMasterProfileData(
        getFormattedMasterProfileForBackend(
          masterProfileFormData,
          userId,
          auth
        ),
        userId
      );

      navigate(`/profile/${userId}`);
    } catch {
      setError(error);
    }
  };

  useEffect(() => {
    if (masterProfileFormData?.radioBookingDirectlyWithYou === "No") {
      setMasterProfileFormData({
        ...masterProfileFormData,
        contactNumberForBooking: "",
        linkToYourSocialMedia: "",
      });
    }
  }, [masterProfileFormData.radioBookingDirectlyWithYou]);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div>
      <h2 className={classes.page_title}>Services you offer</h2>
      <p className={classes.sub_title}>
        Tell the world about the services you offer and your specialisation{" "}
      </p>
      <form onSubmit={handleSubmit}>
        <div className={classes.checkboxes_wrapper}>
          <div className={classes.select_wrapper}>
            <MySelect
              text="Which service(s) do you specialise in? "
              options={masterProfileFormData.services}
              onChange={setCurrentSelectValue}
              require
              value={currentSelectValue}
              isDisabled={subServices?.length === MAX_SUB_SERVICE_NUMBER}
            />
          </div>

          <div className={`${classes.bottom_subtitle} ${classes.spec_text}`}>
            Please specify if you are particularly strong in any of the below
            <div className={classes.explain_icon}>
              <ExplanationIcon text="We recommend to focus on your key specialty only, you can select up to 10 features in total across your services" />
            </div>
            <div className="req_sign">*</div>
          </div>

          <div className={classes.specialization_wrapper}>
            {getCurrentService()?.subServices?.map((el) => {
              return (
                <CustomCheckbox
                  key={el.subServiceId}
                  children={el.name}
                  name={el.name}
                  value={el.name}
                  callback={(id, value, e) =>
                    onCheckboxHandler(el.serviceId, el.subServiceId, value)
                  }
                  id={el.subServiceId}
                  checked={el.value}
                  disabled={subServices?.length === MAX_SUB_SERVICE_NUMBER}
                />
              );
            })}
          </div>
          <div className={classes.err_text}>{errs.services}</div>
        </div>
        <div className={classes.sub_services_wrapper}>
          {subServices?.map((subService) => {
            return (
              <div key={subService.id} className={classes.sub_service}>
                {subService.name}
                <button
                  type="button"
                  onClick={() =>
                    onCheckboxHandler(
                      subService.serviceId,
                      subService.subServiceId,
                      false
                    )
                  }
                >
                  <img src={crossIcon} alt={"cross"} />
                </button>
              </div>
            );
          })}
        </div>
        <div className={classes.bottom_text}>
          Would you like to allow your customers booking directly with you?{" "}
          <span className="req_sign">*</span>
        </div>
        <div className={classes.radio_group_wrapper}>
          <RadioButtonsGroup
            value={masterProfileFormData.radioBookingDirectlyWithYou}
            onChange={handleRadioButtonsChange}
          />
        </div>
        {/*Phone Block*/}
        {masterProfileFormData.radioBookingDirectlyWithYou === "Yes" && (
          <div className={classes.contacts_wrapper}>
            <div className={classes.phone_wrapper}>
              <CustomPhoneInput
                text="Add your contact number for booking"
                value={masterProfileFormData.contactNumberForBooking}
                onChange={(value) =>
                  setMasterProfileFormData({
                    ...masterProfileFormData,
                    contactNumberForBooking: value,
                  })
                }
                info="This contact will become available on your profile and will be shared publicly, it can be a link, an email or a phone number"
                disabled={masterProfileFormData.linkToYourSocialMedia}
                err={
                  !masterProfileFormData?.linkToYourSocialMedia &&
                  errs.contactNumberForBooking
                }
              />
            </div>
            <div className={classes.text_delimiter}>or</div>
            <div className={classes.social_input_wrapper}>
              <CustomInput
                text="Add link to your social media or messenger for booking"
                name="linkToYourSocialMedia"
                type="text"
                value={masterProfileFormData.linkToYourSocialMedia}
                onChange={handleInputChange}
                placeholder="https://www.instagram.com/vervvey/"
                info="This contact will become available on your profile and will be shared publicly, it can be a link, an email or a phone number"
                disabled={masterProfileFormData.contactNumberForBooking}
                err={
                  !masterProfileFormData?.contactNumberForBooking &&
                  errs.linkToYourSocialMedia
                }
              />
            </div>
          </div>
        )}
        <div className={classes.btn_wrapper}>
          {!userId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
