import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { vw } from "../../styles";

interface Props {
  xl: Record<string, number>;
  sm?: Record<string, number>;
  md?: Record<string, number>;
  xs: Record<string, number>;
  weight?: string | number;
  uppercase?: boolean;
  capitalize?: boolean;
  component?: string;
  decoration?: string;
  fontFamily?: string;
}

const StyledText = styled(Typography)<Props>`
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Greenwich Med, sans-serif"};
  font-size: ${(props) => props.xl.size}px;
  line-height: ${(props) => props.xl.height}px;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  text-transform: ${(props) =>
    props.uppercase ? "uppercase" : props.capitalize ? "capitalize" : "normal"};
  text-decoration: ${(props) => props.decoration || "inherit"};

  ${(props) => props.theme.breakpoints.down("lg")} {
    font-size: ${(props) => props.md?.size || props.xl.size}px;
    line-height: ${(props) => props.md?.height || props.xl.height}px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: ${(props) =>
      props.sm?.size || props.md?.size || props.xl.size}px;
    line-height: ${(props) =>
      props.sm?.height || props.md?.height || props.xl.height}px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${(props) => vw(props.xs.size)};
    line-height: ${(props) => vw(props.xs.height)};
  }
`;

export { StyledText };
