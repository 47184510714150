import styled from "@emotion/styled";
import { AdaptiveText } from "../../../../components";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  margin-top: 100px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(60)};
  }
`;

const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  min-height: 174px;
  border-radius: 20px;
  border: 1px solid ${colors.grey};

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-height: ${vw(174)};
    border-radius: ${vw(20)};
    padding: ${vw(20)};
  }
`;

const CardTitle = styled(AdaptiveText)`
  margin-bottom: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(32)};
  }
`;

const Vibe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;

  img {
    width: 46px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(24)};

    img {
      width: ${vw(46)};
    }
  }
`;

const Countries = styled.div`
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 24px;

  svg {
    width: 46px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(24)};
    height: ${vw(24)};

    svg {
      width: ${vw(46)};
    }
  }
`;

const Sense = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  img {
    width: 46px;
  }

  span {
    max-width: 343px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(24)};
    height: ${vw(24)};

    img {
      width: ${vw(46)};
    }
  }
`;

export { Wrapper, InfoCard, CardTitle, Vibe, Countries, Sense };
