import styled from "@emotion/styled";
import { colors, vw } from "../../../styles";

const RequiredLabel = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  font-family: "Greenwich", serif;
  color: ${colors.grey2};
  margin-left: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(18)};
    line-height: ${vw(21)};
    margin-left: ${vw(8)};
  }
`;

RequiredLabel.defaultProps = { children: "*" };

export default RequiredLabel;
