import styled from "@emotion/styled";
import { colors, vw } from "../../styles";
import { Button, Grid } from "@mui/material";

const Wrapper = styled.form`
  width: 100%;
  padding: 80px;
  background-color: ${colors.blu1};

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 80px 32px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(80)} ${vw(20)};
  }
`;

const FormButton = styled(Button)`
  width: 311px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 50%;
  }
`;

const ButtonsWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: ${vw(40)};
    margin-bottom: ${vw(20)};
    gap: ${vw(16)};
  }
`;

const FieldWrapper = styled.div`
  max-width: 634px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export { Wrapper, FormButton, ButtonsWrapper, FieldWrapper, InfoWrapper };
