import React, { FC, useEffect, useState } from "react";
import { Wrapper, Content, StickyContainer } from "./salon-profile-styles";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getSalonProfileData } from "@services/salonsApi";
import {
  getCoworkingRelation,
  getDictionaryLanguageData,
} from "@services/masterApi";
import { getMappedCountryCodesByLanguage } from "../profile/profile";
import CustomSpinner from "../../components/spinner/customSpinner";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { vw } from "../../styles";
import {
  SalonInfo,
  SalonPlace,
  SalonHeader,
  SalonAbout,
  SalonBooking,
  SalonHours,
  SalonCowork,
} from "./components";
import { useSelector } from "react-redux";
import { AppState } from "@redux/reducers/rootReducer";
import WithDialog from "../../contexts/dialog/WithDialog";
import { OpenDialogType } from "@contexts/dialog/types";
import { CanceledPayment, SuccessPayment } from "../../modals";
import { getUserInfo } from "@services/api";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

interface IProps {
  openDialog: OpenDialogType;
  closeDialog: () => void;
}

const SalonProfile: FC<IProps> = ({ openDialog, closeDialog }) => {
  const { auth } = useSelector((state: AppState) => state);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [isLoading, setIsLoading] = useState(true);
  const [relation, setRelation] = useState(null);
  const [error, setError] = useState(null);
  const [salonProfile, setSalonProfile] = useState(null);
  const [countriesCodes, setCountriesCodes] = useState([]);
  const { salonId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const okCallback = () => {
    navigate("/bookings_list");
    closeDialog();
  };

  useEffect(() => {
    if (searchParams.get("payment") === "success") {
      // ReactGA.gtag('event','purchase', {
      //   value: 25.42,
      //   tax: 4.90,
      //   currency: "EUR",
      //   items: [
      //     {
      //       item_id: "SKU_12345",
      //       item_name: "Stan and Friends Tee",
      //       affiliation: "Google Merchandise Store",
      //       coupon: "SUMMER_FUN",
      //       currency: "USD",
      //       discount: 2.22,
      //       index: 0,
      //       item_brand: "Google",
      //       item_category: "Apparel",
      //       item_category2: "Adult",
      //       item_category3: "Shirts",
      //       item_category4: "Crew",
      //       item_category5: "Short sleeve",
      //       item_list_id: "related_products",
      //       item_list_name: "Related Products",
      //       item_variant: "green",
      //       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
      //       price: 9.99,
      //       quantity: 1
      //     }]
      // });

      openDialog({
        component: <SuccessPayment okCallback={okCallback} />,
        mw: 596,
      });
    } else if (searchParams.get("payment") === "canceled") {
      openDialog({
        component: <CanceledPayment okCallback={closeDialog} />,
        mw: 596,
      });
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth?.userId) {
        const userData = await getUserInfo();
        //console.log (userData);

        if (userData.token) {
          localStorage.setItem("user", JSON.stringify(userData));
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchSalonProfileData = async () => {
      try {
        setIsLoading(true);

        const { salon } = await getSalonProfileData(salonId);
        setSalonProfile(salon);

        const dictionary = await getDictionaryLanguageData();
        const _countriesCode = getMappedCountryCodesByLanguage(
          salon?.languages,
          dictionary
        );
        setCountriesCodes(_countriesCode);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchSalonProfileData();
  }, []);

  useEffect(() => {
    const fetchRelationData = async () => {
      if (auth?.userId) {
        try {
          const responseRelation = await getCoworkingRelation(
            salonId,
            auth.userId
          );

          setRelation(responseRelation);
        } catch (error) {
          setError(error);
        }
      }
    };

    fetchRelationData();
  }, [auth.userId]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <CustomSpinner />;
  }

  const updateRelation = (newRelation) => {
    setRelation(newRelation);
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{salonProfile?.name} | Salon</title>
        <meta name="description" content={salonProfile?.description} />
      </Helmet>
      <SalonHeader salonProfile={salonProfile} />
      <Content>
        <Grid container spacing={{ xs: vw(12), sm: 2.5 }}>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12}>
                <SalonInfo
                  salonProfile={salonProfile}
                  countriesCodes={countriesCodes}
                />
              </Grid>
              {!isDesktop && (
                <Grid item xs={12} lg={6}>
                  <SalonCowork
                    salonProfile={salonProfile}
                    relation={relation}
                    updateRelation={updateRelation}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <SalonPlace salonProfile={salonProfile} />
              </Grid>
              <Grid item xs={12}>
                <SalonAbout salonProfile={salonProfile} />
              </Grid>
              {salonProfile?.specificCoworkingReq && (
                <Grid item xs={12}>
                  <SalonBooking salonProfile={salonProfile} />
                </Grid>
              )}
              <Grid item xs={12}>
                <SalonHours salonProfile={salonProfile} />
              </Grid>
            </Grid>
          </Grid>
          {isDesktop && (
            <Grid item xs={12} lg={6}>
              <StickyContainer>
                <SalonCowork
                  salonProfile={salonProfile}
                  relation={relation}
                  updateRelation={updateRelation}
                />
              </StickyContainer>
            </Grid>
          )}
        </Grid>
      </Content>
    </Wrapper>
  );
};

export default WithDialog(SalonProfile);
