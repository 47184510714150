import React, { useState } from "react";
import {
  Wrapper,
  Address,
  Gallery,
  GalleryPhoto,
  MainPhoto,
  Title,
  ShowMoreButton,
} from "./salon-header-styles";
import { ReactComponent as Location } from "../../../../assets/icons/location.svg";
import { ReactComponent as RightIcon } from "../../../../assets/icons/blue_arrow_right.svg";
import { AdaptiveText } from "../../../../components";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { vw } from "../../../../styles";
import { ModalSlider } from "../../../salons/components/modalSlider/modalSlider";

const SalonHeader = ({ salonProfile }) => {
  const theme = useTheme();
  const [isSeeMore, setIsSeeMore] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Wrapper>
      <Title
        xl={{ size: 54, height: 70 }}
        sm={{ size: 38, height: 38 }}
        xs={{ size: 38, height: 38 }}
      >
        {salonProfile?.name}
      </Title>
      <Address>
        <Location />
        <AdaptiveText
          xl={{ size: 24, height: 28 }}
          xs={{ size: 18, height: 21 }}
          weight={300}
          fontFamily="Greenwich"
        >
          {`${salonProfile?.address?.street} ${salonProfile?.address?.number}, ${salonProfile?.address?.postCode} ${salonProfile?.city}`}
        </AdaptiveText>
      </Address>
      <Gallery>
        <Grid container spacing={{ xs: vw(12), sm: 1.5 }}>
          <ShowMoreButton
            disableTouchRipple
            disableFocusRipple
            endIcon={<RightIcon />}
            onClick={() => setIsSeeMore(true)}
          >
            See more photos
          </ShowMoreButton>
          {isSeeMore && (
            <ModalSlider
              cards={salonProfile?.salonPhotos}
              close={() => setIsSeeMore(false)}
            />
          )}
          <Grid item xs={12} md={8} xl={6} flexDirection="row">
            <MainPhoto
              src={salonProfile?.profilePhoto?.imageUrl}
              alt="salon photo"
            />
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={4} xl={6}>
              <Grid
                container
                spacing={{ xs: vw(12), sm: 1.5 }}
                flexDirection="row"
              >
                {salonProfile?.salonPhotos
                  ?.slice(0, isDesktop ? 4 : 2)
                  .map((salonPhoto) => {
                    return (
                      <Grid item xs={12} xl={6} key={salonPhoto.imageId}>
                        <GalleryPhoto
                          src={salonPhoto.imageUrl}
                          alt="salon"
                          key={salonPhoto.imageId}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Gallery>
    </Wrapper>
  );
};

export default SalonHeader;
