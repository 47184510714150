import styled from "@emotion/styled";
import { colors, vw } from "../../../../../../styles";
import { alpha, Button } from "@mui/material";
import isPropValid from "@emotion/is-prop-valid";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
    padding: ${vw("20 12")};
  }
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }
`;

const Number = styled(Button, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})<any>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;
  min-width: 38px;
  padding: 0;

  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: ${colors.blu4};
  border: 2px solid ${colors.blu4};
  border-radius: 8px;
  background-color: ${(props) => (props.active ? colors.blu4 : "transparent")};
  color: ${(props) => (props.active ? colors.white : colors.blu4)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(38)};
    height: ${vw(38)};
    min-width: ${vw(38)};

    font-size: ${vw(16)};
    line-height: ${vw(18)};
    border: ${vw(2)} solid ${colors.blu4};
    border-radius: ${vw(8)};
  }

  &.Mui-disabled {
    border-color: ${colors.grey};
  }

  &:hover:not(.Mui-disabled) {
    background-color: ${(props) =>
      props.active ? colors.blu3 : alpha(colors.blu2, 0.3)};
  }
`;

const ControlButton = styled(Button, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "pink",
})<any>`
  padding: 4px;
  height: 28px;
  width: 60px;
  min-height: auto;
  min-width: auto;
  font-size: 18px;
  line-height: 21px;
  text-transform: none;
  text-decoration: underline;
  color: ${(props) => (props.pink ? colors.pink4 : colors.blu4)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(4)};
    height: ${vw(28)};
    width: ${vw(60)};

    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

export { Wrapper, Number, NumberContainer, ControlButton };
