import classes from "./GrantsForBeautyArtists.module.scss";
import CustomButton from "../../components/customButton/customButton";
import { useNavigate } from "react-router-dom";
import ArtistsSlide from "../home/components/artistsSlider/artistsSlider";
import CustomSwiper from "../../components/CustomSwiper/CustomSwiper";
import { RandomFaceImage } from "./components/RandomFaceImage";
import QuestionsBlock from "./components/questions-block/questions-block";
import ReactGA from 'react-ga4'
import {Helmet} from "react-helmet";
import React, {FC, useEffect, useState} from "react";
import {AdaptiveText} from "@components";
import {Divider} from "../salon-profile/components/salon-about/salon-about-styles";
import {StepsBlock} from "./components/steps-block";
import {Typography} from "@mui/material";
import {colors} from "../../styles";
import {Description, DescriptionWrapper} from "../for-salons/components/header-block/header-block-styles";
import {artistsData, ROLES, STATUSES} from "../../constants/common.consts";
import {Request} from "../salon-profile/components/salon-cowork/salon-cowork-styles";
import {useSelector} from "react-redux";
import {AppState} from "@redux/reducers/rootReducer";
import {SuccessGrantApply} from "@modals";
import {applyForGrant, getUserGrantInfo, getUserInfo} from "@services/api";
import {OpenDialogType} from "@contexts/dialog/types";
import WithDialog from "@contexts/dialog/WithDialog";
import {Content, StyledButton, Subtitle, Wrapper} from "../main-page/components/grant-block/grant-block-styles";

interface IProps {
  openDialog: OpenDialogType;
  closeDialog: () => void;
}

const GrantsForBeautyArtists: React.FC<IProps> = ({ openDialog, closeDialog }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: AppState) => state);

  let btn_value = {
    buttonText: "APPLY NOW",
    buttonDesc: "To submit your application, you must first create an account and complete your online profile"};
  const [btnText, setBtnText] = useState(btn_value);
  const [userApplied, setUserApplied] = useState(null);

  const apply = async () => {
    if (auth?.userId) {
      const userData = await applyForGrant();
      if (userData.token) {
        localStorage.setItem("user", JSON.stringify(userData));
      }
    }
  };

  const handleJoinNowBtn = () => {
    ReactGA.event({category: 'engagement', action: 'apply_for_grant_pressed', label: 'master'});

    let navigate_to = "/register_modal";
    if (auth?.status === STATUSES.APPROVEMENT) {
      if (auth) {
        if (auth.name) {
          navigate_to = `/profile_edit/professional_profile/${auth.userId}`;
        } else {
          navigate_to = "/eligibility_check";
        }
      }
    }

    if (auth?.status === STATUSES.ACTIVE) {
      if (userApplied){
        return;
      }

      apply();

      openDialog({
        component: <SuccessGrantApply okCallback={okCallback} />,
        mw: 596,
      });
      return;
    }

    navigate(navigate_to);
  };

  const okCallback = () => {
    navigate("/salon_catalog");
    closeDialog();
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth?.userId) {
        const userData = await getUserInfo();
        if (userData.token) {
          localStorage.setItem("user", JSON.stringify(userData));
        }
      }
    };

    const fetchGrantApplicationData = async () => {
      if (auth?.userId) {
        const grantData = await getUserGrantInfo();
        if (grantData?.id) {
          setUserApplied(true);
        } else {
          setUserApplied(false);
        }

      }
    };

    fetchUserData();
    fetchGrantApplicationData();
  }, []);



  useEffect(() => {

    if (auth?.status === STATUSES.APPROVEMENT){
      if (auth.name){
        btn_value = {
          buttonText: "Waiting for approval",
          buttonDesc: "Thank you for completing your profile! We will review it shortly."
        };
      }
      else {
        btn_value = {
          buttonText: "Complete your profile",
          buttonDesc: "Please complete your profile to get verified as a member and be able to apply for talent grant."
        };
      }
    } else if (auth?.status === STATUSES.ACTIVE) {
      if (userApplied){
        btn_value = {
          buttonText: "Request is submitted",
          buttonDesc: "Your application is submitted to the committee."
        };
      } else {
        btn_value = {
          buttonText: "APPLY NOW",
          buttonDesc: "We will contact you, using the contact details provided on your profile, to schedule a 15 min online interview to discuss your background and motivation."
        };
      }

    }
    setBtnText(btn_value);

  }, [auth, userApplied]);


  return (
      <><Helmet>
          <title>APPLY FOR THE TALENT GRANT FOR BEAUTY ARTISTS</title>
          <meta name="description"
              content="Do you want to build your clientele, make your work more visible, have more flexibility or conduct experiments? Or are you a beauty artist starting up your own practice? If so, please apply for VERVVEY Talent grant to get a financial boost for your own business development."/>
        </Helmet>
        <div className={classes.beautyArtists_wrapper}>
          <div className={classes.top_section_wrapper}>
            <div className={classes.titles_wrapper}>
              <Typography variant="h2" color={colors.white}>
                Apply for<br/>
                the Talent Grant<br/>
                for beauty artists<br/>
                <br/>
              </Typography>
              <DescriptionWrapper>
                <Description variant="h5" color={colors.accent}>
                  GET MORE FREEDOM TO BE VISIBLE AND CONDUCT EXPERIMENTS WITH THE TALENT PROGRAM FOR BEAUTY ARTISTS.
                  <br/><br/>
                </Description>
              </DescriptionWrapper>
              <div className={classes.primary_bnt_wrapper}>
                <CustomButton variant="primary" onClick={handleJoinNowBtn}>
                  {btnText.buttonText}
                </CustomButton>
                <p>{btnText.buttonDesc}</p>
              </div>
            </div>
            <div className={classes.top_section_image_wrapper}>
              <div className={classes.colored_blocks_wrapper}>
                <div></div>
                <div></div>
              </div>
              <RandomFaceImage/>
            </div>
          </div>

          <div className={classes.who_vervvey_wrapper}>
          <AdaptiveText
              xl={{ size: 44, height: 56 }}
              xs={{ size: 44, height: 56 }}
              align="center"
              fontFamily="Greenwich SemBd"
              weight={600}
          >
            VERVVEY TALENT PROGRAM
          </AdaptiveText>
          <Divider />
          <AdaptiveText
              xl={{ size: 24, height: 28 }}
              xs={{ size: 20, height: 30 }}
          >
            The Talent Program offers individual grants to talented and ambitious beauty artists: hairstylists, barbers, nail technicians, estheticians, make-up artists, massage therapists, tattoo artists. This enables them to build their own clientele coworking in the salons that match their vibe.
            <br/><br/>
            The grant amount is €1,000 for a single calendar year. It can be used for rentals at any salons listed on VERVVEY platform. A maximum of 15 grants will be awarded for the year 2023.
          </AdaptiveText>
          </div>

          <StepsBlock/>

          {/*<div className={classes.who_vervvey_wrapper}>*/}
          {/*  <ImageSlider className={classes.who_is_vv_for}/>*/}
          {/*  <div className={classes.who_vervvey_text}>*/}
          {/*    <AdaptiveText*/}
          {/*        xl={{ size: 24, height: 28 }}*/}
          {/*        xs={{ size: 20, height: 30 }}*/}
          {/*    >*/}
          {/*      <p>WANT TO BUILD YOUR CLIENTELE, MAKE YOUR WORK MORE VISIBLE, HAVE MORE FLEXIBILITY OR CONDUCT EXPERIMENTS?</p>*/}
          {/*      <p>APPLY FOR VERVVEY TALENT GRANT TO GET A FINANCIAL BOOST FOR YOUR OWN BUSINESS DEVELOPMENT</p>*/}
          {/*    </AdaptiveText>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <Wrapper className={classes.vrezka}>
            <Content>
              <Subtitle>
                <AdaptiveText
                    xl={{ size: 24, height: 28 }}
                    xs={{ size: 20, height: 30 }}
                    weight={500}
                    color={colors.white}
                    align="center"
                    fontFamily="Greenwich"
                >
                  <br/><br/>
                  WANT TO BUILD YOUR CLIENTELE, MAKE YOUR WORK MORE VISIBLE, HAVE MORE FLEXIBILITY OR CONDUCT EXPERIMENTS?
                  <br/><br/>
                  APPLY FOR VERVVEY TALENT GRANT TO GET A FINANCIAL BOOST FOR YOUR OWN BUSINESS DEVELOPMENT
                </AdaptiveText>
              </Subtitle>
              <div className={classes.primary_bnt_wrapper}>
                <StyledButton variant="contained" onClick={handleJoinNowBtn} disableRipple>
                  Apply for grant
                </StyledButton>
                <AdaptiveText
                  xl={{ size: 20, height: 30 }}
                  xs={{ size: 16, height: 26 }}
                  color={colors.white}
                  align="center"
                >
                {btnText.buttonDesc}
                </AdaptiveText>
              </div>
            </Content>
          </Wrapper>

          {/*<div className={classes.btn_wrapper}>*/}
          {/*  <Request className={classes.pd_top_0}>*/}
          {/*    <CustomButton*/}
          {/*        className={classes.btn}*/}
          {/*        variant="secondary"*/}
          {/*        onClick={handleJoinNowBtn}*/}
          {/*    >*/}
          {/*      {btnText.buttonText}*/}
          {/*    </CustomButton>*/}
          {/*    <AdaptiveText*/}
          {/*        xl={{ size: 14, height: 16 }}*/}
          {/*        xs={{ size: 14, height: 16 }}*/}
          {/*        fontFamily="Greenwich"*/}
          {/*        align="center"*/}
          {/*    >*/}
          {/*      {btnText.buttonDesc}*/}
          {/*    </AdaptiveText>*/}
          {/*  </Request>*/}
          {/*</div>*/}
          <div className={classes.used_by_wrapper}>
            <div className={classes.used_by_title}>
              Trusted by thousand of artists & salons
            </div>
            <div className={classes.divider}></div>
            <CustomSwiper
                showSlides={{xs: 1, md: 2, lg: 3}}
                bigNavigation
                slides={artistsData.map((item) => (
                    <ArtistsSlide item={item}/>
                ))}/>
          </div>
          <QuestionsBlock />
          <div className={classes.btn_wrapper}>
            <Request className={classes.pd_top_0}>
              <CustomButton
                  className={classes.btn}
                  variant="primary"
                  onClick={handleJoinNowBtn}
              >
                {btnText.buttonText}
              </CustomButton>
              <AdaptiveText
                  xl={{ size: 14, height: 16 }}
                  xs={{ size: 14, height: 16 }}
                  fontFamily="Greenwich"
                  align="center"
              >
                {btnText.buttonDesc}
              </AdaptiveText>
            </Request>
          </div>
        </div>
      </>
  );
};

export default WithDialog(GrantsForBeautyArtists);
