import styled from "@emotion/styled";
import { colors, vw } from "../../styles";
import { Dialog } from "@mui/material";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    max-width: 596px;
    background-color: ${colors.blu1};
    border-radius: 20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: ${vw(20)};
    }
  }
`;

const StyledDialogContent = styled.div`
  width: 100%;
  padding: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(12)};
  }
`;

const Wrapper = styled.div`
  padding: 28px;
  margin-top: -48px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(8)};
    margin-top: ${vw(-32)};
  }
`;

const PaddedBox = styled.div`
  padding: 4px 0 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("4 0 16")};
  }
`;

const ForgotBox = styled.div`
  padding: 8px 0 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("8 0 16")};
  }
`;

const LoginBlock = styled.div`
  padding: 32px 0;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("32 0")};
  }
`;

const StyledLink = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: ${colors.pink4};
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(16)};
    line-height: ${vw(19)};
  }

  &:hover {
    color: ${colors.pink2};
  }
`;

export {
  StyledDialog,
  StyledDialogContent,
  Wrapper,
  PaddedBox,
  ForgotBox,
  LoginBlock,
  StyledLink,
};
