import React from "react";
import { AdaptiveText, Carousel } from "../../../../components";
import { WorkplaceSlide } from "../workplace-slide";
import {
  Wrapper,
  Divider,
  Request,
  Await,
  RequestStatus,
  RequestText,
  SliderWrapper,
} from "./salon-cowork-styles";
import { ROLES, STATUSES } from "../../../../constants/common.consts";
import { useNavigate, useParams } from "react-router-dom";
import { createCoworkingRelation } from "@services/masterApi";
import { Button } from "@mui/material";
import { colors } from "../../../../styles";
import { ReactComponent as Ban } from "../../../../assets/icons/ban.svg";
import { ReactComponent as Allow } from "../../../../assets/icons/allow.svg";
import { useSelector } from "react-redux";
import { AppState } from "@redux/reducers/rootReducer";

const SalonCowork = ({ salonProfile, relation, updateRelation }) => {
  const { auth } = useSelector((state: AppState) => state);
  const { salonId } = useParams();
  const navigate = useNavigate();

  const requestCowork = async () => {
    try {
      const responseRelation = await createCoworkingRelation(
        salonId,
        auth?.userId
      );

      updateRelation(responseRelation);
    } catch (error) {
      return error;
    }
  };

  const browseSalons = () => {
    navigate("/salon_catalog");
  };

  const bookSalon = () => {
    navigate(`/booking/${salonId}`);
  };

  const completeProfile = () => {
    navigate(auth?.name
        ? `/profile_edit/professional_profile/${auth.userId}`
        : "/eligibility_check");
  };

  const joinToBook = () => {
    navigate("/register_modal");
  };

  return (
    <Wrapper>
      <SliderWrapper>
        <AdaptiveText
          xl={{ size: 28, height: 40 }}
          xs={{ size: 30, height: 38 }}
          weight={600}
          fontFamily="Greenwich SemBd"
        >
          Workplace types
        </AdaptiveText>
        <Divider />
        <Carousel
          showSlides={{ xs: 1 }}
          withDots
          autoplay
          slides={salonProfile?.workStations.map((item) => (
            <WorkplaceSlide item={item} />
          ))}
        />
      </SliderWrapper>

      {auth?.status === STATUSES.APPROVEMENT && (
          <>
          {auth?.role === ROLES.MASTER && (
              <Request>
                <Button variant="contained" onClick={completeProfile}>
                  Complete your profile
                </Button>
                <AdaptiveText
                    xl={{ size: 14, height: 16 }}
                    xs={{ size: 14, height: 16 }}
                    fontFamily="Greenwich"
                    align="center"
                >
                  Please complete your profile to get verified as a member, explore coworking opportunities with some of the coolest salons and have a customized experience on the platform.
                </AdaptiveText>
              </Request>
          )}
          </>

      )}
      {auth?.status === STATUSES.ACTIVE && (
        <>
          {auth?.role === ROLES.MASTER && relation && !relation.id && (
            <Request>
              <Button variant="contained" onClick={requestCowork}>
                Request to cowork
              </Button>
              <AdaptiveText
                xl={{ size: 14, height: 16 }}
                xs={{ size: 14, height: 16 }}
                fontFamily="Greenwich"
                align="center"
              >
                Your profile will be shared with the salon for pre-approval for
                any future coworking occasion. You won't be charged anything and
                no booking will be made automatically.
              </AdaptiveText>
            </Request>
          )}
          {auth?.role === ROLES.MASTER && relation?.state === "await" && (
            <Request>
              <Await>
                <AdaptiveText
                  xl={{ size: 28, height: 39 }}
                  xs={{ size: 28, height: 39 }}
                  fontFamily="Greenwich SemBd"
                  weight={600}
                  align="center"
                  color={colors.pink4}
                >
                  Coworking request sent
                </AdaptiveText>
              </Await>
              <AdaptiveText
                xl={{ size: 14, height: 16 }}
                xs={{ size: 14, height: 16 }}
                fontFamily="Greenwich"
                align="center"
              >
                Please wait for the salon to review your request for coworking.
                You will receive an e-mail notification once you can book
                workstations here.
              </AdaptiveText>
            </Request>
          )}

          {auth?.role === ROLES.MASTER && relation?.state === "declined" && (
            <Request>
              <RequestStatus>
                <Ban />
                <RequestText>
                  <AdaptiveText
                    xl={{ size: 20, height: 20 }}
                    xs={{ size: 28, height: 39 }}
                    fontFamily="Greenwich SemBd"
                    weight={600}
                    color={colors.red}
                  >
                    Coworking denied
                  </AdaptiveText>
                  <AdaptiveText
                    xl={{ size: 14, height: 16 }}
                    xs={{ size: 14, height: 16 }}
                    fontFamily="Greenwich"
                  >
                    Sorry, this salon is not available for coworking.
                  </AdaptiveText>
                </RequestText>
              </RequestStatus>
              <Button variant="contained" onClick={browseSalons}>
                Browse other salons
              </Button>
              <AdaptiveText
                xl={{ size: 14, height: 16 }}
                xs={{ size: 14, height: 16 }}
                fontFamily="Greenwich"
                align="center"
              >
                There are many more salons ready for collaboration, please
                browse the catalog to find a suitable option to meet your
                clients.
              </AdaptiveText>
            </Request>
          )}

          {auth?.role === ROLES.MASTER && relation?.state === "accepted" && (
            <Request>
              <RequestStatus>
                <Allow />
                <RequestText>
                  <AdaptiveText
                    xl={{ size: 20, height: 20 }}
                    xs={{ size: 28, height: 39 }}
                    fontFamily="Greenwich SemBd"
                    weight={600}
                    color={colors.green}
                  >
                    Coworking approved
                  </AdaptiveText>
                  <AdaptiveText
                    xl={{ size: 14, height: 16 }}
                    xs={{ size: 14, height: 16 }}
                    fontFamily="Greenwich"
                  >
                    We are happy to host you in this salon!
                  </AdaptiveText>
                </RequestText>
              </RequestStatus>
              <Button variant="contained" onClick={bookSalon}>
                Book now
              </Button>
              <AdaptiveText
                xl={{ size: 14, height: 16 }}
                xs={{ size: 14, height: 16 }}
                fontFamily="Greenwich"
                align="center"
              >
                You will be able to view availability in this salon and reserve
                your space to cowork here
              </AdaptiveText>
            </Request>
          )}
        </>
      )}
      {!auth?.role && (
          <>
            <Request>
              <Button variant="contained" onClick={joinToBook}>
                Join now
              </Button>
              <AdaptiveText
                  xl={{ size: 14, height: 16 }}
                  xs={{ size: 14, height: 16 }}
                  fontFamily="Greenwich"
                  align="center"
              >
                Create your profile – it only takes 15 minutes. It helps us and salon owners to get to know you better and you can use your profile page for social media marketing of your work to get new clients.
              </AdaptiveText>
            </Request>
          </>
      )}
    </Wrapper>
  );
};

export default SalonCowork;
