import { useState } from "react";
import classes from "./ReadMore.module.scss";

export const ReadMore = ({ children, maxLength = 90, className = "" }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className={classes.read_more_description}>
      {isReadMore ? `${text.slice(0, maxLength)}...` : text}
      <a onClick={toggleReadMore} className={classes.see_more_link}>
        {isReadMore ? "See more" : "Read less"}
      </a>
    </div>
  );
};
