import classes from "./RadioButtonsGroup.module.scss";

export const RadioButtonsGroup = ({ onChange, value }) => {
  return (
    <div className={classes.radio_buttons_group_wrapper}>
      {[
        { name: "Yes", value: true },
        { name: "No", value: false },
      ].map((option) => (
        <label key={option.name}>
          <input
            className={classes.custom_radio}
            onChange={onChange}
            type="radio"
            name="answer"
            value={option.name}
            checked={option.name === value}
          />
          {option.name}
        </label>
      ))}
    </div>
  );
};
