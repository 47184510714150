import classes from "./customePureSwitch.module.scss";

export const CustomPureSwitch = ({ isOpen, onChange }) => {
  let openColor = isOpen ? "#FFFFFF" : "#EB1864";
  let closedColor = isOpen ? "#EB1864" : "#FFFFFF";

  let openBackground = isOpen ? "#EB1864" : "#FFFFFF";
  let closedBackground = isOpen ? "#FFFFFF" : "#EB1864";

  const onClickHandler = () => {
    onChange(!isOpen);
  };

  return (
    <div className={classes.main_wrapper} onClick={onClickHandler}>
      <div
        style={{ color: openColor, backgroundColor: openBackground }}
        className={classes.item_wrapper}
      >
        Open
      </div>
      <div
        style={{ color: closedColor, backgroundColor: closedBackground }}
        className={classes.item_wrapper}
      >
        Closed
      </div>
    </div>
  );
};
