import {
  Wrapper,
  StyledTab,
  StyledTabs,
  TabWrapper,
  Navigation,
} from "./accounting-styles";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Refunds, Payouts } from "./components";

const Accounting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e, tab) => {
    navigate(tab);
  };

  return (
    <Wrapper>
      <Navigation>
        <TabWrapper>
          <StyledTabs value={location.pathname} onChange={handleChange}>
            <StyledTab
              label="Artist refunds"
              value="/accounting/refunds"
              disableRipple
            />
            <StyledTab
              label="Salon payouts"
              value="/accounting/payouts"
              disableRipple
            />
          </StyledTabs>
        </TabWrapper>
      </Navigation>

      <Routes>
        <Route path="refunds" element={<Refunds />} />
        <Route path="payouts" element={<Payouts />} />
      </Routes>
    </Wrapper>
  );
};

export default Accounting;
