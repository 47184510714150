import axios from "axios";
import { authHeader, BASE_API_URL } from "./authApi";

export const createMasterProfileData = async (
  masterProfileData
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/master`,
      { ...masterProfileData },
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateMasterProfileData = async (
  masterProfileData,
  userId = ""
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}/master/${userId}`,
      { ...masterProfileData },
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMasterProfileData = async (
  userId = "",
  lang = "EN"
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/master/${userId}/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDictionaryCityData = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/dictionary/city`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDictionaryLanguageData = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/dictionary/lang`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDictionaryCountryData = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/dictionary/country`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllInterests = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/interest/lang/${lang}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllBios = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/bio/lang/${lang}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllVibes = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/vibe/lang/${lang}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllServices = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/service/lang/${lang}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Add work or profile photo
export const addWorkOrProfileImage = async (
  imageFormData,
  userId = ""
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/user/${userId}/image/photo`,
      imageFormData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Get certificates, work-photos or profile-photos image
export const getImage = async (userId, imageId): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/user/${userId}/image/${imageId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Delete certificates, work-photos or profile-photos image
export const deleteImage = async (userId = "", imageId): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BASE_API_URL}/user/${userId}/image/${imageId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const addCertificateImage = async (
  imageFormData,
  userId = ""
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/user/${userId}/image/certificate`,
      imageFormData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getWorkEligibility = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/workEligibility/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInsurances = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/dictionary/liabilityInsurance/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const presaveMasterProfileData = (body): Promise<any> => {
  try {
    axios.post(
      `${BASE_API_URL}/user/presave-master-profile-data`,
      body,
      {
        headers: authHeader(),
      }
    );

    return;
  } catch (error) {
    throw error;
  }
};

export const updateMasterPassword = async (body): Promise<any> => {
  try {
    const response = await axios.put(
        `${BASE_API_URL}/user/change-password`,
        body,
        {
          headers: authHeader(),
        }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCoworkingRelation = async (salonId, masterId): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/relation/salon/${salonId}/master/${masterId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createCoworkingRelation = async (
  salonId,
  masterId
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/relation/salon/${salonId}/master/${masterId}`,
      {},
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCoworkingRelation = async (
  relationId,
  state: "accepted" | "declined" | "await"
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}/relation/${relationId}/state/${state}`,
      {},
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const bookWorkstation = async (
  salonId,
  masterId,
  body
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/workstation/rent/salon/${salonId}/master/${masterId}`,
      body,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBookings = async (salonId, masterId, params): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/workstation/rent/salon/${salonId}/master/${masterId}`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBookingPrice = async (
  salonId,
  workstationId,
  days
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/workstation/rent/price/salon/${salonId}/workstation/${workstationId}/day/${days}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
