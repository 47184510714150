import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  gap: 74px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-bottom: ${vw(60)};
    gap: ${vw(52)};
  }
`;

const TextSlide = styled.div`
  display: flex;
  align-items: center;

  & p {
    max-width: 400px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 60px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0;
  }
`;

const StyledButton = styled(Button)`
  width: 404px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export { Wrapper, StyledButton, TextSlide };
