import styled from "@emotion/styled";
import { colors, vw } from "../../styles";
import { Tab, Grid, Tabs } from "@mui/material";

interface Props {
  active?: boolean;
}

const TabLabel = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: ${(props) => (props.active ? 1 : 0.7)};
  }

  ${(props) =>
    props.active
      ? `
    p {
      color: ${colors.pink4};
      font-weight: bold;
    }

    svg path {
      fill: ${colors.white};
    }

    div {
      background-color: ${colors.pink4};

      p {
        color: ${colors.white};
        font-weight: bold;
      }
    }

    ${(props) => props.theme.breakpoints.up("lg")} {
      &:hover {
        p {
          color: ${colors.pink3};
        }

        div {
          background-color: ${colors.pink3};

          p {
            color: ${colors.white};
          }
        }
      }
    }
  `
      : ""}
`;

const StyledTab = styled(Tab)`
  &.Mui-disabled {
    opacity: 0.5;
  }
`;

const Wrapper = styled.div`
  padding: 80px 80px 100px;
  background-color: ${colors.blu1};

  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -999;
  }

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 40px 40px 100px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 16px 20px 100px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(16)} ${vw(20)} ${vw(100)};
  }
`;

const TabsWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.down("lg")} {
    min-width: 100%;
  }
`;

const NavigationTabs = styled(Tabs)`
  min-width: 320px;

  & .MuiTabs-scroller .MuiTabs-flexContainer {
    flex-direction: column;

    & button {
      text-transform: none;
      height: 28px;
      cursor: auto;

      ${(props) => props.theme.breakpoints.up("lg")} {
        padding: 8px 0;
        align-items: flex-start;
      }
    }

    ${(props) => props.theme.breakpoints.down("lg")} {
      flex-direction: row;
    }
  }
`;

export { TabLabel, StyledTab, Wrapper, TabsWrapper, NavigationTabs };
