import styled from "@emotion/styled";
import { colors, vw } from "../../styles";
import { ReactComponent as Expand } from "../../assets/icons/expand_icon.svg";
import { alpha, Tab, Tabs } from "@mui/material";

const Wrapper = styled.div`
  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
`;

const StyledTab = styled(Tab)`
  margin-right: 40px;
  font-weight: 700;
  color: ${alpha(colors.accent, 0.3)};
  text-transform: none;
  font-size: 20px;
  line-height: 24px;
  font-family: Greenwich, sans-serif;

  ${(props) => props.theme.breakpoints.down("md")} {
    font-size: ${vw(20)};
    line-height: ${vw(24)};
  }

  &.Mui-selected {
    color: ${colors.accent};
  }
`;

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${colors.pink4};
  }
`;

const TabWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  border-bottom: 2px solid ${colors.white};

  & div {
    margin-bottom: -2px;
  }
`;

const Navigation = styled.div`
  padding: 0 80px;
`;

export { Wrapper, StyledTab, StyledTabs, TabWrapper, Navigation };
