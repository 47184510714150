import { CHECKBOXES_OUTSIDE_WORK, languages } from "./mocks";
import { getCategories } from "../pages/profile/EditProfileForm/Pages/PersonalProfileInfo/PersonalProfile.helper";
import { ROLES } from "../constants/common.consts";

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const refreshPage = () => {
  window.location.reload();
};

const WEEKDAYS_NAMES = [
  { weekDay: 1, weekDayName: "Monday", from: "8AM", to: "5PM", opened: true },
  { weekDay: 2, weekDayName: "Tuesday", from: "8AM", to: "5PM", opened: true },
  {
    weekDay: 3,
    weekDayName: "Wednesday",
    from: "8AM",
    to: "5PM",
    opened: true,
  },
  { weekDay: 4, weekDayName: "Thursday", from: "8AM", to: "5PM", opened: true },
  { weekDay: 5, weekDayName: "Friday", from: "8AM", to: "5PM", opened: true },
  { weekDay: 6, weekDayName: "Saturday", from: "8AM", to: "5PM", opened: true },
  { weekDay: 7, weekDayName: "Sunday", from: "8AM", to: "5PM", opened: false },
];

export const getWeekDayNameById = (weekDayId) => {
  const { weekDayName } = WEEKDAYS_NAMES?.find((day) => {
    return day.weekDay === weekDayId;
  });

  return weekDayName;
};

const getLanguageLabelByCode = (countryCode = "FR") => {
  const _language = languages?.find((language) => {
    return language[1] === countryCode;
  });

  return _language[0];
};

const getLanguagesMultiSelectValue = (languages) => {
  const _languagesMultiSelect = languages.map((language) => {
    return { value: language, label: getLanguageLabelByCode(language) };
  });

  return _languagesMultiSelect;
};

export const getFormattedMasterProfileFormData = (masterProfile) => {
  return {
    // Professional profile
    nickname: masterProfile.master.nickname,
    resume: masterProfile.master.resume,
    citySelect: masterProfile.master.city,
    experienceSelect: `${masterProfile.master.experience}`, // to string convert, BE use number
    vibeSelect: {
      value: masterProfile.master.vibe.description,
      vibeId: masterProfile.master.vibe.vibeId,
    }, // Classic need refactor
    languagesMultiSelect: getLanguagesMultiSelectValue(
      masterProfile.user.languages
    ),
    profilePhoto: masterProfile.profilePhoto,
    // Services you offer
    services: masterProfile.services, // handle in Service form
    radioBookingDirectlyWithYou: "Yes", // use only in frontend side
    contactNumberForBooking: masterProfile.user.publicPhone,
    linkToYourSocialMedia: masterProfile.user.publicProfile,
    //Your portfolio
    works: masterProfile.works,
    //Professional trainings
    certificates: masterProfile.certificates,
    // Personal Profile
    interestsCheckboxesOutSideWork: CHECKBOXES_OUTSIDE_WORK, // handle in Personal Profile form
    countrySelect: masterProfile.user.countryOfBirth,
    starSignsSelect: {
      value: masterProfile.master?.STAR_SIGN?.bioId,
      label: masterProfile.master?.STAR_SIGN?.name,
    },
    maritalSelect: {
      value: masterProfile.master?.MARITAL_SITUATION?.bioId,
      label: masterProfile.master?.MARITAL_SITUATION?.name,
    },
    haveKidsSelect: {
      value: masterProfile.master?.KIDS?.bioId,
      label: masterProfile.master?.KIDS?.name,
    },
    educationSelect: {
      value: masterProfile.master?.EDUCATION?.bioId,
      label: masterProfile.master?.EDUCATION?.name,
    },
    smokeSelect: {
      value: masterProfile.master?.SMOKE?.bioId,
      label: masterProfile.master?.SMOKE?.name,
    },
    religionSelect: {
      value: masterProfile.master?.RELIGION?.bioId,
      label: masterProfile.master?.RELIGION?.name,
    },
    covidStatusSelect: {
      value: masterProfile.master?.COVID_STATUS?.bioId,
      label: masterProfile.master?.COVID_STATUS?.name,
    },
    descriptionSelect: {
      value: masterProfile.master?.DESCRIBES_BEST?.bioId,
      label: masterProfile.master?.DESCRIBES_BEST?.name,
    },
    describeYourselfSelect: {
      value: masterProfile.master?.DESCRIBE_YOURSELF?.bioId,
      label: masterProfile.master?.DESCRIBE_YOURSELF?.name,
    },
    // Personal details
    name: masterProfile.user.name,
    surname: masterProfile.user.surname,
    birthdate: masterProfile.master.birthdate,
    socialProfile: masterProfile.user.socialProfile,
    email: masterProfile.master.email,
    mobilePhone: masterProfile.user.mobilePhone,
    personalNote: masterProfile.master.personalNote || "",
    kvkNumber: masterProfile.master.workEligibility.kvkNumber || "",
    eligibilityId: masterProfile.master.workEligibility.eligibilityId || "",
    // status for Super Admin field
    statusSelect: masterProfile.user.status,
    insured: masterProfile.master.insured,
  };
};

const getMappedInterests = (interests) => {
  const filteredInterests = interests.filter((interest) => {
    return interest.checked;
  });

  const _interesets = filteredInterests.map((interest) => {
    return { interestId: parseInt(interest.interestId), lang: "EN" };
  });

  return _interesets;
};

const getMappedLanguages = (languages) => {
  const _languages = languages.map((language) => {
    return language.value;
  });

  return _languages;
};

const getMappedServices = (services) => {
  const _services = services
    .map((service) => {
      const _subServices = service.subServices
        .filter((subService) => {
          return subService.value;
        })
        .map((subService) => {
          return {
            serviceId: subService.serviceId,
            subServiceId: subService.subServiceId,
            name: subService.name,
            lang: "EN",
          };
        });

      return {
        serviceId: service.serviceId,
        name: service.label,
        subServices: _subServices,
        lang: "EN",
      };
    })
    .filter((service) => {
      return service.subServices?.length;
    });

  return _services;
};

export const getFormattedMasterProfileForBackend = (
  masterProfileFormData,
  userId,
  currentUser
) => {
  let _user: any = {
    userId: userId,
    tokenFromProvider: currentUser.tokenFromProvider,
    restoreToken: currentUser.restoreToken,
    rating: 3.5,
    reviews: 3,
    // Professional profile
    languages: getMappedLanguages(masterProfileFormData.languagesMultiSelect),
    // Services you offer
    publicPhone: masterProfileFormData.contactNumberForBooking,
    publicProfile: masterProfileFormData.linkToYourSocialMedia,
    // Personal Profile
    countryOfBirth: masterProfileFormData.countrySelect,
    // Personal details
    name: masterProfileFormData.name,
    surname: masterProfileFormData.surname,
    socialProfile: masterProfileFormData.socialProfile,
    mobilePhone: masterProfileFormData.mobilePhone,
  };

  const _master = {
    userId: userId,
    lang: "EN",
    // Personal Profile
    ...getCategories(masterProfileFormData),
    // Professional profile
    nickname: masterProfileFormData.nickname,
    resume: masterProfileFormData.resume,
    city: masterProfileFormData.citySelect,
    experience: parseInt(masterProfileFormData.experienceSelect), // to string convert, BE use number
    vibe: {
      description: masterProfileFormData.vibeSelect.value,
      vibeId: masterProfileFormData.vibeSelect.vibeId,
      lang: "EN",
    },
    birthdate: masterProfileFormData.birthdate,
    personalNote: masterProfileFormData.personalNote,
    eligibilityId: masterProfileFormData.eligibilityId,
    eligible: masterProfileFormData.eligible,
    kvkNumber: masterProfileFormData.kvkNumber,
    email: masterProfileFormData.email,
    insured: masterProfileFormData.insured,
  };

  if (currentUser.role === ROLES.SUPER_ADMIN) {
    _user = { ..._user, status: masterProfileFormData.statusSelect };
  }

  return {
    entityType: "master",
    lang: "EN",
    user: _user,
    master: _master,
    //Your portfolio
    works: masterProfileFormData.works,
    //Professional trainings
    certificates: masterProfileFormData.certificates,
    interests: getMappedInterests(
      masterProfileFormData.interestsCheckboxesOutSideWork
    ),
    services: getMappedServices(masterProfileFormData.services),
  };
};

const getFormattedWorkstations = (workstations) => {
  const _workstations = workstations.map((workstation) => {
    return {
      additionCondition: workstation.additionCondition,
      productAvailability: {
        wAvailId: workstation.availability.wAvailId,
        value: workstation.availability.wAvailId,
        label: workstation.availability.description,
      },
      workstationsNumber: `${workstation.count}`,
      instantBooking: workstation.instantBooking,
      workstationId: workstation.workstationId,
      count: workstation.count,
      workstationsTypes: {
        value: workstation.workstationId,
        label: workstation.name,
        workstationId: workstation.workstationId,
        workstationType: workstation.workstationType,
      },
      rentalDuration: "Full Day only (8hrs)",
      pricePerHour: workstation.pricePerHour,
      pricePerDay: workstation.pricePerDay,
    };
  });

  return _workstations;
};

export const getFormattedSalonFormData = (salonData) => {
  const _servicesMultiSelect = salonData.salon.services.map((service) => {
    return {
      value: service.serviceId,
      label: service.name,
      serviceId: service.serviceId,
    };
  });

  return {
    hostUserId: salonData.salon.hostUserId,
    // Salon Information
    salonName: salonData.salon.name,
    name: salonData.salon.name,
    citySelect: salonData.salon.city,
    address: {
      addressId: salonData.salon.address.addressId,
      street: salonData.salon.address.street,
      number: salonData.salon.address.number,
      postCode: salonData.salon.address.postCode,
      lang: salonData.salon.address.lang,
    },
    salonPhoneNumber: salonData.salon.phone,
    website: salonData.salon.site,
    socialNetwork: salonData.salon.profile,
    artist: {
      value: salonData.salon.frontOfHouse.name,
      fohId: salonData.salon.frontOfHouse.fohId,
    },
    hearAbout: {
      value: salonData.salon.howFound.name,
      howFoundId: salonData.salon.howFound.howFoundId,
    },
    banking: {
      bankingId: salonData.salon.banking.bankingId,
      benefitName: salonData.salon.banking.benefitName,
      accountNumber: salonData.salon.banking.accountNumber,
    },
    accountNumber: salonData.salon.banking.accountNumber,
    showInFeaturedSalons: salonData.salon.showInFeaturedSalons,
    // Working Hours
    sameOpeningHours: false,
    weekWorkingHours: salonData.salon.workingHours,
    salonHoursAvailability: {
      sAvailId: salonData.salon.sAvail.sAvailId,
      value: salonData.salon.sAvail.description,
      label: salonData.salon.sAvail.description,
    },
    // Salon Concept
    vibe: {
      value: salonData.salon.vibe.name,
      vibeId: salonData.salon.vibe.vibeId,
      lang: salonData.salon.vibe.lang,
    },
    languagesMultiSelect: getLanguagesMultiSelectValue(
      salonData.salon.languages
    ),

    languages: salonData.salon.languages,
    salonDescription: salonData.salon.description,
    services: _servicesMultiSelect,
    teamwork: {
      value: salonData.salon.senseOfTeamwork.name,
      sotId: salonData.salon.senseOfTeamwork.sotId,
    },
    branding: salonData.salon.brand,
    mainPhoto: salonData.salon.profilePhoto,
    galleryWorks: salonData.salon.salonPhotos,
    // Salon amenities & coworking expectations
    checkboxesAmenitiesExpectations: [], // handle in form
    amenitiesDescription: salonData.salon.amenitiesDescription,
    coworkingCollaborationCheckbox: salonData.salon.collaborationExpectation,
    specificInformation: salonData.salon.specificCoworkingReq,
    specificInformationCheckbox: false, // only on FE side
    // Workstations rentals, terms & pricing
    workstations: getFormattedWorkstations(salonData.salon.workStations),
    // status for Super Admin field
    statusSelect: salonData.salon.status,
  };
};

const getMappedAmenities = (amenities) => {
  const filteredInterests = amenities.filter((amenity) => {
    return amenity.checked;
  });

  const _interesets = filteredInterests.map((amenity) => {
    return { amenityId: parseInt(amenity.amenityId), lang: "EN" };
  });

  return _interesets;
};

const getMappedSalonServices = (services) => {
  const _services = services.map((service) => {
    return {
      serviceId: service.serviceId,
      name: service.label,
      lang: "EN",
    };
  });

  return _services;
};

const getFormattedWorkstationsForBE = (workstations) => {
  const _workstations = workstations.map((workstation) => {
    return {
      workstationId: workstation?.workstationId,
      // workstationType: workstation.workstationsTypes.workstationType,
      count: parseInt(workstation.count),
      minDuration: "2 hour", // out of scope, BE will ignore it
      pricePerHour: parseFloat(workstation.pricePerHour),
      pricePerDay: parseFloat(workstation.pricePerDay),
      wAvailId: parseInt(
        workstation?.availability?.wAvailId || workstation?.wAvailId
      ),
      additionCondition: workstation.additionCondition,
      instantBooking: workstation.instantBooking,
      lang: "EN",
    };
  });

  return _workstations;
};

const getWorkingHours = (weekWorkingHours) => {
  return weekWorkingHours.map((week) => {
    return {
      weekDay: week.weekDay,
      opened: week.opened,
      from: week.from,
      to: week.to,
    };
  });
};

export const getFormattedSalonFormDataForBEUpdateMode = (
  salonProfileFormData,
  salonId,
  currentUser
) => {
  let _salonProfileFormData: any = {
    salon: {
      salonId: salonId,
      showInFeaturedSalons: salonProfileFormData.showInFeaturedSalons,
      hostUserId: salonProfileFormData.hostUserId,
      name: salonProfileFormData.salonName,
      address: {
        addressId: salonProfileFormData.address.addressId,
        number: salonProfileFormData.address.number,
        postCode: salonProfileFormData.address.postCode,
        street: salonProfileFormData.address.street,
        lang: "EN",
      },
      fohId: salonProfileFormData.artist.fohId,
      howFoundId: salonProfileFormData.hearAbout.howFoundId,
      banking: {
        bankingId: salonProfileFormData.banking.bankingId,
        benefitName: salonProfileFormData.banking.benefitName,
        accountNumber: salonProfileFormData.banking.accountNumber,
      },
      vibe: {
        name: salonProfileFormData.vibe.value,
        vibeId: salonProfileFormData.vibe.vibeId,
        lang: "EN",
      },
      languages: getMappedLanguages(salonProfileFormData.languagesMultiSelect),
      sotId: salonProfileFormData.teamwork.sotId,
      brand: salonProfileFormData.branding,
      services: getMappedSalonServices(salonProfileFormData.services),
      workingHours: getWorkingHours(salonProfileFormData.weekWorkingHours),
      amenities: getMappedAmenities(
        salonProfileFormData.checkboxesAmenitiesExpectations
      ),
      workStations: getFormattedWorkstationsForBE(
        salonProfileFormData.workstations
      ),
      phone: salonProfileFormData.salonPhoneNumber,
      socialProfile: salonProfileFormData.socialNetwork,
      site: salonProfileFormData.website,
      city: salonProfileFormData.citySelect,
      profile: salonProfileFormData.socialNetwork,
      amenitiesDescription: salonProfileFormData.amenitiesDescription,
      collaborationExpectation:
        salonProfileFormData.coworkingCollaborationCheckbox,
      specificCoworkingReq: salonProfileFormData.specificInformation,
      description: salonProfileFormData.salonDescription,
      sAvailId: salonProfileFormData.salonHoursAvailability.sAvailId,
      lang: "EN",
    },
  };

  if (currentUser.role === ROLES.SUPER_ADMIN) {
    _salonProfileFormData = {
      salon: {
        ..._salonProfileFormData.salon,
        status: salonProfileFormData.statusSelect,
      },
    };
  }

  return _salonProfileFormData;
};

const getSalonGalleryPhotosIds = (galleryWorks) => {
  return galleryWorks.map((photo) => {
    return photo.imageId;
  });
};

const getAddress = (address) => {
  return {
    number: address.number,
    postCode: address.postCode,
    street: address.street,
    lang: "EN",
  };
};

const getBanking = (banking) => {
  return {
    benefitName: banking.benefitName,
    accountNumber: banking.accountNumber,
  };
};

export const getFormattedSalonFormDataForBackendCreateMode = (
  salonProfileFormData,
  currentUser
) => {
  return {
    salon: {
      hostUserId: currentUser.userId,
      name: salonProfileFormData.salonName,
      address: getAddress(salonProfileFormData.address),
      fohId: salonProfileFormData.artist.fohId,
      howFoundId: salonProfileFormData.hearAbout.howFoundId,
      banking: getBanking(salonProfileFormData.banking),
      vibe: {
        name: salonProfileFormData.vibe.value,
        vibeId: salonProfileFormData.vibe.vibeId,
        lang: "EN",
      },
      languages: getMappedLanguages(salonProfileFormData.languagesMultiSelect),
      sotId: salonProfileFormData.teamwork.sotId,
      brand: salonProfileFormData.branding,
      salonPhotos: [
        ...getSalonGalleryPhotosIds(salonProfileFormData.galleryWorks),
        salonProfileFormData.mainPhoto.imageId,
      ],
      services: getMappedSalonServices(salonProfileFormData.services),
      workingHours: getWorkingHours(salonProfileFormData.weekWorkingHours),
      amenities: getMappedAmenities(
        salonProfileFormData.checkboxesAmenitiesExpectations
      ),
      workStations: getFormattedWorkstationsForBE(
        salonProfileFormData.workstations
      ),
      phone: salonProfileFormData.salonPhoneNumber,
      socialProfile: salonProfileFormData.socialNetwork,
      site: salonProfileFormData.website,
      city: salonProfileFormData.citySelect,
      profile: salonProfileFormData.socialNetwork,
      amenitiesDescription: salonProfileFormData.amenitiesDescription,
      collaborationExpectation:
        salonProfileFormData.coworkingCollaborationCheckbox,
      specificCoworkingReq: salonProfileFormData.specificInformation,
      description: salonProfileFormData.salonDescription,
      sAvailId: salonProfileFormData.salonHoursAvailability.sAvailId,
      lang: "EN",
    },
  };
};
