import { FC } from "react";
import { SlideWrapper, Image } from "./slide-styles";
import { ReadMore } from "../read-more";
import { AdaptiveText } from "../../../../../components";
import { colors } from "../../../../../styles";

const Slide: FC<any> = ({ item }) => {
  return (
    <SlideWrapper>
      <Image src={item?.image} alt="Master photo" />
      <AdaptiveText
        xl={{ size: 24, height: 28 }}
        xs={{ size: 24, height: 28 }}
        color={colors.grey2}
        weight={300}
        fontFamily="Greenwich ExtLt"
      >
        {item.name}
      </AdaptiveText>
      <ReadMore text={item.description} />
    </SlideWrapper>
  );
};

export default Slide;
