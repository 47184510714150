export const CATEGORY_NAMES = {
  STAR_SIGN: "STAR_SIGN",
  MARITAL_SITUATION: "MARITAL_SITUATION",
  KIDS: "KIDS",
  EDUCATION: "EDUCATION",
  SMOKE: "SMOKE",
  RELIGION: "RELIGION",
  COVID_STATUS: "COVID_STATUS",
  DESCRIBES_BEST: "DESCRIBES_BEST",
  DESCRIBE_YOURSELF: "DESCRIBE_YOURSELF",
};

export const starSignsOptions = [
  { value: 1, label: "ARIES" },
  { value: 2, label: "TAURUS" },
  { value: 3, label: "GEMINI" },
  { value: 4, label: "CANCER" },
  { value: 5, label: "LEO" },
  { value: 6, label: "VIRGO" },
  { value: 7, label: "LIBRA" },
  { value: 8, label: "SCORPIO" },
  { value: 9, label: "SAGITTARIUS" },
  { value: 10, label: "CAPRICORN" },
  { value: 11, label: "AQUARIUS" },
  { value: 12, label: "PISCES" },
];

export const maritalOptions = [
  { value: 13, label: "MARRIED" },
  { value: 14, label: "IN RELATIONSHIP" },
  { value: 15, label: "SINGLE" },
];

export const haveKidsOptions = [
  { value: 16, label: "HAVE KIDS" },
  { value: 17, label: "NO KIDS" },
  { value: 18, label: "PLAN TO HAVE KIDS" },
];

export const educationOptions = [
  { value: 19, label: "POSTGRADUATE DEGREE" },
  { value: 20, label: "TECHNICAL COLLEGE" },
  { value: 21, label: "UNDERGRADUATE DEGREE" },
];

export const smokeOptions = [
  { value: 22, label: "DON'T SMOKE" },
  { value: 23, label: "REGULAR SMOKER" },
  { value: 24, label: "SMOLE OCCASIONALLY" },
];

export const religionOptions = [
  { value: 25, label: "AGNOSTIC" },
  { value: 26, label: "ATHEIST" },
  { value: 27, label: "BUDDHIST" },
  { value: 28, label: "CATHOLIC" },
  { value: 29, label: "CHRISTIAN" },
  { value: 30, label: "HINDU" },
  { value: 31, label: "JAIN" },
  { value: 32, label: "JEWISH" },
  { value: 33, label: "MUSLIM" },
  { value: 34, label: "SPIRITUAL" },
];

export const covidStatusOptions = [
  { value: 35, label: "VACCINATED" },
  { value: 36, label: "NOT GETTING VACCINATED" },
];

export const descriptionOptions = [
  { value: 38, label: "CONFIDENCE" },
  { value: 39, label: "EMPATHY" },
  { value: 40, label: "INTELLIGENCE" },
  { value: 41, label: "CURIOUSITY" },
  { value: 42, label: "POSITIVITY" },
  { value: 43, label: "SELF-AWARENESS" },
  { value: 44, label: "SENSE OF HUMOUR" },
  { value: 45, label: "LGBTQIA+ ALLY" },
  { value: 46, label: "ENVIRONMENTALIST" },
];

export const describeYourselfOptions = [
  { value: 47, label: "AMBITIOUS" },
  { value: 48, label: "ACTIVE" },
  { value: 49, label: "FAMILY-ORIENTED" },
  { value: 50, label: "OPEN-MINDED" },
  { value: 51, label: "ROMANTIC" },
  { value: 52, label: "CREATIVE" },
];
