import styled from "@emotion/styled";
import { vw } from "../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 80px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 0 60px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 0 36px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 36px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 20")};
  }
`;

export { Wrapper };
