import check from "../../../../assets/icons/check.svg";
import classes from "./customDropDown.module.scss";

const statusList = [
  { title: "All" },
  { title: "Approvement" },
  { title: "Active" },
  { title: "Blocked" },
  { title: "Deleted" },
];

export const CustomDropdown = ({ statusSort, setStatusSort, setPage }) => {
  const onChangeStatusHandler = (title) => {
    setStatusSort(title);
    setPage(0);
  };

  return (
    <div className={classes.status_dropdown}>
      {statusList.map((item) => {
        return (
          <div
            className={classes.dropdown_item_wrapper}
            key={item.title}
            onClick={() => onChangeStatusHandler(item.title)}
          >
            {item.title === statusSort && <img src={check} alt={"check"} />}
            <div className={classes.dropdown_title}>{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};
