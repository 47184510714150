import styled from "@emotion/styled";
import { vw } from "../../../../../../../styles";

const HeaderWrappper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }
`;

export { HeaderWrappper };
