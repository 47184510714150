import classes from "./CustomSlider.module.scss";
import { useNavigate } from "react-router-dom";
import { SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import {
  LeftButton,
  RightButton,
  SwiperContainer,
  StyledSwiper,
} from "./CustomSwiper-styles";
import React from "react";
import CustomButton from "../../../../components/customButton/customButton";

const SlideNextButton = ({ big }) => {
  const swiper = useSwiper();

  return (
    <LeftButton
      onClick={() => swiper.slideNext()}
      size={big ? "large" : "medium"}
    >
      <ArrowForwardIcon fontSize={big ? "large" : "medium"} />
    </LeftButton>
  );
};

const SlidePrevButton = ({ big }) => {
  const swiper = useSwiper();

  return (
    <RightButton
      onClick={() => swiper.slidePrev()}
      size={big ? "large" : "medium"}
    >
      <ArrowBackIcon fontSize={big ? "large" : "medium"} />
    </RightButton>
  );
};

const firstSlideTexts = [
  "Create your profile – it only takes 15 minutes. It helps us and salon owners to get to know you better and you can use your profile page for social media marketing of your work to get new clients",
  "We verify every profile submitted on the platform to build trust within our coworking community. We might also invite you to meet online or in person to go through your paperwork and discuss your experience.",
];

const secondSlideTexts = [
  "Search for the salon that meets your vibes in the location you want.",
  "Search for a salon space near you or your clients. Find rent-by-the-day salon bookings. Filter what’s important to you: the days you want to work, location, vibe and amenities.",
];

const thirdSlideTexts = [
  "Service your clients in the professional environment and do your magic.",
  "All you have to do is show up! The salon knows you are coming, and you know what your rental includes. We also offer onboarding training to deliver better customer experience together.",
];

const sliderData = [
  {
    id: 1,
    title: "Apply for free",
    textFirst: firstSlideTexts[0],
    textSecond: firstSlideTexts[1],
  },
  {
    id: 2,
    title: "Find your chair",
    textFirst: secondSlideTexts[0],
    textSecond: secondSlideTexts[1],
  },
  {
    id: 3,
    title: "Meet your clients",
    textFirst: thirdSlideTexts[0],
    textSecond: thirdSlideTexts[1],
  },
];

export const CustomSlider = () => {
  const navigate = useNavigate();

  function handleJoinNowBtn() {
    navigate("/register_modal");
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>How VERVVEY works</div>
      <div className={classes.divider}></div>
      <div className={classes.title_description}>Get started in 3 steps</div>
      <div className={classes.slider_wrapper}>
        <SwiperContainer>
          <StyledSwiper
            className={"swiper-wide"}
            modules={[Pagination, Autoplay]}
            spaceBetween={0}
            loop
            autoplay={{
              delay: 5000,
            }}
          >
            {sliderData.map((slide, idx) => (
              <SwiperSlide key={idx}>
                <div className={classes.slider}>
                  <div className={classes.slider_id}>{slide.id}</div>
                  <div className={classes.text_wrapper}>
                    <div className={classes.title}>{slide.title}</div>
                    <div className={classes.textFirst}>{slide.textFirst}</div>
                    <div className={classes.textSecond}>
                      <div>{slide.textSecond}</div>
                      <CustomButton
                        variant="primary"
                        onClick={handleJoinNowBtn}
                      >
                        JOIN NOW
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <>
              <SlidePrevButton big />
              <SlideNextButton big />
            </>
          </StyledSwiper>
        </SwiperContainer>
      </div>
    </div>
  );
};
