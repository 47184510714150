import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getAllInterests,
  getMasterProfileData,
} from "../../../services/masterApi";
import getFormDataFromStorage from "./Stepper/services/getFormDataFromStorage";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

const getFormattedInterests = (interests) => {
  const _interests = interests.map((interest) => {
    return {
      interestId: interest.interestId,
      label: interest.label,
      checked: false,
    };
  });

  return _interests;
};

export default function useFetchInterestsData() {
  const { auth } = useSelector((state: AppState) => state);
  const { setMasterProfileFormData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useParams();

  useEffect(() => {
    const fetchInterestsData = async () => {
      try {
        setIsLoading(true);

        // TODO: refactor to simplify

        const interests = await getAllInterests();

        let _interests = getFormattedInterests(interests);

        // create mode
        if (!auth?.name) {
          if (
            !getFormDataFromStorage().interestsCheckboxesOutSideWork?.length
          ) {
            setMasterProfileFormData((prevState) => {
              return {
                ...prevState,
                interestsCheckboxesOutSideWork: _interests,
              };
            });
          }
        }

        // update mode
        if (userId) {
          const masterResult = await getMasterProfileData(userId);

          _interests = getFormattedInterests(interests).map((interest) => {
            const isInterestChecked = masterResult.interests.some((value) => {
              return (
                parseInt(value.interestId) === parseInt(interest.interestId)
              );
            });

            return {
              ...interest,
              checked: isInterestChecked,
            };
          });

          setMasterProfileFormData((prevState) => {
            return {
              ...prevState,
              interestsCheckboxesOutSideWork: _interests,
            };
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchInterestsData();
  }, []);
}
