import classes from "./PersonalProfileInfo.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CustomCheckboxButton } from "../../../../../components/customCheckboxButton/customCheckboxButton";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import { ExplanationIcon } from "@components";
import countryList from "react-select-country-list";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateMasterProfileData } from "../../../../../services/masterApi";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import { getFormattedMasterProfileForBackend } from "../../../../../utils/utils";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { Button, Stack } from "@mui/material";
import { NextButton } from "../ProfessionalTrainings/professionalTrainings-styles";
import { useFetchBiosData } from "./hooks/useFetchBiosData";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../../../../constants/common.consts";
import { colors } from "../../../../../styles";

const DEFAULT_MAX_BIOS = 10;

const DEFAULT_ERROR_STATE = {
  countrySelect: "",
  descriptionSelect: "",
  describeYourselfSelect: "",
  interestsCheckboxesOutSideWork: "",
};

export const PersonalProfileInfo = () => {
  const { auth } = useSelector((state: AppState) => state);

  const [error, setError] = useState(null);
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const {
    isLoading,
    starSignsOptions,
    maritalOptions,
    smokeOptions,
    educationOptions,
    descriptionOptions,
    describeYourselfOptions,
  } = useFetchBiosData();

  const countryOptions = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [masterProfileFormData]);

  const isFieldsValid = () => {
    let isError = false;

    if (!masterProfileFormData.countrySelect) {
      setErrs((prevState) => {
        return {
          ...prevState,
          countrySelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData?.descriptionSelect.value) {
      setErrs((prevState) => {
        return {
          ...prevState,
          descriptionSelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    if (!masterProfileFormData?.describeYourselfSelect?.value) {
      setErrs((prevState) => {
        return {
          ...prevState,
          describeYourselfSelect: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    const checkboxes =
      masterProfileFormData.interestsCheckboxesOutSideWork.filter(
        (interest) => interest.checked
      );

    if (!checkboxes?.length) {
      setErrs((prevState) => {
        return {
          ...prevState,
          interestsCheckboxesOutSideWork: DEFAULT_ERROR_TEXT_MESSAGE,
        };
      });

      isError = true;
    }

    return !isError;
  };

  const onCheckboxChange = (value: boolean, interestId: number) => {
    let _interestsCheckboxesOutSideWork =
      masterProfileFormData.interestsCheckboxesOutSideWork.map((el) => {
        if (el.interestId === interestId) {
          return { ...el, checked: value, disabled: false };
        }

        return el;
      });

    const getActiveCheckboxes = () => {
      const activeCheckboxes = _interestsCheckboxesOutSideWork.filter(
        (checkbox) => {
          return checkbox.checked;
        }
      );

      return activeCheckboxes;
    };

    if (getActiveCheckboxes()?.length === DEFAULT_MAX_BIOS) {
      _interestsCheckboxesOutSideWork = _interestsCheckboxesOutSideWork.map(
        (el) => {
          return { ...el, disabled: !el.checked };
        }
      );
    } else {
      _interestsCheckboxesOutSideWork = _interestsCheckboxesOutSideWork.map(
        (el) => {
          return { ...el, disabled: false };
        }
      );
    }

    setMasterProfileFormData({
      ...masterProfileFormData,
      interestsCheckboxesOutSideWork: _interestsCheckboxesOutSideWork,
    });
  };

  useEffect(() => {

    for (const [key, value] of Object.entries(errs)) {
      if (value != DEFAULT_ERROR_STATE[key]) {
        console.log(key);
        const errorElement = document.querySelector("[id=div_"+key+"]");
        console.log(errorElement);
        if (errorElement) {
          errorElement.scrollIntoView({behavior: 'smooth'})
          return;
        }
      }
    }

  }, [errs]);

  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const submitCreateMode = async () => {
    setFormDataToStorage({
      ...masterProfileFormData,
    });

    navigate(location, {
      state: {
        activeStep: 5,
      },
    });
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const updatedMasterProfile = await updateMasterProfileData(
        getFormattedMasterProfileForBackend(
          masterProfileFormData,
          userId,
          auth
        ),
        userId
      );

      navigate(`/profile/${userId}`);
    } catch (error) {
      setError(error);
    }
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (
    isLoading ||
    !masterProfileFormData.interestsCheckboxesOutSideWork?.length
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div>
      <h2 className={classes.page_title}>Personal profile</h2>
      <h3 className={classes.title}>
        Let the world get to know you more personally - it's always more fun:
        you decide what you want to share on your profile
      </h3>

      <form className={classes.form_wrapper} onSubmit={handleSubmit}>
        <div className={classes.wrapper_for_selects}>
          <div className={classes.select_country}>
            <MySelect
              name="countrySelect"
              text="Where are you from?"
              value={masterProfileFormData.countrySelect}
              options={countryOptions}
              onChange={handleSelectInputChange}
              placeholder={"Select one option"}
              require
              err={errs.countrySelect}
            />
          </div>
          <h3 className={`${classes.title} ${classes.title_alignment}`}>
            What would you like to share from your bio?
            <ExplanationIcon text="Select the areas you feel comfortable to share on your profile" />
          </h3>

          <div className={classes.select_flex_wrapper}>
            <MySelect
              name="starSignsSelect"
              text="What's your star sign?"
              value={masterProfileFormData.starSignsSelect?.value}
              options={starSignsOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder={"Select one option"}
              isClearable
            />
            <MySelect
              name="maritalSelect"
              text="What's your marital situation?"
              value={masterProfileFormData.maritalSelect?.value}
              options={maritalOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder={"Select one option"}
              isClearable
            />
          </div>

          <div className={classes.select_flex_wrapper}>
            {/*<MySelect*/}
            {/*  name="haveKidsSelect"*/}
            {/*  text="Do you have kids?"*/}
            {/*  value={masterProfileFormData.haveKidsSelect?.value}*/}
            {/*  options={haveKidsOptions}*/}
            {/*  onChange={(value, actionMeta, newValue) =>*/}
            {/*    setMasterProfileFormData({*/}
            {/*      ...masterProfileFormData,*/}
            {/*      [actionMeta.name]: newValue,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  placeholder={"Select one option"}*/}
            {/*  isClearable*/}
            {/*/>*/}
            <MySelect
              name="educationSelect"
              text="What's your education?"
              value={masterProfileFormData.educationSelect?.value}
              options={educationOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder={"Select one option"}
              isClearable
            />
            <MySelect
              name="smokeSelect"
              text="Do you smoke?"
              value={masterProfileFormData.smokeSelect?.value}
              options={smokeOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder={"Select one option"}
              isClearable
            />
          </div>

          {/*<MySelect*/}
          {/*  name="religionSelect"*/}
          {/*  text="Do you identify with religion?"*/}
          {/*  value={masterProfileFormData.religionSelect?.value}*/}
          {/*  options={religionOptions}*/}
          {/*  onChange={(value, actionMeta, newValue) =>*/}
          {/*    setMasterProfileFormData({*/}
          {/*      ...masterProfileFormData,*/}
          {/*      [actionMeta.name]: newValue,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  placeholder={"Select one option"}*/}
          {/*  isClearable*/}
          {/*/>*/}

          <div className={classes.select_flex_wrapper}>
            {/*<MySelect*/}
            {/*  name="covidStatusSelect"*/}
            {/*  text="What's your COVID Vaccine status?"*/}
            {/*  value={masterProfileFormData.covidStatusSelect?.value}*/}
            {/*  options={covidStatusOptions}*/}
            {/*  onChange={(value, actionMeta, newValue) =>*/}
            {/*    setMasterProfileFormData({*/}
            {/*      ...masterProfileFormData,*/}
            {/*      [actionMeta.name]: newValue,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  placeholder={"Select one option"}*/}
            {/*  isClearable*/}
            {/*/>*/}
            <MySelect
              name="descriptionSelect"
              text="Which of the below describes you best?"
              value={masterProfileFormData.descriptionSelect?.value}
              options={descriptionOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder={"Select one option"}
              require
              err={errs.descriptionSelect}
            />
            <MySelect
              name="describeYourselfSelect"
              text="How do you describe yourself?"
              value={masterProfileFormData.describeYourselfSelect?.value}
              options={describeYourselfOptions}
              onChange={(value, actionMeta, newValue) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  [actionMeta.name]: newValue,
                })
              }
              placeholder={"Select one option"}
              require
              err={errs.describeYourselfSelect}
            />
          </div>

          <div
            className={`${classes.select_flex_wrapper} ${classes.select_describe}`}
          ></div>
        </div>
        <h3 className={classes.title}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack flexDirection="row" gap="8px">
              <span
                style={{
                  color: errs.interestsCheckboxesOutSideWork
                    ? colors.red
                    : colors.blu4,
                }}
              >
                What do you like outside work?{" "}
              </span>
              <ExplanationIcon text="You can select up to 10 interests" />
            </Stack>
            <span style={{ color: colors.grey2 }}>*</span>
          </Stack>
        </h3>
        <div className={classes.checkboxes}>
          <div className={classes.checkboxes_wrapper}>
            {masterProfileFormData?.interestsCheckboxesOutSideWork?.map(
              (el) => {
                return (
                  <div key={el.interestId}>
                    <CustomCheckboxButton
                      label={el.label}
                      value={el.checked}
                      id={el.interestId}
                      disabled={el.disabled}
                      onChange={onCheckboxChange}
                    />
                  </div>
                );
              }
            )}

            {errs.interestsCheckboxesOutSideWork && (
              <div className={classes.err_text}>
                {errs.interestsCheckboxesOutSideWork}
              </div>
            )}
          </div>
        </div>

        <div className={classes.btn_bottom_wrapper}>
          {!userId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <NextButton
                variant="contained"
                disableTouchRipple
                disableFocusRipple
                type="submit"
              >
                Next
              </NextButton>
            </>
          ) : (
            <>
              <Button
                type="submit"
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
