import styled from "@emotion/styled";
import { alpha, Button, Grid } from "@mui/material";
import { colors, vw } from "../../../../styles";
import { AdaptiveText } from "../../../../components";
const Wrapper = styled.div`
  padding: 100px 0 0;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("60 0 0")};
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 20px;
  background-color: ${colors.blu1};
  padding: 60px 0;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("36 0")};

    border-radius: ${vw(20)};
  }

  .carousel-next,
  .carousel-prev {
    background-color: ${alpha(colors.white, 0.4)};
  }

  .carousel-next {
    right: 22px;

    ${(props) => props.theme.breakpoints.down("md")} {
      right: -10px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      right: ${vw(-10)};
    }
  }

  .carousel-prev {
    left: 22px;

    ${(props) => props.theme.breakpoints.down("md")} {
      left: -10px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      left: ${vw(-10)};
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 3px;
  margin: 12px 8px;
  background-color: ${colors.pink4};
  opacity: 0.3;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("12 8")};

    height: ${vw(3)};
  }
`;

const StepRow = styled(Grid)`
  margin-top: 46px;
  padding: 0 114px;
  gap: 72px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 32px;
    padding: 0 36px;
    gap: 30px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(32)};
    padding: ${vw("0 36")};
    gap: ${vw(30)};
  }
`;

const BottomRow = styled(Grid)`
  padding: 0 114px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 36px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 36")};
  }
`;

const StepNum = styled(AdaptiveText)`
  color: ${colors.pink4};
  font-family: "Greenwich", serif;
`;

const StepDescription = styled(AdaptiveText)`
  margin: 16px 0 24px;
  max-width: 600px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("15 0 24")};
  }
`;

const StyledButton = styled(Button)`
  width: 336px;
`;

const TextPaddingBox = styled.div`
  padding: 0 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("0 20")};
  }
`;

const BottomBtnBlock = styled.div`
  display: none;

  ${(props) => props.theme.breakpoints.down("xl")} {
    display: flex;
    margin: ${vw("0 20")};
  }
`;

export {
  Wrapper,
  Card,
  Divider,
  StepRow,
  StepNum,
  StepDescription,
  StyledButton,
  TextPaddingBox,
  BottomRow,
  BottomBtnBlock,
};
