import { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../../../components/customButton/customButton";
import "./ContactMeModal.scss";

export default function ContactMeModal({ masterProfileData }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleUrlRedirect = async () => {
    window.open(`${masterProfileData.user.publicProfile}`, "_blank");

    handleClose();
  };

  return (
    <>
      <CustomButton
        className="contact_me_button"
        variant="secondary"
        onClick={handleShow}
      >
        Contact me
      </CustomButton>

      <Modal
        className="contact_me_modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <div className="title">CONTACT THIS BEAUTY ARTIST</div>
            <div className="sub-title">
              You will be redirected to the external service provided by the
              beauty artist as their main contact
            </div>
          </div>
          <div className="buttons">
            <CustomButton variant="outline-primary" onClick={handleClose}>
              Back
            </CustomButton>
            <CustomButton variant="primary" onClick={handleUrlRedirect}>
              Continue
            </CustomButton>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
