import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getAllAmenities,
  getSalonProfileData,
} from "../../../services/salonsApi";
import getSalonFormDataFromStorage from "./Stepper/services/getSalonFormDataFromStorage";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

const getFormattedAmenities = (amenities) => {
  const _amenities = amenities.map((amenity) => {
    return {
      amenityId: amenity.amenityId,
      label: amenity.name,
      checked: false,
    };
  });

  return _amenities;
};

export default function useFetchAmenitiesData() {
  const { auth } = useSelector((state: AppState) => state);
  const { setSalonFormData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { salonId } = useParams();

  useEffect(() => {
    const fetchInterestsData = async () => {
      try {
        setIsLoading(true);

        // TODO: refactor to simplify

        const amenities = await getAllAmenities();

        let _amenities = getFormattedAmenities(amenities);

        // create mode
        if (!auth?.salonId) {
          if (
            !getSalonFormDataFromStorage()?.checkboxesAmenitiesExpectations
              ?.length
          ) {
            setSalonFormData((prevState) => {
              return {
                ...prevState,
                checkboxesAmenitiesExpectations: _amenities,
              };
            });
          }
        }

        // update mode
        if (salonId) {
          const salonProfileFormData = await getSalonProfileData(salonId);

          _amenities = getFormattedAmenities(amenities).map((amenity) => {
            const isInterestChecked = salonProfileFormData.salon.amenities.some(
              (value) => {
                return (
                  parseInt(value.amenityId) === parseInt(amenity.amenityId)
                );
              }
            );

            return {
              ...amenity,
              checked: isInterestChecked,
            };
          });

          setSalonFormData((prevState) => {
            return {
              ...prevState,
              checkboxesAmenitiesExpectations: _amenities,
            };
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchInterestsData();
  }, []);
}
