import "antd/dist/antd.css";
import { TimePicker } from "antd";
import "./customeRangeTimePicker.scss";
import classes from "./customeRangeTimePicker.module.scss";
import moment from "moment";

export const CustomRangeTimePicker = ({
  startTime,
  onChangeStartTime,
  endTIme,
  onChangeEndTime,
  isOpen,
  ...restProps
}) => {
  const background = isOpen ? "" : "#F5F9FF";
  const color = isOpen ? "" : "#BABABA";

  const onChangeStartTimeHandler = (value) => {
    onChangeStartTime(moment(value).format("HH:mm"));
  };

  const onChangeEndTimeHandler = (value) => {
    onChangeEndTime(moment(value).format("HH:mm"));
  };

  return (
    <div
      className={classes.main_wrapper}
      style={{ backgroundColor: background }}
    >
      <div className={classes.time_wrapper}>
        <div className={classes.time_text} style={{ color: color }}>
          From
        </div>
        <TimePicker
          format="HH:mm"
          value={startTime}
          showNow={false}
          suffixIcon={false}
          onSelect={onChangeStartTimeHandler}
          style={{ color: color }}
          disabled={!isOpen}
          {...restProps}
        />
      </div>
      <div className={classes.time_wrapper}>
        <div className={classes.time_text} style={{ color: color }}>
          To
        </div>
        <TimePicker
          format="HH:mm"
          value={endTIme}
          showNow={false}
          suffixIcon={false}
          onSelect={onChangeEndTimeHandler}
          style={{ color: color }}
          disabled={!isOpen}
          {...restProps}
        />
      </div>
    </div>
  );
};
