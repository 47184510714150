import React, { useEffect, useState } from "react";
import artist_1 from "../../../assets/images/artist_1.webp";
import artist_2 from "../../../assets/images/artist_2.webp";
import artist_3 from "../../../assets/images/artist_3.webp";
import artist_4 from "../../../assets/images/artist_4.webp";
import artist_5 from "../../../assets/images/artist_5.webp";
import artist_6 from "../../../assets/images/artist_6.webp";
import artist_7 from "../../../assets/images/artist_7.webp";
import artist_8 from "../../../assets/images/artist_8.webp";
import artist_9 from "../../../assets/images/artist_9.webp";
import classes from "./RandomFaceImage.module.scss";

const faceImagesArray = [
  {
    id: 1,
    image: artist_1,
  },
  {
    id: 2,
    image: artist_2,
  },
  {
    id: 3,
    image: artist_3,
  },
  {
    id: 4,
    image: artist_4,
  },
  {
    id: 5,
    image: artist_5,
  },
  {
    id: 6,
    image: artist_6,
  },
  {
    id: 7,
    image: artist_7,
  },
  {
    id: 8,
    image: artist_8,
  },
  {
    id: 9,
    image: artist_9,
  },
];

const shuffle = (array) => {
  const shuffledArray = array.sort(() => Math.random() - 0.5);

  return shuffledArray;
};

export const RandomFaceImage = () => {
  const [faceImages, setFaceImages] = useState<any>(faceImagesArray);

  useEffect(() => {
    setFaceImages(shuffle([...faceImagesArray]));
  }, []);

  return (
    <img
      src={faceImages[0].image}
      alt="artist"
      className={classes.top_section_image}
    />
  );
};
