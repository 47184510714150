import React, { useContext, useState } from "react";
import { loginUserWithGoogle } from "../services/authApi";
import { authService } from "../services/authApi";
import getFormDataFromStorage from "../pages/profile/EditProfileForm/Stepper/services/getFormDataFromStorage";
import getSalonFormDataFromStorage from "../pages/salons/EditSalonsForm/Stepper/services/getSalonFormDataFromStorage.js";
import {
  INITIAL_MASTER_PROFILE_FORM_DATA,
  INITIAL_SALON_FORM_DATA,
} from "../constants/common.consts.tsx";

// 86400000 = 1 day
const TIME = 86400000;

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [masterProfileFormData, setMasterProfileFormData] = useState(
    getFormDataFromStorage() || INITIAL_MASTER_PROFILE_FORM_DATA
  );
  const [salonFormData, setSalonFormData] = useState(
    getSalonFormDataFromStorage() || INITIAL_SALON_FORM_DATA
  );
  const [loading, setLoading] = useState(false);

  async function registerWithEmailAndPassword({ email, password, ...rest }) {
    const data = await authService.register({ email, password, ...rest });

    setCurrentUser(data);

    runLogoutTimer(data.userId);

    return data;
  }

  async function runLogoutTimer(userId) {
    setTimeout(() => {
      logout(userId);
    }, TIME);
  }

  async function loginWithEmailAndPassword({ email, password }) {
    const data = await authService.login(email, password);

    if (data?.error) {
      alert(data.error + " " + data.message);

      return Promise.reject(new Error("error"));
    }

    setCurrentUser(data);

    runLogoutTimer(data.userId);

    return data;
  }

  async function logout(userId) {
    await authService.logout(userId);

    setCurrentUser(null);
    setMasterProfileFormData(INITIAL_MASTER_PROFILE_FORM_DATA);
    setSalonFormData(INITIAL_SALON_FORM_DATA);

    localStorage.clear();
    sessionStorage.clear();
  }

  async function loginWithGoogle(response) {
    const data = await loginUserWithGoogle(response);

    setCurrentUser(data);
  }

  async function loginWithFacebook(response) {
    console.log(response);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  const value = {
    currentUser,
    setCurrentUser,
    masterProfileFormData,
    setMasterProfileFormData,
    runLogoutTimer,
    registerWithEmailAndPassword,
    loginWithEmailAndPassword,
    loginWithGoogle,
    loginWithFacebook,
    logout,
    updateEmail,
    updatePassword,
    salonFormData,
    setSalonFormData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
