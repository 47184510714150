import styled from "@emotion/styled";
import { Button, Link } from "@mui/material";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("60 0 30")};
  }
`;

const Title = styled.div`
  width: auto;
  padding: 0 79px 12px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 30px 12px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 30 12")};
  }
`;

const Advantages = styled.div`
  width: 100%;
  margin: 44px 0;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin: 0 44px 0;
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("0 0 44")};
    width: calc(100% + ${vw(40)});

    & .carousel-next {
      right: ${vw(6)};
    }

    & .carousel-prev {
      left: ${vw(6)};
    }
  }

  & .swiper {
    margin: 0;
  }
`;

const AdvantageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 100%;
  padding: 17px 35px 0;
  gap: 8px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin: 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(195)};
    padding: ${vw("17 30 0")};
    border-radius: ${vw(12)};
    gap: ${vw(8)};
    margin: ${vw(20)};
  }
`;

const AdvantageIcon = styled.div`
  margin-bottom: 16px;

  & svg {
    width: 70px;
    height: 70px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(70)};
      height: ${vw(70)};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(16)};
  }
`;
const StyledButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 538px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(10)};
    margin-bottom: ${vw(16)};
  }
`;

const StyledLink = styled(Link)`
  font-size: 18px;
  margin-top: 10px;
  height: 24px;
  line-height: 24px;
  text-decoration: underline;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(21)};
    margin-top: ${vw(10)};
    font-size: ${vw(16)};
    line-height: ${vw(21)};
  }
`;

export {
  Wrapper,
  Title,
  Advantages,
  AdvantageWrapper,
  AdvantageIcon,
  StyledButton,
  StyledLink,
};
