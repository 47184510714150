import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthProvider } from "./contexts/AuthContext";
import { ThemeProvider } from "@mui/material";
import { ThemeProvider as StyledThemeProvider } from "@emotion/react";
import { defaultTheme } from "./styles/theme";
import DialogProvider from "./contexts/dialog/dialogProvider";
import store from "./redux/store/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <AuthProvider>
          <BrowserRouter>
            <StyledThemeProvider theme={defaultTheme}>
              <DialogProvider>
                <App />
              </DialogProvider>
            </StyledThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
