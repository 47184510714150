import { Wrapper, Container, FormRow, ButtonRow } from "./reports-styles";
import { AdaptiveText } from "../../components";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getValidation, validationSchema } from "./utils";
import { DateInput, Select } from "../../form-units";
import React, { useEffect, useState } from "react";
import { getReport, getReportTypes } from "../../services/api";
import { Button } from "@mui/material";
import moment from "moment";
import WithDialog from "../../contexts/dialog/WithDialog";
import { SuccessReportSent } from "../../modals";

const Reports = ({ openDialog, closeDialog }) => {
  const [reportTypes, setReportTypes] = useState([]);
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getValidation().defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    getReportTypes().then((data) => {
      setReportTypes(
        data.map((reportType) => {
          return { value: reportType.type, label: reportType.description };
        })
      );
    });
  }, []);

  const onSubmit = () => {
    const values = formProviderProps.getValues();

    getReport({
      fromDate: moment(values.fromDate, "DD/MM/YYYY").toISOString(),
      toDate: moment(values.toDate, "DD/MM/YYYY").toISOString(),
      reportType: values.reportType,
    }).then((data) => {
      if (data.id) {
        formProviderProps.reset({});
        openDialog({
          component: <SuccessReportSent okCallback={closeDialog} />,
          mw: 596,
        });
      }
    });
  };

  const clearFilter = () => {
    formProviderProps.reset();
  };

  return (
    <Wrapper>
      <FormProvider {...formProviderProps}>
        <Container
          onSubmit={formProviderProps.handleSubmit(onSubmit)}
          noValidate
        >
          <AdaptiveText
            xl={{ size: 36, height: 46 }}
            xs={{ size: 36, height: 46 }}
            weight={600}
            fontFamily="Greenwich"
          >
            Report
          </AdaptiveText>
          <FormRow>
            <Select
              name="reportType"
              options={reportTypes}
              placeholder="Select one option"
              label="Report type"
            />
            <DateInput
              name="fromDate"
              placeholder="DD-MM-YYYY"
              label="Start date"
            />
            <DateInput
              name="toDate"
              placeholder="DD-MM-YYYY"
              label="End date"
            />
          </FormRow>
          <ButtonRow>
            <Button variant="outlined" disableRipple onClick={clearFilter}>
              Clear
            </Button>
            <Button type="submit" variant="contained" disableRipple>
              Submit
            </Button>
          </ButtonRow>
        </Container>
      </FormProvider>
    </Wrapper>
  );
};

export default WithDialog(Reports);
