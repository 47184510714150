import styled from "@emotion/styled";
import { AdaptiveText } from "../../../../components";
import { colors, vw } from "../../../../styles";
import { alpha, Button } from "@mui/material";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(AdaptiveText)`
  margin-top: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(16)};
    order: 1;
  }
`;

const ShowMoreButton = styled(Button)`
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 190px;
  height: 40px;
  padding: 0;
  background-color: ${colors.white};
  font-size: 18px;
  line-height: 21;
  color: ${colors.blu2};
  font-family: "Greenwich", serif;
  text-transform: none;

  &:hover {
    background-color: ${alpha(colors.white, 0.7)};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    right: ${vw(8)};
    bottom: ${vw(8)};
    width: ${vw(190)};
    height: ${vw(40)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
    padding: 0;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 11px;
  gap: 8px;

  & svg {
    width: 18px;
    height: 18px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(16)};
    gap: ${vw(8)};
    order: 0;

    & svg {
      width: ${vw(18)};
      height: ${vw(18)};
    }
  }
`;

const MainPhoto = styled.img`
  height: 446px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(340)};
    border-radius: ${vw(20)};
  }
`;

const Gallery = styled.div`
  position: relative;
  order: 2;
  margin-top: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(16)};
  }
`;

const GalleryPhoto = styled.img`
  height: 217px;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

export {
  Wrapper,
  Address,
  Gallery,
  GalleryPhoto,
  MainPhoto,
  Title,
  ShowMoreButton,
};
