const colors = {
  accent: "#121212",
  white: "#ffffff",
  grey: "#bababa",
  grey2: "#6e6c6c",
  grey3: "#8a8383",
  grey4: "#e6e6e6",
  purple_linear: "linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%)",
  purple1: "#382c5d",
  purple2: "#3e3b93",
  purple3: "#5553a3",
  blu1: "#e6f0ff",
  blu2: "#79a7ec",
  blu3: "#639ff9",
  blu4: "#4a83da",
  blu5: "#f5f9ff",
  blu6: "#D1E4FF",
  pink1: "#ffe4ee",
  pink2: "#ff669d",
  pink3: "#fd3f83",
  pink4: "#eb1864",
  pink5: "#da125a",
  red: "#e23600",
  green: "#147E03",
};

export { colors };
