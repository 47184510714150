import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  width: 100%;

  &:before {
    content: "";
    z-index: -999;
    position: absolute;
    left: 0;
    width: 100%;
    height: 298px;
    background: linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%);

    ${(props) => props.theme.breakpoints.down("md")} {
      height: 174px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      height: ${vw(174)};
    }
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${(props) => props.theme.breakpoints.down("md")} {
    gap: 32px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(32)};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  max-width: 735px;
  margin-right: 470px;
  height: 298px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-right: 35vw;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding-bottom: 8px;
    height: 174px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-bottom: ${vw(8)};
    margin-right: 0;
    width: ${vw(240)};
    height: ${vw(174)};
  }
`;

const DescriptionWrapper = styled.div`
  width: 55vw;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

const Description = styled(Typography)`
  letter-spacing: 1.5px;
`;

const HeaderImage = styled.div`
  position: absolute;
  top: 104px;
  right: 0;
  width: 432px;
  height: 432px;
  border-radius: 12px;

  img {
    border-radius: 12px;
  }

  ${(props) => props.theme.breakpoints.down("xl")} {
    width: 30vw;
    height: 30vw;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    top: 32px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    top: ${vw(32)};
    width: ${vw(160)};
    height: ${vw(160)};
  }

  &:before,
  &:after {
    content: "";
    z-index: -997;
    position: absolute;
    left: -82px;
    width: 68px;
    height: 68px;
    background: ${colors.purple3};
    border-radius: 8px;

    ${(props) => props.theme.breakpoints.down("md")} {
      display: none;
    }
  }

  &:after {
    left: -164px;
    background: ${colors.purple2};
  }
`;

const StyledButton = styled(Button)`
  width: 336px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`;

export {
  Wrapper,
  HeaderWrapper,
  TitleWrapper,
  DescriptionWrapper,
  Description,
  HeaderImage,
  StyledButton,
};
