import Select, { components } from "react-select";
import classes from "./multiSelect.module.scss";
import search_icon from "../../assets/icons/search.svg";
import { ExplanationIcon } from "@components";
import { useMediaQuery, useTheme } from "@mui/material";
import { vw, vw1440 } from "../../styles";

const DEFAULT_COLOR_OPTIONS: any = [
  { value: "ocean", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];

type PropsType = {
  text?: string;
  value?: any;
  name?: string;
  div_name?: string;
  onChange?: (value: any[], actionMeta?: any) => void;
  blueValue?: boolean;
  isMulti?: boolean;
  require?: boolean;
  err?: string;
  info?: string;
  placeholder?: string;
  options?: any[];
  defaultValue?: any;
  variant?: "default" | "pink";
};

export const MultiSelect = ({
  text = "",
  value,
  div_name,
  onChange = () => {},
  blueValue,
  require,
  err,
  info,
  options = [],
  variant = "default",
  ...restProps
}: PropsType) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraLargeDisplay = useMediaQuery(theme.breakpoints.down("xl"));
  const isLargeDisplay = useMediaQuery(theme.breakpoints.down("lg"));
  const isDeviceLarge = isExtraLargeDisplay
    ? isExtraLargeDisplay
    : isLargeDisplay;
  const onChangeHandler = (newValue: any[], actionMeta) => {
    onChange(newValue, actionMeta);
  };

  const isColorBLue = blueValue ? "#E6F0FF" : "#fff";

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className={classes.img_wrapper}>
          <img src={search_icon} alt="vv-icon" />
        </div>
      </components.DropdownIndicator>
    );
  };

  let borderColor = err ? "1px solid #E23600" : "none";
  let textColor = "#8A8383";

  if (variant === "pink") {
    borderColor = "1px solid #EB1864 !important";
    textColor = "#EB1864";
  }

  const customStyles = {
    control: (provided, state) =>
      isMobile
        ? {
            ...provided,
            background: isColorBLue,
            minHeight: vw(60),
            border: borderColor,
            borderRadius: vw(8),
            color: textColor,
          }
        : isDeviceLarge
        ? {
            ...provided,
            background: isColorBLue,
            minHeight: vw1440(60),
            border: borderColor,
            borderRadius: vw1440(8),
            color: textColor,
          }
        : {
            ...provided,
            background: isColorBLue,
            minHeight: "60px",
            border: borderColor,
            borderRadius: "8px",
            color: textColor,
          },

    focus: (provided, state) => ({
      ...provided,
      border: "1px solid #639FF9",
    }),

    menu: (provided, state) => ({
      ...provided,
      marginTop: "0px",
      backgroundColor: isColorBLue,
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "8px",
        marginRight: "0px",
        paddingRight: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#ffffff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#E6F0FF",
        borderRadius: "8px",
      },
    }),

    option: (provided, state) =>
      isMobile
        ? {
            ...provided,
            backgroundColor: isColorBLue,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw(20),
            lineHeight: vw(24),
            color: "#121212",
          }
        : isDeviceLarge
        ? {
            ...provided,
            backgroundColor: isColorBLue,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw1440(20),
            color: "#121212",
          }
        : {
            ...provided,
            backgroundColor: isColorBLue,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            color: "#121212",
          },

    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) =>
      isMobile
        ? {
            ...provided,
            height: vw(60),
            marginRight: vw(10),
          }
        : isDeviceLarge
        ? {
            ...provided,
            height: vw1440(60),
            marginRight: vw1440(10),
          }
        : {
            ...provided,
            height: "60px",
            marginRight: "10px",
          },
    clearIndicator: (prevStyle, state) => ({
      ...prevStyle,
      display: "none",
    }),
    valueContainer: (prevState, state) =>
      isMobile
        ? {
            ...prevState,
            fontSize: vw(20),
          }
        : isDeviceLarge
        ? {
            ...prevState,
            color: "#4A83DA",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw1440(20),
          }
        : {
            ...prevState,
            color: "#4A83DA",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
          },
    multiValue: (prevState, state) =>
      isMobile
        ? {
            ...prevState,
            color: "red",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw(20),
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "centre",
          }
        : isDeviceLarge
        ? {
            ...prevState,
            color: "red",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: vw1440(20),
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "centre",
          }
        : {
            ...prevState,
            color: "red",
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "centre",
          },
    ValueContainer: (prevState, state) => ({
      ...prevState,
      color: "#4A83DA",
    }),
    multiValueRemove: (prevState, state) => ({
      ...prevState,
      color: "#4A83DA",
      paddingLeft: "0px",
      svg: {
        width: "24px",
        height: "24px",
      },
      ":hover": {
        backgroundColor: "transparent",
        color: variant === "pink" ? "#EB1864" : "#4A83DA",
      },
    }),
    multiValueLabel: (prevState, state) =>
      isMobile
        ? {
            ...prevState,
            color: "#4A83DA",
            fontSize: vw(20),
          }
        : isDeviceLarge
        ? {
            ...prevState,
            color: "#4A83DA",
            fontSize: vw1440(20),
          }
        : {
            ...prevState,
            color: "#4A83DA",
            fontSize: "20px",
          },
    placeholder: (defaultStyles) =>
      isMobile
        ? {
            ...defaultStyles,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: vw(18),
            color: variant === "pink" ? "#EB1864" : "#BABABA",
          }
        : isDeviceLarge
        ? {
            ...defaultStyles,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: vw1440(18),
            color: variant === "pink" ? "#EB1864" : "#BABABA",
          }
        : {
            ...defaultStyles,
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            color: variant === "pink" ? "#EB1864" : "#BABABA",
          },
  };

  const labelStyle = err ? "#E23600" : "#121212";

  return (
    <div className={classes.main_wrapper} id={"div_"+div_name}>
      <div className={classes.my_select_wrapper}>
        <label className={classes.select_label} style={{ color: labelStyle }}>
          <div className={classes.text_icon_wrapper}>
            {text} {info && <ExplanationIcon text={info} />}
          </div>
          {require && <div className="req_sign">*</div>}
        </label>
        <Select
          options={options?.length ? options : DEFAULT_COLOR_OPTIONS}
          components={{
            DropdownIndicator,
          }}
          value={value}
          onChange={onChangeHandler}
          styles={customStyles}
          isMulti
          className={`${classes.values}`}
          {...restProps}
        />
      </div>
      {err && <div className={classes.err_text}>{err}</div>}
    </div>
  );
};
