import { AdaptiveText } from "../../components";
import React, { useEffect } from "react";
import { Wrapper, ButtonContainer, IconBlock } from "./delete-reject-styles";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Ban } from "../../assets/icons/ban.svg";

const DeleteReject = ({ okCallback }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.delete("payment");
    setSearchParams(searchParams);
  }, []);

  return (
    <Wrapper flexDirection="column">
      <IconBlock>
        <Ban />
      </IconBlock>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={600}
        fontFamily="Greenwich SemBd"
        align="center"
      >
        You can't apply this changes due to the existing booking. Please contact
        us immediately at{" "}
        <a href="mailto:booking@vervvey.com">booking@vervvey.com</a> or dial{" "}
        <a href="tel:+31 20 890 5527">+31 20 890 5527</a> in case you need to
        cancel existing bookings.
      </AdaptiveText>

      <ButtonContainer>
        <Button
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
          OK
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default DeleteReject;
