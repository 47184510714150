import classes from "./explanationIcon.module.scss";
import "./mainInfo.scss";
import icon_svg from "../../assets/icons/info.svg";
import { Tooltip, ThemeProvider, createTheme } from "@mui/material";

type PropsType = {
  text: string;
};

const ExplanationIcon = ({ text = "text" }: PropsType) => {
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: "Greenwich",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "20px",
            lineHeight: "20px",
            color: "#4A83DA",
            backgroundColor: "#F5F9FF",
            borderRadius: "8px",
            marginLeft: "0px",
            padding: "20px 21px 20px 16px",
          },
        },
      },
    },
  });
  return (
    <div className={classes.main_wrapper}>
      <ThemeProvider theme={theme}>
        <Tooltip title={text} placement="right-end">
          <div className={classes.icon_wrapper}>
            <img src={icon_svg} alt="vv-icon" style={{ marginBottom: "3px" }} />
          </div>
        </Tooltip>
      </ThemeProvider>
    </div>
  );
};

export default ExplanationIcon;
