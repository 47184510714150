import React from "react";
import { Wrapper, Title } from "./questions-block-styles";
import { Link, Typography } from "@mui/material";
import { colors } from "../../../../styles";
import { Question } from "../../../../components";

const questions = [
  {
    headerText: "How do I get started as a host?",
    title: `
      The process is simple. To get started listing your salon on the VERVVEY platform all you have to do is follow
      the instructional prompts to create your profile and your first listing. Once your listing is complete and
      submitted, our operations team will validate your listing within 24-48 hours.
    `,
  },
  {
    headerText: "What are the costs to list my empty stations with VERVVEY?",
    title: (
      <Typography>
        VERVVEY hosts list the space of their choice on the platform at no cost.
        VERVVEY takes a service fee of 10% from the total payment when a VERVVEY
        beauty artist make their payment for the workstation rental.
        <br />
        <br />
        For example if a rental costs €100, VERVVEY receives €10 from the
        transactions and the host keeps €90 (€100 minus 10% service fee)
      </Typography>
    ),
  },
  {
    headerText:
      "As a Host, do I need to provide access to the backbar and towel service?",
    title: `
      Yes, as a minimum courtesy VERVVEY hosts provide electrical outlets, trash receptacle, towel service and access to backbar (shampoo/conditioner only)
      for visiting beauty artists. You can specify which other salon amenities are available for artists and their customers and provide any additional
      information regarding amenities availability on your host profile.
    `,
  },
  {
    headerText: "How do I choose a daily rate for my space?",
    title: (
      <Typography>
        Hosts can view available salons on VERVVEY platform to get a sense for
        daily rates in your area. If you have additional questions, our customer
        support team will work with you during the onboarding process. You can
        email us at hello@vervvey.com SUBJECT: Pricing Help
        <br />
        <br />
        <i>
          Tip: Set a lower price for a new listing to help get more bookings,
          you can always adjust later.
        </i>
      </Typography>
    ),
  },
  {
    headerText:
      "What Information can I review about a beauty artist before accepting a request for coworking?",
    title: (
      <Typography>
        As a host, when you receive a coworking request for pre-approval you’ll
        be able to review the beauty artist profile which includes: photo,
        specialty, years of experience, personal and professional background.
        See how it looks:
        <br />
        <br />
        <Link href="https://vervvey.com/profile/f6fb2024-5c8c-4451-901c-534c8df78147">
          <Typography color={colors.blu4} component="span">
            https://vervvey.com/profile/f6fb2024-5c8c-4451-901c-534c8df78147
          </Typography>
        </Link>
      </Typography>
    ),
  },
  {
    headerText: "Any other questions not covered here?",
    title: (
      <Typography component="span">
        We’d love to hear from you! Reach us at&nbsp;
        <Link href="mailto:welcome@vervvey.com">
          <Typography color={colors.blu4} component="span">
            welcome@vervvey.com
          </Typography>
        </Link>
      </Typography>
    ),
  },
];

const QuestionsBlock = () => {
  const [expanded, setExpanded] = React.useState<number | null>(null);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    };

  return (
    <Wrapper>
      <Title>
        <Typography variant="h2" textAlign="center">
          Questions about hosting?
        </Typography>
      </Title>

      {questions.map((question, idx) => (
        <Question
          key={question.headerText}
          title={question.headerText}
          answer={question.title}
          expanded={expanded === idx}
          handleChange={handleChange(idx)}
        />
      ))}
    </Wrapper>
  );
};

export default QuestionsBlock;
