import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import edit from "./../../../assets/icons/edit.svg";
import external_link from "./../../../assets/icons/external_link.svg";
import { PhoneInputTable } from "../phoneInputForTable/phoneInputTable";

const LinkAction = ({ cell, row }) => {
  return (
    <Link to={`${cell.previewUrl}/${row.id}`}>
      <img src={external_link} alt={"link"} style={{ marginRight: "25px" }} />
    </Link>
  );
};

const EditAction = ({ cell, row }) => {
  const { pathname, search } = useLocation();

  const setUrlToLocalStorage = () => {
    localStorage.setItem(cell.memorizedUrl, `${pathname}${search}`);
  };

  return (
    <Link to={`${cell.editUrl}/${row.id}`} onClick={setUrlToLocalStorage}>
      <img src={edit} alt={"edit"} />
    </Link>
  );
};

export const TableBodyContent = ({ tableList, emptyRows, columnCells }) => {
  if (!tableList?.length)
    return (
      <TableRow style={{ height: 60 * emptyRows }}>
        <TableCell colSpan={6}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            No Records to display
          </div>
        </TableCell>
      </TableRow>
    );

  return (
    <>
      {tableList?.map((row) => (
        <TableRow
          key={row.id}
          sx={{
            height: 60,
            borderBottom: "2px solid #e6f0ff",
          }}
        >
          {columnCells.map((cell) => {
            if (cell.id === "action") {
              return (
                <TableCell
                  key={`${cell.id} ${row.id}`}
                  sx={{
                    width: `${cell.width}px` || "auto",
                    color: "#121212",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "Greenwich",
                    fontStyle: "normal",
                  }}
                >
                  <LinkAction cell={cell} row={row} />
                  <EditAction cell={cell} row={row} />
                </TableCell>
              );
            }

            if (cell.id === "phone") {
              return (
                <TableCell
                  key={`${cell.id} ${row.id}`}
                  sx={{
                    fontFamily: "Greenwich",
                    fontWeight: 500,
                    fontStyle: "normal",
                    color: "#121212",
                    fontSize: "14px",
                    width: `${cell.width}px` || "auto",
                  }}
                >
                  <PhoneInputTable value={row[cell.id]} />
                </TableCell>
              );
            }

            if (cell.id === "name") {
              return (
                <TableCell
                  key={`${cell.id} ${row.id}`}
                  sx={{
                    fontFamily: "Greenwich",
                    fontWeight: 500,
                    fontStyle: "normal",
                    color: "#4A83DA",
                    fontSize: "14px",
                    width: `${cell.width}px` || "auto",
                  }}
                >
                  <Link to={`${cell.previewUrl}/${row.id}`}>
                    {row[cell.id]}
                  </Link>
                </TableCell>
              );
            }

            return (
              <TableCell
                key={`${cell.id} ${row.id}`}
                sx={{
                  fontFamily: "Greenwich",
                  fontWeight: 500,
                  fontStyle: "normal",
                  color: "#121212",
                  fontSize: "14px",
                  width: `${cell.width}px` || "auto",
                }}
              >
                {row[cell.id]}
              </TableCell>
            );
          })}
        </TableRow>
      ))}

      {emptyRows > 0 && (
        <TableRow style={{ height: 60 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}

      {!emptyRows && tableList?.length < 5 && (
        <TableRow style={{ height: 60 * 5 }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </>
  );
};
