import { AdaptiveText } from "../../components";
import React from "react";
import {
  StyledButton,
  Wrapper,
  ButtonContainer,
} from "./decline-coworking-styles";

const DeclineCoworking = ({ close, okCallback }) => {
  return (
    <Wrapper flexDirection="column">
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        uppercase
        variant="body1"
      >
        Are you sure you want to decline coworking request?
      </AdaptiveText>
      <AdaptiveText
        variant="body2"
        xl={{ size: 16, height: 18 }}
        xs={{ size: 12, height: 12 }}
      >
        This beauty artist will not have the opportunity of coworking with your
        salon
      </AdaptiveText>
      <ButtonContainer>
        <StyledButton
          variant="outlined"
          disableTouchRipple
          disableFocusRipple
          onClick={close}
        >
          Cancel
        </StyledButton>
        <StyledButton
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
          Decline
        </StyledButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default DeclineCoworking;
