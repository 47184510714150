import React, { useEffect, useState } from "react";
import { Tab, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useAuth } from "../../../../contexts/AuthContext";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { getMasterProfileData } from "../../../../services/masterApi";
import { getFormattedMasterProfileFormData } from "../../../../utils/utils";
import CustomSpinner from "../../../../components/spinner/customSpinner";
import { ReactComponent as Password } from "../../../../assets/icons/password.svg";
import {
  Divider,
  EditProfileWrapper,
  NavigationTabs,
  TabsWrapper,
} from "./EditProfile-styles";
import { vw } from "../../../../styles";
import { NavTab } from "../../components/nav-tab";
import { ROLES } from "../../../../constants/common.consts";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

const tabs = [
  {
    to: "professional_profile",
    title: "Professional profile",
  },
  {
    to: "services_you_offer",
    title: "Services you offer",
  },
  {
    to: "work",
    title: "Your portfolio",
  },
  {
    to: "trained_with",
    title: "Professional trainings",
  },
  {
    to: "personal_profile",
    title: "Personal profile",
  },
  {
    to: "personal_details",
    title: "Personal details",
  },
  {
    to: "personal_administrator",
    title: "Administrator",
  },
];

export const UpdateModeProfile = () => {
  const { auth } = useSelector((state: AppState) => state);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down("lg"));
  const { setMasterProfileFormData } = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { userId } = useParams();

  const [tabValue, setTabValue] = useState(
    location.pathname.split("/").slice(-2).join("/")
  );

  const handleChangeTab = (e, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const masterResult = await getMasterProfileData(userId);

        setMasterProfileFormData(
          getFormattedMasterProfileFormData(masterResult)
        );

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <CustomSpinner />;
  }

  return (
    <EditProfileWrapper>
      <Grid
        container
        direction={isDesktop ? "column" : "row"}
        spacing={{ xs: vw(42), sm: 7 }}
        wrap="nowrap"
      >
        <Grid item>
          <TabsWrapper>
            <NavigationTabs
              value={tabValue}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {tabs.map((tab, idx) => {
                if (
                  auth?.role !== ROLES.SUPER_ADMIN &&
                  tab.to === "personal_administrator"
                ) {
                  return null;
                }

                return (
                  <Tab
                    disableTouchRipple
                    disableFocusRipple
                    value={`${tab.to}/${userId}`}
                    key={`${tab.to}/${userId}`}
                    label={
                      <NavTab
                        to={`${tab.to}/${userId}`}
                        title={tab.title}
                        index={idx + 1}
                      />
                    }
                  />
                );
              })}

              {auth?.userId === userId && (
                <>
                  <Divider />
                  <Tab
                    disableTouchRipple
                    disableFocusRipple
                    value={`change_password/${userId}`}
                    label={
                      <NavTab
                        to={`change_password/${userId}`}
                        title="Change password"
                        index={8}
                        icon={Password}
                      />
                    }
                  />
                </>
              )}
            </NavigationTabs>
          </TabsWrapper>
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </EditProfileWrapper>
  );
};
