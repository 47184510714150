import React, { useState } from "react";
import { Dispatch } from "redux";
import { Button } from "@mui/material";
import { Alert } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authService } from "@services/authApi";
import { PasswordInput, TextField } from "@form-units";
import { AuthActions } from "@redux/actions/authActions";

import { getValidation } from "./duck/utils";
import ReactGA from 'react-ga4'

import {
  Wrapper,
  PaddedBox,
  ForgotBox,
  LoginBlock,
  StyledLink,
  FieldsWrapper,
} from "./login-styles";
import { colors } from "../../styles";
import { AdaptiveText } from "@components";
import {ROLES, STATUSES} from "../../constants/common.consts";

export default function Login({ close }) {
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { validationSchema, defaultValues } = getValidation();

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const register = () => {
    close();
    navigate("/register_modal");
  };

  const forgot = () => {
    close();
    navigate("/forgot_password_modal");
  };

  async function handleSubmit() {
    try {
      setLoading(true);

      const { email, password } = formProviderProps.getValues();

      const userData = await authService.login(email, password);

      authDispatch({ type: "SET_USER", payload: userData });

      if (userData.token) {
        ReactGA.set({ userId: userData.userId, user_role: userData.role, user_status: userData.status });
        localStorage.setItem("user", JSON.stringify(userData));
        if (userData.role === ROLES.MASTER) {
          if (userData.status === STATUSES.ACTIVE) {
            navigate("/salon_catalog");
          }
          else {
            if (userData.eligible === 1) {
              navigate(`profile_edit/professional_profile/${userData.userId}`);
            }
            else {
              navigate("/eligibility_check");
            }
          }
        }
        else if (userData.role === ROLES.SALON_ADMIN) {
          if (userData.salonId) {
            navigate(`/salon_profile/${userData.salonId}`);
          }
          else {
            navigate(userData.name
                ? `/edit_salons`
                : "/host_information");
          }
        }
      }

      close();
      return;
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  return (
    <Wrapper>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        fontFamily="Greenwich"
        uppercase
      >
        Welcome back
      </AdaptiveText>
      <PaddedBox>
        <AdaptiveText
          xl={{ size: 16, height: 19 }}
          xs={{ size: 12, height: 12 }}
          fontFamily="Greenwich"
        >
          Please login to your account
        </AdaptiveText>
      </PaddedBox>

      {error && <Alert variant="danger">{error}</Alert>}
      <FormProvider {...formProviderProps}>
        <form
          onSubmit={formProviderProps.handleSubmit(handleSubmit)}
          noValidate
        >
          <FieldsWrapper>
            <TextField
              name="email"
              type="email"
              required
              label="E-mail address"
              size="small"
            />
            <PasswordInput
              name="password"
              label="Password"
              required
              size="small"
            />
          </FieldsWrapper>
          <ForgotBox>
            <StyledLink onClick={forgot}>Forgot your password?</StyledLink>
          </ForgotBox>

          <AdaptiveText
            xl={{ size: 16, height: 18 }}
            xs={{ size: 16, height: 18 }}
            fontFamily="Greenwich"
            color={colors.grey2}
          >
            By creating or logging into an account you are agreeing with our{" "}
            <StyledLink> Terms of Use</StyledLink> and&nbsp;
            <StyledLink> Privacy Policy</StyledLink>
          </AdaptiveText>

          <LoginBlock>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              disableRipple
            >
              Log In
            </Button>
          </LoginBlock>
        </form>
      </FormProvider>
      <AdaptiveText
        xl={{ size: 16, height: 18 }}
        xs={{ size: 16, height: 18 }}
        fontFamily="Greenwich"
      >
        Don’t have an account yet?&nbsp;
        <StyledLink onClick={register}>Register</StyledLink>
      </AdaptiveText>
    </Wrapper>
  );
}
