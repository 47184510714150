import { useState } from "react";
import { Modal, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/customButton/customButton";
import { ROLES } from "../../../../constants/common.consts";
import { useAuth } from "../../../../contexts/AuthContext";
import "./logoutModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { Dispatch } from "redux";
import { AuthActions } from "../../../../redux/actions/authActions";

const CreateModeLogout = ({ handleLogout, handleClose }) => {
  return (
    <>
      <div>
        <div className="title">Do you really want to leave the page?</div>
        <div className="sub-title">
          If you leave now, you will lose your data
        </div>
      </div>
      <div className="buttons">
        <CustomButton variant="outline-primary" onClick={handleLogout}>
          Yes
        </CustomButton>
        <CustomButton variant="primary" onClick={handleClose}>
          No
        </CustomButton>
      </div>
    </>
  );
};

const UpdateModeLogout = ({ handleLogout, handleClose }) => {
  return (
    <>
      <div>
        <div className="title">Are you sure you want to log out?</div>
      </div>
      <div className="buttons">
        <CustomButton variant="outline-primary" onClick={handleLogout}>
          Log out
        </CustomButton>
        <CustomButton variant="primary" onClick={handleClose}>
          Cancel
        </CustomButton>
      </div>
    </>
  );
};

const LogOutModalBody = ({ handleLogout, handleClose }) => {
  const { auth } = useSelector((state: AppState) => state);

  if (auth?.role === ROLES.SALON_ADMIN && !auth?.salonId) {
    return (
      <CreateModeLogout handleLogout={handleLogout} handleClose={handleClose} />
    );
  }

  if (
    (auth?.role === ROLES.MASTER || auth?.role === ROLES.SUPER_ADMIN) &&
    !auth?.name
  ) {
    return (
      <CreateModeLogout handleLogout={handleLogout} handleClose={handleClose} />
    );
  }

  return (
    <UpdateModeLogout handleLogout={handleLogout} handleClose={handleClose} />
  );
};

export default function LogOutModal() {
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const { auth } = useSelector((state: AppState) => state);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  async function handleLogout() {
    try {
      navigate("/");
      await logout(auth.userId);
      authDispatch({ type: "LOGOUT" });
      handleClose();
    } catch {}
  }

  return (
    <>
      <a onClick={handleShow}>
        <NavDropdown.Item as="button">Log out</NavDropdown.Item>
      </a>

      <Modal className="logout_modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <LogOutModalBody
            handleLogout={handleLogout}
            handleClose={handleClose}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
