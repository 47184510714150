import { createTheme } from "@mui/material";
import { colors } from "./colors";
import { vw } from "./helpers";

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480.05,
      md: 800.05,
      lg: 1000.05,
      xl: 1440.05,
    },
  },
  spacing: (x) => (typeof x === "string" ? x : `${x * 8}px`),
  typography: {
    fontFamily: "Greenwich",

    h2: {
      fontSize: "44px",
      lineHeight: "56px",
      fontWeight: 600,
      textTransform: "uppercase",
      color: colors.accent,

      ["@media (max-width: 1440px)"]: {
        fontSize: "38px",
        lineHeight: "44px",
      },

      ["@media (max-width: 1200px)"]: {
        fontSize: "32px",
        lineHeight: "40px",
      },

      ["@media (max-width: 900px)"]: {
        fontSize: "22px",
        lineHeight: "32px",
      },

      ["@media (max-width: 480px)"]: {
        fontSize: vw(22),
        lineHeight: vw(32),
      },
    },
    h3: {
      fontSize: "26px",
      lineHeight: "33 px",
      fontWeight: 700,
      textTransform: "uppercase",
      color: colors.accent,

      ["@media (max-width: 480px)"]: {
        fontSize: vw(26),
        lineHeight: vw(33),
      },
    },
    h4: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 600,
      color: colors.accent,

      ["@media (max-width: 480px)"]: {
        fontSize: vw(16),
        lineHeight: vw(20),
      },
    },
    h5: {
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: 600,
      textTransform: "uppercase",
      color: colors.accent,

      ["@media (max-width: 480px)"]: {
        fontSize: vw(20),
        lineHeight: vw(32),
      },
    },
    subtitle1: {
      fontSize: "26px",
      lineHeight: "33px",
      fontWeight: 300,
      color: colors.accent,

      ["@media (max-width: 480px)"]: {
        fontSize: vw(20),
        lineHeight: vw(32),
      },
    },
    body1: {
      fontFamily: "Greenwich",
      color: colors.accent,
    },
    body2: {
      fontFamily: "Greenwich",
      color: colors.accent,
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {},
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          gap: "10px",
          textDecoration: "none",
          color: colors.blu2,
          fontSize: "24px",
          lineHeight: "30px",
          fontWeight: 500,

          "&:hover": {
            color: colors.blu3,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "9px 20px",
          height: "62px",
          width: "100%",
          minWidth: 0,
          maxWidth: "414px",
          borderRadius: "10px",
          fontFamily: "Greenwich",
          fontSize: "32px",
          lineHeight: "40px",
          fontWeight: 700,
          textTransform: "uppercase",

          ["@media (max-width: 800px)"]: {
            fontSize: "26px",
            lineHeight: "33px",
          },

          ["@media (max-width: 480px)"]: {
            padding: vw("9 20"),
            height: vw(62),
            maxWidth: "100%",
            fontSize: vw(26),
            lineHeight: vw(33),
            borderRadius: vw(10),
          },
        },
        outlined: {},
        outlinedPrimary: {
          border: `2px solid ${colors.pink4}`,
          color: colors.pink4,

          ["@media (max-width: 480px)"]: {
            borderWidth: vw(2),
          },

          "&:hover": {
            backgroundColor: "transparent",
            borderColor: colors.pink3,
            color: colors.pink3,
          },

          "&:active": {
            borderColor: colors.pink5,
            color: colors.pink5,
          },
        },
        contained: {
          boxShadow: "none",
        },
        containedPrimary: {
          backgroundColor: colors.pink4,

          "&:hover": {
            boxShadow: "none",
            backgroundColor: colors.pink3,
          },

          "&:active": {
            boxShadow: "none",
            backgroundColor: colors.pink5,
          },

          "&.Mui-disabled": {
            backgroundColor: colors.grey4,
            color: colors.grey,
          },
        },
        containedSecondary: {
          backgroundColor: colors.blu2,

          "&:hover": {
            boxShadow: "none",
            backgroundColor: colors.blu3,
          },

          "&:active": {
            boxShadow: "none",
            backgroundColor: colors.blu4,
          },

          "&.Mui-disabled": {
            backgroundColor: colors.grey4,
            color: colors.grey,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: `1px solid ${colors.blu4}`,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "16px 0",
          boxShadow: "none",

          ["@media (max-width: 480px)"]: {
            padding: vw("12 0"),
          },

          "&.Mui-expanded": {
            margin: 0,
          },

          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: "auto",
          flexDirection: "row-reverse",

          "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: "20px",

            ["@media (max-width: 480px)"]: {
              marginRight: vw(20),
            },
          },

          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },

          "&.Mui-expanded": {
            minHeight: "auto",
            margin: 0,
          },

          "& .MuiAccordionSummary-content": {
            margin: 0,
            color: colors.pink4,
            fontSize: "26px",
            lineHeight: "30px",
            fontWeight: 600,

            "&:hover": {
              color: colors.pink3,
            },

            "&.Mui-expanded": {
              margin: 0,
            },

            ["@media (max-width: 480px)"]: {
              fontSize: vw(22),
              lineHeight: vw(28),
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "flex",

          padding: "24px 0 0 90px",
          color: colors.accent,
          fontFamily: "Greenwich",
          fontWeight: 300,
          fontSize: "18px",
          lineHeight: "25px",

          ["@media (max-width: 480px)"]: {
            padding: vw("16 0 0 80"),
            fontSize: vw(20),
            lineHeight: vw(20),
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 48,
          height: 26,
          padding: 0,

          ["@media (max-width: 480px)"]: {
            width: vw(48),
            height: vw(26),
          },
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 3,
            transitionDuration: "300ms",
            color: colors.pink1,
            boxShadow: "none",
            ["@media (max-width: 480px)"]: {
              margin: vw(3),
            },
            "&.Mui-checked": {
              transform: "translateX(22px)",
              color: colors.pink4,
              ["@media (max-width: 480px)"]: {
                transform: `translateX(${vw(22)})`,
              },
              "& + .MuiSwitch-track": {
                backgroundColor: colors.white,
                opacity: 1,
                border: 0,
                transition: "background-color, 1500",
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
              ["@media (max-width: 480px)"]: {
                borderWidth: vw(6),
              },
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: "white",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
            boxShadow: "none",
            ["@media (max-width: 480px)"]: {
              width: vw(20),
              height: vw(20),
            },
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: colors.white,
            opacity: 1,
            transition: "background-color, 1500",
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            border: "none",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: "8px",
          transform: "none",
          position: "initial",
          fontFamily: "Greenwich",
          fontSize: "18px",
          lineHeight: "21px",
          color: colors.accent,

          "&.Mui-focused": {
            color: colors.accent,
          },

          ["@media (max-width: 480px)"]: {
            marginBottom: vw(8),
            fontSize: vw(18),
            lineHeight: vw(21),
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "20px 16px",
          backgroundColor: colors.white,
          borderRadius: "8px",
          height: "20px",
          minHeight: "20px",

          ["@media (max-width: 480px)"]: {
            padding: vw("20 16"),
            borderRadius: vw(8),
            height: vw(20),
            minHeight: vw(20),
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.accent,
          fontSize: "20px",
          lineHeight: "20px",
          padding: "13.5px 16px",
          minHeight: "auto",
          fontFamily: "Greenwich",

          ["@media (max-width: 480px)"]: {
            fontSize: vw(20),
            lineHeight: vw(20),
            padding: vw("13.5 16"),
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "none",
          border: `1px solid ${colors.blu2}`,

          ["@media (max-width: 480px)"]: {
            borderRadius: vw(8),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Greenwich",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "20px",
          lineHeight: "20px",
          color: "#4A83DA",
          backgroundColor: "#F5F9FF",
          borderRadius: "8px",
          marginLeft: "0px",
          padding: "20px 21px 20px 16px",

          ["@media (max-width: 480px)"]: {
            fontSize: vw(20),
            lineHeight: vw(20),
            borderRadius: vw(8),
            padding: vw("20 21 20 16"),
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "8px",
          paddingLeft: 0,

          "& svg": {
            width: "18px",
            height: "18px",

            path: {
              fill: colors.pink4,
            },

            ["@media (max-width: 480px)"]: {
              width: vw(18),
              height: vw(18),
            },
          },

          ["@media (max-width: 480px)"]: {
            padding: vw(8),
            paddingLeft: 0,
          },

          "&~.MuiFormControlLabel-label": {
            fontSize: "20px",
            lineHeight: "20px",
            fontFamily: "Greenwich",
            color: colors.accent,

            ["@media (max-width: 480px)"]: {
              fontSize: vw(20),
              lineHeight: vw(20),
            },
          },

          "&.Mui-disabled svg": {
            path: {
              fill: colors.grey,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          "& span": {
            fontFamily: "Greenwich",
            fontSize: "20px",
            lineHeight: "20px",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& svg": {
            width: "24px",
            height: "24px",

            ["@media (max-width: 480px)"]: {
              width: vw(24),
              height: vw(24),
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginLeft: "-9px",
          "& svg path": {
            fill: colors.pink4,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          borderRadius: "20px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0,
          fontFamily: "Greenwich",
          fontSize: "14px",
          lineHeight: "16px",
          borderBottom: `2px solid`,
          borderColor: colors.blu1,

          ["@media (max-width: 480px)"]: {
            fontSize: vw(14),
            lineHeight: vw(16),
            borderWidth: vw(2),
          },
        },
        head: {
          height: "48px",
          padding: "4px 16px",
          fontWeight: 400,
          color: colors.grey2,

          ["@media (max-width: 480px)"]: {
            height: vw(48),
            padding: vw("4 16"),
          },
        },
        body: {
          height: "52px",
          minHeight: "16px",
          padding: "12px 16px",
          fontWeight: 500,
          color: colors.accent,

          ["@media (max-width: 480px)"]: {
            height: vw(52),
            minHeight: vw(16),
            padding: vw("12 16"),
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          ["@media (max-width: 480px)"]: {
            minHeight: vw(52),
            height: vw(52),
          },
        },
      },
    },
  },
});

export { defaultTheme };
