import styled from "@emotion/styled";
import { alpha } from "@mui/material";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  width: auto;
  padding: 0 79px 12px;
  margin-bottom: 32px;
  border-bottom: 3px solid ${alpha(colors.pink4, 0.3)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0 0 ${vw(12)};
    margin-bottom: ${vw(32)};
  }
`;

export { Wrapper, Title };
