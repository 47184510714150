import styled from "@emotion/styled";
import { AdaptiveText } from "../../components";
import { colors, vw } from "../../styles";
import { Grid } from "@mui/material";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 80px;

  &:before {
    content: "";
    z-index: -999;
    position: absolute;
    left: 0;
    width: 100%;
    height: 486px;
    background: ${colors.blu1};
  }

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 0 60px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 0 40px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("0 20")};

    &:before {
      height: ${vw(170)};
    }
  }
`;

const Content = styled.div``;

const StickyContainer = styled.div`
  padding: 100px 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
`;

export { Wrapper, Content, StickyContainer };
