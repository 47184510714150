import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Register } from "@modals";
import { WithDialog } from "@contexts";

const RegisterModal = ({ openDialog, closeDialog }) => {
  const navigate = useNavigate();
  const close = () => {
    closeDialog();
    navigate("/");
  };
  useEffect(() => {
    openDialog({
      component: <Register close={closeDialog} />,
      mw: 598,
      cancelCallback: close,
    });

    return closeDialog;
  }, []);

  return <></>;
};

export default WithDialog(RegisterModal);
