import styled from "@emotion/styled";
import { colors, vw } from "../../styles";

type ConfitmProps = {
  theme?: any;
  error: boolean;
};

const ConfirmTitle = styled.div<ConfitmProps>`
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => (props.error ? colors.red : colors.accent)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(10)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 20px;
  margin: 14px 0 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${(props) => (props.theme as any).breakpoints.down("sm")} {
    gap: ${vw(20)};
    margin: ${vw(14)} 0 ${vw(8)};
  }
`;

const RequiredLabel = styled.span`
  margin-left: 10px;

  ${(props) => (props.theme as any).breakpoints.down("sm")} {
    margin-left: ${vw(10)};
  }
`;

export { ConfirmTitle, RequiredLabel, CheckboxRow };
