import axios from "axios";
import { authHeader, BASE_API_URL } from "./authApi";

export const createSalonFormData = async (salon): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/salon`,
      { ...salon },
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSalonFormData = async (salon, salonId): Promise<any> => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}/salon/${salonId}`,
      { ...salon },
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSalonProfileData = async (
  salonId,
  lang = "EN"
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/salon/${salonId}/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllFrontOfHouseOptions = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/frontOfHouse/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllHowToFoundOptions = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/HowToFound/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllWorkstationOptions = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/workstation/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllWorkstationAvailabilityOptions = async (
  lang = "EN"
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/workstation/availability/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllBrandOptions = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/brand`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllSenseOfTeamworkOptions = async (
  lang = "EN"
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/senseOfTeamwork/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllAmenities = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/amenity/lang/${lang}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSalonAvailability = async (lang = "EN"): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/salon/availability/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Add SALON-MAIN-PHOTO || SALON-GALLERY-PHOTO
export const addSalonImage = async (imageFormData, salonId): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/salon/${salonId}/image/photo`,
      imageFormData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Add SALON-MAIN-PHOTO || SALON-GALLERY-PHOTO
export const addSalonImageCreateMode = async (imageFormData): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/image/photo`,
      imageFormData,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Delete SALON-MAIN-PHOTO || SALON-GALLERY-PHOTO
export const deleteSalonImage = async (salonId, imageId): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BASE_API_URL}/salon/${salonId}/image/${imageId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// Delete SALON-MAIN-PHOTO || SALON-GALLERY-PHOTO
export const deleteSalonImageCreateMode = async (imageId): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BASE_API_URL}/salon/image/${imageId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSalonList = async ({
  lang = "EN",
  page = 0,
  pageSize = 10,
  status = "",
  order = "",
  sortBy = "",
  city = "",
  workstationType = "",
  showInFeaturedSalons = false,
}): Promise<any> => {
  try {
    let _params: any = {
      page,
      pageSize,
    };

    if (status) {
      _params = { ..._params, status };
    }
    if (sortBy) {
      _params = { ..._params, sortBy };
    }
    if (order) {
      _params = { ..._params, order };
    }
    if (city) {
      _params = { ..._params, city };
    }
    if (workstationType) {
      _params = { ..._params, workstationType };
    }
    if (showInFeaturedSalons) {
      _params = { ..._params, showInFeaturedSalons };
    }

    const response = await axios.get(`${BASE_API_URL}/salon/lang/${lang}`, {
      headers: authHeader(),
      params: _params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMasterList = async ({
  lang = "EN",
  page = 1,
  pageSize = 10,
  status = "",
  order = "asc",
  sortBy = "mobilePhone",
}): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/master/lang/${lang}`, {
      headers: authHeader(),
      params: { page, pageSize, status, order, sortBy },
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSalonsWorkstations = async (
  salonId,
  lang = "EN"
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/salon/${salonId}/workstation/relation/lang/${lang}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const addSalonWorkstation = async (workstation): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/salon/workstation/relation`,
      { ...workstation },
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSalonWorkstation = async (
  relationId,
  workstation
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}/salon/workstation/relation/${relationId}`,
      { ...workstation },
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteSalonWorkstation = async (relationId): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BASE_API_URL}/salon/workstation/relation/${relationId}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSchedule = async (
  salonId,
  workstationId,
  params
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/salon/${salonId}/workstation/${workstationId}/schedule`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const addSchedule = async (body): Promise<any> => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/workstation/schedule`,
      body,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSchedule = async (body): Promise<any> => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}/workstation/schedule`,
      body,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
