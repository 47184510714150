import React from "react";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

export const HostNavDesktopList = () => {
  const { auth } = useSelector((state: AppState) => state);
  const location = useLocation();

  const isHostInfoFilled = !!(auth.name && auth.surname);

  const setSalonUrlToLocalStorage = () => {
    localStorage.setItem("memorizedSalonUrl", location.pathname);
  };

  const setHostUrlToLocalStorage = () => {
    localStorage.setItem("memorizedHostUrl", location.pathname);
  };

  return (
    <>
      {auth?.salonId && (
        <Link to={`salon_profile/${auth.salonId}`}>
          <NavDropdown.Item as="button">Salon profile</NavDropdown.Item>
        </Link>
      )}
      <Link
        to={
          !isHostInfoFilled
            ? "host_information"
            : auth.salonId
            ? `edit_salons/salon_information/${auth.salonId}`
            : "edit_salons"
        }
        state={{ from: location.pathname }}
        onClick={setSalonUrlToLocalStorage}
      >
        <NavDropdown.Item as="button">Edit profile</NavDropdown.Item>
      </Link>
      {isHostInfoFilled && (
        <Link
          to="host_information"
          state={{ from: location.pathname }}
          onClick={setHostUrlToLocalStorage}
        >
          <NavDropdown.Item as="button">Edit host info</NavDropdown.Item>
        </Link>
      )}
    </>
  );
};
