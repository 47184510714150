import React from "react";
import { AdaptiveText } from "../../../../components";
import { Wrapper, Divider, Row, Day, Hours } from "./salon-hours-styles";
import classes from "../../../salons/Salons.module.scss";
import { getWeekDayNameById } from "../../../../utils/utils";

const SalonHours = ({ salonProfile }) => {
  return (
    <Wrapper>
      <AdaptiveText
        xl={{ size: 44, height: 56 }}
        xs={{ size: 30, height: 38 }}
        fontFamily="Greenwich SemBd"
        weight={600}
        align="center"
      >
        Working hours
      </AdaptiveText>
      <Divider />
      <>
        {salonProfile?.workingHours.map((day) => {
          return (
            <Row key={day.whId}>
              <Day
                xl={{ size: 24, height: 28 }}
                xs={{ size: 20, height: 24 }}
                fontFamily="Greenwich"
                weight={700}
              >
                {getWeekDayNameById(day.weekDay)}
              </Day>
              <Hours
                xl={{ size: 24, height: 28 }}
                xs={{ size: 20, height: 20 }}
                fontFamily="Greenwich"
              >
                {day.opened ? `${day.from} - ${day.to}` : "Closed"}
              </Hours>
            </Row>
          );
        })}
      </>
    </Wrapper>
  );
};

export default SalonHours;
