import { AuthActions } from "../actions/authActions";

type AuthState = Record<string, any>;

const initialState: AuthState = {};

const AuthReducer = (state: AuthState = initialState, action: AuthActions) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...action.payload,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};
export default AuthReducer;
