import styled from "@emotion/styled";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(60)};
    margin-top: ${vw(30)};
  }
`;

const Title = styled.div`
  width: auto;
  padding: 0 79px 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0 0 ${vw(12)};
  }
`;

const Divide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 60px 0 44px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("40 0 32")};
  }
`;

export { Wrapper, Title, Divide };
