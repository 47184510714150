import React from "react";
import { QuestionTypes } from "./question-types";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandIcon, StyledAccordion } from "./question-styles";

const Question: React.FC<QuestionTypes> = ({
  title,
  answer,
  expanded,
  handleChange,
}) => {
  return (
    <StyledAccordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandIcon />}>{title}</AccordionSummary>
      <AccordionDetails>{answer}</AccordionDetails>
    </StyledAccordion>
  );
};

export default Question;
