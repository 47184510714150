import { TableWrapper } from "./refunds-styles";
import React, { useEffect, useState } from "react";
import { Details } from "../details";
import { Table } from "../../../../components/v2/table";
import {
  geBookingDayStatuses,
  getBookingStatuses,
  getRefunds,
} from "../../../../services/api";
import { ActionComponent } from "./action-component";

const getColumns = (expand, expanded) => [
  {
    name: "Artist's nickname",
    data: "nickname",
  },
  {
    width: "76px",
    data: "action-component",
    getComponent: (item) => {
      return (
        <ActionComponent
          requestId={item?.id}
          expand={expand}
          expanded={expanded}
        />
      );
    },
  },
];

const Refunds = () => {
  const [expanded, setExpanded] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    geBookingDayStatuses().then((data) => {
      setStatuses(data);
    });
  }, []);

  const expand = (item) => {
    if (expanded.includes(item)) {
      setExpanded(expanded.filter((el) => el !== item));
    } else {
      setExpanded([...expanded, item]);
    }
  };

  return (
    <TableWrapper>
      <Table
        desktopOnly
        columns={getColumns(expand, expanded)}
        api={getRefunds}
        minWidth="1440px"
        initialExpand={setExpanded}
        expanded={expanded}
        detailed
        detailComponent={(detailData) => (
          <Details data={detailData} statuses={statuses} />
        )}
      />
    </TableWrapper>
  );
};

export default Refunds;
