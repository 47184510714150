import styled from "@emotion/styled";
import CustomSpinner from "../../spinner/customSpinner";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { colors } from "../../../styles";
import isPropValid from "@emotion/is-prop-valid";

interface Props {
  active?: boolean;
  order: string;
  width?: string;
  last?: boolean;
  withBorders?: boolean;
}

const StyledSpinner = styled(CustomSpinner)`
  width: 100%;
`;

const SortButton = styled(IconButton, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<Props>`
  margin-right: -8px;
  transform: ${(props) =>
    props.order === "asc" ? "rotate(0)" : "rotate(180deg)"};
  transition: all 0.3s;

  & svg path {
    fill: ${(props) => (props.active ? colors.pink4 : colors.grey4)};
  }
`;

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<Pick<Props, "width" | "last" | "withBorders">>`
  width: ${(props) => props.width || "auto"};
  border-right: ${(props) =>
    props.withBorders ? `2px solid ${colors.blu1}` : "none"};

  ${(props) => props.last && `border-right: none;`}
`;

export { StyledSpinner, SortButton, StyledTableCell };
