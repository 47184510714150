const vw = (sizes: string | number) => {
  return typeof sizes === "number"
    ? `${sizes / 4.8}vw`
    : sizes
        .split(" ")
        .map((size) => `${+size / 4.8}vw`)
        .join(" ");
};

export { vw };

export const vw1440 = (...args) => {
  return args.map((arg) => `${((arg * 100) / 1440).toFixed(3)}vw`).join(" ");
};
