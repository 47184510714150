import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { alpha } from "@mui/material";

const Wrapper = styled.div`
  border: 8px solid ${colors.blu1};
  border-radius: 12px;
  padding: 48px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("xl")} {
    border-radius: 12px;
    padding: 48px 16px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    border: none;
    border-radius: 0;
    padding: 48px 0 0;
    margin-top: 60px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0;
    margin-top: ${vw(60)};
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${(props) => props.theme.breakpoints.down("lg")} {
    background-color: ${colors.blu1};
    border: none;
    border-radius: 12px;
    padding: 60px 12px;

    button {
      background-color: ${alpha(colors.white, 0.4)};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    border-radius: ${vw(12)};
    padding: ${vw("60 12")};
  }
`;

const Divider = styled.div`
  margin: 12px 0 32px;
  width: 260px;
  height: 3px;
  background-color: ${alpha(colors.blu4, 0.3)};

  ${(props) => props.theme.breakpoints.down("lg")} {
    width: calc(100% + 24px);
    background-color: ${alpha(colors.pink4, 0.3)};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: calc(100% + ${vw(24)});
    height: ${vw(3)};
    margin: ${vw("12 0 32")};
  }
`;

const Request = styled.div`
  width: 100%;
  max-width: 444px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    width: 100%;
    max-width: 444px;
    margin-bottom: 12px;
    font-size: 28px;

    ${(props) => props.theme.breakpoints.down("xl")} {
      font-size: 24px;
    }
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    z-index: 10;
    width: 100vw;
    box-shadow: 0px -6px 20px 0px #1212120f;
    max-width: 100vw;
    padding: 24px;
    background-color: ${colors.white};
    position: fixed;
    bottom: 0;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("24 20")};
    & button {
      font-size: ${vw(28)};
    }
  }
`;

const Await = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62px;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  margin-bottom: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(62)};
    border-radius: ${vw(4)};
    margin-bottom: ${vw(12)};
  }
`;

const RequestStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;

  svg {
    width: 48px;
    height: 48px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(12)};
    margin-bottom: ${vw(24)};

    svg {
      width: ${vw(48)};
      height: ${vw(48)};
    }
  }
`;

const RequestText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(4)};
  }
`;

export {
  Wrapper,
  Divider,
  Request,
  Await,
  RequestStatus,
  RequestText,
  SliderWrapper,
};
