import classes from "./PersonalDetailsInfo.module.scss";
import { CustomInput } from "@components/customInput/customInput";
import React, { useCallback, useEffect, useState } from "react";
import { CustomTextarea } from "@components/customTextarea/customTextarea";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomDatePicker } from "../../../../../components/customDatePicker/customDatePicker";
import { CustomPhoneInput } from "../../../../../components/phoneInput/phoneInput";
import {
  createMasterProfileData,
  getInsurances,
  getWorkEligibility,
  updateMasterProfileData,
} from "../../../../../services/masterApi";
import { useAuth } from "../../../../../contexts/AuthContext";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import { getFormattedMasterProfileForBackend } from "../../../../../utils/utils";
import {
  DEFAULT_ERROR_TEXT_MESSAGE,
} from "../../../../../constants/common.consts";
import { Button, Grid } from "@mui/material";
import { NextButton } from "../ProfessionalTrainings/professionalTrainings-styles";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { AuthActions } from "@redux/actions/authActions";
import { AppState } from "@redux/reducers/rootReducer";

const DEFAULT_ERROR_STATE = {
  name: "",
  surname: "",
  birthdate: "",
  socialProfile: "",
  email: "",
  mobilePhone: "",
  kvkNumber: "",
  eligibilityId: "",
};

export const PersonalDetailsInfo = () => {
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const { auth } = useSelector((state: AppState) => state);
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const [eligibilities, setEligibilities] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (_, e) => {
    const { name, value } = e.target;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  const isValidURL = (url) => {
    const res = url.match(/^(http(s)?:\/\/.)/g);

    return res !== null;
  };

  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [masterProfileFormData]);

  useEffect(() => {
    const fetchWorkEligibility = async () => {
      try {
        setIsLoading(true);

        const data = await getWorkEligibility();

        setEligibilities(data);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };
    const fetchInsuranceTypes = async () => {
      try {
        const data = await getInsurances();

        setInsurances(data);
      } catch (error) {}
    };

    fetchWorkEligibility();
    fetchInsuranceTypes();
  }, []);

  const isFieldsValid = () => {
    let isError = false;

    if (!masterProfileFormData.name) {
      setErrs((prevState) => {
        return { ...prevState, name: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!masterProfileFormData.surname) {
      setErrs((prevState) => {
        return { ...prevState, surname: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!masterProfileFormData.birthdate) {
      setErrs((prevState) => {
        return { ...prevState, birthdate: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!masterProfileFormData.socialProfile) {
      setErrs((prevState) => {
        return { ...prevState, socialProfile: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!masterProfileFormData.email) {
      setErrs((prevState) => {
        return { ...prevState, email: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    if (!masterProfileFormData.mobilePhone) {
      setErrs((prevState) => {
        return { ...prevState, mobilePhone: DEFAULT_ERROR_TEXT_MESSAGE };
      });

      isError = true;
    }

    // if (!masterProfileFormData.kvkNumber && userId) {
    //   setErrs((prevState) => {
    //     return { ...prevState, kvkNumber: DEFAULT_ERROR_TEXT_MESSAGE };
    //   });
    //
    //   isError = true;
    // }
    //
    // if (masterProfileFormData.kvkNumber?.length !== 8 && userId) {
    //   setErrs((prevState) => {
    //     return { ...prevState, kvkNumber: "Must be exactly 8 characters" };
    //   });
    //
    //   isError = true;
    // }

    // if (!masterProfileFormData.eligibilityId && userId) {
    //   setErrs((prevState) => {
    //     return { ...prevState, eligibilityId: DEFAULT_ERROR_TEXT_MESSAGE };
    //   });
    //
    //   isError = true;
    // }

    if (
      !masterProfileFormData?.socialProfile ||
      !isValidURL(masterProfileFormData.socialProfile)
    ) {
      setErrs((prevState) => {
        return {
          ...prevState,
          socialProfile: "url is not valid",
        };
      });

      isError = true;
    }

    return !isError;
  };

  useEffect(() => {

    for (const [key, value] of Object.entries(errs)) {
      if (value != DEFAULT_ERROR_STATE[key]) {
        console.log(key);
        const errorElement = document.querySelector("[id=div_"+key+"]");
        console.log(errorElement);
        if (errorElement) {
          errorElement.scrollIntoView({behavior: 'smooth'})
          return;
        }
      }
    }

  }, [errs]);

  const submitCreateMode = async () => {
    setFormDataToStorage(masterProfileFormData);

    try {
      setError(null);

      const masterData = await createMasterProfileData(
        getFormattedMasterProfileForBackend(
          masterProfileFormData,
          auth.userId,
          auth
        )
      );

      navigate(`/profile/${masterData.master.userId}`);

      authDispatch({
        type: "SET_USER",
        payload: { ...auth, ...masterData.user },
      });
      localStorage.setItem("user", JSON.stringify(masterData.user));
    } catch (error) {
      setError(error);
    }
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const updatedMasterProfile = await updateMasterProfileData(
        getFormattedMasterProfileForBackend(
          masterProfileFormData,
          userId,
          auth
        ),
        userId
      );

      navigate(`/profile/${userId}`);
    } catch (error) {
      setError(error);
    }
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div className={classes.personal_info_wrapper}>
      <h2 className={classes.page_title}>Personal details</h2>
      <h3 className={classes.title}>
        This is for internal use only, we never share your personal details
        externally, but require them for verification of your profile and
        emergency contacts
      </h3>
      <form className={classes.form_wrapper} onSubmit={handleSubmit} noValidate>
        <div className={classes.inputs_wrapper}>
          <div className={classes.input_wrapper}>
            <CustomInput
              text={"Name"}
              name={"name"}
              type={"text"}
              value={masterProfileFormData.name}
              onChange={handleInputChange}
              require
              err={errs.name}
              placeholder="Eva"
              info="Your first name as written in your ID - we need it for verification of your profile"
            />
            <CustomInput
              text={"Surname"}
              name={"surname"}
              type={"text"}
              value={masterProfileFormData.surname}
              onChange={handleInputChange}
              require
              err={errs.surname}
              placeholder="Stark"
              info="Your surname as written in your ID - we need it for verification of your profile"
            />
          </div>
          <div className={classes.input_wrapper}>
            <CustomDatePicker
              startDate={
                masterProfileFormData.birthdate
                  ? new Date(masterProfileFormData.birthdate)
                  : new Date()
              }
              setStartDate={(value) => {
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  birthdate: value || new Date(),
                });
              }}
              text="Date of birth"
              require
              info="Our support team would love to send you birthday greetings"
            />
            <CustomPhoneInput
              text={"Contact mobile number"}
              name="mobilePhone"
              value={masterProfileFormData.mobilePhone}
              onChange={(value) =>
                setMasterProfileFormData({
                  ...masterProfileFormData,
                  mobilePhone: value,
                })
              }
              require
              err={errs.mobilePhone}
              info="This number will be used by our support team in case of emergency"
            />
          </div>
          <div className={classes.input_wrapper}>
            <CustomInput
              text={"Contact email"}
              name={"email"}
              type={"email"}
              value={masterProfileFormData.email}
              onChange={handleInputChange}
              className={classes.input_style}
              require
              err={errs.email}
              placeholder={"example@mail.com"}
              info="This email will be used for direct communication with you by our support team"
            />
            <CustomInput
              text={"Link to your main social media profile "}
              name={"socialProfile"}
              type={"text"}
              value={masterProfileFormData.socialProfile}
              onChange={handleInputChange}
              require
              err={errs.socialProfile}
              placeholder={"https://www.instagram.com/vervvey/"}
              info="Share the link to the profile on social media, where you are most active, e.g. Instagram or Facebook or Youtube or Tiktok, etc"
            />
          </div>
          <CustomTextarea
            text={
              "Is there anything else you would like to share with us to provide you\n" +
              "   with the best service on the platform?"
            }
            name={"personalNote"}
            value={masterProfileFormData.personalNote}
            onChange={handleInputChange}
            isLimitCounterActive={false}
          />
          {auth?.name && (
            <>
              <div className={classes.input_wrapper}>
                <MySelect
                  name="insured"
                  text="Do you have liability insurance?"
                  value={masterProfileFormData.insured}
                  options={insurances.map((item) => {
                    return { value: item.type, label: item.name };
                  })}
                  onChange={(value, actionMeta, newValue) =>
                    setMasterProfileFormData({
                      ...masterProfileFormData,
                      [actionMeta.name]: value,
                    })
                  }
                  placeholder={"Select one option"}
                />
              </div>
              <div className={classes.input_wrapper}>
                <MySelect
                  name="eligibilityId"
                  text="Eligibility to work is based on"
                  value={masterProfileFormData.eligibilityId}
                  options={eligibilities.map((item) => {
                    return { value: item.eligibilityId, label: item.name };
                  })}
                  onChange={handleSelectInputChange}
                  placeholder="Select one option"
                  info="Please provide the basis for your work eligibility"
                  require
                  err={errs.eligibilityId}
                />
                <CustomInput
                  text="KvK registration number"
                  name="kvkNumber"
                  type="text"
                  value={masterProfileFormData.kvkNumber}
                  onChange={handleInputChange}

                  err={errs.kvkNumber}
                  placeholder={"12345678"}
                  info="Please provide your registration number at the Chamber of Commerce (Kamer van Koophandel)"
                />
              </div>
            </>
          )}
        </div>
        <div className={classes.btn_bottom_wrapper}>
          {!userId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <NextButton
                variant="contained"
                disableTouchRipple
                disableFocusRipple
                type="submit"
              >
                Submit
              </NextButton>
            </>
          ) : (
            <>
              <Button
                type="button"
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
