import styled from "@emotion/styled";
import { Button, Stack } from "@mui/material";
import { vw } from "../../styles";

const Wrapper = styled(Stack)`
  padding: 8px 8px 28px;
  gap: 4px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("8 8 20")};
    gap: ${vw(4)};
  }
`;

const StyledButton = styled(Button)`
  width: 50%;
  max-width: 50%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  max-width: 100%;
  margin-top: 56px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(48)};
  }
`;

export { StyledButton, Wrapper, ButtonContainer };
