import React, { FC } from "react";
import { FilterWrapper, Block } from "./filter-styles";
import { TableDatepicker, TableSelect } from "../../../../../components/v2";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const Filter: FC<any> = ({ statuses = [], managers = [] }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const managerId = searchParams.get("managerId");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");

  const setParam = (name, value) => {
    if (!value) {
      const serchObject = Object.fromEntries([...(searchParams as any)]);
      delete serchObject[name];
      const params = createSearchParams(serchObject).toString();

      navigate({
        search: params,
      });
    } else {
      const params = createSearchParams({
        ...Object.fromEntries([...(searchParams as any)]),
        [name]: value,
      }).toString();

      navigate({
        search: params,
      });
    }
  };

  return (
    <FilterWrapper>
      <Block>
        <TableSelect
          withReset
          options={statuses?.map((item) => ({ value: item, label: item }))}
          value={status}
          onChange={(value) => {
            setParam("status", value);
          }}
          placeholder="Status"
        />
        <TableSelect
          withReset
          options={managers}
          value={managerId}
          onChange={(value) => {
            setParam("managerId", value);
          }}
          placeholder="Manager"
        />
      </Block>
      <Block>
        <TableDatepicker
          withReset
          placeHolder="Start date"
          startDate={fromDate || null}
          setStartDate={(value) => {
            setParam("fromDate", value);
          }}
        />
        <TableDatepicker
          withReset
          placeHolder="End date"
          startDate={toDate || null}
          setStartDate={(value) => {
            setParam("toDate", value);
          }}
        />
      </Block>
    </FilterWrapper>
  );
};

export default Filter;
