import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { Button } from "@mui/material";

interface Props {
  mb?: number;
}

const DetailsTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
    margin-bottom: ${vw(32)};
  }
`;

const SalonLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(8)};
  }

  svg {
    width: 20px;
    height: 20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(20)};
      height: ${vw(20)};
    }

    path {
      stroke: ${colors.blu4};
    }
  }
`;

const LocationTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 3px;
  margin: 40px 0;
  background-color: ${colors.white};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw(40)} 0;
  }
`;

const BookingDetails = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }
`;

const TotalBlock = styled.div`
  margin: 40px 0;
  max-width: 534px;
  border: 2px solid ${colors.white};
  border-radius: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw(40)} 0;
    border-radius: ${vw(20)};
  }
`;

const Summary = styled.div`
  width: 100%;
  padding: 40px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(40)} ${vw(16)} ${vw(32)};
  }
`;

const Descriptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    gap: ${vw(16)};
  }
`;

const Row = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.mb ? `-${props.mb}px` : 0)};
`;

const Total = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 40px;
  background-color: ${colors.white};
  border-radius: 0 0 18px 18px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(8)};
    padding: ${vw(24)} ${vw(16)};
    border-radius: 0 0 ${vw(18)} ${vw(18)};
  }
`;

const Controls = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(94)};
    gap: ${vw(12)};
  }
`;

const DatesBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(16)};
  }
`;

const PayButton = styled(Button)`
  width: 383px;

  & .MuiCircularProgress-root {
    opacity: 0.3;
    color: ${colors.pink4};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(294)};
    padding: ${vw("9 12")};
  }
`;

const BackButton = styled(Button)`
  width: 312px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 126px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(126)};
  }
`;

const RiskBlock = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;

  & svg {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    gap: ${vw(8)};

    & svg {
      width: ${vw(20)};
      height: ${vw(20)};
    }
  }
`;

export {
  DetailsTitle,
  LocationTitle,
  SalonLocation,
  Divider,
  BookingDetails,
  TotalBlock,
  Summary,
  Descriptions,
  Row,
  Total,
  Controls,
  DatesBlock,
  PayButton,
  BackButton,
  RiskBlock,
};
