import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { colors, vw } from "../../styles";

const CoworkingWrapper = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1640px;
  top: 0;
  height: 102px;

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  &::after {
    content: "";
    background-color: ${colors.white};
    border-bottom: 2px solid ${colors.blu1};
    height: 102px;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    height: 138px;

    &::after {
      height: 138px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    &.sticky {
      width: 100vw;
    }

    height: ${vw(138)};

    &::after {
      height: ${vw(138)};
      border-bottom: ${vw(2)} solid ${colors.blu1};
    }
  }
`;

const CoworkingBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  gap: 54px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
    gap: 16px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${vw(16)};
    padding: ${vw("10 20")};
    border-radius: ${vw(20)};
  }
`;

const CoworkBtn = styled(Button)`
  width: 176px;
  height: 52px;
  font-size: 20px;
  line-height: 20px;
  padding: 0;
  text-transform: none;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(214)};
    height: ${vw(52)};
    font-size: ${vw(18)};
    line-height: ${vw(20)};
    padding: ${vw("12 20")};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    gap: 0;
    justify-content: space-between;
  }
`;

const RequestLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  & svg {
    width: 48px;
    height: 48px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(12)};

    & svg {
      width: ${vw(44)};
      height: ${vw(44)};
    }
  }
`;

const Block = styled.div`
  height: 104px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    height: 140px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(140)};
  }
`;

export {
  CoworkingWrapper,
  CoworkingBlock,
  CoworkBtn,
  ButtonContainer,
  RequestLabel,
  Block,
};
