import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import dialogContext from "./dialogContext";
import {
  DialogWidthType,
  DialogPropTypes,
  OpenDialogType,
  EmptyFunctionType,
} from "./types";
import { StyledDialogContent, StyledDialog } from "./dialog-styles";
import { IconButton, Stack } from "@mui/material";

interface StateTypes {
  value: DialogPropTypes;
  isOpen: boolean;
  title: string;
  mw?: number;
  withoutCloser?: boolean;
  cancelText?: string;
  width?: DialogWidthType;
  component: React.ReactNode;
  okCallback: EmptyFunctionType;
  cancelCallback?: EmptyFunctionType;
}

interface PropTypes {
  children: React.ReactNode;
}

class DialogProvider extends React.Component<PropTypes, StateTypes> {
  constructor(props: PropTypes) {
    super(props);

    this.state = {
      isOpen: false,
      title: "",
      cancelText: "Cancel",
      width: "md",
      withoutCloser: false,
      component: null,
      okCallback: () => {
        console.log("Not implemented!");
      },
      cancelCallback: () => {
        console.log("Not implemented!");
      },
      value: {
        openDialog: this.open,
        closeDialog: this.close,
      },
    };
  }

  public open: OpenDialogType = ({
    component,
    cancelCallback,
    mw,
    withoutCloser,
  }) => {
    this.setState({
      component,
      cancelCallback,
      isOpen: true,
      withoutCloser,
      mw,
    });
  };

  public close = (): void => {
    this.setState({ isOpen: false });
  };

  public handleCloseClick = () => {
    if (this.state.cancelCallback) {
      this.state.cancelCallback();
      this.close();
    } else {
      this.close();
    }
  };

  render() {
    const { value, isOpen, component, mw } = this.state;

    return (
      <dialogContext.Provider value={value}>
        <StyledDialog
          open={isOpen}
          onClose={this.handleCloseClick}
          scroll="body"
          fullWidth
          maxWidth="md"
          mw={mw}
        >
          <StyledDialogContent>
            {!this.state.withoutCloser && (
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  aria-label="close"
                  onClick={this.handleCloseClick}
                  disableRipple
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            )}
            {component}
          </StyledDialogContent>
        </StyledDialog>
        {this.props.children}
      </dialogContext.Provider>
    );
  }
}

export default DialogProvider;
