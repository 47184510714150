import { CustomMUISwitch } from "../../../../../components/customMUISwitch/customMUISwitch";
import { useCallback, useState } from "react";
import classes from "./workingHours.module.scss";
import { CustomRangeTimePicker } from "../../../../../components/customRangeTimePicker/customRangeTimePicker";
import { CustomPureSwitch } from "../../../../../components/customPureSwitch/customPureSwitch";
import moment from "moment";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../../../components/BackButton/BackButton";
import { CustomCheckboxButton } from "../../../../../components/customCheckboxButton/customCheckboxButton";
import setSalonFormDataToStorage from "../../Stepper/services/setSalonFormDataToStorage";
import {
  getFormattedSalonFormDataForBEUpdateMode,
  getWeekDayNameById,
} from "../../../../../utils/utils";
import { updateSalonFormData } from "../../../../../services/salonsApi";
import { Button } from "@mui/material";
import { ExplanationIcon } from "@components";
import CustomRadioGroup from "../../../../../components/customRadioGroup/customRadioGroup";
import { NextButton } from "../../../../profile/EditProfileForm/Pages/YourPortfolio/yourPortfolio-styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const salonHoursAvailabilityOptions = [
  { sAvailId: 1, label: "No", value: "No", disabled: false },
  {
    sAvailId: 2,
    label: "Yes, upon request",
    value: "Yes, upon request",
    disabled: false,
  },
];

const getSalonHoursAvailabilityValueObjectByName = (value) => {
  const _objValue = salonHoursAvailabilityOptions?.find((option) => {
    return option.value === value;
  });

  return _objValue;
};

export const WorkingHours = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [error, setError] = useState(null);

  const { salonFormData, setSalonFormData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { salonId } = useParams();

  const onChangeSwitchHandler = (id, value) => {
    let _weekWorkingHours = salonFormData?.weekWorkingHours?.map((day) => {
      return day.weekDay === id ? { ...day, opened: value } : day;
    });

    setSalonFormData({
      ...salonFormData,
      weekWorkingHours: _weekWorkingHours,
    });
  };

  const onChangeStartTimeHandler = (id, value) => {
    let _weekWorkingHours = salonFormData?.weekWorkingHours?.map((day) =>
      day.weekDay === id ? { ...day, from: value } : day
    );

    setSalonFormData({
      ...salonFormData,
      weekWorkingHours: _weekWorkingHours,
    });
  };

  const onChangeSameStartTime = (value) => {
    let _weekWorkingHours = salonFormData?.weekWorkingHours?.map((day) => ({
      ...day,
      from: value,
    }));

    setSalonFormData({
      ...salonFormData,
      weekWorkingHours: _weekWorkingHours,
    });
  };

  const onChangeEndTimeHandler = (id, value) => {
    let _weekWorkingHours;

    if (salonFormData.sameOpeningHours) {
      _weekWorkingHours = salonFormData?.weekWorkingHours?.map((day) => ({
        ...day,
        to: value,
      }));
    } else {
      _weekWorkingHours = salonFormData?.weekWorkingHours?.map((day) =>
        day.weekDay === id ? { ...day, to: value } : day
      );
    }

    setSalonFormData({
      ...salonFormData,
      weekWorkingHours: _weekWorkingHours,
    });
  };

  const onChangeSameEndTime = (value) => {
    let _weekWorkingHours = salonFormData?.weekWorkingHours?.map((day) => ({
      ...day,
      to: value,
    }));

    setSalonFormData({
      ...salonFormData,
      weekWorkingHours: _weekWorkingHours,
    });
  };

  const isFieldsValid = () => {
    return true;
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const data = await updateSalonFormData(
        getFormattedSalonFormDataForBEUpdateMode(salonFormData, salonId, auth),
        salonId
      );

      navigate(`/salon_profile/${salonId}`);
    } catch (error) {
      setError(error);
    }
  };

  const submitCreateMode = async () => {
    setSalonFormDataToStorage(salonFormData);

    navigate(location, {
      state: {
        activeStep: 2,
      },
    });
  };

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!salonId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const changeIsOpenValueHandler = (value, id) => {
    const _workingDays = salonFormData?.weekWorkingHours?.map((day) =>
      day.weekDay === id ? { ...day, opened: value } : day
    );

    setSalonFormData({
      ...salonFormData,
      weekWorkingHours: _workingDays,
    });
  };

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedSalonUrl"));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h2 className={`page_main_title`}>Working hours</h2>
      <h3 className={`page_blue_subtitle`}>
        This gives the range of days and hours when the salon is opened for the
        customers & artists
      </h3>
      <form onSubmit={handleSubmit}>
        <div className={classes.switcherWrapper}>
          <div>Same opening hours</div>
          <CustomMUISwitch
            value={salonFormData.sameOpeningHours}
            onChange={(value) =>
              setSalonFormData({
                ...salonFormData,
                sameOpeningHours: value,
              })
            }
          />
        </div>
        {!salonFormData.sameOpeningHours && (
          <div className={classes.week_wrapper}>
            {salonFormData?.weekWorkingHours?.map((day) => {
              return (
                <div className={classes.day_wrapper} key={day.weekDay}>
                  <div className={classes.day_title}>
                    {getWeekDayNameById(day.weekDay)}
                  </div>
                  <div className={classes.time_values_wrapper}>
                    <CustomPureSwitch
                      isOpen={day.opened}
                      onChange={(value) =>
                        onChangeSwitchHandler(day.weekDay, value)
                      }
                    />
                    <CustomRangeTimePicker
                      startTime={moment(day.from, "HH:mm")}
                      onChangeStartTime={(value) =>
                        onChangeStartTimeHandler(day.weekDay, value)
                      }
                      endTIme={moment(day.to, "HH:mm")}
                      onChangeEndTime={(value) =>
                        onChangeEndTimeHandler(day.weekDay, value)
                      }
                      isOpen={day.opened}
                      minuteStep={5}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {salonFormData.sameOpeningHours && (
          <div className={classes.same_opening_hours_wrapper}>
            <CustomRangeTimePicker
              startTime={moment(
                salonFormData.weekWorkingHours[0].from,
                "HH:mm"
              )}
              onChangeStartTime={onChangeSameStartTime}
              endTIme={moment(salonFormData.weekWorkingHours[0].to, "HH:mm")}
              onChangeEndTime={onChangeSameEndTime}
              isOpen
              minuteStep={5}
            />
            <div className={classes.working_days_wrapper}>
              <p className={classes.working_days_title}>Working days</p>
              <div className={classes.days_wrapper}>
                {salonFormData.weekWorkingHours.map((el) => {
                  return (
                    <div key={el.weekDay}>
                      <CustomCheckboxButton
                        id={el.weekDay}
                        label={getWeekDayNameById(el.weekDay)}
                        value={el.opened}
                        onChange={changeIsOpenValueHandler}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className={classes.salon_hours_availability_wrapper}>
          <div className={classes.salon_hours_availability_title}>
            <div>Salon off hours availability</div>
            <ExplanationIcon
              text={
                "Are you open to visiting artists working outside your hours of operation?"
              }
            />
            <div className="req_sign">*</div>
          </div>
          <CustomRadioGroup
            value={salonFormData.salonHoursAvailability.value}
            values={salonHoursAvailabilityOptions}
            onChange={(value) => {
              setSalonFormData({
                ...salonFormData,
                salonHoursAvailability:
                  getSalonHoursAvailabilityValueObjectByName(value),
              });
            }}
          />
        </div>
        <div className={"btn_bottom_wrapper"}>
          {!salonId ? (
            <>
              <BackButton backNavigationCallback={onBack} />
              <NextButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </NextButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
