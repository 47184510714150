import { ReactComponent as ExpandIcon } from "../../../../../assets/icons/expand_icon.svg";
import { StyledExpandButton } from "./action-component-styles";

const ActionComponent = ({ requestId, expand, expanded }) => {
  return (
    <StyledExpandButton
      disableTouchRipple
      disableFocusRipple
      onClick={() => {
        expand(requestId);
      }}
    >
      <ExpandIcon
        style={{
          rotate: expanded.includes(requestId) ? "90deg" : "0deg",
          transition: "0.2s",
        }}
      />
    </StyledExpandButton>
  );
};

export default ActionComponent;
