import React from "react";
import { Typography } from "@mui/material";
import photo1 from "../../../../assets/images/for_salon/photo_1.webp";
import photo2 from "../../../../assets/images/for_salon/photo_2.webp";
import photo3 from "../../../../assets/images/for_salon/photo_3.webp";
import photo4 from "../../../../assets/images/for_salon/photo_4.webp";
import photo5 from "../../../../assets/images/for_salon/photo_5.webp";
import photo6 from "../../../../assets/images/for_salon/photo_6.webp";
import photo7 from "../../../../assets/images/for_salon/photo_7.webp";
import photo8 from "../../../../assets/images/for_salon/photo_8.webp";
import photo9 from "../../../../assets/images/for_salon/photo_9.webp";
import photo10 from "../../../../assets/images/for_salon/photo_10.webp";
import photo11 from "../../../../assets/images/for_salon/photo_11.webp";

import {
  Wrapper,
  HeaderWrapper,
  TitleWrapper,
  DescriptionWrapper,
  Description,
  HeaderImage,
  StyledButton,
} from "./header-block-styles";
import { colors } from "../../../../styles";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const rotations = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
];

const HeaderBlock = ({ showPopup }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  const becomeHost = (e) => {
    if (auth?.role) {
      e.preventDefault();

      showPopup();
    } else {
      navigate(`/register_modal?mode=${ROLES.SALON_ADMIN}`);
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <Typography variant="h2" color={colors.white}>
            OPEN YOUR SALON
            <br />
            TO HOSTING ON YOUR TERMS.
            <br />
            WE’LL TAKE CARE OF THE REST
          </Typography>
        </TitleWrapper>
        <DescriptionWrapper>
          <Description variant="h5" color={colors.accent}>
            Join the biggest beauty coworking
            <br />
            community of salon hosts
            <br />
            and freelance beauty artists
          </Description>
        </DescriptionWrapper>
        <StyledButton
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={becomeHost}
        >
          Become a host
        </StyledButton>
        <HeaderImage>
          <img
            width="100%"
            height="100%"
            src={rotations[Math.floor(Math.random() * rotations?.length)]}
            alt=""
          />
        </HeaderImage>
      </HeaderWrapper>
    </Wrapper>
  );
};

export default HeaderBlock;
