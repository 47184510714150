import { DEFAULT_ERROR_TEXT_MESSAGE } from "../../constants/common.consts";
import { object, string } from "yup";

export const validationSchema = object({
  reportType: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  fromDate: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  toDate: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({}),
});
