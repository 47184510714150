import { Wrapper, ToggleButton } from "./toggle-styles";

const Toggle = ({
  firstLabel,
  secondLabel,
  firstValue,
  secondValue,
  checked,
  onChange,
}) => {
  return (
    <Wrapper>
      <ToggleButton
        disableRipple
        variant="contained"
        checked={checked === firstValue}
        onClick={() => {
          onChange(firstValue);
        }}
      >
        {firstLabel}
      </ToggleButton>
      <ToggleButton
        disableRipple
        variant="contained"
        checked={checked === secondValue}
        onClick={() => {
          onChange(secondValue);
        }}
      >
        {secondLabel}
      </ToggleButton>
    </Wrapper>
  );
};

export default Toggle;
