import styled from "@emotion/styled";
import { colors, vw } from "../../../../../styles";

const SlideWrapper = styled.div`
  width: 100%;
  padding: 0 14px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 474px;
  border-radius: 20px;
  background-color: ${colors.blu1};
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(474)};
    border-radius: ${vw(20)};
  }
`;

const CardInfo = styled.div`
  padding: 24px 16px;
  height: 226px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("24 16")};
    height: ${vw(226)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 248px;
  border-radius: 20px 20px 0 0;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.down("md")} {
    height: 220px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(268)};
    border-radius: ${vw("20 20 0 0")};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 4px 0;

  & img {
    width: 20px;
    height: 20px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(8)};
    margin: ${vw("4 0")};

    & img {
      width: ${vw(20)};
      height: ${vw(20)};
    }
  }
`;

export { Card, CardInfo, SlideWrapper, Image, Row };
