import { StyledSelect } from "./table-select-styles";
import { IconButton, MenuItem } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { colors } from "../../../styles";
import { MdOutlineClose } from "react-icons/md";

interface IProps {
  options: Record<string, any>[];
  value: number | string;
  onChange: Dispatch<SetStateAction<number | string>>;
  placeholder?: string;
  withReset?: boolean;
}

const TableSelect: FC<IProps> = ({
  options,
  value,
  onChange,
  placeholder,
  withReset,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <StyledSelect
      value={options?.length && value ? value : ""}
      onChange={handleChange}
      displayEmpty
      renderValue={
        value
          ? () => options?.find((option) => option.value === value)?.label
          : () => <span style={{ color: colors.pink4 }}>{placeholder}</span>
      }
      {...(withReset
        ? {
            endAdornment: (
              <IconButton
                sx={{ display: value ? "" : "none" }}
                disableRipple
                onClick={() => {
                  onChange("");
                }}
              >
                <MdOutlineClose />
              </IconButton>
            ),
          }
        : {})}
    >
      {options?.map((option) => {
        return (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

export default TableSelect;
