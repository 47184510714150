import React, { useEffect, useMemo, useState } from "react";
import { AdaptiveText } from "../../../../components";
import {
  SalonInfo,
  SalonLocation,
  SalonOpenes,
  WorkingDays,
  DayContainer,
  Divider,
  Workplace,
  CalendarBlock,
  StyledCalendar,
  WorkplaceSelect,
  Controls,
  SelectContainer,
  WorkspaceCoast,
  WorkplaceProducts,
  DayButton,
} from "./selection-styles";
import { colors } from "../../../../styles";

import { ReactComponent as Location } from "../../../../assets/icons/location.svg";
import { ReactComponent as Clock } from "../../../../assets/icons/clock.svg";
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { getWeekDayNameById } from "../../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ControlledDropdown } from "../../../../v2/components";
import { getSchedule } from "../../../../services/salonsApi";
import { Spinner } from "../../../../components/v2/spinner";
import { getBookings } from "../../../../services/masterApi";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const Selection = ({ salonData, setTab, updateData, bookingData }) => {
  const { auth } = useSelector((state: AppState) => state);
  const [schedule, setSchedule] = useState(null);
  const [bookings, seBookings] = useState([]);
  const navigate = useNavigate();
  const { salonId } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedWorkStation, setSelectedWorkstation] = useState(
    bookingData.workstation || null
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment()
    .add(2, "months")
    .clone()
    .endOf("month")
    .format("YYYY-MM-DD");

  useEffect(() => {
    if (selectedWorkStation) {
      setLoading(true);

      getSchedule(salonId, selectedWorkStation, {
        fromDate: startOfMonth,
        toDate: endOfMonth,
      }).then((data) => {
        setSchedule(data);
        if (bookingData.workstation !== selectedWorkStation) {
          updateData({});
        }
        setLoading(false);
      });

      getBookings(salonId, auth.userId, {
        fromDate: startOfMonth,
        toDate: endOfMonth,
        workstationId: selectedWorkStation,
      }).then((data) => {
        const booked = data.rents.reduce((acc, item) => {
          if (item?.schedules?.length) {
            const days = item?.schedules?.map((day) => {
              return moment(day.rentDate).format("YYYY-MM-DD");
            });

            return [...acc, ...days];
          }
          return acc;
        }, []);

        seBookings(booked);
      });
    }
  }, [selectedWorkStation]);

  const setDate = (selectedDay) => () => {
    const foundedDay = bookingData?.days?.find(
      (day) => day.scheduleDay === selectedDay.scheduleDay
    );

    if (foundedDay) {
      updateData({
        days: bookingData.days.filter(
          (day) => day.scheduleDay !== selectedDay.scheduleDay
        ),
        workstation: selectedWorkStation,
      });
    } else {
      const days = bookingData?.days || [];
      updateData({
        days: [...days, selectedDay],
        workstation: selectedWorkStation,
      });
    }
  };

  const backToSalon = () => {
    navigate(`/salon_profile/${salonId}`);
  };

  const workstations = useMemo(() => {
    if (salonData?.workStations?.length) {
      const options = salonData.workStations.map((item) => {
        return { value: item.workstationId, label: item.name };
      });

      if (!selectedWorkStation) {
        setSelectedWorkstation(options[0].value);
      }

      return options;
    }

    return [];
  }, [salonData]);

  return (
    <div>
      <AdaptiveText
        xl={{ size: 36, height: 46 }}
        xs={{ size: 28, height: 39 }}
        fontFamily="Greenwich SemBd"
        weight={600}
      >
        Time and venue selection
      </AdaptiveText>
      <SalonInfo>
        <AdaptiveText
          xl={{ size: 26, height: 33 }}
          xs={{ size: 20, height: 20 }}
          fontFamily="Greenwich SemBd"
          weight={600}
        >
          Booking at&nbsp;
          <AdaptiveText
            as="span"
            xl={{ size: 26, height: 33 }}
            xs={{ size: 20, height: 20 }}
            fontFamily="Greenwich SemBd"
            weight={600}
            color={colors.blu4}
          >
            {salonData?.name}
          </AdaptiveText>
        </AdaptiveText>
        <SalonLocation>
          <Location />
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 16, height: 19 }}
            fontFamily="Greenwich"
          >
            {`${salonData?.address?.street} ${salonData?.address?.number}, ${salonData?.address?.postCode} ${salonData?.city}`}
          </AdaptiveText>
        </SalonLocation>
        {salonData?.workingHours?.length ? (
          <SalonOpenes>
            <Clock />
            <DayContainer container>
              <Grid item xs={12} md={6}>
                {salonData?.workingHours.slice(0, 4).map((workingDay) => (
                  <WorkingDays key={workingDay.whId}>
                    <AdaptiveText
                      xl={{ size: 18, height: 21 }}
                      xs={{ size: 16, height: 19 }}
                      fontFamily="Greenwich"
                      weight={700}
                    >
                      {getWeekDayNameById(workingDay.weekDay)}
                    </AdaptiveText>
                    <AdaptiveText
                      xl={{ size: 18, height: 21 }}
                      xs={{ size: 16, height: 19 }}
                      fontFamily="Greenwich"
                    >
                      {workingDay.opened
                        ? `${workingDay.from} - ${workingDay.to}`
                        : "Closed"}
                    </AdaptiveText>
                  </WorkingDays>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                {salonData?.workingHours.slice(4).map((workingDay) => (
                  <WorkingDays key={workingDay.whId}>
                    <AdaptiveText
                      xl={{ size: 18, height: 21 }}
                      xs={{ size: 16, height: 19 }}
                      fontFamily="Greenwich"
                      weight={700}
                    >
                      {getWeekDayNameById(workingDay.weekDay)}
                    </AdaptiveText>
                    <AdaptiveText
                      xl={{ size: 18, height: 21 }}
                      xs={{ size: 16, height: 19 }}
                      fontFamily="Greenwich"
                    >
                      {workingDay.opened
                        ? `${workingDay.from} - ${workingDay.to}`
                        : "Closed"}
                    </AdaptiveText>
                  </WorkingDays>
                ))}
              </Grid>
            </DayContainer>
          </SalonOpenes>
        ) : null}
      </SalonInfo>
      <Divider />
      <AdaptiveText
        xl={{ size: 20, height: 23 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={700}
        color={colors.blu4}
      >
        Workplace type
      </AdaptiveText>
      <Workplace>
        <WorkplaceSelect>
          <SelectContainer>
            <ControlledDropdown
              options={workstations}
              value={selectedWorkStation}
              onChange={(value) => {
                setSelectedWorkstation(value);
              }}
            ></ControlledDropdown>
          </SelectContainer>
          <WorkspaceCoast>
            <AdaptiveText
              xl={{ size: 20, height: 20 }}
              xs={{ size: 18, height: 21 }}
              fontFamily="Greenwich"
              weight={400}
            >
              Full Day rate (excl. VAT)
            </AdaptiveText>
            <AdaptiveText
              xl={{ size: 20, height: 23 }}
              xs={{ size: 18, height: 21 }}
              fontFamily="Greenwich"
              weight={700}
            >
              {`€${
                salonData?.workStations?.find(
                  (workStation) =>
                    workStation.workstationId === selectedWorkStation
                )?.pricePerDay
              }`}
            </AdaptiveText>
          </WorkspaceCoast>
        </WorkplaceSelect>
        <Divider />
        <WorkplaceProducts>
          <AdaptiveText
            xl={{ size: 20, height: 23 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
            weight={700}
          >
            Availability of products
          </AdaptiveText>
          <AdaptiveText
            xl={{ size: 20, height: 20 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
          >
            {
              salonData?.workStations?.find(
                (workStation) =>
                  workStation.workstationId === selectedWorkStation
              )?.availability?.description
            }
          </AdaptiveText>
        </WorkplaceProducts>
      </Workplace>
      <AdaptiveText
        xl={{ size: 20, height: 23 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        weight={700}
        color={colors.blu4}
      >
        Calendar
      </AdaptiveText>
      <CalendarBlock>
        {loading ? (
          <Spinner height="362px" />
        ) : (
          <StyledCalendar
            className="bookingCalendar"
            format="YYYY-MM-DD"
            multiple
            readOnly={true}
            weekStartDayIndex={1}
            numberOfMonths={isDesktop ? 2 : 1}
            weekDays={weekDays}
            minDate={moment().add(1, "days").toDate()}
            maxDate={new Date(endOfMonth)}
            mapDays={({ isSameDate, date, today }) => {
              let className = "";

              if (isSameDate(date, today)) {
                className += "today ";
              }

              const isBooked = bookings.includes(date.toString());

              if (isBooked) {
                //className += "booked ";
                //console.log(date.toString());
              }

              const scheduleDay = schedule?.find(
                (day) => day.scheduleDay === date.toString()
              );
              //console.log(scheduleDay);
              if (
                //!isBooked &&
                scheduleDay &&
                scheduleDay?.availableCount > scheduleDay?.currentCount &&
                moment(date.toString()).isAfter(today.toString(), "day")
              ) {
                return {
                  className: className,
                  children: (
                    <DayButton
                      disableRipple
                      onClick={setDate(scheduleDay)}
                      checked={bookingData?.days?.find(
                        (day) => day.scheduleDay === date.toString()
                      )}
                    >
                      {date.day}
                    </DayButton>
                  ),
                };
              }

              return { className: className };
            }}
          />
        )}
      </CalendarBlock>
      <Controls>
        <Button variant="outlined" onClick={backToSalon}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setTab(2);
          }}
          disabled={!bookingData?.days?.length}
        >
          Next
        </Button>
      </Controls>
    </div>
  );
};

export default Selection;
