import styled from "@emotion/styled";
import { alpha, IconButton } from "@mui/material";
import { Swiper } from "swiper/react";
import { colors, vw } from "../../styles";

interface Props {
  padding?: Record<"xs" | "sm" | "md", number>;
}

const SwiperContainer = styled.div<Props>`
  width: 100%;
  position: relative;
  padding: ${(props) => (props.padding?.md ? `0 ${props.padding.md}px` : 0)};

  & .swiper-pagination-bullets {
    margin-top: 36px;
  }

  & .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid ${colors.blu2};
    background-color: transparent;
    opacity: 1;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${colors.blu2};
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: ${(props) => (props.padding?.sm ? `0 ${props.padding?.sm}px` : 0)};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${(props) =>
      props.padding?.xs || props.padding?.xs === 0
        ? `0 ${props.padding.xs}px`
        : `0 ${props?.padding?.sm || props?.padding?.md || 0}px`};

    & .swiper-pagination-bullets {
      margin-top: ${vw(36)};
    }

    & .swiper-pagination-bullet {
      width: ${vw(12)};
      height: ${vw(12)};
    }
  }
`;

const RightButton = styled(IconButton)`
  z-index: 1;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
  background-color: ${alpha(colors.blu1, 0.4)};

  &.MuiIconButton-sizeLarge {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);

    &.MuiIconButton-sizeLarge {
      width: 60px;
      height: 60px;
      top: calc(50% - 30px);
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(60)};
    height: ${vw(60)};
    top: calc(50% - ${vw(30)});

    &.MuiIconButton-sizeLarge {
      width: ${vw(60)};
      height: ${vw(60)};
      top: calc(50% - ${vw(30)});
    }
  }
`;

const LeftButton = styled(IconButton)`
  z-index: 1;
  width: 60px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  background-color: ${alpha(colors.blu1, 0.4)};
  left: 0;

  &.MuiIconButton-sizeLarge {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);

    &.MuiIconButton-sizeLarge {
      width: 60px;
      height: 60px;
      top: calc(50% - 30px);
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(60)};
    height: ${vw(60)};
    top: calc(50% - ${vw(30)});

    &.MuiIconButton-sizeLarge {
      width: ${vw(60)};
      height: ${vw(60)};
      top: calc(50% - ${vw(30)});
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  margin: 0;
  position: static;

  & .swiper-pagination {
    bottom: -22px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0 ${vw(60)};

    &.swiper-wide {
      margin: 0 ${vw(20)};
    }

    & .swiper-pagination {
      bottom: ${vw(-22)};
    }
  }
`;

export { SwiperContainer, LeftButton, RightButton, StyledSwiper };
