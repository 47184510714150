import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { NavLink } from "react-router-dom";

const TabIndex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: ${colors.white};

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(20)};
    height: ${vw(20)};
    border-radius: ${vw(6)};
  }
`;

const IconWrapper = styled.div`
  & svg {
    path {
      fill: ${colors.accent};
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover:not(.active) {
    opacity: 0.7;
  }

  &.active {
    p {
      color: ${colors.pink4};
      font-weight: bold;
    }

    svg path {
      fill: ${colors.white};
    }

    div {
      background-color: ${colors.pink4};

      p {
        color: ${colors.white};
        font-weight: bold;
      }
    }

    ${(props) => props.theme.breakpoints.up("lg")} {
      &:hover {
        p {
          color: ${colors.pink3};
        }

        div {
          background-color: ${colors.pink3};

          p {
            color: ${colors.white};
          }
        }
      }
    }
  }
`;

export { TabIndex, StyledNavLink, IconWrapper };
