import styled from "@emotion/styled";
import { colors, vw } from "../../../styles";
import { InputLabel, RadioGroup } from "@mui/material";

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  color: ${colors.accent};

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(20)};
    line-height: ${vw(20)};
  }
`;

const StyledInputLabel = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Greenwich, serif;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.accent};

  &.Mui-disabled {
    color: ${colors.grey};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(16)};
    gap: ${vw(10)};
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
`;

export { LabelContainer, StyledInputLabel, StyledRadioGroup };
