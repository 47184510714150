import React from "react";
import { AdaptiveText } from "../../../../components";
import { Grid } from "@mui/material";
import { vw } from "../../../../styles";
import {
  Wrapper,
  Container,
  Divider,
  Amentities,
  Amentity,
  AdditionalInfo,
} from "./salon-place-styles";

const SalonPlace = ({ salonProfile }) => {
  return (
    <Wrapper>
      <Container>
        <AdaptiveText
          xl={{ size: 44, height: 56 }}
          sm={{ size: 30, height: 38 }}
          xs={{ size: 30, height: 38 }}
          uppercase
          fontFamily="Greenwich Med"
          align="center"
        >
          this place offers
        </AdaptiveText>
        <Divider />
        <Amentities>
          <Grid container spacing={{ xs: vw(24), sm: 3 }}>
            {salonProfile?.amenities.map((amenity) => {
              return (
                <Grid item xs={6} lg={12} key={amenity.amenityId}>
                  <Amentity>
                    <img src={amenity.imageUrl} alt="amenity" />
                    <AdaptiveText
                      xl={{ size: 24, height: 28 }}
                      sm={{ size: 18, height: 21 }}
                      xs={{ size: 18, height: 21 }}
                    >
                      {amenity.name}
                    </AdaptiveText>
                  </Amentity>
                </Grid>
              );
            })}
          </Grid>
        </Amentities>
        {salonProfile?.amenitiesDescription && (
          <AdditionalInfo>
            <AdaptiveText
              xl={{ size: 24, height: 28 }}
              xs={{ size: 20, height: 24 }}
              fontFamily="Greenwich"
              weight={700}
            >
              Additional info
            </AdaptiveText>
            <AdaptiveText
              xl={{ size: 24, height: 28 }}
              xs={{ size: 18, height: 21 }}
              fontFamily="Greenwich"
            >
              {salonProfile?.amenitiesDescription}
            </AdaptiveText>
          </AdditionalInfo>
        )}
      </Container>
    </Wrapper>
  );
};

export default SalonPlace;
