import { SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import {
  LeftButton,
  RightButton,
  SwiperContainer,
  StyledSwiper,
} from "./carousel-styles";

function SlideNextButton({
  bigNavigation,
}: Pick<CarouselProps, "bigNavigation">) {
  const swiper = useSwiper();

  return (
    <RightButton
      className="carousel-next"
      onClick={() => swiper.slideNext()}
      size={bigNavigation ? "large" : "medium"}
    >
      <ArrowForwardIcon fontSize={bigNavigation ? "large" : "medium"} />
    </RightButton>
  );
}

function SlidePrevButton({
  bigNavigation,
}: Pick<CarouselProps, "bigNavigation">) {
  const swiper = useSwiper();

  return (
    <LeftButton
      className="carousel-prev"
      onClick={() => swiper.slidePrev()}
      size={bigNavigation ? "large" : "medium"}
    >
      <ArrowBackIcon fontSize={bigNavigation ? "large" : "medium"} />
    </LeftButton>
  );
}

interface CarouselProps {
  showSlides: Record<string, number>;
  slides: React.ReactNode[];
  bigNavigation?: boolean;
  withDots?: boolean;
  autoplay?: boolean;
  padding?: Record<"xs" | "sm" | "md", number>;
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const calcSlide = () => {
    if (props.showSlides.xl && window.innerWidth > 1440) {
      return props.showSlides.xl;
    }

    if (props.showSlides.lg && window.innerWidth > 1000) {
      return props.showSlides.lg;
    }

    if (props.showSlides.md && window.innerWidth > 800) {
      return props.showSlides.md;
    }

    if (props.showSlides.sm && window.innerWidth > 480) {
      return props.showSlides.sm;
    }

    if (props.showSlides.xs) {
      return props.showSlides.xs;
    }

    return 1;
  };

  const [slides, setSlides] = useState(calcSlide());
  const [slide, setSlide] = useState(0);

  const handleResize = () => {
    setSlides(calcSlide());
  };

  window.addEventListener("resize", handleResize);

  return (
    <SwiperContainer padding={props.padding}>
      <StyledSwiper
        className={props.bigNavigation ? "swiper-wide" : ""}
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={
          props.slides?.length < slides ? props.slides?.length : slides
        }
        loop={props.slides?.length > slides}
        autoplay={
          props.autoplay
            ? {
                delay: 5000,
              }
            : false
        }
        pagination={
          props.slides?.length < slides
            ? false
            : props.withDots
            ? { clickable: true }
            : false
        }
        onSlideChange={({ realIndex }) => setSlide(realIndex)}
      >
        {props.slides?.map((slide, idx) => (
          <SwiperSlide
            style={{ display: "flex", justifyContent: "center" }}
            key={idx}
          >
            {slide}
          </SwiperSlide>
        ))}
        {props.slides?.length > slides && (
          <>
            {(props.autoplay || slide !== 0) && (
              <SlidePrevButton bigNavigation={props.bigNavigation} />
            )}
            {(props.autoplay || slide !== props.slides?.length - 1) && (
              <SlideNextButton bigNavigation={props.bigNavigation} />
            )}
          </>
        )}
      </StyledSwiper>
    </SwiperContainer>
  );
};

export default Carousel;
