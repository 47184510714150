import React from "react";

import { Wrapper, Name, Data, Row } from "./workplace-slide-styles";
import { AdaptiveText } from "../../../../components";

const WorkplaceSlide = ({ item }) => {
  return (
    <Wrapper key={item.id}>
      <Name
        xl={{ size: 24, height: 28 }}
        xs={{ size: 24, height: 28 }}
        weight={500}
        fontFamily="Greenwich"
        capitalize
        align="center"
      >
        {item.name}
      </Name>
      <Data>
        <Row>
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 24, height: 28 }}
            fontFamily="Greenwich"
            capitalize
          >
            Number of workstations
          </AdaptiveText>
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 24, height: 28 }}
            fontFamily="Greenwich"
            capitalize
            weight={700}
          >
            {item.count}
          </AdaptiveText>
        </Row>
        <Row>
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 24, height: 28 }}
            fontFamily="Greenwich"
            capitalize
          >
            Full Day rate (excl. VAT)
          </AdaptiveText>
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 24, height: 28 }}
            fontFamily="Greenwich"
            capitalize
            weight={700}
          >
            {`€${item.pricePerDay}`}
          </AdaptiveText>
        </Row>
        <Row>
          <AdaptiveText
              xl={{ size: 14, height: 16 }}
              xs={{ size: 14, height: 16 }}
              fontFamily="Greenwich"
              weight={400}
          >
           {`${item.additionCondition}`}
          </AdaptiveText>
        </Row>
      </Data>
    </Wrapper>
  );
};

export default WorkplaceSlide;
