import { FC } from "react";
import { SlideWrapper, Image, Card, CardInfo, Row } from "./slide-styles";
import LocationIcon from "../../../../../assets/icons/location.png";
import { useNavigate } from "react-router-dom";
import { AdaptiveText } from "../../../../../components";

const Slide: FC<any> = ({ item }) => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate(`/salon_profile/${item.salonId}`);
  };

  return (
    <SlideWrapper>
      <Card onClick={redirect}>
        <Image src={item?.profilePhoto.imageUrl} alt="Master photo" />
        <CardInfo>
          <div>
            <AdaptiveText
              xl={{ size: 26, height: 28 }}
              xs={{ size: 24, height: 33 }}
              weight={600}
              fontFamily="Greenwich"
            >
              {item.name}
            </AdaptiveText>

            <Row>
              <img src={LocationIcon} alt="location" />
              <AdaptiveText
                xl={{ size: 18, height: 21 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
                weight={300}
                align="left"
              >
                {`${item.address.street} ${item.address.number}, ${item.address.postCode} ${item.city}`}
              </AdaptiveText>
            </Row>
            <Row>
              <img src={item.vibe.imageUrl} alt="location" />
              <AdaptiveText
                xl={{ size: 18, height: 21 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
                weight={300}
                align="left"
              >
                {item.vibe.name}
              </AdaptiveText>
            </Row>
            <AdaptiveText
              xl={{ size: 18, height: 21 }}
              xs={{ size: 18, height: 21 }}
              fontFamily="Greenwich"
              weight={300}
              align="left"
            >
              {item.description?.length > 80
                ? `${item.description.slice(0, 80)}...`
                : item.description}
            </AdaptiveText>
          </div>

          <AdaptiveText
            xl={{ size: 16, height: 19 }}
            xs={{ size: 16, height: 19 }}
            fontFamily="Greenwich"
            weight={700}
            align="right"
            decoration="underline"
          >
            See More
          </AdaptiveText>
        </CardInfo>
      </Card>
    </SlideWrapper>
  );
};

export default Slide;
