import styled from "@emotion/styled";
import DatePicker from "react-datepicker";
import { FormControl, IconButton } from "@mui/material";
import { colors, vw } from "../../styles";
import { ReactComponent as Expand } from "../../assets/icons/expand_icon.svg";
import arrow from "../../assets/icons/arrow_list_down.svg";
import calendar from "../../assets/icons/calendar.svg";

const StyledFormControl = styled(FormControl)`
  & .react-datepicker {
    border: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  & .react-datepicker-popper {
    padding: 0;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__month-container {
    width: 478px;
    border: none;
    padding: 9px 0 0;

    .react-datepicker__header {
      padding: 0;
      padding-bottom: 8px;
      border: none;
      background-color: ${colors.white};
    }

    & .react-datepicker__month {
      margin: 0;
      padding-bottom: 16px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: calc(100vw - ${vw(40)});
      padding: ${vw(9)} 0 0;

      .react-datepicker__header {
        padding-bottom: ${vw(8)};
      }

      & .react-datepicker__month {
        padding-bottom: ${vw(16)};
      }
    }
  }

  & .react-datepicker__day {
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    margin: 3px 6px;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    font-family: Greenwich, serif;
    color: ${colors.grey3};
    font-weight: 700;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(38)};
      height: ${vw(38)};
      margin: ${vw(3)} ${vw(6)};
      font-size: ${vw(14)};
      line-height: ${vw(38)};
    }
  }

  & .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  & .react-datepicker__day-name {
    width: 38px;
    height: 38px;
    margin: 0 6px;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    font-family: Greenwich, serif;
    color: ${colors.blu2};
    font-weight: 700;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(38)};
      height: ${vw(38)};
      margin: ${vw(3)} ${vw(6)};
      font-size: ${vw(14)};
      line-height: ${vw(38)};
    }
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border: 1px solid ${colors.pink4};
    background-color: ${colors.pink4};
    border-radius: 8px;
    color: ${colors.white};

    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: ${vw(8)};
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 16px;
  border-radius: 8px;
  font-family: "Greenwich", serif;
  font-size: 20px;
  line-height: 20px;
  height: 60px;
  color: ${colors.accent};
  background-color: ${colors.white};
  border: none;
  box-shadow: none;
  outline: none;

  &::placeholder {
    color: ${colors.grey};
  }

  &:focus-visible {
    border: 1px solid ${colors.blu4};
    padding: 19px 15px;
  }

  &.Mui-error {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid ${colors.red};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(20)} ${vw(16)};
    border-radius: ${vw(8)};
    font-size: ${vw(20)};
    line-height: ${vw(20)};
    height: ${vw(60)};

    &:focus-visible {
      border: ${vw(1)} solid ${colors.blu4};
      padding: ${vw(19)} ${vw(15)};
    }
  }
`;

const ExpandIcon = styled(Expand)`
  width: 28px;
  height: 28px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(20)};
    height: ${vw(20)};
  }
`;

const PrevButton = styled(IconButton)`
  transform: rotate(180deg);
`;

const CalendarSelect = styled.select`
  position: relative;
  width: 167px;
  padding: 12px 16px;
  border-radius: 8px;
  font-family: Greenwich, serif;
  font-size: 20px;
  line-height: 20px;
  height: 50px;
  color: ${colors.accent};
  background-color: ${colors.blu1};
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus-visible {
    border: none;
    outline: none;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    border-radius: ${vw(8)};
    font-size: ${vw(20)};
    line-height: ${vw(20)};
    height: ${vw(50)};
    width: ${vw(164)};
    padding: ${vw(15)} ${vw(16)};
    // background-position-x: calc(100% - ${vw(10)});
    // background-position-y: ${vw(8)};
  }
`;

const SelectWrapper = styled.div`
  width: 167px;
  height: 50px;
  position: relative;

  &:after {
    pointer-events: none;
    z-index: 1;
    content: "";
    position: absolute;
    right: 18px;
    top: 13px;
    width: 24px;
    height: 24px;
    background: url(${arrow}) no-repeat;
    background-size: 100%;

    ${(props) => props.theme.breakpoints.down("sm")} {
      right: ${vw(18)};
      top: ${vw(13)};
      width: ${vw(24)};
      height: ${vw(24)};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(164)};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  button {
    padding: 0;

    svg {
      width: 28px;
      height: 28px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(36)};
      height: ${vw(50)};

      svg {
        width: ${vw(28)};
        height: ${vw(28)};
      }
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;

  &:after {
    pointer-events: none;
    z-index: 0;
    content: "";
    position: absolute;
    right: 18px;
    top: 18px;
    width: 24px;
    height: 24px;
    background: url(${calendar}) no-repeat;
    background-size: 100%;

    ${(props) => props.theme.breakpoints.down("sm")} {
      right: ${vw(18)};
      top: ${vw(18)};
      width: ${vw(24)};
      height: ${vw(24)};
    }
  }
`;

export {
  StyledFormControl,
  StyledDatePicker,
  ExpandIcon,
  PrevButton,
  CalendarSelect,
  Row,
  SelectWrapper,
  InputWrapper,
};
