import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

export const SuperAdminNavDesktopList = () => {
  const { auth } = useSelector((state: AppState) => state);
  const location = useLocation();

  const setUrlToLocalStorage = () => {
    localStorage.setItem("memorizedProfileUrl", location.pathname);
  };

  return (
    <>
      {/* Do not display in create master profile mode */}
      {auth?.name && (
        <Link to={`profile/${auth.userId}`}>
          <NavDropdown.Item as="button">My profile</NavDropdown.Item>
        </Link>
      )}
      <Link
        to={`profile_edit/professional_profile/${auth.userId}`}
        state={{ from: location.pathname }}
        onClick={setUrlToLocalStorage}
      >
        <NavDropdown.Item as="button">Edit profile</NavDropdown.Item>
      </Link>
      <Link to={`salon_catalog`}>
        <NavDropdown.Item as="button">View salons</NavDropdown.Item>
      </Link>
      <Link to={`master_list`}>
        <NavDropdown.Item as="button" style={{ backgroundColor: "#fff" }}>
          Artists list
        </NavDropdown.Item>
      </Link>
      <Link to={`salon_list`}>
        <NavDropdown.Item as="button" style={{ backgroundColor: "#fff" }}>
          Salons list
        </NavDropdown.Item>
      </Link>
      <Link to={`bookings_list`}>
        <NavDropdown.Item as="button" style={{ backgroundColor: "#fff" }}>
          Bookings
        </NavDropdown.Item>
      </Link>
      <Link to={`accounting/refunds`}>
        <NavDropdown.Item as="button" style={{ backgroundColor: "#fff" }}>
          Payouts
        </NavDropdown.Item>
      </Link>
      <Link to={`reports`}>
        <NavDropdown.Item as="button" style={{ backgroundColor: "#fff" }}>
          Reports
        </NavDropdown.Item>
      </Link>
    </>
  );
};
