import React from "react";
import {Helmet} from "react-helmet";

import {
  FeesBlock,
  HeaderBlock,
  GalleryBlock,
  WhyBlock,
  StepsBlock,
  StoriesBlock,
  QuestionsBlock,
  FinalBlock,
} from "./components";

import { Wrapper } from "./for-salons-styles";
import WithDialog from "../../contexts/dialog/WithDialog";
import { DialogPropTypes } from "@contexts/dialog/types";
import { AlreadyLogged } from "../../modals";

const ForSalons: React.FC<DialogPropTypes> = ({ openDialog, closeDialog }) => {
  const showPopup = () => {
    openDialog({
      component: <AlreadyLogged close={closeDialog} />,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  return (
      <>
          <Helmet>
            <title>Open your salon to hosting on your terms | Vervvey</title>
            <meta name="description" content="Open your salon to hosting on your terms. We’ll take care of the rest. Join the biggest beauty coworking community of salon hosts and freelance beauty artists." />
          </Helmet>
          <Wrapper>
            <HeaderBlock showPopup={showPopup} />
            <WhyBlock showPopup={showPopup} />
            <FeesBlock />
            <StepsBlock showPopup={showPopup} />
            <GalleryBlock />
            <StoriesBlock showPopup={showPopup} />
            <QuestionsBlock />
            <FinalBlock showPopup={showPopup} />
          </Wrapper>
      </>
  );
};

export default WithDialog(ForSalons);
