import styled from "@emotion/styled";
import { vw } from "../../../styles";
import { Tooltip } from "@mui/material";

const StyledTooltip = styled(Tooltip)`
  justify-self: flex-start;
  z-index: 99;
`;

const IconContainer = styled.div`
  svg {
    margin-top: -3px;
    width: 20px;
    height: 20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      margin-top: ${vw(-3)};
      width: ${vw(20)};
      height: ${vw(20)};
    }
  }
`;

export { IconContainer, StyledTooltip };
