import React, { FC, useRef, useState } from "react";
import { FormControl, MenuItem } from "@mui/material";
import { colors } from "../../../styles";
import { get } from "lodash-es";
import {
  StyledSelect,
  LabelContainer,
  StyledInputLabel,
  ErrorMessage,
} from "./dropdown-styles";
import { LabelTooltip } from "../label-tooltip";
import { useController, useFormContext } from "react-hook-form";

const DropDown: FC<any> = ({
  label,
  placeholder,
  options,
  defaultValue,
  name,
  required,
  tooltip,
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const error = get(errors, fieldName)?.message;

  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <FormControl fullWidth>
      {label && (
        <LabelContainer
          onClick={() => {
            inputRef.current?.click();
            setOpen(true);
          }}
        >
          <StyledInputLabel error={error}>
            {label}
            {tooltip && <LabelTooltip tooltip={tooltip} />}
          </StyledInputLabel>
          {required && "*"}
        </LabelContainer>
      )}
      <StyledSelect
        ref={inputRef}
        value={options?.length && value ? value : ""}
        displayEmpty
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        renderValue={
          value
            ? null
            : () => <p style={{ color: colors.grey }}>{placeholder}</p>
        }
        onChange={onChange}
      >
        {options?.map((option) => {
          return (
            <MenuItem
              value={option.value}
              disableTouchRipple
              disableRipple
              key={option.value}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </StyledSelect>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormControl>
  );
};
export default DropDown;
