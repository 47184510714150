import { AdaptiveText } from "../../components";
import React from "react";
import { StyledButton, Wrapper } from "./already-logged-styles";

const AlreadyLogged = ({ close }) => {
  return (
    <Wrapper flexDirection="column">
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        uppercase
        variant="body1"
      >
        You are logged in already
      </AdaptiveText>
      <AdaptiveText
        variant="body2"
        xl={{ size: 16, height: 18 }}
        xs={{ size: 12, height: 12 }}
      >
        Please log out from currently used account to set up a new one
      </AdaptiveText>
      <StyledButton
        variant="contained"
        disableTouchRipple
        disableFocusRipple
        onClick={close}
      >
        OK
      </StyledButton>
    </Wrapper>
  );
};

export default AlreadyLogged;
