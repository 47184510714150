import { Slide, OrgName } from "./cetSlide-styles";

export default function CertSlide({ slide }) {
  return (
    <Slide key={slide.certificateId} className="vv_card">
      <img src={slide.imageUrl} alt="vv_card_photo" />
      <OrgName>{slide.organizationName}</OrgName>
    </Slide>
  );
}
