import React, { FC } from "react";
import { FormControl } from "@mui/material";
import { get } from "lodash-es";
import {
  LabelContainer,
  StyledInputLabel,
  StyledTextField,
  ErrorMessage,
  Label,
  LimitCounter,
} from "./textarea-styles";
import { LabelTooltip } from "../label-tooltip";
import { useController, useFormContext } from "react-hook-form";
import classes from "../../../components/customTextarea/customTextarea.module.scss";

const Textarea: FC<any> = ({
  label,
  placeholder,
  required,
  defaultValue,
  name,
  tooltip,
  limit = 440,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const error = get(errors, fieldName)?.message;

  return (
    <FormControl fullWidth>
      {label && (
        <LabelContainer>
          <StyledInputLabel>
            <Label shrink htmlFor={name} error={!!error}>
              {label}
            </Label>
            {tooltip && <LabelTooltip tooltip={tooltip} />}
          </StyledInputLabel>
          {required && "*"}
        </LabelContainer>
      )}
      <StyledTextField
        id={name}
        value={value}
        placeholder={placeholder}
        inputProps={{ maxLength: limit }}
        {...rest}
        onChange={onChange}
      />
      <LimitCounter>
        {value?.length || 0} / {limit}
      </LimitCounter>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormControl>
  );
};
export default Textarea;
