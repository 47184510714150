import React, { FC, Suspense, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./layout.scss";

export const DefaultLayout: FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes("salon_catalog")) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className="default_layout">
      <Header />

      <main className="main">
        <Suspense fallback={<div>Loading... </div>}>
          <Outlet />
        </Suspense>
      </main>

      <Footer />
    </div>
  );
};
