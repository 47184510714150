import React, { useEffect, useState } from "react";
import {
  getAllFrontOfHouseOptions,
  getAllHowToFoundOptions,
} from "../../../../../../services/salonsApi";

const getMappedFrontOfHouseOptions = (options) => {
  const _options = options.map((option) => {
    return { fohId: option.fohId, value: option.name, label: option.name };
  });

  return _options;
};

const getMappedHowToFoundOptions = (options) => {
  const _options = options.map((option) => {
    return {
      howFoundId: option.howFoundId,
      value: option.name,
      label: option.name,
    };
  });

  return _options;
};

export const useFetchSalonInformationOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [frontOfHouseOptions, setFrontOfHouseOptions] = useState([]);
  const [howToFoundOptions, setHowToFoundOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        setIsLoading(true);

        const frontOfHouse = await getAllFrontOfHouseOptions();

        setFrontOfHouseOptions(getMappedFrontOfHouseOptions(frontOfHouse));

        const howToFoundOptions = await getAllHowToFoundOptions();

        setHowToFoundOptions(getMappedHowToFoundOptions(howToFoundOptions));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchDataOptions();
  }, []);

  return { isLoading, frontOfHouseOptions, howToFoundOptions };
};
