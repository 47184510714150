import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";

interface StyledProgressItemProps {
  $active: boolean;
  $disabled: boolean;
  $passed: boolean;
}

const TabIndex = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: ${colors.white};

  ${(props) => (props.theme as any).breakpoints.down("sm")} {
    width: ${vw(20)};
    height: ${vw(20)};
    border-radius: ${vw(6)};
  }
`;

const StyledProgressItem = styled.div<StyledProgressItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.$active &&
    `
    p {
      color: ${colors.pink4};
    }

    div {
      background-color: ${colors.pink4};

      p {
        color: ${colors.white};
      }
    }
  `}

  ${(props) =>
    props.$disabled &&
    `
    p {
      color: ${colors.grey};
      font-weight: 400;
    }

    div {

      p {
        color: ${colors.grey};
        font-weight: 400;
      }
    }
  `}

  ${(props) =>
    props.$passed &&
    `
    p {
      color: ${colors.accent};
      font-weight: 400;
    }

    div {
      background-color: ${colors.pink4};
      
      svg {
          width: ${vw(16)};
        path {
          fill: ${colors.white};
        }
      }

      p {
        color: ${colors.accent};
        font-weight: 400;
      }
    }
  `}
`;

export { TabIndex, StyledProgressItem };
