import { FormControl, FormControlLabel, Radio } from "@mui/material";
import {
  LabelContainer,
  StyledInputLabel,
  StyledRadioGroup,
} from "./radiogroup-styles";
import { LabelTooltip } from "../label-tooltip";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

const Radiogroup: FC<any> = ({
  label,
  tooltip,
  required,
  options,
  name,
  defaultValue,
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  return (
    <FormControl fullWidth>
      {label && (
        <LabelContainer>
          <StyledInputLabel>
            {label}
            {tooltip && <LabelTooltip tooltip={tooltip} />}
          </StyledInputLabel>
          {required && "*"}
        </LabelContainer>
      )}
      <StyledRadioGroup row value={value} onChange={onChange}>
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            disabled={!!option.disabled}
            label={option.label}
          />
        ))}
      </StyledRadioGroup>
    </FormControl>
  );
};

export default Radiogroup;
