import styled from "@emotion/styled";
import { TablePagination, Table } from "@mui/material";
import { vw } from "../../../styles";

const StyledMuiTable = styled(Table)`
  background-color: transparent;
`;

const StyledPagination = styled(TablePagination)`
  & .MuiTablePagination-actions {
    ${(props) => props.theme.breakpoints.down("sm")} {
      margin-left: ${vw(20)} !important;

      & button {
        width: ${vw(40)};
        height: ${vw(40)};

        svg {
          width: ${vw(24)};
          height: ${vw(24)};
        }
      }
    }
  }

  & .MuiTablePagination-selectLabel {
    display: none;
  }

  & .MuiInputBase-root {
    display: none;
  }

  & .MuiToolbar-root {
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiTablePagination-displayedRows {
      margin: 0;
      font-family: Greenwich, serif;
      font-size: 16px;
      line-height: 19px;

      ${(props) => props.theme.breakpoints.down("sm")} {
        font-size: ${vw(16)};
        line-height: ${vw(19)};
      }
    }
  }
`;

export { StyledMuiTable, StyledPagination };
