export const getCategories = (masterProfileFormData) => {
  let categories = {};

  if (masterProfileFormData?.starSignsSelect?.value) {
    categories = {
      ...categories,
      STAR_SIGN: {
        lang: "EN",
        bioId: masterProfileFormData.starSignsSelect.value,
        name: masterProfileFormData.starSignsSelect.label,
      },
    };
  }

  if (masterProfileFormData?.maritalSelect?.value) {
    categories = {
      ...categories,
      MARITAL_SITUATION: {
        lang: "EN",
        bioId: masterProfileFormData.maritalSelect.value,
        name: masterProfileFormData.maritalSelect.label,
      },
    };
  }

  if (masterProfileFormData?.haveKidsSelect?.value) {
    categories = {
      ...categories,
      KIDS: {
        lang: "EN",
        bioId: masterProfileFormData.haveKidsSelect.value,
        name: masterProfileFormData.haveKidsSelect.label,
      },
    };
  }

  if (masterProfileFormData?.educationSelect?.value) {
    categories = {
      ...categories,
      EDUCATION: {
        lang: "EN",
        bioId: masterProfileFormData.educationSelect.value,
        name: masterProfileFormData.educationSelect.label,
      },
    };
  }

  if (masterProfileFormData?.smokeSelect?.value) {
    categories = {
      ...categories,
      SMOKE: {
        lang: "EN",
        bioId: masterProfileFormData.smokeSelect.value,
        name: masterProfileFormData.smokeSelect.label,
      },
    };
  }

  if (masterProfileFormData?.smokeSelect?.value) {
    categories = {
      ...categories,
      SMOKE: {
        lang: "EN",
        bioId: masterProfileFormData.smokeSelect.value,
        name: masterProfileFormData.smokeSelect.label,
      },
    };
  }

  if (masterProfileFormData?.religionSelect?.value) {
    categories = {
      ...categories,
      RELIGION: {
        lang: "EN",
        bioId: masterProfileFormData.religionSelect.value,
        name: masterProfileFormData.religionSelect.label,
      },
    };
  }

  if (masterProfileFormData?.covidStatusSelect?.value) {
    categories = {
      ...categories,
      COVID_STATUS: {
        lang: "EN",
        bioId: masterProfileFormData.covidStatusSelect.value,
        name: masterProfileFormData.covidStatusSelect.label,
      },
    };
  }

  if (masterProfileFormData?.descriptionSelect?.value) {
    categories = {
      ...categories,
      DESCRIBES_BEST: {
        lang: "EN",
        bioId: masterProfileFormData.descriptionSelect.value,
        name: masterProfileFormData.descriptionSelect.label,
      },
    };
  }

  if (masterProfileFormData?.describeYourselfSelect?.value) {
    categories = {
      ...categories,
      DESCRIBE_YOURSELF: {
        lang: "EN",
        bioId: masterProfileFormData.describeYourselfSelect.value,
        name: masterProfileFormData.describeYourselfSelect.label,
      },
    };
  }

  return categories;
};
