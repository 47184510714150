import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

const StyledExpandButton = styled(IconButton)`
  svg {
    width: 18px;
    height: 18px;
  }
`;

export { StyledExpandButton };
