import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  getAllServices,
  getMasterProfileData,
} from "../../../services/masterApi";
import getFormDataFromStorage from "./Stepper/services/getFormDataFromStorage";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

export default function useFetchServicesData() {
  const { auth } = useSelector((state: AppState) => state);
  const { setMasterProfileFormData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useParams();

  const mappedServices = (servicesOptions) => {
    let _services = servicesOptions.map((service) => {
      const _subServices = service.subServices.map((subService) => {
        return {
          serviceId: subService.serviceId,
          subServiceId: subService.subServiceId,
          name: subService.name,
          lang: "EN",
          value: false,
        };
      });

      return {
        serviceId: service.serviceId,
        value: service.serviceId,
        label: service.name,
        subServices: _subServices,
        lang: "EN",
      };
    });

    return _services;
  };

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        setIsLoading(true);

        // TODO: refactor to simplify

        const servicesOptions = await getAllServices();

        let _services = mappedServices(servicesOptions);

        // create mode
        if (!auth?.name) {
          if (!getFormDataFromStorage().services?.length) {
            setMasterProfileFormData((prevState) => {
              return {
                ...prevState,
                services: _services,
              };
            });
          }
        }

        // update mode
        if (userId) {
          const masterResult = await getMasterProfileData(userId);

          const newServices = _services.map((service) => {
            const activeService = masterResult.services?.find(
              (activeService) => {
                return service.serviceId === activeService.serviceId;
              }
            );

            if (activeService) {
              const _subServices = service.subServices.map((subService) => {
                const isSubServiceChecked = activeService.subServices.some(
                  (activeSubService) => {
                    return subService.name === activeSubService.name;
                  }
                );

                return {
                  ...subService,
                  value: isSubServiceChecked,
                };
              });

              const newService = { ...service, subServices: _subServices };

              return newService;
            }

            return service;
          });

          setMasterProfileFormData((prevState) => {
            return {
              ...prevState,
              services: newServices,
            };
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchServicesData();
  }, []);
}
