import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Divider from "../../components/divider/divider";
import logo from "../../assets/icons/Logo_Vervvey2.svg";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import profileDefaultImage from "../../assets/images/photo_blank.webp";
import "./profile.scss";
import Flags from "country-flag-icons/react/3x2";
import { ReactComponent as Allow } from "../../assets/icons/allow.svg";
import { ReactComponent as Ban } from "../../assets/icons/ban.svg";
import React, { useCallback, useEffect, useState } from "react";
import {
  getCoworkingRelation,
  getDictionaryLanguageData,
  getMasterProfileData,
  updateCoworkingRelation,
} from "@services/masterApi";
import CustomSpinner from "../../components/spinner/customSpinner";
import MyWorkSlider from "./components/myWorkSlider/myWorkSlider";
import phoneIcon from "../../assets/icons/phoneIcon.svg";
import ContactMeModal from "./components/ContactMeModal/ContactMeModal";
import { Grid } from "@mui/material";
import CustomSwiper from "../../components/CustomSwiper/CustomSwiper";
import CertSlide from "./components/certSlider/certSlider";
import {Helmet} from "react-helmet";

import {
  Block,
  ButtonContainer,
  CoworkBtn,
  CoworkingBlock,
  CoworkingWrapper,
  RequestLabel,
} from "./profile-styles";
import { AdaptiveText } from "@components";
import { ROLES, STATUSES } from "../../constants/common.consts";
import { colors } from "../../styles";
import WithDialog from "../../contexts/dialog/WithDialog";
import { DeclineCoworking, Login } from "../../modals";
import AcceptCoworking from "../../modals/accept-coworking/accept-coworking";
import { useSelector } from "react-redux";
import { AppState } from "@redux/reducers/rootReducer";

const experienceOptions = {
  "1": "0-1 year",
  "2": "1-2 years",
  "5": "3-6 years",
  "9": "6-10 years",
  "10": "10+ years",
};

const getSubServices = (services) => {
  return services
    .map((service) => {
      return service.subServices;
    })
    .flat(1);
};

export function FlagIcon({ flagNationCode }: { flagNationCode: string }) {
  const Flag = Flags[flagNationCode];

  return (
    <>
      <Flag className="flag_icon" />
    </>
  );
}

export const getMappedCountryCodesByLanguage = (languages, dictionary) => {
  const getCountryByCode = (language) => {
    return dictionary?.find((item) => item.code === language);
  };

  return languages.map((language) => getCountryByCode(language));
};

function Profile({ openDialog, closeDialog }) {
  const {
    auth: { salonId, role, userId: authUserId },
  } = useSelector((state: AppState) => state);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [masterProfileData, setMasterProfileData] = useState(null);
  const [relation, setRelation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [countriesCodes, setCountriesCodes] = useState([]);
  const { userId } = useParams();
  const [searchParams] = useSearchParams();

  const coworkingRef = useCallback((node) => {
    if (node !== null) {
      const viewportOffset = node.getBoundingClientRect();

      window.onscroll = function () {
        stick();
      };

      const stick = () => {
        if (window.pageYOffset > top) {
          node.classList.add("sticky");
        } else {
          node.classList.remove("sticky");
        }
      };

      const top = viewportOffset.top;

      return () => {
        window.removeEventListener("scroll", stick);
      };
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);

      getMasterProfileData(userId)
        .then((data) => {
          setMasterProfileData(data);
          if (
            data.user.status === STATUSES.ACTIVE ||
            user?.role === ROLES.SUPER_ADMIN ||
            data.user.userId === authUserId
          ) {
            getDictionaryLanguageData().then((res) => {
              const _countriesCode = getMappedCountryCodesByLanguage(
                data?.user?.languages,
                res
              );
              setCountriesCodes(_countriesCode);
              setIsLoading(false);
            });
          } else {
            navigate("/404");
          }
        })
        .catch(() => {
          navigate("/");
          setIsLoading(false);
        });
    }, 100);
  }, [userId]);

  useEffect(() => {
    const fetchRelationData = async () => {
      if (role === ROLES.SALON_ADMIN) {
        try {
          const responseRelation = await getCoworkingRelation(salonId, userId);

          setRelation(responseRelation);
        } catch (error) {
          setError(error);
        }
      }
    };

    if (searchParams.get("coworkingRequest") === "" && masterProfileData) {
      if (!authUserId) {
        openDialog({
          component: <Login close={closeDialog} />,
          mw: 598,
          cancelCallback: () => {
            closeDialog();
          },
        });
      } else {
        fetchRelationData();
      }
    }
  }, [masterProfileData, authUserId]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <CustomSpinner />;
  }

  const acceptRequest = async () => {
    try {
      const responseRelation = await updateCoworkingRelation(
        relation.id,
        "accepted"
      );

      setRelation(responseRelation);
      closeDialog();
    } catch (error) {
      setError(error);
    }
  };

  const declineRequest = async () => {
    try {
      const responseRelation = await updateCoworkingRelation(
        relation.id,
        "declined"
      );

      closeDialog();
      setRelation(responseRelation);
    } catch (error) {
      setError(error);
    }
  };

  const showAcceptPopup = async () => {
    openDialog({
      component: (
        <AcceptCoworking close={closeDialog} okCallback={acceptRequest} />
      ),
      mw: 716,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  const showDeclinePopup = async () => {
    openDialog({
      component: (
        <DeclineCoworking close={closeDialog} okCallback={declineRequest} />
      ),
      mw: 716,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  // let servicesString = masterProfileData?.services.reduce((result, item) => {
  //   return `${result}${item.name}, `
  // }, "")

  let servicesString = masterProfileData?.services.map(({name}) => `${name}`).join(', ');
  servicesString = servicesString + ' | ' + masterProfileData?.services[0]?.subServices.map(({name}) => `${name}`).join(', ');

  return (
      <>
      <Helmet>
        <title>{masterProfileData?.master?.nickname} - {servicesString} | Beauty Artist in {masterProfileData?.master?.city}</title>
        <meta name="description" content={masterProfileData?.master?.resume} />
      </Helmet>
    <div className="vv_profile_page">
      <div
        className={`background${relation?.state === "await" ? " tall" : ""}`}
      ></div>
      {relation?.state === "await" && (
        <Block>
          <CoworkingWrapper ref={coworkingRef}>
            <CoworkingBlock className="coworking">
              <AdaptiveText
                xl={{ size: 24, height: 28 }}
                xs={{ size: 20, height: 20 }}
                weight={500}
                fontFamily="Greenwich"
              >
                This artist requests coworking
              </AdaptiveText>
              <ButtonContainer>
                <CoworkBtn
                  variant="outlined"
                  disableTouchRipple
                  disableFocusRipple
                  onClick={showDeclinePopup}
                >
                  Decline request
                </CoworkBtn>
                <CoworkBtn
                  variant="contained"
                  disableTouchRipple
                  disableFocusRipple
                  onClick={showAcceptPopup}
                >
                  Accept request
                </CoworkBtn>
              </ButtonContainer>
            </CoworkingBlock>
          </CoworkingWrapper>
        </Block>
      )}
      <div className="user_header_info_block">
        <div className="profile_avatar_wrapper">
          <div className="location">
            <Location />
            {masterProfileData?.master?.city}
          </div>

          <div className="profile_name">
            {masterProfileData?.master?.nickname}
            {relation?.state === "accepted" && (
              <RequestLabel>
                <Allow />
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 20 }}
                  color={colors.green}
                  fontFamily="Greenwich SemBd"
                  weight={600}
                >
                  Request
                  <br />
                  accepted
                </AdaptiveText>
              </RequestLabel>
            )}
            {relation?.state === "declined" && (
              <RequestLabel>
                <Ban />
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 20 }}
                  color={colors.red}
                  fontFamily="Greenwich SemBd"
                  weight={600}
                >
                  Request
                  <br />
                  declined
                </AdaptiveText>
              </RequestLabel>
            )}
          </div>

          <img
            className="profile_avatar_image"
            src={
              masterProfileData?.profilePhoto?.imageUrl || profileDefaultImage
            }
            alt="avatar"
          />
          {/* TODO: rating out of scope */}
          {/* <div className="rating_wrapper">
            <div className="rating">
              <Rating
                name="read-only"
                value={parseFloat(data.rating?.toFixed(1))}
                precision={0.5}
                readOnly
                icon={<img src={fullStarIcon} alt="fullStarIcon" />}
                emptyIcon={<img src={emptyStarIcon} alt="emptyStarIcon" />}
              />
              <span className="count">{data.rating?.toFixed(1)}</span>
            </div>
            <div className="review">
              {masterProfileData?.user?.reviews} reviews
            </div>
          </div>
          <a className="feedback">All reviews & feedback</a> */}

          {role !== ROLES.SALON_ADMIN && (
            <>
              {masterProfileData.user.publicProfile && (
                <div className="public_contact_wrapper">
                  <ContactMeModal masterProfileData={masterProfileData} />
                </div>
              )}

              {masterProfileData.user.publicPhone && (
                <div className="public_phone_wrapper">
                  <img src={phoneIcon} alt="phone" />
                  <a className="number">{`+${masterProfileData.user.publicPhone}`}</a>
                </div>
              )}
            </>
          )}

          <div className="verified_text">
            Verified by <img src={logo} alt="logo" /> VERVVEY
          </div>

          <div className="vibe_text">
            My vibe{" "}
            <img src={masterProfileData?.master?.vibe?.imageUrl} alt="icon" />{" "}
            {masterProfileData?.master?.vibe?.description}
          </div>
        </div>
        <div className="profile_info_wrapper">
          <div className="header_info_wrapper">
            <div className="location">
              <Location />
              {masterProfileData?.master?.city}
            </div>
          </div>

          <div className="profile_name">
            {masterProfileData?.master?.nickname}
            {relation?.state === "accepted" && (
              <RequestLabel>
                <Allow />
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 20 }}
                  color={colors.green}
                  fontFamily="Greenwich SemBd"
                  weight={600}
                >
                  Request accepted
                </AdaptiveText>
              </RequestLabel>
            )}
            {relation?.state === "declined" && (
              <RequestLabel>
                <Ban />
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 20 }}
                  color={colors.red}
                  fontFamily="Greenwich SemBd"
                  weight={600}
                >
                  Request declined
                </AdaptiveText>
              </RequestLabel>
            )}
          </div>

          <div className="skills_wrapper">
            <div>
              Experience{" "}
              {experienceOptions[masterProfileData?.master?.experience]}
            </div>
            <div className="languages">
              I speak{" "}
              {countriesCodes?.map(({ countryCode }) => {
                return (
                  <FlagIcon key={countryCode} flagNationCode={countryCode} />
                );
              })}
            </div>
          </div>
          <div className="profile_about_description">
            {masterProfileData?.master?.resume}
          </div>
          <Divider />
          <div className="services_list">
            {masterProfileData?.services.map((item) => {
              return (
                <div key={item.serviceId} className="card_wrapper">
                  <div>
                    <img src={item.imageUrl} alt="icon" className="svg_card" />
                  </div>
                  <div className="label">{item.name}</div>
                </div>
              );
            })}
          </div>
          <div className="bio_lists">
            {getSubServices(masterProfileData?.services).map((item) => {
              return (
                <div key={item.subServiceId} className="bio_item">
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="my_work_block">
        <div className="title">My work</div>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <CustomSwiper
              bigNavigation
              showSlides={{ xs: 1, md: 2, lg: 3 }}
              slides={masterProfileData?.works?.map((item) => (
                <MyWorkSlider slide={item} key={item.id} />
              ))}
            />
          </Grid>
        </Grid>
      </div>
      <div className="my_bio_block">
        <div className="title">My bio</div>
        {masterProfileData.user?.countryOfBirth && (
          <div className="text">
            I was born in{" "}
            <FlagIcon flagNationCode={masterProfileData.user.countryOfBirth} />
          </div>
        )}
        <Divider />
        <div className="bio_lists">
          {masterProfileData?.bios
            ?.filter((item) => {
              return !(
                item.category === "KIDS" ||
                item.category === "COVID_STATUS" ||
                item.category === "RELIGION"
              );
            })
            .map((item) => {
              return (
                <div key={item.bioId} className="bio_item">
                  {item.name}
                </div>
              );
            })}
        </div>
      </div>
      {/* Block */}
      {masterProfileData.interests?.length ? (
        <div className="my_interests_block">
          <div className="my_interests_wrapper">
            <div className="title">My interests</div>
            <Divider />
            <div className="my_interests_lists">
              {masterProfileData.interests.map((item) => {
                return (
                  <div key={item.imageId} className="my_interests_item">
                    <img src={item.imageUrl} alt="icon" />
                    {item.label || "interest"}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {/* Block */}
      {masterProfileData.certificates?.length ? (
        <div className="my_certificates_block">
          <div className="title">Trained with</div>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <CustomSwiper
                bigNavigation
                showSlides={{ xs: 1, md: 2, lg: 3, xl: 4 }}
                slides={masterProfileData.certificates?.map((item) => (
                  <CertSlide slide={item} key={item.id} />
                ))}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
      {role !== ROLES.SALON_ADMIN && (
        <>
          {masterProfileData.user.publicProfile && (
            <div className="contact_me_block">
              <ContactMeModal masterProfileData={masterProfileData} />
            </div>
          )}
        </>
      )}
    </div>
      </>
  );
}

export default WithDialog(Profile);
