import styled from "@emotion/styled";
import { alpha, Button } from "@mui/material";
import bg from "../../../../assets/images/for_salon/salons_bg_2.webp";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  margin-left: -80px;
  margin-top: 84px;
  width: calc(100% + 160px);

  background: linear-gradient(
      0deg,
      ${alpha(colors.accent, 0.5)},
      ${alpha(colors.accent, 0.5)}
    ),
    url(${bg});
  background-size: cover;

  ${(props) => props.theme.breakpoints.down("xl")} {
    margin-left: -60px;
    width: calc(100% + 120px);
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-left: -36px;
    width: calc(100% + 72px);
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: ${vw(-20)};
    width: calc(100% + ${vw(40)});
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 112px 80px;

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 112px 60px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 112px 36px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("120 20")};
  }
`;

const Subtitle = styled.div`
  margin: 34px 0 106px;
  max-width: 590px;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  width: 414px;
`;

export { Wrapper, Content, Subtitle, StyledButton };
