import styled from "@emotion/styled";
import { Button, Stack } from "@mui/material";
import { vw } from "../../styles";

const Wrapper = styled(Stack)`
  padding: 18px 18px 28px;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("18 18 20")};
    gap: ${vw(16)};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  max-width: 100%;
  margin-top: 56px;

  button {
    max-width: 100%;
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(48)};
  }
`;

const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  svg {
    width: 70px;
    height: 70px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: ${vw(16)};

    svg {
      width: ${vw(60)};
      height: ${vw(60)};
    }
  }
`;

export { Wrapper, ButtonContainer, IconBlock };
