import { ReactComponent as Expand } from "../../../assets/icons/expand_icon.svg";
import styled from "@emotion/styled";
import { Accordion } from "@mui/material";
import { vw } from "../../../styles";

const ExpandIcon = styled(Expand)`
  width: 28px;
  height: 28px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(20)};
    height: ${vw(20)};
  }
`;

const Wrapper = styled(Accordion)`
  border: 0;
`;

export { Wrapper, ExpandIcon };
