import classes from "./modalSlider.module.scss";
import ReactDom from "react-dom";
import closeIcon from "../../../../assets/icons/close.svg";
import salonImg from "../../../../assets/images/salonPhoto1.webp";
import ArrowControl from "../../../../components/slider/arrowControl/arrowControl";
import React, { useState } from "react";

let DEFAULT_CARDS = [
  {
    imageId: 1,
    imageUrl: salonImg,
  },
  {
    imageId: 2,
    imageUrl: salonImg,
  },
  {
    imageId: 3,
    imageUrl: salonImg,
  },
  {
    imageId: 4,
    imageUrl: salonImg,
  },
  {
    imageId: 5,
    imageUrl: salonImg,
  },
];

const startIndex = 0;
const endIndex = 1;

export const ModalSlider = ({ cards = DEFAULT_CARDS, close }) => {
  const [currentEndIndex, setCurrentEndIndex] = useState(endIndex);
  const [currentStartIndex, setCurrentStartIndex] = useState(startIndex);
  const [slides, setSlides] = useState(cards.slice(startIndex, endIndex));

  function nextSlide() {
    if (currentEndIndex === cards?.length) {
      return;
    }

    setSlides(cards.slice(currentStartIndex + 1, currentEndIndex + 1));

    setCurrentEndIndex((prevState) => prevState + 1);
    setCurrentStartIndex((prevState) => prevState + 1);
  }

  function prevSlide() {
    if (currentStartIndex === 0) {
      return;
    }

    setSlides(cards.slice(currentStartIndex - 1, currentEndIndex - 1));

    setCurrentEndIndex((prevState) => prevState - 1);
    setCurrentStartIndex((prevState) => prevState - 1);
  }

  return ReactDom.createPortal(
    <div className={classes.wrapper}>
      <div className={classes.visible_wrapper}>
        <div className={classes.btn_wrapper}>
          <button onClick={close} className={classes.closeBtn}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        <div className={classes.count_wrapper}>
          <div className={classes.slides_count}>
            {currentEndIndex}/{cards?.length}
          </div>
          <div className={classes.slider}>
            <ArrowControl direction="left" handleClick={prevSlide} />
            {slides.map((item, index) => {
              return (
                <div key={item.imageId} className="card_wrapper">
                  <div>
                    <img
                      src={item.imageUrl}
                      alt="icon"
                      className="slider_card_img"
                    />
                  </div>
                </div>
              );
            })}
            <ArrowControl direction="right" handleClick={nextSlide} />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
