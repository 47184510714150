import React, { ChangeEvent, useState } from "react";
import classes from "./UploadMainPhoto.module.scss";
import default_upload_photo from "../../../../../../assets/images/default_upload_photo.webp";
import { useAuth } from "../../../../../../contexts/AuthContext";
import {
  DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
  DEFAULT_FILE_SIZE_LIMIT_KB,
} from "../../../../../../constants/common.consts";
import setSalonFormDataToStorage from "../../../Stepper/services/setSalonFormDataToStorage";
import {
  addSalonImage,
  addSalonImageCreateMode,
  deleteSalonImage,
} from "../../../../../../services/salonsApi";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";

export default function UploadProfilePhoto({ setErrs, errs }) {
  const { auth } = useSelector((state: AppState) => state);
  const { salonId } = useParams();
  const [error, setError] = useState(null);
  const { salonFormData, setSalonFormData } = useAuth();

  const deleteImageUpdateMode = async (imageId) => {
    try {
      setError("");

      const deletedImageData = await deleteSalonImage(auth.salonId, imageId);
    } catch {
      setError("Failed to delete image");
    }
  };

  const deleteImageCreateMode = async (imageId) => {
    try {
      setError("");

      const deletedImageData = await deleteImageCreateMode(imageId);
    } catch {
      setError("Failed to delete image");
    }
  };

  const deletePhotoHandler = async (imageId: number) => {
    // create mode
    if (!auth?.salonId) {
      const deleteImageFromStorage = () => {
        setSalonFormDataToStorage({
          ...salonFormData,
          mainPhoto: { ...salonFormData.mainPhoto, imageUrl: "" },
        });
      };

      deleteImageFromStorage();

      deleteImageCreateMode(imageId);
    }

    setSalonFormData({
      ...salonFormData,
      mainPhoto: { ...salonFormData.mainPhoto, imageUrl: "" },
    });

    if (salonId) {
      deleteImageUpdateMode(imageId);
    }
  };

  const addFileImage = async (fileImage) => {
    try {
      const _formData = new FormData();

      // Update the formData object
      _formData.append("photo", fileImage, fileImage.name);
      _formData.append("type", "SALON-MAIN-PHOTO");
      _formData.append("lang", "EN");

      //create mode
      if (!auth?.salonId) {
        const responseImageData = await addSalonImageCreateMode(_formData);

        setSalonFormData({
          ...salonFormData,
          mainPhoto: responseImageData,
        });
      }

      //update mode
      if (salonId) {
        const responseImageData = await addSalonImage(_formData, salonId);

        setSalonFormData({
          ...salonFormData,
          mainPhoto: responseImageData,
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  const onUploadImageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const fileImage = e.target.files[0];

      const fileSize = fileImage.size;
      const fileSizeInKB = fileSize / 1024; // this would be in kilobytes defaults to bytes

      if (fileSizeInKB < DEFAULT_FILE_SIZE_LIMIT_KB) {
        addFileImage(fileImage);
      } else {
        setErrs((prevState) => {
          return {
            ...prevState,
            mainPhoto: DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE,
          };
        });
      }
    }
  };

  return (
    <div className={classes.upload_wrapper}>
      <div className={classes.upload_title}>Main photo:</div>

      <div className={classes.camera_wrapper}>
        <img
          src={salonFormData?.mainPhoto?.imageUrl || default_upload_photo}
          alt="profile"
        />
      </div>
      <div className={classes.upload_btn_wrapper}>
        {salonFormData?.mainPhoto?.imageUrl ? (
          <button
            type="button"
            onClick={() =>
              deletePhotoHandler(salonFormData?.mainPhoto?.imageId)
            }
          >
            delete photo
          </button>
        ) : (
          <label className={classes.upload_label}>
            <input
              type="file"
              accept="image/*"
              onChange={onUploadImageHandler}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
            Upload photo
          </label>
        )}
        {errs && <div className={classes.err_text}>{errs}</div>}
      </div>
    </div>
  );
}
