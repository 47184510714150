import { object, string } from "yup";
import {
  DEFAULT_ERROR_TEXT_MESSAGE,
  DEFAULT_PASSWORD_LENGTH,
} from "../../../constants/common.consts";

export const validationSchema = object({
  email: string()
    .email("Please provide a valid email address")
    .required(DEFAULT_ERROR_TEXT_MESSAGE)
    .default(""),
  password: string()
    .required(DEFAULT_ERROR_TEXT_MESSAGE)
    .min(DEFAULT_PASSWORD_LENGTH, "Password must be at least 8 characters")
    .test("length", "Passwords must match", (val, { parent }) => {
      return parent.confirmPassword === val;
    })
    .default(""),
  confirmPassword: string()
    .required(DEFAULT_ERROR_TEXT_MESSAGE)
    .min(DEFAULT_PASSWORD_LENGTH, "Password must be at least 8 characters")
    .test("length", "Passwords must match", (val, { parent }) => {
      return parent.password === val;
    })
    .default(""),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({}),
});
