import styled from "@emotion/styled";
import { Select } from "@mui/material";
import { colors, vw } from "../../../styles";

const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 280px;

  &.MuiOutlinedInput-root {
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Greenwich, serif;
    font-size: 20px;
    line-height: 20px;
    height: 52px;
    color: ${colors.accent};
    border: 2px solid ${colors.pink4};

    & .MuiSelect-select {
      background-color: transparent;
      padding: 16px;
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      max-width: 100%;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      max-width: 100%;
      border-radius: ${vw(8)};
      font-size: ${vw(20)};
      line-height: ${vw(20)};
      height: ${vw(52)};

      & textarea {
        min-height: ${vw(20)};
      }

      & input {
        height: ${vw(20)};
      }
    }

    .MuiButtonBase-root {
      margin-right: 18px;
      padding: 8px;
      width: 40px;
      height: 40px;

      svg {
        position: initial;
        width: 30px;
        height: 30px;

        path:first-of-type {
          fill: none;
        }
      }

      ${(props) => props.theme.breakpoints.down("sm")} {
        margin-right: ${vw(18)};
        padding: ${vw(8)};
        width: ${vw(40)};
        height: ${vw(40)};

        svg {
          width: ${vw(30)};
          height: ${vw(30)};
        }
      }
    }

    svg {
      position: absolute;
      top: 8px;
      width: 32px;
      height: 32px;

      path {
        fill: ${colors.pink4};
      }

      ${(props) => props.theme.breakpoints.down("sm")} {
        top: ${vw(8)};
        width: ${vw(32)};
        height: ${vw(32)};
      }
    }

    fieldset {
      top: 0;
      border: none;

      legend {
        display: none;
      }
    }

    &.Mui-error {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.red};
      }
    }
  }
`;

export { StyledSelect };
