import { FormHeader } from "../../../../components";
import React, { useEffect, useState } from "react";
import { Button, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import {
  TabWrapper,
  CalendarBlock,
  ModifyControls,
  StyledCalendar,
  DayButton,
  WorkstationInfo,
  WorkstationTooltip,
  StyledTab,
  StyledTabs,
  ButtonBlock,
  CancelButton,
  SaveBlock,
  BulkOpenButton,
} from "./shedule-styles";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import {
  addSchedule,
  getSalonsWorkstations,
  getSchedule,
  updateSchedule,
} from "../../../../../services/salonsApi";
import WithDialog from "../../../../../contexts/dialog/WithDialog";
import { CountSelect } from "./cout-select";
import { Spinner } from "../../../../../components/v2/spinner";
import { AdaptiveText } from "../../../../../components";
import { colors } from "../../../../../styles";
import { SaveSchedule } from "../../../../../modals";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { cloneDeep } from "lodash-es";

const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

const Schedule = ({ openDialog, closeDialog }) => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: AppState) => state);
  const { salonId } = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();

  const [schedule, setSchedule] = useState(null);
  const [initialSchedule, setInitialSchedule] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [value, setValue] = useState(0);
  const [workStations, setWorkStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scheduleLoading, setScheduleLoading] = useState(false);

  const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment()
    .add(2, "months")
    .clone()
    .endOf("month")
    .format("YYYY-MM-DD");

  const [selectedMonth, setSelectedMonth] = useState(startOfMonth);

  const submitSchedule = async () => {
    const newDates = schedule.filter((day) => !day.id);

    const updatedDays = schedule.filter((day) => day.id);

    const [createResponse, updateResponse] = await Promise.all([
      addSchedule(newDates),
      updateSchedule(updatedDays),
    ]);

    if (createResponse && updateResponse) {
      setIsDirty(false);
      setInitialSchedule(cloneDeep(schedule));
    }
  };

  useEffect(() => {
    const initSchedule = async () => {
      const workstationsData = await getSalonsWorkstations(
        auth?.salonId || salonId
      );

      setWorkStations(workstationsData);

      const currentSchedule = await getSchedule(
        auth?.salonId || salonId,
        workstationsData[0].workstationId,
        {
          fromDate: startOfMonth,
          toDate: endOfMonth,
        }
      );

      setSchedule(cloneDeep(currentSchedule));
      setInitialSchedule(cloneDeep(currentSchedule));

      setLoading(false);
    };

    initSchedule();
  }, []);

  useEffect(() => {
    if (workStations?.length) {
      setScheduleLoading(true);
      setIsDirty(false);

      getSchedule(
        auth?.salonId || salonId,
        workStations[value]?.workstationId,
        {
          fromDate: startOfMonth,
          toDate: endOfMonth,
        }
      ).then((data) => {
        setSchedule(data);
        setInitialSchedule(cloneDeep(data));
        setScheduleLoading(false);
      });
    }
  }, [value]);

  const setDate = (date, available) => {
    setIsDirty(true);

    const copy = [...schedule];
    const index = copy.findIndex((day) => day.scheduleDay === date.toString());
    if (index >= 0) {
      copy[index].availableCount = available;

      setSchedule(copy);
    } else {
      setSchedule([
        ...copy,
        {
          stwRelId: workStations[value].relationId,
          scheduleDay: date.toString(),
          availableCount: available,
        },
      ]);
    }
  };

  const handleChange = (e, tab) => {
    if (isDirty) {
      openDialog({
        component: (
          <SaveSchedule
            close={closeDialog}
            okCallback={() => {
              closeDialog();
              setValue(tab);
            }}
          />
        ),
        mw: 716,
        cancelCallback: () => {
          closeDialog();
        },
      });
    } else {
      setValue(tab);
    }
  };

  const clearDate = (date) => {
    setIsDirty(true);

    const dayIdx = schedule.findIndex(
      (day) => day.scheduleDay === date.toString()
    );
    const copy = [...schedule];

    if (dayIdx >= 0) {
      if (schedule[dayIdx]?.currentCount) {
        copy[dayIdx].availableCount = copy[dayIdx].currentCount;

        setSchedule(copy);
      } else {
        copy[dayIdx].availableCount = 0;

        setSchedule(copy);
      }
    }
  };

  const openSelector = (date) => () => {
    openDialog({
      component: (
        <CountSelect
          close={closeDialog}
          date={date}
          onCheck={setDate}
          onClear={clearDate}
          schedule={schedule}
          defaultCount={workStations[value]?.count}
        />
      ),
      mw: 304,
      withoutCloser: true,
      cancelCallback: () => {
        closeDialog();
      },
    });
  };

  const bulkOpenDates = (from, to) => async () => {
    setIsDirty(true);
    let copy = [...schedule];
    let available = workStations[value]?.count;
    for (let d = from; d <= to; d.setDate(d.getDate() + 1)) {
      let date = moment(d).format("YYYY-MM-DD");
      const index = copy.findIndex((day) => day.scheduleDay === date.toString());
      if (index >= 0) {
        copy[index].availableCount = available;


      } else {
        copy = ([
          ...copy,
          {
            stwRelId: workStations[value].relationId,
            scheduleDay: date.toString(),
            availableCount: available,
          },
        ]);
      }
    }

    setSchedule(copy);
  };

  const restoreDefault = () => {
    setIsDirty(false);
    setSchedule(cloneDeep(initialSchedule));
  };

  const finishEdit = () => {
    if (isDirty) {
      openDialog({
        component: (
          <SaveSchedule
            close={closeDialog}
            okCallback={() => {
              closeDialog();
              navigate(`/salon_profile/${auth?.salonId || salonId}`);
            }}
          />
        ),
        mw: 716,
        cancelCallback: () => {
          closeDialog();
        },
      });
    } else {
      navigate(`/salon_profile/${auth?.salonId || salonId}`);
    }
  };

  function MyPlugin({ position }) {

    return <BulkOpenButton
        variant="contained"
        color="secondary"
        disableTouchRipple
        disableFocusRipple
        onClick={bulkOpenDates(
            moment(selectedMonth).toDate(),
            moment(selectedMonth).endOf('month').toDate(),
            //new Date(endOfMonth)
        )
        }
    >
      Open all days this month
    </BulkOpenButton>;
  }

  return loading ? (
    <Spinner height="600px" />
  ) : (
    <Stack flexDirection="column">
      <FormHeader
        title="Availability schedule"
        subtitle="You can create the availability schedule for your workstations here. Please select the dates when the workstation(s) is available for rent. You can modify the schedule later at any moment "
      />
      <TabWrapper>
        <StyledTabs value={value} onChange={handleChange}>
          {workStations?.map((workStation) => {
            return (
              <StyledTab
                label={workStation.name}
                key={workStation.relationId}
              />
            );
          })}
        </StyledTabs>
      </TabWrapper>
      <CalendarBlock>
        {scheduleLoading ? (
          <Spinner height="402px" />
        ) : (
          <>
            <ModifyControls>
              <Stack flexDirection="row">

                {/*<Button*/}
                {/*  variant="contained"*/}
                {/*  disableTouchRipple*/}
                {/*  disableFocusRipple*/}
                {/*  onClick={() => {}}*/}
                {/*>*/}
                {/*  Modify by range*/}
                {/*</Button>*/}
              </Stack>
              <Stack></Stack>
              <Stack flexDirection="row" justifyContent="flex-end">
                <CancelButton
                  disableRipple
                  disabled={!isDirty}
                  onClick={restoreDefault}
                >
                  Cancel changes
                </CancelButton>
              </Stack>
            </ModifyControls>

            <StyledCalendar
              className="bookingCalendar"
              format="YYYY-MM-DD"
              multiple
              weekStartDayIndex={1}
              numberOfMonths={isDesktop ? 1 : 1}
              weekDays={weekDays}
              readOnly={true}
              minDate={moment().add(1, "days").toDate()}
              maxDate={new Date(endOfMonth)}
              plugins={[<MyPlugin position="top"/>]}
              onMonthChange={(date) => setSelectedMonth(date.toString())}
              mapDays={({ isSameDate, date, today }) => {
                let className = "";

                if (isSameDate(date, today)) {
                  className += "today ";
                }

                if (
                  date <= today ||
                  new Date(moment(date.toString()).format("YYYY-MM-DD")) >
                    new Date(endOfMonth)
                ) {
                  return { className: className };
                } else {
                  return {
                    className: className,
                    children: schedule?.find(
                      (day) => day.scheduleDay === date.toString()
                    )?.availableCount ? (
                      <Tooltip
                        title={
                          <WorkstationTooltip>
                            <span>
                              <b>Workstations</b>
                            </span>
                            <Stack flexDirection="row">
                              <WorkstationInfo>
                                <b>Total:</b>
                              </WorkstationInfo>
                              <span>
                                {
                                  schedule?.find(
                                    (day) => day.scheduleDay === date.toString()
                                  )?.availableCount
                                }
                              </span>
                            </Stack>
                            <Stack flexDirection="row">
                              <WorkstationInfo>
                                <b>Booked:</b>
                              </WorkstationInfo>
                              <span>
                                {schedule?.find(
                                  (day) => day.scheduleDay === date.toString()
                                )?.currentCount || 0}
                              </span>
                            </Stack>
                          </WorkstationTooltip>
                        }
                      >
                        <DayButton
                          disableRipple
                          onClick={openSelector(date)}
                          checked={schedule?.find(
                            (day) => day.scheduleDay === date.toString()
                          )}
                          booked={
                            schedule?.find(
                              (day) => day.scheduleDay === date.toString()
                            )?.currentCount
                          }
                        >
                          {date.day}
                        </DayButton>
                      </Tooltip>
                    ) : (
                      <DayButton
                        disableRipple
                        onClick={openSelector(date)}
                        checked={false}
                        booked={false}
                      >
                        {date.day}
                      </DayButton>
                    ),
                  };
                }
              }}
            />
            <SaveBlock>
              <Button
                disabled={!isDirty}
                variant="contained"
                disableRipple
                onClick={submitSchedule}
              >
                Save
              </Button>
            </SaveBlock>
          </>
        )}
      </CalendarBlock>
      <AdaptiveText
        xl={{ size: 20, height: 24 }}
        xs={{ size: 20, height: 24 }}
        color={colors.blu4}
        fontFamily="Greenwich"
      >
        <b>Tip:</b>&nbsp; on the last day of every month update the calendar for
        the following 2 months
      </AdaptiveText>
      <ButtonBlock>
        <Button
          variant="outlined"
          disableTouchRipple
          disableFocusRipple
          onClick={() => {
            (location?.state as any)?.completeCreate
              ? navigate(`/edit_salons/workstations_rentals/${salonId}`)
              : navigate(-1);
          }}
        >
          cancel
        </Button>
        <Button variant="contained" disableRipple onClick={finishEdit}>
          Submit
        </Button>
      </ButtonBlock>
    </Stack>
  );
};

export default WithDialog(Schedule);
