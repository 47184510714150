import { useRef, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/customButton/customButton";
import { useAuth } from "../../../../contexts/AuthContext";

import "./forgotPasswordModal.scss";
import { authService } from "../../../../services/authApi";

export default function ForgotPasswordModal() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);
  const emailRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      const data = await authService.forgotPassword(emailRef.current.value);

      if (data) {
        setMessage("Check your inbox for further instructions");
        setClicked(true);
      }
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  const handleClose = () => {
    navigate("/");
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Modal
        className="forgot_password_modal"
        show={true}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <div className="title">Forgot your password?</div>
            <div className="description">
              No problem! Just fill in the e-mail below and we`ll send you
              password reset instructions
            </div>
          </div>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <form onSubmit={handleSubmit}>
            <div className="input_group">
              <label htmlFor="username">
                Your e-mail address<span className="req_sign">*</span>
              </label>
              <input
                id="username"
                name="username"
                type="text"
                ref={emailRef}
                required
              />
            </div>
            <div className="buttons">
              <CustomButton
                disabled={loading || clicked}
                variant="primary"
                className="w-100"
                type="submit"
              >
                Reset Password
              </CustomButton>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
