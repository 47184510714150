import React from "react";
import { AdaptiveText, Carousel } from "../../../../components";
import { Wrapper, Divider, Slider } from "./salon-about-styles";
import { ServiceSlide } from "../service-slide";

const SalonAbout = ({ salonProfile }) => {
  return (
    <Wrapper>
      <AdaptiveText
        xl={{ size: 44, height: 56 }}
        xs={{ size: 44, height: 56 }}
        align="center"
        fontFamily="Greenwich SemBd"
        weight={600}
      >
        About the salon
      </AdaptiveText>
      <Divider />
      <AdaptiveText
        xl={{ size: 24, height: 28 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
      >
        {salonProfile?.description}
      </AdaptiveText>
      <Slider>
        <Carousel
          showSlides={{ xs: 3 }}
          padding={{ xs: 0, sm: 40, md: 60 }}
          autoplay
          slides={salonProfile?.services?.map((service) => (
            <ServiceSlide service={service} key={service.serviceId} />
          ))}
        />
      </Slider>
    </Wrapper>
  );
};

export default SalonAbout;
