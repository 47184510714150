import styled from "@emotion/styled";
import { colors, vw } from "../../../../../styles";

const StyledLink = styled.span`
  cursor: pointer;
  color: ${colors.pink4};

  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  font-family: "Greenwich", serif;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(18)};
    line-height: ${vw(21)};
  }
`;

const Wrapper = styled.div`
  margin-top: 10px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(10)};
  }
`;

export { StyledLink, Wrapper };
