import hairStyleIcon from "../../../../assets/icons/hairstylist.svg";
import barberIcon from "../../../../assets/icons/barber.svg";
import nailTechnicianIcon from "../../../../assets/icons/nail_technician.svg";
import estheticianIcon from "../../../../assets/icons/esthetician.svg";
import makeupArtistIcon from "../../../../assets/icons/makeup_artist.svg";
import massageTherapistIcon from "../../../../assets/icons/massage_therapist.svg";
import tattooArtistIcon from "../../../../assets/icons/tattooArtist.svg";
import pemanentMakeup from "../../../../assets/icons/perm-make-up.svg";
import "./imageSlider.scss";
import Divider from "../../../../components/divider/divider";
import CustomSwiper from "../../../../components/CustomSwiper/CustomSwiper";
import React from "react";

let cards = [
  {
    id: 1,
    icon: hairStyleIcon,
    label: "Hairstylist",
  },
  {
    id: 2,
    icon: barberIcon,
    label: "Barbers",
  },
  {
    id: 3,
    icon: nailTechnicianIcon,
    label: "Nail technician",
  },
  {
    id: 4,
    icon: estheticianIcon,
    label: "Esthetician",
  },
  {
    id: 5,
    icon: makeupArtistIcon,
    label: "Make-up Artist",
  },
  {
    id: 6,
    icon: massageTherapistIcon,
    label: "Massage Therapist",
  },
  {
    id: 7,
    icon: tattooArtistIcon,
    label: "Tattoo Artist",
  },
  {
    id: 8,
    icon: pemanentMakeup,
    label: "Permanent Make-up Artist",
  },
];

export default function ImageSlider({ className = "" }) {
  return (
    <div className={`who_is_vv_for ${className}`}>
      <div>
        <div className="title">Who is VERVVEY for?</div>
        <Divider />
      </div>
      <div className="slider">
        <CustomSwiper
          showSlides={{ xs: 3, lg: 5 }}
          bigNavigation
          slides={cards.map((item) => (
            <div key={item.id} className="card_wrapper">
              <div>
                <img src={item.icon} alt="icon" className="slider_card_img" />
              </div>
              <div className="label">{item.label}</div>
            </div>
          ))}
        />
      </div>
    </div>
  );
}
