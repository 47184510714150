import React from "react";
import classes from "./hamburgerMenu.module.scss";

const HamburgerButton = ({ isOpen, setIsOpen }) => {
  const clickHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button
      className={`${classes.burger_menu_btn} ${isOpen ? classes.active : ""}`}
      aria-label="Open main menu"
      onClick={clickHandler}
    >
      <span className={isOpen ? classes.active : ""} />
      <span className={isOpen ? classes.active : ""} />
      <span className={isOpen ? classes.active : ""} />
    </button>
  );
};

export default HamburgerButton;
