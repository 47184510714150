import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 13px;
  padding: 16px 36px;
  width: 100%;
  background-color: ${colors.blu1};
  border-radius: 12px;

  img {
    max-width: 200px;
    height: 136px;
    object-fit: cover;
    margin-bottom: 16px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0 ${vw(13)};
    padding: ${vw(16)} ${vw(36)};
    border-radius: ${vw(12)};

    img {
      max-width: ${vw(320)};
      height: ${vw(216)};
      margin-bottom: ${vw(16)};
    }
  }
`;

const OrgName = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;

export { Slide, OrgName };
