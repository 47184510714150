import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";
import { alpha } from "@mui/material";
import { AdaptiveText } from "../../../../components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  margin: 20px 0 60px;
  width: 100%;
  height: 3px;
  background-color: ${alpha(colors.blu4, 0.3)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: ${vw("12 0 32")};
    height: ${vw(3)};
  }
`;

const Specific = styled(AdaptiveText)`
  margin-top: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(16)};
  }
`;

export { Wrapper, Divider, Specific };
