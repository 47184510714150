import React, { useRef } from "react";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import {
  CalendarSelect,
  ExpandIcon,
  InputWrapper,
  PrevButton,
  Row,
  SelectWrapper,
  StyledDatePicker,
  StyledFormControl,
} from "./custiom-date-picker-styles";
import {
  LabelContainer,
  StyledInputLabel,
} from "../../v2/components/dropwdown/dropdown-styles";
import { LabelTooltip } from "../../v2/components/label-tooltip";
import { IconButton } from "@mui/material";
import moment from "moment";

export const CustomDatePicker = ({
  startDate = new Date(),
  setStartDate,
  text = "",
  require = false,
  err = "",
  info = "",
  name = "date_picker",
}) => {
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const inputRef = useRef(null);

  const onChangeHandler = (date, e) => {
    setStartDate(date, e);
  };

  return (
    <StyledFormControl fullWidth id={"div_"+name}>
      {text && (
        <LabelContainer
          onClick={() => {
            inputRef.current.setOpen(true);
            inputRef.current.input.focus();
          }}
        >
          <StyledInputLabel error={!!err}>
            {text}
            {info && <LabelTooltip tooltip={info} />}
          </StyledInputLabel>
          {require && "*"}
        </LabelContainer>
      )}
      <InputWrapper>
        <StyledDatePicker
          dateFormat="dd/MM/yyyy"
          name={name}
          placeholderText={"DD-MM-YYYY"}
          ref={inputRef}
          value={startDate}
          selected={startDate}
          onChange={onChangeHandler}
          autoComplete="off"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Row>
              <PrevButton
                aria-label="prev"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                disableRipple
              >
                <ExpandIcon />
              </PrevButton>
              <SelectWrapper>
                <CalendarSelect
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) => {
                    changeMonth(months.indexOf(value));
                    setStartDate(
                      moment(date).set(
                        "month",
                        months.findIndex((item) => item === value)
                      )
                    );
                  }}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CalendarSelect>
              </SelectWrapper>
              <SelectWrapper>
                <CalendarSelect
                  value={getYear(date)}
                  onChange={({ target: { value } }) => {
                    changeYear(value);
                    setStartDate(moment(date).set("year", +value));
                  }}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CalendarSelect>
              </SelectWrapper>
              <IconButton
                aria-label="prev"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                disableRipple
              >
                <ExpandIcon />
              </IconButton>
            </Row>
          )}
        />
      </InputWrapper>
    </StyledFormControl>
  );
};
