import React from "react";
import {
  Wrapper,
  Card,
  Divider,
  StepRow,
  StepNum,
  StepDescription,
  StyledButton,
  TextPaddingBox,
  BottomRow,
  BottomBtnBlock,
} from "./steps-block-styles";
import { Grid } from "@mui/material";
import { AdaptiveText, Carousel } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ROLES } from "../../../../constants/common.consts";

const steps = [
  {
    num: 1,
    title: "Apply for free",
    text: `Create your profile – it only takes 15 minutes.
      It helps us and beauty artists to get to know your salon and your terms better.`,
    description: `Please complete your profile to get verified as a host, explore coworking opportunities with some
      of the coolest beauty artists and have a customized experience on the platform.`,
  },
  {
    num: 2,
    title: "Pre-approve for coworking",
    text: `Get ready to start coworking with the most interesting
      beauty artists in the city.`,
    description: `Once they get interested in coworking at your salon, you’ll get their detailed profile
      for pre-approval. Only those beauty artists who you pre-approved can book workstations at your salon.`,
  },
  {
    num: 3,
    title: "Host beauty artists",
    text: `Keep pace with new work demands. Coworking is the new
      definition of hybrid — designed by you, powered by VERVVEY.`,
    description: `Happy coworking!`,
  },
];

const StepsBlock = ({ showPopup }) => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();

  const becomeHost = (e) => {
    if (auth?.role) {
      e.preventDefault();

      showPopup();
    } else {
      navigate(`/register_modal?mode=${ROLES.SALON_ADMIN}`);
    }
  };

  return (
    <Wrapper>
      <Card>
        <TextPaddingBox>
          <AdaptiveText
            xl={{ size: 44, height: 56 }}
            xs={{ size: 30, height: 38 }}
            weight={400}
            uppercase
            align="center"
          >
            Host beauty artists in 3 easy steps
          </AdaptiveText>
        </TextPaddingBox>
        <Divider />
        <TextPaddingBox>
          <AdaptiveText
            xl={{ size: 26, height: 33 }}
            xs={{ size: 18, height: 20 }}
            weight={300}
          >
            We’ll help you every step of the way
          </AdaptiveText>
        </TextPaddingBox>
        <Carousel
          showSlides={{ xs: 1 }}
          bigNavigation
          slides={steps.map((step) => (
            <Grid container>
              <StepRow item container wrap="nowrap">
                <Grid item>
                  <StepNum
                    xl={{ size: 270, height: 270 }}
                    sm={{ size: 120, height: 120 }}
                    xs={{ size: 180, height: 180 }}
                    weight={600}
                  >
                    {step.num}
                  </StepNum>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <AdaptiveText
                        xl={{ size: 48, height: 61 }}
                        sm={{ size: 22, height: 28 }}
                        xs={{ size: 22, height: 28 }}
                        weight={700}
                        uppercase
                      >
                        {step.title}
                      </AdaptiveText>
                      <StepDescription
                        xl={{ size: 24, height: 28 }}
                        sm={{ size: 18, height: 21 }}
                        xs={{ size: 18, height: 21 }}
                        weight={300}
                      >
                        {step.text}
                      </StepDescription>
                    </Grid>
                    <Grid
                      item
                      container
                      wrap="nowrap"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      gap="36px"
                      sx={{ display: { xs: "none", xl: "flex" } }}
                    >
                      <Grid item>
                        <AdaptiveText
                          xl={{ size: 20, height: 23 }}
                          sm={{ size: 14, height: 16 }}
                          xs={{ size: 14, height: 16 }}
                          weight={300}
                        >
                          {step.description}
                        </AdaptiveText>
                      </Grid>
                      <Grid sx={{ display: { xs: "none", xl: "flex" } }}>
                        <StyledButton
                          disableTouchRipple
                          disableFocusRipple
                          onClick={becomeHost}
                          variant="contained"
                        >
                          Become a host
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StepRow>

              <BottomRow
                item
                xs={12}
                sx={{ display: { xs: "flex", xl: "none" } }}
              >
                <AdaptiveText
                  xl={{ size: 20, height: 23 }}
                  sm={{ size: 14, height: 16 }}
                  xs={{ size: 14, height: 16 }}
                  weight={300}
                >
                  {step.description}
                </AdaptiveText>
              </BottomRow>
            </Grid>
          ))}
        ></Carousel>
        <BottomBtnBlock>
          <StyledButton
            disableTouchRipple
            disableFocusRipple
            onClick={becomeHost}
            variant="contained"
          >
            Become a host
          </StyledButton>
        </BottomBtnBlock>
      </Card>
    </Wrapper>
  );
};

export default StepsBlock;
