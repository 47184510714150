import React from "react";
import {Helmet} from "react-helmet";

import {
  HeaderBlock,
  WhyBlock,
  QuestionsBlock,
  FinalBlock,
  GrantBlock,
  CarouselBlock,
  UsedBlock,
  FeaturedBlock,
} from "./components";

import { Wrapper } from "./main-page-styles";
import { Outlet } from "react-router-dom";

const MainPage = () => {
  return (
      <><Helmet>
          <title>Vervvey | The biggest beauty coworking community</title>
          <meta name="description" content="Join the Biggest Beauty Coworking Community" />
      </Helmet>
          <Wrapper>
          <HeaderBlock/>
          <WhyBlock/>
          <GrantBlock/>
          <CarouselBlock/>
          <UsedBlock/>
          <FinalBlock/>
          <FeaturedBlock/>
          <QuestionsBlock/>
          <Outlet/>
      </Wrapper></>
  );
};

export default MainPage;
