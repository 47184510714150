import styled from "@emotion/styled";
import { alpha, IconButton } from "@mui/material";
import { Swiper } from "swiper/react";
import { colors, vw } from "../../styles";

const SwiperContainer = styled.div`
  width: 100%;
  position: relative;

  & .swiper-pagination-bullets {
    margin-top: 36px;
  }

  & .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid ${colors.blu2};
    background-color: transparent;
    opacity: 1;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${colors.blu2};
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    & .swiper-pagination-bullets {
      margin-top: ${vw(36)};
    }

    & .swiper-pagination-bullet {
      width: ${vw(12)};
      height: ${vw(12)};
    }
  }
`;

const LeftButton = styled(IconButton)`
  z-index: 1;
  width: 60px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  right: -20px;
  background-color: ${alpha(colors.blu1, 0.4)};

  &.MuiIconButton-sizeLarge {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    right: -40px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(60)};
    height: ${vw(60)};
    top: calc(50% - ${vw(30)});
    right: 0;

    &.MuiIconButton-sizeLarge {
      width: ${vw(60)};
      height: ${vw(60)};
      top: calc(50% - ${vw(30)});
      right: 0;
    }
  }
`;

const RightButton = styled(IconButton)`
  z-index: 1;
  width: 60px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  left: -20px;
  background-color: ${alpha(colors.blu1, 0.4)};

  &.MuiIconButton-sizeLarge {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: -40px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(60)};
    height: ${vw(60)};
    top: calc(50% - ${vw(30)});
    left: 0;

    &.MuiIconButton-sizeLarge {
      width: ${vw(60)};
      height: ${vw(60)};
      top: calc(50% - ${vw(30)});
      left: 0;
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  margin: 0;
  position: static;

  & .swiper-pagination {
    bottom: -22px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0 ${vw(60)};

    &.swiper-wide {
      margin: 0 ${vw(20)};
    }

    & .swiper-pagination {
      bottom: ${vw(-22)};
    }
  }
`;

export { SwiperContainer, LeftButton, RightButton, StyledSwiper };
