import React from "react";

import { Wrapper } from "./service-slide-styles";
import { AdaptiveText } from "../../../../components";

const ServiceSlide = (item) => {
  return (
    <Wrapper>
      <img src={item?.service?.imageUrl} alt="icon" />
      <AdaptiveText
        xl={{ size: 16, height: 19 }}
        xs={{ size: 16, height: 19 }}
        fontFamily="Greenwich"
        align="center"
      >
        {item?.service.description}
      </AdaptiveText>
    </Wrapper>
  );
};

export default ServiceSlide;
