import classes from "./SalonCatalog.module.scss";
import wifi_icon from "../../../assets/icons/wifi.svg";
import closet_icon from "../../../assets/icons/closet.svg";
import towels_icon from "../../../assets/icons/towels.svg";
import welcome_icon from "../../../assets/icons/welcome_area.svg";
import pets_icon from "../../../assets/icons/pets_allows.svg";
import and_more_icon from "../../../assets/icons/more.svg";
import {useEffect, useState} from "react";
import {MySelect} from "@components/mySelect/mySelect";
import {Button} from "@mui/material";
import {getSalonList} from "@services/salonsApi";
import CustomSpinner from "../../../components/spinner/customSpinner";
import {useFetchWorkstationOptions} from "./hooks/useFetchWorkstationOptions";
import {Link} from "react-router-dom";
import {AdaptiveText} from "@components";
import {getCityList, getUserInfo} from "@services/api";
import React from "react";
import {StyledChip, EmptyResultWrapper} from "./salon-catalog-styles";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {SalonListAction} from "@redux/actions/salon-list-actions";
import {AppState} from "@redux/reducers/rootReducer";
import {Helmet} from "react-helmet";

const DEFAULT_READ_MORE_LENGTH = 80;

const locationIcon = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 7.63636C15 12.4091 9 16.5 9 16.5C9 16.5 3 12.4091 3 7.63636C3 6.0089 3.63214 4.44809 4.75736 3.2973C5.88258 2.14651 7.4087 1.5 9 1.5C10.5913 1.5 12.1174 2.14651 13.2426 3.2973C14.3679 4.44809 15 6.0089 15 7.63636Z"
            stroke="#121212"
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z"
            stroke="#121212"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const vibesData = [
    {img: wifi_icon, text: "Wifi"},
    {img: closet_icon, text: "Closet"},
    {img: towels_icon, text: "Towels"},
    {img: welcome_icon, text: "Welcome area"},
    {img: pets_icon, text: "Pets allowed"},
    {img: and_more_icon, text: "and more!"},
];

const DEFAULT_PAGE_SIZE = 100;

export const getFormattedSalonList = (list) => {
    return list.map((item) => {
        return {
            phone: item.phone,
            email: item.email,
            status: item.status,
            id: item.salonId,
            image: item.profilePhoto?.imageUrl,
            name: item.name,
            location: `${item.address.street} ${item.address.number}, ${item.address.postCode}`,
            city: item.city,
            vibeIcon: item.vibe.imageUrl,
            vibeText: item.vibe.name,
            description: item.description,
            pricePerDay: item.pricePerDay,
        };
    });
};

const SalonCatalogPageCounterDivider = ({page}) => {
    return (
        <div className={classes.page_number_wrapper}>
            <div className={classes.line}></div>
            <div className={classes.page_number}>{`Page ${page + 1}`}</div>
            <div className={classes.line}></div>
        </div>
    );
};

const SalonCatalogPageSizeCounter = ({salonList, itemsCount}) => {
    return (
        <div className={classes.page_size_wrapper}>
            <div className={classes.line}></div>
            <div
                className={classes.page_number}
            >{`${salonList?.length} of ${itemsCount} shown`}</div>
            <div className={classes.line}></div>
        </div>
    );
};

const SalonCard = ({item}) => {

    const onRedirectHandler = () => {
        sessionStorage.setItem(`scroll_salon_catalog`, window.scrollY.toString());
        //navigate(`/salon_profile/${item.id}`);
    };

    return (
        <Link to={`/salon_profile/${item.id}`} onClick={onRedirectHandler}>
            <div
                className={classes.catalog_item_wrapper}
                key={item.id}
            >
                <div className={classes.image_wrapper}>
                    <img src={item.image} alt="card"/>
                    {item?.pricePerDay && <StyledChip label={`€${item.pricePerDay}`}/>}
                </div>

                <div className={classes.catalog_item_text_area}>
                    <div className={classes.catalog_item_name}>{item.name}</div>
                    <div className={classes.catalog_item_location}>
                        {locationIcon}
                        <p>
                            {item.location}
                            <br/>
                            {item.city}
                        </p>
                    </div>
                    <div className={classes.catalog_item_vibe}>
                        <img src={item.vibeIcon} alt={"vibe"}/>
                        <p>{item.vibeText}</p>
                    </div>
                    <div className={classes.catalog_item_descriptions}>
                        <div>
                            {item.description?.length > DEFAULT_READ_MORE_LENGTH
                                ? `${item.description.slice(0, 80)}...`
                                : item.description}
                        </div>
                        <AdaptiveText
                            align="right"
                            variant="body1"
                            xl={{size: 16, height: 19}}
                            xs={{size: 16, height: 19}}
                            weight={700}
                        >
                            <u>See more</u>
                        </AdaptiveText>
                    </div>
                </div>
            </div>
        </Link>
    );
};

const SalonCatalogBody = ({
                              salonList,
                              itemsCount,
                              onSeeMoreHandler,
                              resetFilters,
                          }) => {
    if (!salonList?.length) {
        return (
            <EmptyResultWrapper>
                <AdaptiveText
                    xl={{size: 20, height: 20}}
                    xs={{size: 20, height: 20}}
                    weight={300}
                    fontFamily="Greenwich"
                    align="center"
                >
                    We have no beauty coworking workstations meeting selected criteria
                    yet, but we are working on adding new salons every day
                </AdaptiveText>
                <Button variant="contained" onClick={resetFilters}>
                    See all available offers
                </Button>
            </EmptyResultWrapper>
        );
    }

    return (
        <>
            <div className={classes.catalog_wrapper}>
                {salonList.map((item, index) => {
                    const currentPageSize = index + 1;

                    if (currentPageSize % DEFAULT_PAGE_SIZE === 0) {
                        return (
                            <React.Fragment key={item.id}>
                                <SalonCard item={item}/>

                                {salonList?.length !== currentPageSize && (
                                    <SalonCatalogPageCounterDivider
                                        page={currentPageSize / DEFAULT_PAGE_SIZE}
                                    />
                                )}
                            </React.Fragment>
                        );
                    }

                    return <SalonCard item={item} key={item.id}/>;
                })}
            </div>
            <SalonCatalogPageSizeCounter
                salonList={salonList}
                itemsCount={itemsCount}
            />
            <div className={classes.see_more_button}>
                {salonList?.length < itemsCount && (
                    <Button variant="contained" onClick={onSeeMoreHandler}>
                        SEE MORE
                    </Button>
                )}
            </div>
        </>
    );
};

export const SalonCatalog = () => {
    const {salonList} = useSelector((state: AppState) => state);
    const {auth} = useSelector((state: AppState) => state);
    const authDispatch = useDispatch<Dispatch<SalonListAction>>();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [cityList, setCityList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
    const workstationOptions = useFetchWorkstationOptions();

    const fetchData = async (pageSize) => {
        try {
            setIsLoading(true);

            const response = await getSalonList({
                page: 0,
                pageSize,
                city: salonList.city,
                workstationType: salonList.workstationType,
                status: "active",
            });

            authDispatch({
                type: "SET_SALON_LIST",
                payload: {
                    list: getFormattedSalonList(response.page),
                    city: salonList.city,
                    workstationType: salonList.workstationType,
                    count: response.itemsCount,
                },
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        getCityList().then((cities) => {
            setCityList(
                cities.map((city) => {
                    return {value: city, label: city};
                })
            );
        });
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {

            if (auth?.userId) {
                const userData = await getUserInfo();

                if (userData.token) {
                    localStorage.setItem("user", JSON.stringify(userData));
                }
            }

        };

        fetchUserData();
    }, []);

    useEffect(() => {
        if (!(salonList?.list instanceof Array)) {
            fetchData(rowsPerPage);
            window.scrollTo(0, 0);
            sessionStorage.removeItem(`scroll_salon_catalog`);
        } else {
            let scroll_salon_catalog = parseInt(sessionStorage.getItem(`scroll_salon_catalog`));
            if (scroll_salon_catalog > 0) {
                window.scrollTo(0, scroll_salon_catalog);
                sessionStorage.removeItem(`scroll_salon_catalog`);
            }
            else {
                window.scrollTo(0, 0);
            }
        }
    }, [salonList.list]);

    const handleSelectInputChange = async (value, e) => {
        const name = e.name;

        try {
            setIsLoading(true);

            const response = await getSalonList({
                page: 0,
                pageSize: DEFAULT_PAGE_SIZE,
                status: "active",
                city: salonList.city,
                workstationType: salonList.workstationType,
                ...{[name]: value},
            });

            authDispatch({
                type: "SET_SALON_LIST",
                payload: {
                    list: getFormattedSalonList(response.page),
                    city: salonList.city,
                    workstationType: salonList.workstationType,
                    count: response.itemsCount,
                    ...{[name]: value},
                },
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    };

    const onSeeMoreHandler = () => {
        fetchData(rowsPerPage + DEFAULT_PAGE_SIZE);
        setRowsPerPage((prevState) => {
            return prevState + DEFAULT_PAGE_SIZE;
        });
    };

    const resetFilters = async () => {
        try {
            setIsLoading(true);

            const response = await getSalonList({
                page: 0,
                pageSize: DEFAULT_PAGE_SIZE,
                status: "active",
            });

            authDispatch({
                type: "SET_SALON_LIST",
                payload: {
                    list: getFormattedSalonList(response.page),
                    count: response.itemsCount,
                },
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError(error);
        }
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <><Helmet>
            <title>Choose a salon that suits your vibe | Vervvey</title>
            <meta name="description" content="The right place, team, everything... perfect for you"/>
        </Helmet>
            <div className={classes.salon_catalog_wrapper}>
                <div className={classes.top_area_wrapper}>
                    <div className={classes.max_width_wrapper}>
                        <div className={classes.top_area_title}>
                            Choose a salon that suits your vibe
                        </div>
                        <div className={classes.divider}></div>
                        <div className={classes.top_area_sub_title}>
                            The right place, team, everything... perfect for you
                        </div>
                        <div className={classes.vibes_wrapper}>
                            {vibesData.map((item, index) => {
                                return (
                                    <div className={classes.vibe_item} key={index}>
                                        <img
                                            src={item.img}
                                            alt="wifi"
                                            className={classes.vibe_item_image}/>
                                        <div className={classes.vibe_item_text}>{item.text}</div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={classes.selects_wrapper}>
                            <MySelect
                                name="city"
                                value={salonList.city}
                                options={cityList}
                                onChange={handleSelectInputChange}
                                placeholder="City"
                                variant="pink"
                                isClearable/>
                            <MySelect
                                name="workstationType"
                                value={salonList.workstationType}
                                options={workstationOptions}
                                onChange={handleSelectInputChange}
                                placeholder="Workplace type"
                                variant="pink"
                                isClearable/>
                        </div>
                    </div>
                </div>
                <div className={classes.max_width_wrapper}>
                    <div className={classes.catalog_flex_wrapper}>
                        {isLoading ? (
                            <CustomSpinner/>
                        ) : (
                            <SalonCatalogBody
                                salonList={salonList.list}
                                itemsCount={salonList.count}
                                onSeeMoreHandler={onSeeMoreHandler}
                                resetFilters={resetFilters}/>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
