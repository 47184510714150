import { ReactComponent as ExternalLink } from "../../../../assets/icons/external-link.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/icons/expand_icon.svg";
import { IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledExpandButton } from "./action-component-styles";

const ActionComponent = ({ requestId, expand, expanded }) => {
  const navigate = useNavigate();

  const edit = () => {
    navigate(`${requestId}`);
  };

  return (
    <Stack gap={1} flexDirection="row">
      <IconButton disableTouchRipple disableFocusRipple onClick={edit}>
        <ExternalLink />
      </IconButton>
      <StyledExpandButton
        disableTouchRipple
        disableFocusRipple
        onClick={() => {
          expand(requestId);
        }}
      >
        <ExpandIcon
          style={{
            rotate: expanded.includes(requestId) ? "90deg" : "0deg",
            transition: "0.2s",
          }}
        />
      </StyledExpandButton>
    </Stack>
  );
};

export default ActionComponent;
