import "./sharedEditSalonPages.scss";
import { useParams } from "react-router-dom";
import React from "react";
import { CreateModeSalon } from "./Stepper/CreateModeSalon/CreateModeSalon";
import useFetchAmenitiesData from "./useFetchAmenitiesData";
import { ROLES } from "../../../constants/common.consts";
import { UpdateModeSalons } from "./UpdateModeSalon/UpdateModeSalon";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

export default function EditSalons() {
  const { auth } = useSelector((state: AppState) => state);
  const { salonId } = useParams();

  useFetchAmenitiesData();

  if (salonId && auth?.role === ROLES.SUPER_ADMIN) {
    return <UpdateModeSalons />;
  }

  if (!auth?.salonId) {
    return <CreateModeSalon />;
  }

  return <UpdateModeSalons />;
}
