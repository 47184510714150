import {
  Container,
  Wrapper,
  BookInfo,
  TableFilter,
} from "./master-booking-list-styles";
import moment from "moment";
import { getMaserBookings } from "../../../services/api";
import { AdaptiveText } from "../../../components";
import { Table } from "../../../components/v2/table";
import { colors } from "../../../styles";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MdMail, MdPhone } from "react-icons/md";
import { TableSelect } from "../../../components/v2";
import range from "lodash/range";
import { useEffect, useState } from "react";
import { getAllWorkstationOptions } from "../../../services/salonsApi";

const getColumns = () => [
  {
    width: "21%",
    name: "Booking date",
    data: "rentDate",
    sortable: "true",
    formatter: (item) => {
      return moment(item).format("DD/MM/YYYY");
    },
  },
  {
    width: "27,5%",
    name: "Workplace type",
    data: "workstationType",
    formatter: (item) => {
      return (
        <AdaptiveText
          xs={{ size: 14, height: 16 }}
          xl={{ size: 14, height: 16 }}
          fontFamily="Greenwich"
          weight={500}
          capitalize
        >
          {item}
        </AdaptiveText>
      );
    },
  },
  {
    width: "34,5%",
    name: "Salon name",
    data: "salonName",
    formatter: (item) => {
      return (
        <AdaptiveText
          xs={{ size: 14, height: 16 }}
          xl={{ size: 14, height: 16 }}
          weight={500}
          fontFamily="Greenwich"
          color={colors.pink4}
        >
          {item}
        </AdaptiveText>
      );
    },
  },
  {
    width: "17%",
    name: "Rental fee",
    data: "priceVat",
    formatter: (item) => {
      return (
        <AdaptiveText
          xs={{ size: 14, height: 16 }}
          xl={{ size: 14, height: 16 }}
          weight={500}
          fontFamily="Greenwich"
        >
          €{item.toFixed(2).replace(/[.,]00$/, "")}
        </AdaptiveText>
      );
    },
  },
];
const years = range(2022, moment().year() + 2, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MasterBookingList = () => {
  const { auth } = useSelector((state: AppState) => state);

  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const [workstation, setWorkstation] = useState("");
  const [workstations, setWorkstations] = useState([]);
  const [period, setPeriod] = useState({
    fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().clone().endOf("month").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getAllWorkstationOptions().then((data) => {
      setWorkstations(data);
    });
  }, []);

  useEffect(() => {
    setPeriod({
      fromDate: moment(new Date(`${year}-${month}-01`))
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD"),
      toDate: moment(new Date(`${year}-${month}-01`))
        .clone()
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
  }, [month, year]);

  return (
    <Wrapper>
      <Container>
        <div>
          <AdaptiveText
            xl={{ size: 36, height: 46 }}
            xs={{ size: 36, height: 46 }}
            fontFamily="Greenwich"
            weight={600}
          >
            Bookings
          </AdaptiveText>
        </div>

        <BookInfo>
          <AdaptiveText
            xl={{ size: 20, height: 20 }}
            xs={{ size: 20, height: 20 }}
            fontFamily="Greenwich"
          >
            If you need to cancel your booking please contact the customer
            support team at
          </AdaptiveText>
          <AdaptiveText
            xl={{ size: 20, height: 20 }}
            xs={{ size: 20, height: 20 }}
            fontFamily="Greenwich"
          >
            <MdMail color={colors.blu4} />
            hello@vervvey.com or&nbsp;
            <MdPhone color={colors.blu4} />
            +31 20 890 5527
          </AdaptiveText>
        </BookInfo>

        <TableFilter>
          <TableSelect
            options={months.map((item, index) => ({
              value: index + 1,
              label: item,
            }))}
            value={month}
            onChange={setMonth}
          />
          <TableSelect
            options={years.map((item) => ({
              value: item,
              label: item,
            }))}
            value={year}
            onChange={setYear}
          />
          <TableSelect
            options={workstations?.map((item) => ({
              value: item.workstationId,

              label: item.name,
            }))}
            value={workstation}
            onChange={setWorkstation}
            withReset
            placeholder="Workplace type"
          />
        </TableFilter>

        <Table
          withBorders
          columns={getColumns()}
          api={getMaserBookings(auth.userId)}
          sorting="rentDate"
          sortingOrder="desc"
          params={{
            fromDate: period.fromDate,
            toDate: period.toDate,
            statuses: "success,prepaid,completed,failed",
            ...(workstation ? { workstationId: workstation } : {}),
          }}
          watchFields={[period.fromDate, period.toDate, workstation]}
          minWidth="auto"
          checkHighlightRow={(item, data) => {
            const dates = data
              .map((book) => book.rentDate)
              .filter((date) =>
                moment(new Date(date)).isAfter(new Date(), "day")
              )
              .sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
            return item.rentDate === dates[0];
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default MasterBookingList;
