import { AdaptiveText } from "../../components";
import React, { useEffect } from "react";
import { Wrapper, ButtonContainer, IconBlock } from "./success-payment-styles";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Allow } from "../../assets/icons/allow.svg";

const SuccessPayment = ({ okCallback }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.delete("payment");
    setSearchParams(searchParams);
  }, []);

  return (
    <Wrapper flexDirection="column">
      <IconBlock>
        <Allow />
      </IconBlock>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={600}
        fontFamily="Greenwich SemBd"
        align="center"
      >
        Your payment has been completed successfully. We have sent you
        confirmation e-mail with booking details.
      </AdaptiveText>
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={600}
        fontFamily="Greenwich SemBd"
        align="center"
      >
        You can also find it in Your bookings
      </AdaptiveText>

      <ButtonContainer>
        <Button
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
          OK
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default SuccessPayment;
