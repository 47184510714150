import { Container, Wrapper } from "./booking-list-styles";
import { AdaptiveText } from "../../../components";
import { Table } from "../../../components/v2/table";
import {
  geBookingDayStatuses,
  getBookingStatuses,
  getUsers,
  getWorkstationBookings,
} from "../../../services/api";
import moment from "moment";
import { ActionComponent, ManagerSelect, StatusSelect } from "./../components";
import { useEffect, useState } from "react";
import { Details, Filter } from "./components";
import { useSearchParams } from "react-router-dom";

const getColumns = (admins, statuses, expand, expanded) => [
  {
    width: "144px",
    name: "Request time",
    data: "createDate",
    sortable: "true",
    formatter: (item) => {
      return moment(item).format("DD/MM/YYYY");
    },
  },
  {
    width: "120px",
    name: "Request ID",
    data: "id",
  },
  {
    width: "210px",
    name: "Artist's nickname",
    data: "masterName",
  },
  {
    width: "236px",
    name: "Salon name",
    data: "salonName",
  },
  {
    width: "236px",
    name: "Workplace type",
    data: "workstationType",
  },
  {
    width: "220px",
    name: "Booking status",
    data: "status",
    formatter: (item, data) => {
      return (
        <StatusSelect defaultValue={item} data={data} statuses={statuses} />
      );
    },
  },
  // {
  //   width: "325px",
  //   name: "Comment",
  //   data: "comment",
  //   formatter: (item, data) => {
  //     return <CommentInput defaultValue={item} data={data} />;
  //   },
  // },
  {
    width: "205px",
    name: "Manager",
    data: "managerId",
    formatter: (item, data) => {
      return <ManagerSelect defaultValue={item} admins={admins} data={data} />;
    },
    withoutBorder: true,
  },
  {
    width: "76px",
    data: "action-component",
    getComponent: (item) => {
      return (
        <ActionComponent
          requestId={item?.id}
          expand={expand}
          expanded={expanded}
        />
      );
    },
  },
];

const BookingList = () => {
  const [admins, setAdmins] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [dayStatuses, setDayStatuses] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const managerId = searchParams.get("managerId");
  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");

  useEffect(() => {
    getBookingStatuses().then((data) => {
      setStatuses(data);
    });
    geBookingDayStatuses().then((data) => {
      setDayStatuses(data);
    });
  }, []);

  useEffect(() => {
    getUsers({
      page: 0,
      pageSize: 10,
      filterBy: "role",
      filterValue: "admin",
    }).then(({ page }) => {
      setAdmins(
        page.map((admin) => {
          return { value: admin.userId, label: admin.name };
        })
      );
    });
  }, []);

  const expand = (item) => {
    if (expanded.includes(item)) {
      setExpanded(expanded.filter((el) => el !== item));
    } else {
      setExpanded([...expanded, item]);
    }
  };

  return (
    <Wrapper>
      <Container>
        <AdaptiveText
          xl={{ size: 36, height: 46 }}
          xs={{ size: 36, height: 46 }}
          fontFamily="Greenwich"
          weight={600}
        >
          Bookings
        </AdaptiveText>
        <Filter statuses={statuses} managers={admins} />
        <Table
          desktopOnly
          withBorders
          columns={getColumns(admins, statuses, expand, expanded)}
          expanded={expanded}
          detailed
          detailComponent={(detailData) => (
            <Details detailData={detailData} statuses={dayStatuses} />
          )}
          sorting="createDate"
          sortingOrder="desc"
          params={{
            ...(status ? { status } : {}),
            ...(managerId ? { managerId } : {}),
            ...(fromDate
              ? { fromDate: moment(fromDate).utcOffset(0, true).toISOString() }
              : {}),
            ...(toDate
              ? { toDate: moment(toDate).utcOffset(0, true).toISOString() }
              : {}),
          }}
          watchFields={[status, managerId, fromDate, toDate]}
          api={getWorkstationBookings}
        />
      </Container>
    </Wrapper>
  );
};

export default BookingList;
