import styled from "@emotion/styled";
import { colors } from "../../../../styles";

const TableWrapper = styled.div`
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${colors.white};
  border-radius: 20px 20px 0 0;
`;

export { TableHeader, TableWrapper };
