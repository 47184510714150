import styled from "@emotion/styled";
import { colors, vw } from "../../styles";

const Wrapper = styled.div`
  padding: 28px;
  margin-top: -48px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw(8)};
    margin-top: ${vw(-32)};
  }
`;

const PaddedBox = styled.div`
  padding: 4px 0 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("4 0 16")};
  }
`;

const LoginBlock = styled.div`
  padding: 32px 0;

  button {
    max-width: 100%;
    width: 100%;
    font-size: 26px;
    line-height: 32px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("32 0")};

    button {
      font-size: ${vw(18)};
      line-height: ${vw(18)};
    }
  }
`;

const StyledLink = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: ${colors.pink4};
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: ${vw(16)};
    line-height: ${vw(19)};
  }

  &:hover {
    color: ${colors.pink2};
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(14)};
    margin-bottom: ${vw(8)};
  }
`;

const ToggleWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(12)};
    margin-bottom: ${vw(24)};
  }
`;

export {
  Wrapper,
  PaddedBox,
  LoginBlock,
  StyledLink,
  FieldsWrapper,
  ToggleWrapper,
};
