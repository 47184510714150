import ReactPhoneInput from "react-phone-input-2";
import classes from "./phoneInput.module.scss";
import "react-phone-input-2/lib/style.css";
import "./generalPhoneInput.scss";
import { ExplanationIcon } from "@components";
import { vw } from "../../styles";
import { useMediaQuery, useTheme } from "@mui/material";

type PropsType = {
  text?: string;
  name?: string;
  value?: string;
  onChange?: (value: string, e: any) => void;
  placeholder?: string;
  className?: string;
  require?: boolean;
  id?: number;
  err?: string;
  info?: string;
  disabled?: boolean;
};

const DEFAULT_COUNTRY_CODE = "nl";

export const CustomPhoneInput = ({
  value,
  text, name,
  require,
  err,
  info,
  onChange,
  ...restProps
}: PropsType) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const borderColor = err && "1px solid red";
  const container_class = isMobile
    ? {
        height: vw(60),
        borderRadius: vw(8),
        border: borderColor,
      }
    : {
        height: "60px",
        borderRadius: "8px",
        border: borderColor,
      };
  const button_class = isMobile
    ? {
        border: "none",
        backgroundColor: "#FFFFFF",
        padding: vw(2),
        borderRadius: vw(8),
      }
    : {
        border: "none",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        padding: "2px",
      };
  const input_class = isMobile
    ? {
        fontSize: vw(20),
        borderRadius: vw(8),
        border: "none",
        backgroundColor: "#FFFFFF",
        width: "100%",
        height: "100%",
        fontFamily: "Greenwich",
        fontStyle: "normal",
        fontWeight: "normal",
      }
    : {
        border: "none",
        backgroundColor: "#FFFFFF",
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        fontFamily: "Greenwich",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "20px",
      };

  const labelStyle = err ? "#E23600" : "#121212";

  const onChangeHandler = (value, e) => {
    onChange(value, e);
  };

  return (
    <div className={classes.main_wrapper} id={"div_"+name}>
      <div className={classes.input_wrapper}>
        <label style={{ color: labelStyle }} className={classes.label_wrapper}>
          <div className={classes.text_icon_wrapper}>
            {text}
            {info !== "" && info && <ExplanationIcon text={info} />}
          </div>
          {require && <span className="req_sign">*</span>}
        </label>
        <ReactPhoneInput
          country={DEFAULT_COUNTRY_CODE}
          value={value}
          onChange={onChangeHandler}
          containerStyle={container_class}
          inputStyle={input_class}
          buttonStyle={button_class}
          {...restProps}
        />
      </div>
      {err !== "" && <div className={classes.err_text}>{err}</div>}
    </div>
  );
};
