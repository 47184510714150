import { ReactComponent as Expand } from "../../assets/icons/expand_icon.svg";
import styled from "@emotion/styled";
import { vw } from "../../styles";
import { Accordion } from "@mui/material";

const ExpandIcon = styled(Expand)`
  width: 28px;
  height: 28px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(20)};
    height: ${vw(20)};
  }
`;

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: none;
  }
`;

export { ExpandIcon, StyledAccordion };
