import { useEffect, useState } from "react";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { Button } from "@mui/material";
import classes from "./ProfileAdministratorForm.module.scss";
import { STATUSES } from "../../../../../constants/common.consts";
import { getFormattedMasterProfileForBackend } from "../../../../../utils/utils";
import { updateMasterProfileData } from "../../../../../services/masterApi";
import setFormDataToStorage from "../../Stepper/services/setFormDataToStorage";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

const statusOptions = Object.values(STATUSES).map((status) => {
  return { value: status, label: status };
});

const DEFAULT_ERROR_STATE = {
  statusSelect: "",
};

export const ProfileAdministratorForm = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();

  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setMasterProfileFormData({
      ...masterProfileFormData,
      [name]: value,
    });
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const updatedMasterProfile = await updateMasterProfileData(
        getFormattedMasterProfileForBackend(
          masterProfileFormData,
          userId,
          auth
        ),
        userId
      );

      navigate(`/profile/${userId}`);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [masterProfileFormData]);

  const isFieldsValid = () => {
    let isError = false;

    return !isError;
  };

  const submitCreateMode = async () => {
    setFormDataToStorage(masterProfileFormData);

    navigate(location, {
      state: {
        activeStep: 1,
      },
    });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!userId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedProfileUrl"));
  };

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        setIsLoading(true);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchDataOptions();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div>
      <h2 className={`page_main_title`}>Administrator</h2>
      <form onSubmit={handleSubmit} noValidate>
        <div className={`form_group`}>
          <div className={`inputs_group ${classes.status_select}`}>
            <MySelect
              name="statusSelect"
              text="Status"
              value={masterProfileFormData.statusSelect}
              options={statusOptions}
              onChange={handleSelectInputChange}
              placeholder="Select one option"
              err={errs.statusSelect}
            />
          </div>
        </div>
        <div className={"btn_bottom_wrapper"}>
          <>
            <Button
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={onCancelHandler}
            >
              cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disableTouchRipple
              disableFocusRipple
            >
              Submit
            </Button>
          </>
        </div>
      </form>
    </div>
  );
};
