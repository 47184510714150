import styled from "@emotion/styled";
import { vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    max-width: 62px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(10)};

    img {
      max-width: ${vw(62)};
    }
  }
`;

export { Wrapper };
