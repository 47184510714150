import styled from "@emotion/styled";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid
    ${(props) => (props.isLast ? colors.blu2 : colors.blu1)};
  background-color: ${colors.white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: Greenwich, serif;
  ${(props) =>
    props.isFirstItem && props.isFirst && `border-radius: 20px 20px 0 0;`};
  ${(props) =>
    props.isLastItem &&
    props.isLast &&
    `border: none; border-radius: 0 0 20px 20px;`};

  ${(props) => props.theme.breakpoints.down("sm")} {
    border-width: ${vw(2)};
    font-size: ${vw(14)};
    line-height: ${vw(16)};

    ${(props) =>
      props.isFirstItem &&
      props.isFirst &&
      `border-radius: ${vw("20 20 0 0")};`};
    ${(props) =>
      props.isLastItem &&
      props.isLast &&
      `border: none; border-radius: ${vw("0 0 20 20")};`};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 35%;
  height: 48px;
  padding: 0 16px;
  color: ${colors.grey2};
  border-right: 2px solid ${colors.blu1};

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(48)};
    padding: ${vw("0 16")};
    border-width: ${vw(2)};

    & button {
      svg {
        width: ${vw(18)};
        height: ${vw(18)};
      }
    }
  }
`;

const Data = styled.div`
  display: flex;
  align-items: center;
  width: 35%;
  height: 48px;
  padding: 16px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(48)};
    padding: ${vw("0 16")};
  }
`;

export { Wrapper, Row, Title, Data };
