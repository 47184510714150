import styled from "@emotion/styled";
import { colors, vw } from "../../../../../styles";
import { alpha, IconButton } from "@mui/material";

const WorkStation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  border: 2px solid ${colors.white};
  border-radius: 20px;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    border-radius: ${vw(20)};
    border-width: ${vw(2)};
  }
`;

const WorkStationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 40px 16px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: ${vw(24)};
    padding: ${vw("40 16")};
  }
`;

const WorkStationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WorkStationTitle = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  
  margin-right: 70px;

  ${(props) => props.theme.breakpoints.down("md")} {
    margin-right: 30px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${vw(200)};
    margin-right: ${vw(30)};
  }
`;

const WorkstationControls = styled.div`
  //height: 246px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  padding: 21px;
  background-color: ${alpha(colors.white, 0.3)};

  ${(props) => props.theme.breakpoints.down("md")} {
    gap: 0;
    padding: 0;
    height: 70px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(70)};
  }
`;

const ActionButton = styled(IconButton)`
  width: 40px;
  height: 40px;

  &:hover {
    background-color: ${alpha(colors.white, 0.5)};
  }

  & svg {
    width: 28px;
    height: 28px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    top: ${vw(8)};
    right: ${vw(8)};

    & svg {
      width: ${vw(28)};
      height: ${vw(28)};
    }
  }
`;

const Append = styled.div`
  margin-top: 24px;
  margin-bottom: 95px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    opacity: 0.8;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(24)};
    margin-bottom: ${vw(95)};
    gap: ${vw(8)};
  }
`;

export {
  WorkStation,
  WorkStationInfo,
  WorkstationControls,
  WorkStationRow,
  WorkStationTitle,
  ActionButton,
  Append,
};
