import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

import {
  Wrapper,
  Title,
  Advantages,
  AdvantageWrapper,
  AdvantageIcon,
  StyledButton,
  StyledLink,
} from "./why-block-styles";

import { ReactComponent as Advantage1 } from "../../../../assets/icons/benefit_1.svg";
import { ReactComponent as Advantage2 } from "../../../../assets/icons/benefit_2.svg";
import { ReactComponent as Advantage3 } from "../../../../assets/icons/benefit_3.svg";
import { ReactComponent as Advantage4 } from "../../../../assets/icons/benefit_4.svg";
import { ReactComponent as Advantage5 } from "../../../../assets/icons/benefit_5.svg";
import { ReactComponent as Advantage6 } from "../../../../assets/icons/benefit_6.svg";
import { MdOutlineArrowForward } from "react-icons/md";
import { AdaptiveText, Carousel } from "../../../../components";
import { TitleDivider } from "../../../../components/v2/title-divider";
import { useNavigate } from "react-router-dom";

const advantagesData = [
  {
    icon: Advantage1,
    title: "A Salon You’ll Love",
    description: (
      <span>
        Choose a salon or salons
        <br />
        that match your vibe
      </span>
    ),
  },
  {
    icon: Advantage2,
    title: "Build your clientele",
    description: (
      <span>
        We offer training to deliver
        <br />
        better customer experience together
      </span>
    ),
  },
  {
    icon: Advantage3,
    title: "Make More Money",
    description: (
      <span>
        Make double what you would
        <br />
        in a salon, doing what you love
      </span>
    ),
  },
  {
    icon: Advantage4,
    title: "You’re The Boss",
    description: (
      <span>
        Choose how you work. Search for
        <br />a salon space near you or your clients
      </span>
    ),
  },
  {
    icon: Advantage5,
    title: "Join the Community",
    description: (
      <span>
        Join a community of like-minded, talented
        <br />
        professionals. Registration is always free
      </span>
    ),
  },
  {
    icon: Advantage6,
    title: "WORK ON THE GO",
    description: (
      <span>
        Travel the world and make more money.
        <br />
        No more long-term rental contracts
      </span>
    ),
  },
];

const WhyBlock = () => {
  const navigate = useNavigate();

  const joinNow = () => {
    navigate("register_modal");
  };

  return (
    <Wrapper>
      <Title>
        <AdaptiveText
          xl={{ size: 44, height: 56 }}
          xs={{ size: 30, height: 38 }}
          sm={{ size: 30, height: 38 }}
          weight={600}
          align="center"
          fontFamily="Greenwich SemBd"
          uppercase
        >
          Why VERVVEY?
        </AdaptiveText>
      </Title>
      <TitleDivider />
      <Typography variant="subtitle1" textAlign="center">
        More control. More flexibility. More income
      </Typography>
      <Advantages>
        <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
          {advantagesData.map((item) => {
            return (
              <Grid item xs={12} md={6} xl={4} key={item.title}>
                <AdvantageWrapper>
                  <AdvantageIcon>
                    {React.createElement(item.icon)}
                  </AdvantageIcon>
                  <Typography variant="h3" textAlign="center">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    {item.description}
                  </Typography>
                </AdvantageWrapper>
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Carousel
            autoplay
            showSlides={{ xs: 1 }}
            slides={advantagesData.map((item) => {
              return (
                <AdvantageWrapper key={item.title}>
                  <AdvantageIcon>
                    {React.createElement(item.icon)}
                  </AdvantageIcon>
                  <Typography variant="h3" textAlign="center">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" textAlign="center">
                    {item.description}
                  </Typography>
                </AdvantageWrapper>
              );
            })}
          ></Carousel>
        </Box>
      </Advantages>

      <AdaptiveText
        xs={{ size: 16, height: 17 }}
        xl={{ size: 24, height: 30 }}
        fontFamily="Greenwich ExtLt"
        weight={100}
        align="center"
      >
        Find rent-by-the-day salon bookings that work for you
      </AdaptiveText>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={joinNow}
        disableRipple
      >
        Join now
      </StyledButton>
      <AdaptiveText
        xs={{ size: 16, height: 20 }}
        xl={{ size: 24, height: 30 }}
        fontFamily="Greenwich SemBd"
        weight={600}
        align="center"
      >
        BONUS! Free profile page for social media marketing of your work
      </AdaptiveText>
      <StyledLink
        href="profile/f6fb2024-5c8c-4451-901c-534c8df78147"
        target="_blank"
      >
        See how it looks
        <MdOutlineArrowForward />
      </StyledLink>
    </Wrapper>
  );
};

export default WhyBlock;
