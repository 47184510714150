import artist1 from "../assets/images/artist_1.webp";
import artist2 from "../assets/images/artist_2.webp";
import artist3 from "../assets/images/artist_3.webp";
import artist4 from "../assets/images/artist_4.webp";
import artist5 from "../assets/images/artist_5.webp";
import artist6 from "../assets/images/artist_6.webp";
import artist7 from "../assets/images/artist_7.webp";
import artist8 from "../assets/images/artist_8.webp";
import artist9 from "../assets/images/artist_9.webp";

export const DEFAULT_ERROR_TEXT_MESSAGE = "This field is required";
export const DEFAULT_ERROR_FILE_SIZE_TEXT_MESSAGE = "Max image size is 1.2MB";
export const DEFAULT_FILE_SIZE_LIMIT_KB = 1200;
export const INITIAL_MASTER_PROFILE_FORM_DATA = {
  // Professional profile
  nickname: "",
  resume: "",
  citySelect: "",
  experienceSelect: "",
  vibeSelect: null,
  languagesMultiSelect: [],
  profilePhoto: null,
  // Services you offer
  services: [],
  radioBookingDirectlyWithYou: "No",
  contactNumberForBooking: "",
  linkToYourSocialMedia: "",
  //Your portfolio
  works: [],
  //Professional trainings
  certificates: [],
  // Personal Profile
  interestsCheckboxesOutSideWork: [],
  countrySelect: "",
  starSignsSelect: "",
  maritalSelect: "",
  haveKidsSelect: "",
  educationSelect: "",
  smokeSelect: "",
  religionSelect: "",
  covidStatusSelect: "",
  descriptionSelect: "",
  describeYourselfSelect: "",
  // Personal details
  name: "",
  surname: "",
  birthdate: "2022-02-17T22:29:21.690Z",
  socialProfile: "",
  email: "",
  mobilePhone: "",
  personalNote: "",
  eligible: false,
  eligibilityId: "",
  kvkNumber: "",
  // status for Super Admin field
  statusSelect: "approvement",
};

export const INITIAL_SALON_FORM_DATA = {
  // HostInformation
  name: "",
  surname: "",
  role: "",
  mobile: "",
  email: "",
  password: "",
  confirmPassword: "",
  // Salon Information
  salonName: "",
  citySelect: "",
  address: {
    street: "",
    number: "",
    postCode: "",
  },
  salonPhoneNumber: "",
  website: "",
  socialNetwork: "",
  artist: "",
  hearAbout: "",
  banking: {
    bankingId: "",
    benefitName: "",
    accountNumber: "",
  },
  // Working Hours
  sameOpeningHours: false,
  showInFeaturedSalons: false,
  weekWorkingHours: [
    {
      weekDay: 1,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
    {
      weekDay: 2,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
    {
      weekDay: 3,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
    {
      weekDay: 4,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
    {
      weekDay: 5,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
    {
      weekDay: 6,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
    {
      weekDay: 7,
      opened: true,
      from: "9:00",
      to: "21:00",
    },
  ],
  salonHoursAvailability: {
    sAvailId: 1,
    label: "No",
    value: "No",
    disabled: false,
  },
  // Salon Concept
  vibe: "",
  languagesMultiSelect: "",
  salonDescription: "",
  services: "",
  teamwork: "",
  branding: "",
  mainPhoto: null,
  galleryWorks: [],
  // Salon amenities & coworking expectations
  checkboxesAmenitiesExpectations: [],
  amenitiesDescription: "",
  coworkingCollaborationCheckbox: false,
  specificInformation: "",
  specificInformationCheckbox: false,

  // Workstations rentals, terms & pricing
  workstations: [],
  // status for Super Admin field
  statusSelect: "approvement",
};

export const mobileWidth = 480;

export const ROLES = {
  MASTER: "master",
  SALON_ADMIN: "salon-admin",
  SUPER_ADMIN: "admin",
};

export const STATUSES = {
  APPROVEMENT: "approvement",
  ACTIVE: "active",
  BLOCKED: "blocked",
  DELETED: "deleted",
};

export const DEFAULT_PASSWORD_LENGTH = 8;

export const GA_MEASUREMENT_ID = "G-NYHWGGQH8T"

export const IS_IN_PROD = process.env.REACT_APP_ENV === "prod" ? true : false;

export const artistsData = [
  {
    id: 1,
    image: artist1,
    name: "Peter Van den Berg",
    description:
        "Being an independent stylist renting a chair is a very safe and risk-free way for me to work right now. I know that both me and my clients will feel comfortable because all the salons on VERVVEY have been pre-screened. Very positive experience and much safer than I expected.",
  },
  {
    id: 2,
    image: artist2,
    name: "Jolanda Westwood",
    description:
        "I often don’t need a full day as I’m not having that many clients yet. What I like is flexibility to book. I can book a few hours here and there to make sure I’m making money while I’m there and not wasting any money with downtime. It’s been great to keep my schedule really lean.",
  },
  {
    id: 3,
    image: artist3,
    name: "Laurent Maitre ",
    description:
        "You can be a hair entrepreneur without having to put up investments of building your own salon. What is more exciting, you can enjoy variety of salon vibes and meeting new people, when you are renting chair with VERVVEY.",
  },
  {
    id: 4,
    image: artist4,
    name: "Polina Kalinina",
    description:
        "When I got independent, I was expecting to lose some clients, but I was wrong. My clients were excited to follow me, and often we are ending up meeting in a salon much closer to them. I find my clients are much more engaged with me now.",
  },
  {
    id: 5,
    image: artist5,
    name: "Erik  Scholte",
    description:
        "I’ve gone back and forward between different options over my career. I worked in commission-based salons, I rented a chair in salons, I owned salons, I worked as a freelancer visiting clients’ homes. One thing I like is change. It’s definitely not for everyone, but there is so much power you get from being independent.",
  },
  {
    id: 6,
    image: artist6,
    name: "Isabella Girardello",
    description:
        "It’s been great to become independent both financially and personally. I used VERVVEY profile page as my demo website to share with the clients and spread the word. It helped to build new clientele.",
  },
  {
    id: 7,
    image: artist7,
    name: "Dior Diop",
    description:
        "Chair rental experience has never been easier and safer. You can rent a chair quickly, easily, contract-free, no deposits, and have a customer-support by your side if you need it.",
  },
  {
    id: 8,
    image: artist8,
    name: "Marnix Bessem",
    description:
        "Being independent as a stylist you are so much more powerful putting a client in a chair. Renting a chair can be daunting, but with VERVVEY I was able to sign up immediately and find the salon that matches my vibe, and now I’m meeting my clients in the comfort of the salon. ",
  },
  {
    id: 9,
    image: artist9,
    name: "Marjolein Visser",
    description:
        "When you have freedom and independence, you get to do things the way you want to and rediscover the pleasure of being an artist. And when you keep 100% of every euro you earn, you can make the same by seeing less clients. When I became independent, I re-found myself. I dropped down my clients, I dropped down my time, I dropped down my place.",
  },
];
