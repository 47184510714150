import React from "react";
import classes from "./notification1.page.module.scss";
import NotificationHeader from "./components/notificationHeader";
import NotificationFooter from "./components/notificationFooter";

const Notification1Page = () => {
  return (
    <div className={classes.notification_wrapper}>
      <NotificationHeader />
      <div className={classes.body}>
        <div className={classes.welcome_text}>
          <div>Hi!</div>
          <div>
            I’m pleased to welcome you as THE newest member of VERVVEY beauty
            coworking community!
          </div>
        </div>
        <div className={classes.bonus_text}>
          <div>BONUS!</div>
          <div>Get free profile page</div>
          <div>for social media marketing of your work!</div>
        </div>
        <div className={classes.complete_profile_wrapper}>
          <div className={classes.title}>
            Please complete your profile to get verified as a member, explore
            coworking opportunities with some of the coolest salons and have a
            customized experience on the platform
          </div>
          <button className={classes.complete_btn}>
            Complete your profile
          </button>
        </div>
        <div className={classes.with_love}>
          <div>
            <div>With love,</div>
            <div>Lana Filonava,</div>
            <div>Customer Happiness Officer</div>
          </div>
          <div>vervvey.com</div>
        </div>
      </div>
      <NotificationFooter />
    </div>
  );
};

export default Notification1Page;
