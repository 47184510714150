import {
  TableHead,
  Table as MuiTable,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Pagination } from "../pagination";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { StyledSpinner, SortButton, StyledTableCell } from "./table-styles";
import { ReactComponent as ArrowSorting } from "../../../assets/icons/arrow-sorting.svg";
import { colors } from "../../../styles";
import { Mobile } from "./mobile";

const Table: FC<any> = ({
  columns,
  sorting,
  sortingOrder,
  api,
  minWidth,
  params,
  watchFields = [],
  detailed,
  expanded,
  initialExpand,
  checkHighlightRow,
  detailComponent,
  withBorders,
  desktopOnly,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const sortBy = searchParams.get("sortBy") || sorting;
  const order = searchParams.get("order") || sortingOrder;

  useEffect(() => {
    setIsLoading(true);
    api({
      page: +page || "0",
      pageSize: 25,
      ...(sortBy ? { sortBy: sortBy } : {}),
      ...(order ? { order: order } : {}),
      ...params,
    }).then(({ page, itemsCount }) => {
      setData(page);
      if (initialExpand) {
        initialExpand(page.map((item) => item.id));
      }
      setTotal(itemsCount);
      setIsLoading(false);
      window.scrollTo(0, 0);
    });
  }, [page, sortBy, order, ...watchFields]);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const changeSorting = (columnName) => () => {
    const searchParams = createSearchParams({
      page: page || "0",
      sortBy: columnName,
      order: order === "asc" ? "desc" : "asc",
    }).toString();

    navigate({ search: searchParams });
  };

  const reload = () => {
    setIsLoading(true);
    api({
      page: +page || "0",
      pageSize: 25,
      ...(sortBy ? { sortBy: sortBy } : {}),
      ...(order ? { order: order } : {}),
      ...params,
    }).then(({ page, itemsCount }) => {
      if (initialExpand) {
        initialExpand(page.map((item) => item.id));
      }
      setData(page);
      setTotal(itemsCount);
      setIsLoading(false);
      window.scrollTo(0, 0);
    });
  };

  return (
    <>
      {isDesktop || desktopOnly ? (
        <MuiTable
          sx={{
            minWidth: minWidth || "1440px",
            overflow: "hidden",
            width: "100%",
            borderRadius: "20px",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column: any, idx) =>
                column.data === "action-component" ? (
                  <StyledTableCell
                    key={column.data}
                    width={column?.width}
                    last={idx + 1 === columns?.length}
                    withBorders={withBorders && !column.withoutBorder}
                  />
                ) : (
                  <StyledTableCell
                    key={column.data}
                    width={column?.width}
                    last={idx + 1 === columns?.length}
                    withBorders={withBorders && !column.withoutBorder}
                  >
                    {column.sortable ? (
                      <Stack
                        flexDirection="row"
                        flexWrap="nowrap"
                        alignItems="center"
                      >
                        {column.name}
                        <SortButton
                          disableTouchRipple
                          disableFocusRipple
                          active={column.data === sortBy}
                          order={order}
                          onClick={changeSorting(column.data)}
                        >
                          <ArrowSorting />
                        </SortButton>
                      </Stack>
                    ) : (
                      column.name
                    )}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns?.length}>
                  <StyledSpinner />
                </TableCell>
              </TableRow>
            ) : (
              data.map((item: any) => (
                <React.Fragment key={item.id}>
                  <TableRow
                    {...(checkHighlightRow && checkHighlightRow(item, data)
                      ? { style: { backgroundColor: colors.blu6 } }
                      : {})}
                  >
                    {columns.map((column: any, idx) =>
                      column.data === "action-component" ? (
                        <StyledTableCell
                          key={column.data}
                          width={column?.width}
                          last={idx + 1 === columns?.length}
                          withBorders={withBorders && !column.withoutBorder}
                        >
                          {column.getComponent(item)}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          key={column.data}
                          width={column?.width}
                          last={idx + 1 === columns?.length}
                          withBorders={withBorders && !column.withoutBorder}
                        >
                          {typeof column.formatter === "function"
                            ? column.formatter(item[column.data], item, reload)
                            : item[column.data]}
                        </StyledTableCell>
                      )
                    )}
                  </TableRow>
                  {detailed && (
                    <TableRow
                      style={{
                        display: !!expanded.includes(item.id)
                          ? "table-row"
                          : "none",
                      }}
                    >
                      <TableCell colSpan={columns?.length}>
                        {detailComponent && detailComponent(item)}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </MuiTable>
      ) : (
        <Mobile
          data={data}
          checkHighlightRow={checkHighlightRow}
          columns={columns}
          reload={reload}
          sortBy={sortBy}
          order={order}
          changeSorting={changeSorting}
        />
      )}
      <Pagination totalCount={total} pageSize={25} />
    </>
  );
};

export default Table;
