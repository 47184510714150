import styled from "@emotion/styled";
import { colors, vw } from "../../../styles";

interface Props {
  bg?: string;
}

const TitleDivider = styled.div<Props>`
  opacity: 0.3;
  height: 3px;
  width: 67%;
  margin-bottom: 8px;
  background-color: ${(props) => props.bg || colors.pink4};

  ${(props) => props.theme.breakpoints.down("lg")} {
    width: 88%;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${vw(3)};
    margin-bottom: ${vw(8)};
    width: 88%;
  }
`;

export default TitleDivider;
