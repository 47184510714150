import { SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState } from "react";
import {
  LeftButton,
  RightButton,
  SwiperContainer,
  StyledSwiper,
} from "./CustomSwiper-styles";

function SlideNextButton({ big }) {
  const swiper = useSwiper();

  return (
    <LeftButton
      onClick={() => swiper.slideNext()}
      size={big ? "large" : "medium"}
    >
      <ArrowForwardIcon fontSize={big ? "large" : "medium"} />
    </LeftButton>
  );
}

function SlidePrevButton({ big }) {
  const swiper = useSwiper();

  return (
    <RightButton
      onClick={() => swiper.slidePrev()}
      size={big ? "large" : "medium"}
    >
      <ArrowBackIcon fontSize={big ? "large" : "medium"} />
    </RightButton>
  );
}

interface CustomSwiperProps {
  showSlides: Record<string, number>;
  slides: React.ReactNode[];
  bigNavigation?: boolean;
  withDots?: boolean;
}

const CustomSwiper: React.FC<CustomSwiperProps> = (props) => {
  const calcSlide = () => {
    if (props.showSlides.xl && window.innerWidth > 1440) {
      return props.showSlides.xl;
    }

    if (props.showSlides.lg && window.innerWidth > 1000) {
      return props.showSlides.lg;
    }

    if (props.showSlides.md && window.innerWidth > 800) {
      return props.showSlides.md;
    }

    if (props.showSlides.sm && window.innerWidth > 480) {
      return props.showSlides.sm;
    }

    if (props.showSlides.xs) {
      return props.showSlides.xs;
    }

    return 1;
  };

  const [slides, setSlides] = useState(calcSlide());

  const handleResize = () => {
    setSlides(calcSlide());
  };

  window.addEventListener("resize", handleResize);

  return (
    <SwiperContainer>
      <StyledSwiper
        className={props.bigNavigation ? "swiper-wide" : ""}
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={
          props?.slides?.length < slides ? props?.slides?.length : slides
        }
        loop={props?.slides?.length > slides}
        autoplay={{
          delay: 5000,
        }}
        pagination={
          props?.slides?.length < slides
            ? false
            : props.withDots
            ? { clickable: true }
            : false
        }
      >
        {props.slides.map((slide, idx) => (
          <SwiperSlide
            style={{ display: "flex", justifyContent: "center" }}
            key={idx}
          >
            {slide}
          </SwiperSlide>
        ))}
        {props.slides?.length > slides && (
          <>
            <SlidePrevButton big={props.bigNavigation} />
            <SlideNextButton big={props.bigNavigation} />
          </>
        )}
      </StyledSwiper>
    </SwiperContainer>
  );
};

export default CustomSwiper;
