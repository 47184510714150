import { StyledMuiTable, StyledPagination } from "./pagination-styles";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TableBody, TableRow } from "@mui/material";

const Pagination = ({ totalCount, pageSize = 25 }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const changePage = (newPage) => {
    const params = createSearchParams({
      ...Object.fromEntries([...(searchParams as any)]),
      page: newPage,
    }).toString();

    navigate({
      search: params,
    });
  };

  return (
    <StyledMuiTable>
      <TableBody>
        <TableRow>
          <StyledPagination
            count={totalCount || 0}
            page={+page || 0}
            rowsPerPage={pageSize}
            onPageChange={(e, newPage) => {
              changePage(newPage);
            }}
          />
        </TableRow>
      </TableBody>
    </StyledMuiTable>
  );
};

export default Pagination;
