import React, { FC } from "react";
import { Stack } from "@mui/material";

interface Props {
  height?: string;
}

const Spinner: FC<Props> = ({ height }) => {
  return (
    <Stack
      minHeight={height || "800px"}
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <svg
        className="svg_loader"
        viewBox="0 0 100 100"
        width="10em"
        height="10em"
      >
        <path
          stroke="none"
          d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
          fill="#51CACC"
          transform="rotate(179.719 50 51)"
        ></path>
      </svg>
    </Stack>
  );
};

export default Spinner;
