import React from "react";
import classes from "./notification3.page.module.scss";
import NotificationHeader from "./components/notificationHeader";
import NotificationFooter from "./components/notificationFooter";

const Notification3Page = () => {
  return (
    <div className={classes.notification_wrapper}>
      <NotificationHeader />
      <div className={classes.body}>
        <div className={classes.welcome_text}>
          <div>Dear (имя),</div>
          <div>
            We would like to thank you for your time and interest in becoming a
            VERVVEY verified beauty artist and joining our beauty coworking
            community.
          </div>
          <div>
            Unfortunately, we have to share the news that after reviewing your
            application carefully and comparing it with our current
            requirements, we do not see any possibility for collaboration. We’re
            sorry it's not better news.
          </div>
          <div>
            But we never know what the future may hold! We will be happy to keep
            your records at hand should a better collaboration opportunity
            arise.
          </div>
        </div>
        <div className={classes.with_love}>
          <div>
            <div> Sincerely, </div>
            <div>The VERVVEY Team</div>
            <div>welcome@vervvey.com</div>
          </div>
        </div>
      </div>
      <NotificationFooter />
    </div>
  );
};

export default Notification3Page;
