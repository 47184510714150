import {
  StyledTextField,
  TextContainer,
  StyledButton,
} from "./comment-input-styles";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { updateBookingRequest } from "../../../../services/api";

const CommentInput = ({ defaultValue, data }) => {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const [value, setValue] = useState(defaultValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextContainer>
      <StyledTextField
        multiline
        minRows={1}
        onChange={onChange}
        value={value}
        endAdornment={
          initialValue !== value && (
            <StyledButton
              variant="contained"
              startIcon={<CheckIcon />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                updateBookingRequest({
                  rentId: data?.wsRentId,
                  comment: value,
                }).then(() => {
                  setInitialValue(value);
                });
              }}
            />
          )
        }
      />
    </TextContainer>
  );
};

export default CommentInput;
