import styled from "@emotion/styled";
import { vw } from "../../../../styles";

interface Props {
  img: string;
}

const Wrapper = styled.div`
  padding: 100px 0 44px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("60 0 30")};
  }
`;

const Card = styled.div<Props>`
  width: 100%;
  padding-bottom: 100%;
  border-radius: 12px;
  background: url(${(props) => props.img});
  background-size: contain;
`;

export { Wrapper, Card };
