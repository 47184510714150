import { AdaptiveText } from "../../components";
import React from "react";
import { Wrapper, ButtonContainer } from "./success-report-sent-styles";
import { Button } from "@mui/material";

const SuccessReportSent = ({ okCallback }) => {
  return (
    <Wrapper flexDirection="column">
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 18 }}
        weight={700}
        variant="body1"
        align="center"
      >
        Report has been sent to your e-mail
      </AdaptiveText>

      <ButtonContainer>
        <Button
          variant="contained"
          disableTouchRipple
          disableFocusRipple
          onClick={okCallback}
        >
          OK
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default SuccessReportSent;
