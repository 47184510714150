import styled from "@emotion/styled";
import { FormControlLabel } from "@mui/material";
import { vw } from "../../styles";

const StyledLabel = styled(FormControlLabel)`
  span {
    font-size: 20px;
    line-height: 20px;
    font-family: Greenwich, serif;

    ${(props) => props.theme.breakpoints.down("sm")} {
      font-size: ${vw(20)};
      line-height: ${vw(20)};
    }
  }

  .custom-checkbox-root .MuiSvgIcon-root {
    width: 20px;
    height: 20px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(20)};
      height: ${vw(20)};
    }
  }
`;

export { StyledLabel };
