import React, { useEffect, useState } from "react";
import photo1 from "../../../../assets/images/Mаn_1.webp";
import photo2 from "../../../../assets/images/Mаn_2.webp";
import photo3 from "../../../../assets/images/Mаn_3.webp";
import photo4 from "../../../../assets/images/Mаn_4.webp";
import photo5 from "../../../../assets/images/Woman_1.webp";
import photo6 from "../../../../assets/images/Woman_2.webp";
import photo7 from "../../../../assets/images/Woman_3.webp";
import photo8 from "../../../../assets/images/Woman_4.webp";
import photo9 from "../../../../assets/images/Woman_5.webp";

import {
  BigImage,
  Block,
  BottomImages,
  HeaderImages,
  HeaderWrapper,
  LittleImage,
  StyledButton,
  TitleWrapper,
  TopImages,
  Wrapper,
} from "./header-block-styles";
import { alpha, Stack } from "@mui/material";
import { AdaptiveText } from "../../../../components";
import { colors } from "../../../../styles";
import { useNavigate } from "react-router-dom";

const rotations = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
];

const shuffle = (array: string[]) => {
  return array.sort(() => Math.random() - 0.5);
};

const HeaderBlock = () => {
  const navigate = useNavigate();
  const [faceImages, setFaceImages] = useState<string[]>(rotations);

  const joinNow = () => {
    navigate("register_modal");
  };

  useEffect(() => {
    setFaceImages(shuffle([...rotations]));
  }, []);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Stack
          flexDirection={{ xs: "column-reverse", md: "row" }}
          justifyContent="space-between"
        >
          <TitleWrapper>
            <AdaptiveText
              xs={{ size: 30, height: 38 }}
              md={{ size: 40, height: 54 }}
              xl={{ size: 56, height: 70 }}
              weight={600}
              fontFamily="Greenwich SemBd"
              color={colors.white}
              uppercase
            >
              JOIN the biggest
              <br />
              beauty coworking
              <br />
              community
            </AdaptiveText>
            <StyledButton variant="contained" disableRipple onClick={joinNow}>
              Join now
            </StyledButton>
          </TitleWrapper>
          <HeaderImages>
            <TopImages>
              <LittleImage
                width="100%"
                height="100%"
                src={faceImages[0]}
                alt=""
              />
              <Block color={colors.purple2} />
              <LittleImage
                width="100%"
                height="100%"
                src={faceImages[1]}
                alt=""
              />
              <Block color={alpha(colors.pink4, 0.4)} />
              <Block color={colors.purple3} hidden />
              <LittleImage
                width="100%"
                height="100%"
                src={faceImages[2]}
                alt=""
              />
            </TopImages>
            <BottomImages>
              <LittleImage
                width="100%"
                height="100%"
                src={faceImages[3]}
                alt=""
              />
              <Block color={colors.purple3} />
              <BigImage width="100%" height="100%" src={faceImages[4]} alt="" />
            </BottomImages>
          </HeaderImages>
        </Stack>

        <AdaptiveText
          xs={{ size: 20, height: 32 }}
          xl={{ size: 30, height: 38 }}
          weight={600}
          fontFamily="Greenwich SemBd"
          color={colors.white}
          uppercase
        >
          delivering better customer experience together
        </AdaptiveText>
      </HeaderWrapper>
    </Wrapper>
  );
};

export default HeaderBlock;
