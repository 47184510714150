import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "@services/authApi";
import { Grid } from "@mui/material";
import { TextInput } from "../../../../../form-units";
import {
  getDefaultValues,
  getValidation,
  getValidationPassword,
} from "./duck/utils";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@components/text";
import { Subtitle, ButtonsWrapper, FormButton, Wrapper } from "./duck/styles";
import PhoneInput from "../../../../../form-units/phone-input/phone-input";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { AuthActions } from "@redux/actions/authActions";
import { AppState } from "@redux/reducers/rootReducer";
import ReactGA from "react-ga4";

export const HostInformation = () => {
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const { auth } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (auth?.userId) {
      formProviderProps.reset(getDefaultValues(auth));
    }
  }, [auth]);

  const navigate = useNavigate();

  const { validationSchema, defaultValues } = auth?.userId
    ? getValidationPassword()
    : getValidation();

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: auth?.userId ? getDefaultValues(auth) : defaultValues,
    mode: "onBlur",
  });

  const onSubmit = async () => {
    try {
      const updateData = await authService.updateUser({
        ...formProviderProps.getValues(),
        role: "salon-admin",
        lang: "EN",
        userId: auth.userId,
      });

      authDispatch({ type: "SET_USER", payload: updateData });

      if (auth.salonId) {
        ReactGA.event({category: 'engagement', action: 'host_information_updated'});
        navigate(`/salon_profile/${auth.salonId}`);
      }
      else {
        ReactGA.event({category: 'engagement', action: 'host_information_added'});
        navigate("/edit_salons");
      }

    } catch (error) {
      return;
    }
  };

  return (
    <FormProvider {...formProviderProps}>
      <Wrapper onSubmit={formProviderProps.handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          flexDirection={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, sm: 4 }}
        >
          <Grid item xs={12}>
            <Text
              xs={{ size: 28, height: 40 }}
              sm={{ size: 36, height: 46 }}
              fontWeight={600}
            >
              Host information
            </Text>
            <Subtitle
              xs={{ size: 20, height: 23 }}
              sm={{ size: 18, height: 21 }}
              fontWeight={600}
            >
              To get started fill out the details of the host for collaboration
              with VERVVEY platform
            </Subtitle>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name={"name"}
              type={"text"}
              required
              placeholder="Jennifer"
              label="Host name"
              tooltip="First name of the key contact person for collaboration with VERVVEY"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name={"surname"}
              type={"text"}
              required
              placeholder="Pearson"
              label="Host surname"
              tooltip="Surname of the key contact person for collaboration with VERVVEY"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              name={"hostRole"}
              type={"text"}
              required
              placeholder="Owner"
              label="Host role"
              tooltip="Role of the key contact person for collaboration with VERVVEY"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              name={"mobilePhone"}
              required
              label="Host mobile number"
              tooltip="This number will be used by our support team in case of emergency"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonsWrapper>
              <FormButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </FormButton>
            </ButtonsWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    </FormProvider>
  );
};
