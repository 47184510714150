import { useEffect, useState } from "react";
import { MySelect } from "../../../../../components/mySelect/mySelect";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setSalonFormDataToStorage from "../../Stepper/services/setSalonFormDataToStorage";
import CustomSpinner from "../../../../../components/spinner/customSpinner";
import { getFormattedSalonFormDataForBEUpdateMode } from "../../../../../utils/utils";
import { updateSalonFormData } from "../../../../../services/salonsApi";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import classes from "./SalonAdministratorForm.module.scss";
import { STATUSES } from "../../../../../constants/common.consts";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";
import CustomCheckbox from "../../../../../components/checkbox/customCheckbox";
import { AdaptiveText } from "../../../../../components";

const statusOptions = Object.values(STATUSES).map((status) => {
  return { value: status, label: status };
});

const DEFAULT_ERROR_STATE = {
  statusSelect: "",
};

export const SalonAdministratorForm = () => {
  const { auth } = useSelector((state: AppState) => state);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { salonFormData, setSalonFormData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { salonId } = useParams();

  const handleSelectInputChange = (value, e) => {
    const name = e.name;

    setSalonFormData({
      ...salonFormData,
      [name]: value,
    });
  };

  const onCheckboxHandler = (name: string, e: any) => {
    setSalonFormData({
      ...salonFormData,
      [name]: e.target.checked,
    });
  };

  const submitUpdateMode = async () => {
    try {
      setError(null);

      const data = await updateSalonFormData(
        getFormattedSalonFormDataForBEUpdateMode(salonFormData, salonId, auth),
        salonId
      );

      navigate(`/salon_profile/${salonId}`);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setErrs(DEFAULT_ERROR_STATE);
  }, [salonFormData]);

  const isFieldsValid = () => {
    let isError = false;

    return !isError;
  };

  const submitCreateMode = async () => {
    setSalonFormDataToStorage(salonFormData);

    navigate(location, {
      state: {
        activeStep: 1,
      },
    });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    if (!isFieldsValid()) return;

    if (!salonId) {
      submitCreateMode();
    } else {
      submitUpdateMode();
    }
  }

  const onCancelHandler = () => {
    navigate(localStorage.getItem("memorizedSalonUrl"));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div>
      <h2 className={`page_main_title`}>Administrator</h2>
      <form onSubmit={handleSubmit} noValidate>
        <div className={`form_group`}>
          <div className={`inputs_group ${classes.status_select}`}>
            <MySelect
              name="statusSelect"
              text="Status"
              value={salonFormData.statusSelect}
              options={statusOptions}
              onChange={handleSelectInputChange}
              placeholder="Select one option"
              err={errs.statusSelect}
            />
          </div>
          <div className={`inputs_group ${classes.status_select}`}>
            <FormControlLabel
              control={
                <Checkbox
                  name="showInFeaturedSalons"
                  checked={salonFormData.showInFeaturedSalons}
                  onChange={(e) => onCheckboxHandler("showInFeaturedSalons", e)}
                />
              }
              label={
                <AdaptiveText
                  fontFamily="Greenwich"
                  xs={{ size: 20, height: 20 }}
                  xl={{ size: 20, height: 20 }}
                >
                  Show in Featured Salons
                </AdaptiveText>
              }
            />
          </div>
        </div>
        <div className={"btn_bottom_wrapper"}>
          {!salonId ? (
            <>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onCancelHandler}
              >
                cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
