import React from "react";
import NavDropdown from "react-bootstrap/esm/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";

export const MasterNavDesktopList = () => {
  const { auth } = useSelector((state: AppState) => state);
  const location = useLocation();

  const setUrlToLocalStorage = () => {
    localStorage.setItem("memorizedProfileUrl", location.pathname);
  };

  return (
    <>
      {/* Do not display in create master profile mode */}
      {auth?.name && (
        <Link to={`profile/${auth.userId}`}>
          <NavDropdown.Item as="button">My profile</NavDropdown.Item>
        </Link>
      )}

      <Link
        to={
          auth?.name
            ? `/profile_edit/professional_profile/${auth.userId}`
            : "/eligibility_check"
        }
        state={{ from: location.pathname }}
        onClick={setUrlToLocalStorage}
      >
        <NavDropdown.Item as="button">Edit profile</NavDropdown.Item>
      </Link>
      {/*<Link to={`salon_catalog`}>*/}
      {/*  <NavDropdown.Item as="button">View salons</NavDropdown.Item>*/}
      {/*</Link>*/}
      {/*<Link to={`bookings_list`}>*/}
      {/*  <NavDropdown.Item as="button">Bookings</NavDropdown.Item>*/}
      {/*</Link>*/}
    </>
  );
};
