import ReactPhoneInput from "react-phone-input-2";
import classes from "./phoneInputTable.module.scss";
import "react-phone-input-2/lib/style.css";
import { vw } from "../../../styles";
import { useMediaQuery, useTheme } from "@mui/material";

type PropsType = {
  value?: string;
};

export const PhoneInputTable = ({ value, ...restProps }: PropsType) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const container_class = isMobile
    ? {
        height: vw(60),
        borderRadius: vw(8),
      }
    : {
        height: "60px",
        borderRadius: "8px",
      };

  const button_class = {
    display: "none",
  };

  const input_class = isMobile
    ? {
        fontSize: vw(14),
        border: "none",
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        fontFamily: "Greenwich",
        fontStyle: "normal",
        fontWeight: "500",
        cursor: "auto",
        paddingLeft: "0px",
      }
    : {
        border: "none",
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        fontFamily: "Greenwich",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        cursor: "auto",
        paddingLeft: "0px",
      };

  return (
    <div className={classes.input_wrapper}>
      <ReactPhoneInput
        value={value}
        containerStyle={container_class}
        inputStyle={input_class}
        buttonStyle={button_class}
        disabled={true}
        {...restProps}
      />
    </div>
  );
};
