import { combineReducers } from "redux";
import authReducer from "./authReducer";
import SalonListReducer from "./salon-list-reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  salonList: SalonListReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
