import React from "react";
import { AdaptiveText } from "../../../../components";
import { Grid } from "@mui/material";
import { colors, vw } from "../../../../styles";
import {
  Wrapper,
  CardTitle,
  Countries,
  InfoCard,
  Sense,
  Vibe,
} from "./salon-info-styles";
import { FlagIcon } from "../../../profile/profile";

const SalonInfo = ({ salonProfile, countriesCodes }) => {
  return (
    <Wrapper>
      <Grid container spacing={{ xs: vw(12), sm: 1.5 }}>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: vw(12), sm: 1.5 }}>
            <Grid item xs={12} md={6}>
              <InfoCard>
                <CardTitle
                  xl={{ size: 28, height: 28 }}
                  xs={{ size: 28, height: 28 }}
                  weight={700}
                  fontFamily="Greenwich"
                >
                  Our vibe
                </CardTitle>
                <Vibe>
                  <img src={salonProfile?.vibe.imageUrl} alt="vibe" />
                  <AdaptiveText
                    xl={{ size: 24, height: 28 }}
                    xs={{ size: 24, height: 28 }}
                    weight={500}
                    fontFamily="Greenwich"
                    color={colors.pink4}
                    capitalize
                  >
                    {salonProfile?.vibe?.name}
                  </AdaptiveText>
                </Vibe>
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard>
                <CardTitle
                  xl={{ size: 28, height: 28 }}
                  xs={{ size: 28, height: 28 }}
                  weight={700}
                  fontFamily="Greenwich"
                >
                  Languages
                </CardTitle>
                <Countries>
                  {countriesCodes?.map(({ countryCode }) => {
                    return (
                      <FlagIcon
                        key={countryCode}
                        flagNationCode={countryCode}
                      />
                    );
                  })}
                </Countries>
              </InfoCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoCard>
            <CardTitle
              xl={{ size: 28, height: 28 }}
              xs={{ size: 28, height: 28 }}
              weight={700}
              fontFamily="Greenwich"
            >
              Sense of teamwork
            </CardTitle>
            <Sense>
              <img src={salonProfile?.senseOfTeamwork?.imageUrl} alt="sense" />

              <AdaptiveText
                xl={{ size: 18, height: 21 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
              >
                {salonProfile?.senseOfTeamwork.name}
              </AdaptiveText>
            </Sense>
          </InfoCard>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SalonInfo;
