import { useController, useFormContext } from "react-hook-form";
import React, { FC, useRef, useState } from "react";
import { get, range } from "lodash-es";
import {
  LabelContainer,
  StyledInputLabel,
} from "../../v2/components/dropwdown/dropdown-styles";
import { LabelTooltip } from "../../v2/components/label-tooltip";
import {
  StyledFormControl,
  StyledDatePicker,
  ExpandIcon,
  PrevButton,
  CalendarSelect,
  SelectWrapper,
  Row,
  InputWrapper,
} from "./date-input-styles";
import moment from "moment";
import { getMonth, getYear } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton } from "@mui/material";
import { ErrorMessage } from "../../v2/components/textarea/textarea-styles";

const DateInput: FC<any> = ({
  label,
  placeholder,
  defaultValue,
  name,
  required,
  tooltip,
}) => {
  const years = range(2020, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const [date, setDate] = useState(null);

  const error = get(errors, fieldName)?.message;

  const inputRef = useRef(null);

  const handleChange = (val) => {
    onChange(moment(val).format("DD/MM/YYYY"));
    setDate(val);
  };

  return (
    <StyledFormControl fullWidth>
      {label && (
        <LabelContainer
          onClick={() => {
            inputRef.current.setOpen(true);
            inputRef.current.input.focus();
          }}
        >
          <StyledInputLabel error={error}>
            {label}
            {tooltip && <LabelTooltip tooltip={tooltip} />}
          </StyledInputLabel>
          {required && "*"}
        </LabelContainer>
      )}
      <InputWrapper>
        <StyledDatePicker
          dateFormat="dd/MM/yyyy"
          name={name}
          error={error}
          placeholderText={placeholder}
          ref={inputRef}
          value={value}
          selected={date}
          onChange={handleChange}
          autoComplete="off"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Row>
              <PrevButton
                aria-label="prev"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                disableRipple
              >
                <ExpandIcon />
              </PrevButton>
              <SelectWrapper>
                <CalendarSelect
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) => {
                    changeMonth(months.indexOf(value));

                    onChange(
                      moment(date)
                        .set(
                          "month",
                          months.findIndex((item) => item === value)
                        )
                        .format("DD/MM/YYYY")
                    );
                  }}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CalendarSelect>
              </SelectWrapper>
              <SelectWrapper>
                <CalendarSelect
                  value={getYear(date)}
                  onChange={({ target: { value } }) => {
                    changeYear(value);
                    onChange(
                      moment(date)
                        .set("year", +value)
                        .format("DD/MM/YYYY")
                    );
                  }}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CalendarSelect>
              </SelectWrapper>
              <IconButton
                aria-label="prev"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                disableRipple
              >
                <ExpandIcon />
              </IconButton>
            </Row>
          )}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledFormControl>
  );
};

export default DateInput;
