import { object, string } from "yup";
import {
  DEFAULT_ERROR_TEXT_MESSAGE,
  DEFAULT_PASSWORD_LENGTH,
} from "../../../../../../constants/common.consts";

export const validationSchema = object({
  name: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  surname: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  hostRole: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  mobilePhone: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
});

export const validationPasswordSchema = object({
  name: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  surname: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  hostRole: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
  mobilePhone: string().required(DEFAULT_ERROR_TEXT_MESSAGE).default(""),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({}),
});

export const getValidationPassword = () => ({
  validationSchema: validationPasswordSchema,
  defaultValues: validationSchema.cast({}),
});

export const getDefaultValues: any = ({
  email,
  hostRole,
  name,
  role,
  surname,
  mobilePhone,
}) => {
  return {
    email,
    hostRole,
    name,
    role,
    surname,
    mobilePhone,
  };
};
