import React from "react";
import classes from "./notification4.page.module.scss";
import NotificationHeader from "./components/notificationHeader";
import NotificationFooter from "./components/notificationFooter";

const ColorLine = () => {
  return (
    <div className={classes.color_line}>
      <div className={classes.rectangle_left}></div>
      <div className={classes.red_line}></div>
      <div className={classes.rectangle_right}></div>
    </div>
  );
};

const Notification4Page = () => {
  return (
    <div className={classes.notification_wrapper}>
      <NotificationHeader />
      <div className={classes.body}>
        <div className={classes.welcome_text}>
          <div>Dear "name",</div>
          <div>
            We, at VERVVEY, would love to team up with you! We admire your
            background and skills and happy to confirm that your profile is
            verified on VERVVEY platform.
          </div>
        </div>
        <ColorLine />
        <div className={classes.profile_url_wrapper}>
          <div>
            <div>
              Use your profile page for social media marketing of your work:
            </div>

            <a className={classes.profile_url}>"ссылка на профиль"</a>
          </div>
        </div>
        <ColorLine />
        <div className={classes.bonus_text}>
          <div>BONUS!</div>
          <div>Log in to get your 1 hour trial</div>
          <div>with the first booking for free!</div>
        </div>
        <div className={classes.complete_profile_wrapper}>
          <div className={classes.title}>
            Ready to start coworking with the most interesting salons in the
            city? Get access to the list of the VERVVEY coworking spaces where
            you can meet your customers.
          </div>
          <button className={classes.complete_btn}>
            Get 1 hour free trial
          </button>
        </div>
        <div className={classes.best_process_text}>
          See you soon at your first VERVVEY coworking salon!
        </div>
        <div className={classes.with_love}>
          <div>
            <div>With love,</div>
            <div>Lana Filonava,</div>
            <div>Customer Happiness Officer</div>
          </div>
          <div>vervvey.com</div>
        </div>
      </div>
      <NotificationFooter />
    </div>
  );
};

export default Notification4Page;
