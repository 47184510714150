import React from "react";
import { Wrapper, Card } from "./gallery-block-styles";
import { Grid } from "@mui/material";
import gallery_1 from "../../../../assets/images/for_salon/gallery_1.webp";
import gallery_2 from "../../../../assets/images/for_salon/gallery_2.webp";
import gallery_3 from "../../../../assets/images/for_salon/gallery_3.webp";

const GalleryBlock = () => {
  return (
    <Wrapper>
      <Grid container spacing={3.5}>
        <Grid item xs={12} md={6} lg={4}>
          <Card img={gallery_1} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Card img={gallery_2} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          <Card img={gallery_3} />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default GalleryBlock;
