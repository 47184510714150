import React, { useEffect, useState } from "react";
import { getAllWorkstationOptions } from "../../../../services/salonsApi";

const getMappedWorkstationOptions = (options) => {
  const _options = options.map((option) => {
    return {
      workstationId: option.workstationId,
      workstationType: option.workstationType,
      value: option.workstationType,
      label: option.name,
    };
  });

  return _options;
};

export const useFetchWorkstationOptions = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [workstationOptions, setWorkstationOptions] = useState([]);

  useEffect(() => {
    const fetchWorkstationOptionsData = async () => {
      try {
        setIsLoading(true);

        const workstationOptions = await getAllWorkstationOptions();

        setWorkstationOptions(getMappedWorkstationOptions(workstationOptions));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    fetchWorkstationOptionsData();
  }, []);

  return workstationOptions;
};
