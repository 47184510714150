import "./artistsSlider.scss";
import { ReadMore } from "../../../../components/ReadMore/ReadMore";

export default function ArtistsSlide({ item }) {
  return (
    <div key={item.id} className="vv_card">
      <img className="card_photo" src={item.image} alt="vv_card_photo" />
      <div className="vv_card__name">{item.name}</div>
      <ReadMore>{item.description}</ReadMore>
    </div>
  );
}
