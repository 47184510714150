import styled from "@emotion/styled";
import { Tabs } from "@mui/material";
import { vw, colors } from "../../../../../styles";

const CreateProfileWrapper = styled.div`
  padding: 80px 80px 100px;
  background-color: ${colors.blu1};

  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -999;
  }

  ${(props) => props.theme.breakpoints.down("xl")} {
    padding: 40px 40px 100px;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 16px 20px 100px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("16 20 100")};
  }
`;

const TabsWrapper = styled.div`
  min-width: 272px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    min-width: 100%;
  }
`;

const ProgressTabs = styled(Tabs)`
  & .MuiTabs-scroller .MuiTabs-flexContainer {
    flex-direction: column;

    & button {
      text-transform: none;
      height: 28px;
      cursor: auto;

      ${(props) => props.theme.breakpoints.up("lg")} {
        padding: 8px 0;
        align-items: flex-start;
      }
    }

    ${(props) => props.theme.breakpoints.down("lg")} {
      flex-direction: row;
    }
  }
`;

export { CreateProfileWrapper, TabsWrapper, ProgressTabs };
