import { Button, OutlinedInput, TextField } from "@mui/material";
import { colors, vw } from "../../../../styles";
import styled from "@emotion/styled";

const TextContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 4px;
  width: 40px;
  height: 40px;
  padding: 10px;
  min-width: 40px;
  z-index: 1;

  & span {
    margin: 0;
  }
`;

const StyledTextField = styled(OutlinedInput)<any>`
  width: 100%;

  &.MuiOutlinedInput-root {
    padding: 14px 46px 14px 16px;
    border-radius: 8px;
    font-family: "Greenwich", serif;
    font-size: 20px;
    line-height: 20px;
    min-height: 50px;
    color: ${colors.accent};
    background-color: ${colors.white};

    & fieldset {
      top: 0;
      border: none;

      & legend {
        display: none;
      }
    }

    & textarea {
      min-height: 20px;
    }

    & input {
      padding: 0;
      height: 20px;
    }

    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid ${colors.blu4};
      }
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding: ${vw(20)} ${vw(16)};
      border-radius: ${vw(8)};
      font-size: ${vw(20)};
      line-height: ${vw(20)};
      min-height: ${vw(60)};

      & textarea {
        min-height: ${vw(20)};
      }

      & input {
        height: ${vw(20)};
      }
    }
  }
`;

export { StyledTextField, TextContainer, StyledButton };
