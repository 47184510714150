import { SalonListAction } from "../actions/salon-list-actions";

type ListState = Record<string, any>;

const initialState: ListState = {};

const SalonListReducer = (
  state: ListState = initialState,
  action: SalonListAction
) => {
  switch (action.type) {
    case "SET_SALON_LIST":
      return action.payload;
    case "SET_SALON_FILTER":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default SalonListReducer;
