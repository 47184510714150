import styled from "@emotion/styled";
import { vw } from "../../../../styles";

const Slide = styled.div`
  padding: 14px;

  img {
    max-width: 100%;
    height: 390px;
    border-radius: 20px;
    object-fit: cover;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: vw(14px);

    img {
      width: ${vw(390)};
      height: ${vw(390)};
      border-radius: ${vw(20)};
    }

    &.MuiIconButton-sizeLarge {
      max-width: 100%;
      height: 100%;
      border-radius: vw(20px);
    }
  }
`;

export { Slide };
