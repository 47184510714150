import React, { useEffect, useState } from "react";
import {
  Wrapper,
  FormButton,
  ButtonsWrapper,
  FieldWrapper,
  InfoWrapper,
} from "./eligibility-check-styles";
import { Grid } from "@mui/material";
import { Text } from "@components/text";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import {getInsurances, getWorkEligibility, presaveMasterProfileData} from "@services/masterApi";
import { Select, TextInput } from "../../form-units";
import { getValidation } from "./duck/utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@contexts/AuthContext";
import { AdaptiveText } from "@components";
import { colors } from "../../styles";
import ReactGA from "react-ga4";
import {CustomPhoneInput} from "@components/phoneInput/phoneInput";
import {DEFAULT_ERROR_TEXT_MESSAGE} from "../../constants/common.consts";
import {useSelector} from "react-redux";
import {AppState} from "@redux/reducers/rootReducer";

const DEFAULT_ERROR_STATE = {
  mobilePhone: "",
};

const EligibilityCheck = () => {
  const navigate = useNavigate();
  const [eligibilities, setEligibilities] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [errs, setErrs] = useState(DEFAULT_ERROR_STATE);

  const { validationSchema, defaultValues } = getValidation();
  const { masterProfileFormData, setMasterProfileFormData } = useAuth();
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: "onBlur",
  });
  const { auth } = useSelector((state: AppState) => state);

  //const withoutKvk = formProviderProps.watch("withoutKvk");

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("user")) || null;
    if (auth?.eligible === 1) {
      navigate(`/profile_edit/professional_profile/${auth.userId}`);
    }
    else {
      masterProfileFormData.eligible = 1;

      setMasterProfileFormData({
        ...masterProfileFormData,
      });
      navigate("/profile_edit", {
        state: {
          activeStep: 0,
        },
      });
    }


    if (masterProfileFormData.kvkNumber) formProviderProps.setValue("kvkNumber", masterProfileFormData.kvkNumber);
    if (masterProfileFormData.eligible) formProviderProps.setValue("eligible", masterProfileFormData.eligible);
    if (masterProfileFormData.eligibilityId) formProviderProps.setValue("eligibilityId", masterProfileFormData.eligibilityId);
    if (masterProfileFormData.mobilePhone) formProviderProps.setValue("mobilePhone", masterProfileFormData.mobilePhone);
  }, [masterProfileFormData]);

  useEffect(() => {
    const fetchWorkEligibility = async () => {
      try {
        const data = await getWorkEligibility();

        setEligibilities(data);
      } catch (error) {}
    };
    const fetchInsuranceTypes = async () => {
      try {
        const data = await getInsurances();

        setInsurances(data);
      } catch (error) {}
    };

    fetchWorkEligibility();
    fetchInsuranceTypes();
  }, []);

  const onSubmit = () => {
    const values = formProviderProps.getValues();

    if (!masterProfileFormData.mobilePhone) {
      setErrs((prevState) => {
        return { ...prevState, mobilePhone: DEFAULT_ERROR_TEXT_MESSAGE };
      });
      return false;
    }

    if (!values.kvkNumber || values.kvkNumber === "") {
      values.withoutKvk = true;
      values.kvkNumber = null;
    } else {
      values.withoutKvk = false;
    }


    masterProfileFormData.email = auth.email;

    setMasterProfileFormData({
      ...masterProfileFormData,
      ...values,
    });


    try {
      presaveMasterProfileData({
        ...masterProfileFormData,
      });
    } catch (e) {}

    ReactGA.event({category: 'engagement', action: 'eligibility_check_submit'});
    navigate("/profile_edit", {
      state: {
        activeStep: 0,
      },
    });
  };

  return (
    <FormProvider {...formProviderProps}>
      <Wrapper onSubmit={formProviderProps.handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          flexDirection={{ xs: "column", lg: "row" }}
          spacing={{ xs: 2, sm: 4 }}
        >
          <Grid item xs={12}>
            <Text
              xs={{ size: 28, height: 40 }}
              sm={{ size: 36, height: 46 }}
              fontWeight={600}
            >
              Eligibility check
            </Text>
            <InfoWrapper>
              <AdaptiveText
                xl={{ size: 20, height: 23 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
                weight={700}
                color={colors.blu4}
              >
                We create a transparent & secure coworking platform connecting
                Salon Owners & Beauty Artists to share salon infrastructure,
                thus this check. Please confirm:
              </AdaptiveText>{" "}
              <AdaptiveText
                xl={{ size: 20, height: 23 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
                weight={700}
                color={colors.blu4}
              >
                - your contact mobile number (this is for internal use only, we never share your personal details externally, but require them for verification of your profile);
                <br />
                ⁃ that you are eligible to work in the Netherlands;
                <br />
                ⁃ that as a freelancer you have registration at the Chamber of
                Commerce (Kamer van Koophandel, KVK);
                <br />⁃ that you have a liability insurance linked to your
                services (recommended);
              </AdaptiveText>
            </InfoWrapper>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Switch*/}
          {/*    name="eligible"*/}
          {/*    label="I confirm that I’m eligible to work in the Netherlands"*/}
          {/*    tooltip="In order to be able to book workspaces in the salons it is critical that you are eligible to work in the country, i.e. have the legal working permit"*/}
          {/*    required*/}
          {/*    off="No"*/}
          {/*    on="Yes"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <FieldWrapper>
            <CustomPhoneInput
                text={"Contact mobile number"}
                name="mobilePhone"
                value={masterProfileFormData.mobilePhone}
                require
                onChange={(value) =>
                    setMasterProfileFormData({
                      ...masterProfileFormData,
                      mobilePhone: value,
                    })
                }
                err={errs.mobilePhone}
                info="This number will be used by our support team in case of emergency"
            />
            </FieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <FieldWrapper>
              <Select
                name="eligibilityId"
                options={eligibilities.map((item) => {
                  return { value: item.eligibilityId, label: item.name };
                })}
                required
                placeholder="Select one option"
                tooltip="Please provide the basis for your work eligibility"
                label="I’m eligible to work in the Netherlands based on:"
              />
            </FieldWrapper>
          </Grid>
          {/*{!withoutKvk && (*/}
            <Grid item xs={12}>
              <FieldWrapper>
                <TextInput
                  name="kvkNumber"
                  type="tel"
                  placeholder="12345678"
                  label="Please provide your KvK registration number"
                  tooltip="Please provide your registration number at the Chamber of Commerce (Kamer van Koophandel)"
                />
              </FieldWrapper>
            </Grid>
          {/*)}*/}
          {/*<Grid item xs={12}>*/}
          {/*  <Checkbox*/}
          {/*    name="withoutKvk"*/}
          {/*    label="I don't have KvK registration number yet"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <FieldWrapper>
              <Select
                name="insured"
                options={insurances.map((item) => {
                  return { value: item.type, label: item.name };
                })}
                placeholder="Select one option"
                label="Do you have liability insurance?"
              />
            </FieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <ButtonsWrapper>
              <FormButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </FormButton>
            </ButtonsWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    </FormProvider>
  );
};

export default EligibilityCheck;
