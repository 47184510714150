import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import classes from "./CustomTable.module.scss";
import CustomSpinner from "../spinner/customSpinner";
import { TablePaginationActions } from "./components/TablePaginationActions";
import { TableBodyContent } from "./components/TableBodyContent";
import { TableHeadContent } from "./components/TableHeadContent/TableHeadContent";

export interface Data {
  id: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  createDate: string;
}

export type Order = "asc" | "desc";

export const CustomTable = ({
  tableList,
  page,
  statusSort,
  setStatusSort,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isLoading,
  itemsCount,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  columnCells,
}) => {
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemsCount) : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ borderBottom: "none", borderRadius: "16px" }}
    >
      <Table
        sx={{ minWidth: 500, minHeight: 300 }}
        aria-label="custom pagination table"
      >
        <TableHeadContent
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          statusSort={statusSort}
          setStatusSort={setStatusSort}
          columnCells={columnCells}
          setPage={setPage}
        />
        <TableBody>
          <>
            {isLoading ? (
              <TableRow style={{ height: 60 * emptyRows }}>
                <TableCell colSpan={6}>
                  <CustomSpinner className={classes.spinner} />
                </TableCell>
              </TableRow>
            ) : (
              <TableBodyContent
                tableList={tableList}
                emptyRows={emptyRows}
                columnCells={columnCells}
              />
            )}
          </>
        </TableBody>
        <TableFooter>
          <TableRow sx={{ "& p": { marginBottom: "0px" } }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={itemsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
