import styled from "@emotion/styled";
import { colors, vw } from "../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &::after {
    content: "";
    background-color: ${colors.blu1};
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
`;

const Container = styled.form`
  width: 100vw;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding: 78px 80px 100px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: 18px 20px 100px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: ${vw("20 20 40 20")};
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 16px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    flex-direction: column;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 105px;
  gap: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: ${vw(105)};
    gap: ${vw(12)};
  }

  button {
    width: 205px;
    height: 52px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    text-transform: none;

    ${(props) => props.theme.breakpoints.down("sm")} {
      width: ${vw(214)};
      height: ${vw(52)};
      font-size: ${vw(20)};
      line-height: ${vw(20)};
    }
  }
`;

export { Wrapper, Container, FormRow, ButtonRow };
