export const languages = [
  ["AFRIKAANS", "AF"],
  ["ALBANIAN", "SQ"],
  ["AMHARIC", "AM"],
  ["ARABIC", "AR"],
  ["ARMENIAN", "HY"],
  ["AYMARA", "AY"],
  ["AZERBAIJANI", "AZ"],
  ["BELARUSIAN", "BE"],
  ["BENGALI", "BN"],
  ["BISLAMA", "BI"],
  ["BOSNIAN", "BS"],
  ["BULGARIAN", "BG"],
  ["BURMESE", "MY"],
  ["CATALAN", "CA"],
  ["CHICHEWA", "NY"],
  ["CHINESE", "ZH"],
  ["CROATIAN", "HR"],
  ["CZECH", "CS"],
  ["DANISH", "DA"],
  ["DARI", "DRI"],
  ["DHIVEHI", "DV"],
  ["DUTCH", "NL"],
  ["DZONGKHA", "DZ"],
  ["ENGLISH", "EN"],
  ["ESTONIAN", "ET"],
  ["FILIPINO", "FIL"],
  ["FINNISH", "FI"],
  ["FRENCH", "FR"],
  ["GEORGIAN", "KA"],
  ["GERMAN", "DE"],
  ["GREEK", "EL"],
  ["GUARANI", "GN"],
  ["HEBREW", "HE"],
  ["HINDI", "HI"],
  ["HUNGARIAN", "HU"],
  ["ICELANDIC", "IS"],
  ["INDONESIAN", "IN"],
  ["IRISH", "GA"],
  ["ITALIAN", "IT"],
  ["JAPANESE", "JA"],
  ["KAZAKH", "KK"],
  ["KHMER", "KM"],
  ["KINYARWANDA", "RW"],
  ["KYRGYZ", "KY"],
  ["KIRUNDI", "RN"],
  ["KOREAN", "KO"],
  ["KURDISH", "KU"],
  ["LAO", "LO"],
  ["LATVIAN", "LV"],
  ["LITHUANIAN", "LT"],
  ["LUXEMBOURGISH", "LB"],
  ["MACEDONIAN", "MK"],
  ["MALAGASY", "MG"],
  ["MALAYSIAN", "MS"],
  ["MALTESE", "MT"],
  ["MOLDOVAN", "MO"],
  ["MONGOLIAN", "MN"],
  ["NAURUAN", "NA"],
  ["NEPALI", "NE"],
  ["NORWEGIAN", "NO"],
  ["PASHTO", "PS"],
  ["PERSIAN", "FA"],
  ["POLISH", "PL"],
  ["PORTUGUESE", "PT"],
  ["QUECHUA", "QU"],
  ["ROMANIAN", "RO"],
  ["ROMANSH", "RM"],
  ["RUSSIAN", "RU"],
  ["SAMOAN", "SM"],
  ["SERBIAN", "SR"],
  ["SINHALA", "SI"],
  ["SLOVAK", "SK"],
  ["SLOVENE", "SL"],
  ["SOMALI", "SO"],
  ["SOTHO", "ST"],
  ["SPANISH", "ES"],
  ["SWAHILI", "SW"],
  ["SWATI", "SS"],
  ["SWEDISH", "SV"],
  ["TAJIK", "TG"],
  ["TAMIL", "TA"],
  ["TETUM", "TET"],
  ["THAI", "TH"],
  ["TIGRINYA", "TI"],
  ["TONGAN", "TO"],
  ["TSWANA", "TN"],
  ["TURKISH", "TR"],
  ["TURKMEN", "TK"],
  ["UKRAINIAN", "UK"],
  ["URDU", "UR"],
  ["UZBEK", "UZ"],
  ["VIETNAMESE", "VI"],
];

export const country = [
  ["AF", "Afghanistan"],
  ["AL", "Albania"],
  ["DZ", "Algeria"],
  ["AD", "Andorra"],
  ["AO", "Angola"],
  ["AG", "Antigua and Barbuda"],
  ["AR", "Argentina"],
  ["AM", "Armenia"],
  ["AU", "Australia"],
  ["AT", "Austria"],
  ["AZ", "Azerbaijan"],
  ["XA", "Azores"],
  ["BS", "Bahamas"],
  ["BH", "Bahrain"],
  ["BD", "Bangladesh"],
  ["BB", "Barbados"],
  ["BY", "Belarus"],
  ["BE", "Belgium"],
  ["BZ", "Belize"],
  ["BJ", "Benin"],
  ["BT", "Bhutan"],
  ["BO", "Bolivia"],
  ["BA", "Bosnia and Herzegovina"],
  ["BW", "Botswana"],
  ["BR", "Brazil"],
  ["BN", "Brunei"],
  ["BG", "Bulgaria"],
  ["BF", "Burkina Faso"],
  ["BI", "Burundi"],
  ["CV", "Cabo Verde"],
  ["KH", "Cambodia"],
  ["CM", "Cameroon"],
  ["CA", "Canada"],
  ["CF", "Central African Republic"],
  ["TD", "Chad"],
  ["CL", "Chile"],
  ["CN", "China"],
  ["CO", "Colombia"],
  ["KM", "Comoros"],
  ["CG", "Congo"],
  ["CD", "Congo (DR)"],
  ["CR", "Costa Rica"],
  ["CI", "Cote d'Ivoire"],
  ["HR", "Croatia"],
  ["CU", "Cuba"],
  ["CY", "Cyprus"],
  ["CZ", "Czech Republic"],
  ["DK", "Denmark"],
  ["DJ", "Djibouti"],
  ["DM", "Dominica"],
  ["DO", "Dominican Republic"],
  ["EC", "Ecuador"],
  ["EG", "Egypt"],
  ["SV", "El Salvador"],
  ["GQ", "Equatorial Guinea"],
  ["ER", "Eritrea"],
  ["EE", "Estonia"],
  ["SZ", "Eswatini"],
  ["ET", "Ethiopia"],
  ["FJ", "Fiji"],
  ["FI", "Finland"],
  ["FR", "France"],
  ["GA", "Gabon"],
  ["GM", "Gambia"],
  ["GE", "Georgia"],
  ["DE", "Germany"],
  ["GH", "Ghana"],
  ["GR", "Greece"],
  ["GD", "Grenada"],
  ["GT", "Guatemala"],
  ["GN", "Guinea"],
  ["GW", "Guinea-Bissau"],
  ["GY", "Guyana"],
  ["HT", "Haiti"],
  ["VA", "Holy See"],
  ["HN", "Honduras"],
  ["HK", "Hong Kong"],
  ["HU", "Hungary"],
  ["IS", "Iceland"],
  ["IN", "India"],
  ["ID", "Indonesia"],
  ["IR", "Iran"],
  ["IQ", "Iraq"],
  ["IE", "Ireland"],
  ["IL", "Israel"],
  ["IT", "Italy"],
  ["JM", "Jamaica"],
  ["JP", "Japan"],
  ["JO", "Jordan"],
  ["KZ", "Kazakhstan"],
  ["KE", "Kenya"],
  ["KI", "Kiribati"],
  ["KW", "Kuwait"],
  ["KG", "Kyrgyzstan"],
  ["LA", "Laos"],
  ["LV", "Latvia"],
  ["LB", "Lebanon"],
  ["LS", "Lesotho"],
  ["LR", "Liberia"],
  ["LY", "Libya"],
  ["LI", "Liechtenstein"],
  ["LT", "Lithuania"],
  ["LU", "Luxembourg"],
  ["MG", "Madagascar"],
  ["MW", "Malawi"],
  ["MY", "Malaysia"],
  ["MV", "Maldives"],
  ["ML", "Mali"],
  ["MT", "Malta"],
  ["MH", "Marshall Islands"],
  ["MR", "Mauritania"],
  ["MU", "Mauritius"],
  ["MX", "Mexico"],
  ["FM", "Micronesia"],
  ["MD", "Moldova"],
  ["MC", "Monaco"],
  ["MN", "Mongolia"],
  ["ME", "Montenegro"],
  ["MA", "Morocco"],
  ["MZ", "Mozambique"],
  ["MM", "Myanmar"],
  ["NA", "Namibia"],
  ["NR", "Nauru"],
  ["NP", "Nepal"],
  ["NL", "Netherlands"],
  ["NZ", "New Zealand"],
  ["NI", "Nicaragua"],
  ["NE", "Niger"],
  ["NG", "Nigeria"],
  ["KP", "North Korea"],
  ["MK", "North Macedonia"],
  ["NO", "Norway"],
  ["OM", "Oman"],
  ["PK", "Pakistan"],
  ["PW", "Palau"],
  ["PS", "Palestine State"],
  ["PA", "Panama"],
  ["PG", "Papua New Guinea"],
  ["PY", "Paraguay"],
  ["PE", "Peru"],
  ["PH", "Philippines"],
  ["PL", "Poland"],
  ["PT", "Portugal"],
  ["PR", "Puerto Rico"],
  ["QA", "Qatar"],
  ["RO", "Romania"],
  ["RU", "Russia"],
  ["RW", "Rwanda"],
  ["KN", "Saint Kitts and Nevis"],
  ["LC", "Saint Lucia"],
  ["MF", "Saint Martin (FR)"],
  ["VC", "Saint Vincent and the Grenadines"],
  ["WS", "Samoa"],
  ["SM", "San Marino"],
  ["ST", "Sao Tome and Principe"],
  ["SA", "Saudi Arabia"],
  ["SN", "Senegal"],
  ["RS", "Serbia"],
  ["SC", "Seychelles"],
  ["SL", "Sierra Leone"],
  ["SG", "Singapore"],
  ["SK", "Slovakia"],
  ["SI", "Slovenia"],
  ["SB", "Solomon Islands"],
  ["SO", "Somalia"],
  ["ZA", "South Africa"],
  ["KR", "South Korea"],
  ["SS", "South Sudan"],
  ["ES", "Spain"],
  ["LK", "Sri Lanka"],
  ["SD", "Sudan"],
  ["SR", "Suriname"],
  ["SE", "Sweden"],
  ["CH", "Switzerland"],
  ["SY", "Syria"],
  ["TW", "Taiwan"],
  ["TJ", "Tajikistan"],
  ["TZ", "Tanzania"],
  ["TH", "Thailand"],
  ["TL", "Timor-Leste"],
  ["TG", "Togo"],
  ["TK", "Tokelau"],
  ["TO", "Tonga"],
  ["TT", "Trinidad and Tobago"],
  ["TN", "Tunisia"],
  ["TR", "Turkey"],
  ["TM", "Turkmenistan"],
  ["TC", "Turks and Caicos Islands"],
  ["TV", "Tuvalu"],
  ["UG", "Uganda"],
  ["UA", "Ukraine"],
  ["AE", "United Arab Emirates"],
  ["GB", "United Kingdom"],
  ["UM", "United States Minor Outlying Islands"],
  ["US", "United States of America"],
  ["ZZ", "Unknown"],
  ["UY", "Uruguay"],
  ["UZ", "Uzbekistan"],
  ["VU", "Vanuatu"],
  ["VE", "Venezuela"],
  ["VN", "Vietnam"],
  ["VG", "Virgin Islands (GB)"],
  ["VI", "Virgin Islands (US)"],
  ["WF", "Wallis and Futuna"],
  ["EH", "Western Sahara"],
  ["YE", "Yemen"],
  ["YU", "Yugoslavia"],
  ["ZM", "Zambia"],
  ["ZW", "Zimbabwe"],
];

export const CHECKBOXES_OUTSIDE_WORK = [
  {
    interestId: 1,
    label: "Photography",
    checked: false,
  },
  {
    interestId: 2,
    label: "Shopping",
    checked: false,
  },
  {
    interestId: 3,
    label: "DJing",
    checked: false,
  },
  {
    interestId: 4,
    label: "Cooking",
    checked: false,
  },
  {
    interestId: 5,
    label: "Driving ",
    checked: false,
  },
  {
    interestId: 6,
    label: "Dancing",
    checked: false,
  },
  {
    interestId: 7,
    label: "Dancing",
    checked: false,
  },
  {
    interestId: 8,
    label: "Meditating",
    checked: false,
  },
  {
    interestId: 9,
    label: "Yoga",
    checked: false,
  },
  {
    interestId: 10,
    label: "Listening to Music",
    checked: false,
  },
  {
    interestId: 11,
    label: "Astrology",
    checked: false,
  },
  {
    interestId: 12,
    label: "Fashion",
    checked: false,
  },
  {
    interestId: 13,
    label: "Learning languages",
    checked: false,
  },
  {
    interestId: 14,
    label: "Crafts",
    checked: false,
  },
  {
    interestId: 15,
    label: "Design",
    checked: false,
  },
  {
    interestId: 16,
    label: "Make up",
    checked: false,
  },
  {
    interestId: 17,
    label: "Singing",
    checked: false,
  },
  {
    interestId: 18,
    label: "Writing",
    checked: false,
  },
  {
    interestId: 19,
    label: "Foodie",
    checked: false,
  },
  {
    interestId: 20,
    label: "Movies",
    checked: false,
  },
  {
    interestId: 21,
    label: "Hiking",
    checked: false,
  },
  {
    interestId: 22,
    label: "Cycling",
    checked: false,
  },
  {
    interestId: 23,
    label: "Sports",
    checked: false,
  },
  {
    interestId: 24,
    label: "Dogs ",
    checked: false,
  },
  {
    interestId: 25,
    label: "Cats",
    checked: false,
  },
  {
    interestId: 26,
    label: "Café-hopping",
    checked: false,
  },
  {
    interestId: 27,
    label: "Museums",
    checked: false,
  },
  {
    interestId: 28,
    label: "Art",
    checked: false,
  },
  {
    interestId: 29,
    label: "Travel",
    checked: false,
  },
  {
    interestId: 30,
    label: "Pubs",
    checked: false,
  },
  {
    interestId: 31,
    label: "Gigs",
    checked: false,
  },
  {
    interestId: 32,
    label: "Gym",
    checked: false,
  },
  {
    interestId: 33,
    label: "Festivals",
    checked: false,
  },
  {
    interestId: 34,
    label: "Beaches",
    checked: false,
  },
  {
    interestId: 35,
    label: "Camping",
    checked: false,
  },
  {
    interestId: 36,
    label: "Reading",
    checked: false,
  },
  {
    interestId: 37,
    label: "Running",
    checked: false,
  },
];
