import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { colors, vw } from "../../../../styles";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 887px;

  &:before {
    content: "";
    z-index: -999;
    position: absolute;
    left: 0;
    width: 100%;
    height: 887px;
    background: ${colors.blu1};

    ${(props) => props.theme.breakpoints.down("sm")} {
      height: ${vw(778)};
    }
  }

  & .swiper {
    margin: 32px 10px 50px;

    & .carousel-prev {
      left: -38px;
    }

    & .carousel-next {
      right: -38px;
    }

    ${(props) => props.theme.breakpoints.down("lg")} {
      & .carousel-prev {
        left: 0;
      }

      & .carousel-next {
        right: 0;
      }
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      margin: ${vw("32 10 30")};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-bottom: ${vw(60)};
    height: ${vw(778)};
  }
`;

const Title = styled.div`
  margin-top: 100px;
  width: auto;
  padding-bottom: 12px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding-bottom: 12px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-bottom: ${vw(12)};
    margin-top: ${vw(60)};
  }
`;

const StyledButton = styled(Button)`
  width: 404px;
  margin-top: 94px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    margin-top: ${vw(40)};
  }
`;

export { Wrapper, StyledButton, Title };
