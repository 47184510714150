import React from "react";
import { Wrapper, Title, Divide } from "./questions-block-styles";
import { AdaptiveText } from "../../../../components";
import { TitleDivider } from "../../../../components/v2/title-divider";
import { Question } from "../../../../components/v2/question";

const questionsTop = [
  {
    id: 1,
    headerText: "How do I register with VERVVEY?",
    title: `
      It's really easy. Create your profile – it only takes 15
      minutes. It helps us and salon owners to get to know you
      better. You can use your profile page for social media
      marketing of your work to get new clients.
    `,
  },
  {
    id: 2,
    headerText: "How do I rent a chair with VERVVEY?",
    title: `
      Take our online quiz to receive recommended salons that suit
      your vibe and needs. Otherwise search for the salon or book on
      request – our team will help you to find the best match. Once
      you have picked a salon, schedule a Free Trial
    `,
  },
  {
    id: 3,
    headerText: "Which facilities do I have access to with the booking?",
    title: `
      Every salon will provide a detailed description what the
      booking includes. We know how important it is for you to have
      access to the facilities of the salon. That is why whenever
      you rent a salon chair with VERVVEY, we try to secure full
      access to that salon's backbar and other amenities like coffee
      and tea for your clients. Most of our salons will allow you to
      purchase color while you are working there
    `,
  },
];
const questions = [
  {
    id: 4,
    headerText: "Is VERVVEY for Independent Artists?",
    title: `
      Yes, VERVVEY is the perfect solution for Artists that are
      already independent. You can rent a chair in a salon you love,
      pay only while you are using the salon and have full access to
      the backbar and other amenities like tea and coffee for your
      clients
    `,
  },
  {
    id: 5,
    headerText: "Is VERVVEY for Commission Artists?",
    title: `
      Yes, renting a chair through VERVVEY gives you all the
      comforts of being able to work at your preferred salon
      everyday. The main difference is you will be able to control
      your schedule and make significantly more income
    `,
  },
  {
    id: 6,
    headerText: "I am already working at a salon, how is this different?",
    title: `
      With VERVVEY, you pay a fixed amount to rent a salon chair by
      the hour. This means you have full control over your schedule
      and keep 100% of the income you earn. It’s a good opportunity
      for you to try being independent!
    `,
  },
  {
    id: 7,
    headerText: "Is VERVVEY for the starters in the industry?",
    title: `
      Yes, VERVVEY is the perfect solution for Artists that are just
      starting in the industry. You can rent a chair in a salon you
      love and pay only while you are using the salon
    `,
  },
];

const QuestionsBlock = () => {
  const [expanded, setExpanded] = React.useState<number | null>(null);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : null);
    };

  return (
    <Wrapper>
      <Title>
        <AdaptiveText
          xl={{ size: 44, height: 56 }}
          xs={{ size: 30, height: 38 }}
          sm={{ size: 30, height: 38 }}
          weight={600}
          fontFamily="Greenwich SemBd"
          align="center"
          uppercase
        >
          DO YOU HAVE ANY QUESTIONS?
        </AdaptiveText>
      </Title>
      <TitleDivider />
      <AdaptiveText
        xl={{ size: 26, height: 33 }}
        xs={{ size: 18, height: 20 }}
        weight={100}
        fontFamily={"Greenwich ExtLt"}
        align="center"
      >
        Getting started
      </AdaptiveText>

      {questionsTop.map((question, idx) => (
        <Question
          key={question.id}
          title={question.headerText}
          answer={question.title}
          expanded={expanded === question.id}
          handleChange={handleChange(question.id)}
        />
      ))}
      <Divide>
        <TitleDivider />
        <AdaptiveText
          xl={{ size: 26, height: 33 }}
          xs={{ size: 18, height: 20 }}
          weight={100}
          fontFamily={"Greenwich ExtLt"}
          align="center"
        >
          Is this for me?
        </AdaptiveText>
      </Divide>
      {questions.map((question, idx) => (
        <Question
          key={question.id}
          title={question.headerText}
          answer={question.title}
          expanded={expanded === question.id}
          handleChange={handleChange(question.id)}
        />
      ))}
    </Wrapper>
  );
};

export default QuestionsBlock;
