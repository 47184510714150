import React, { useEffect, useState } from "react";
import {
  DetailsTitle,
  SalonLocation,
  LocationTitle,
  Divider,
  BookingDetails,
  TotalBlock,
  Summary,
  Descriptions,
  Row,
  Total,
  Controls,
  DatesBlock,
  PayButton,
  BackButton,
  RiskBlock,
} from "./details-styles";
import { AdaptiveText } from "../../../../components";
import { colors } from "../../../../styles";
import { ReactComponent as Location } from "../../../../assets/icons/location.svg";
import { ReactComponent as Allow } from "../../../../assets/icons/allow.svg";
import { Types } from "../duck/types";
import {
  bookWorkstation,
  getBookingPrice,
} from "../../../../services/masterApi";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import moment from "moment";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { Backdrop } from "@material-ui/core";

const Details: React.FC<Types> = ({
  salonData,
  bookingData,
  setTab,
  reset,
}) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { auth } = useSelector((state: AppState) => state);
  const [total, setTotal] = useState<Record<string, any>>({});
  const { salonId } = useParams();

  useEffect(() => {
    if (bookingData && salonData) {
      getBookingPrice(
        salonId,
        bookingData.workstation,
        bookingData.days?.length
      ).then((data) => {
        setTotal({
          days: bookingData.days,
          pricePerDay: `€${data.price / (bookingData.days?.length || 1)}`,
          price: `€${data.price}`,
          fees: `€${data.fees}`,
          // discount: `€${data.price / 10}`,
          priceVat: `€${data.priceVat}`,
          vat: `€${data.vat}`,
        });
        setLoading(false);
      });
    }
  }, [bookingData, salonData]);

  const handleClose = () => {
    setOpen(false);
    reset();
    setTab(1);
  };

  const bookSalon = async () => {
    let isValid = true;

    bookingData?.days?.forEach((day) => {
      if (moment(day.scheduleDay) < moment()) {
        isValid = false;
      }
    });

    if (!isValid) {
      setOpen(true);
      return;
    }

    setSubmitLoading(true);
    try {
      const redirectUrl = await bookWorkstation(salonId, auth.userId, {
        workstationId: bookingData.workstation,
        workstationType: salonData.workStations?.find(
          (workstation) => workstation.workstationId === bookingData.workstation
        ).workstationType,
        schedules: bookingData?.days?.map((day) => {
          return {
            schedId: day.id,
          };
        }),
      });

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    } catch (e) {
      setSubmitLoading(false);
    }
  };

  return (
    <div>
      {open && (
        <Backdrop open={open} style={{ color: "#fff", zIndex: 999 }}>
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              severity="error"
              sx={{ width: "100%" }}
              onClose={handleClose}
            >
              You can't make a booking on current or previous day
            </Alert>
          </Snackbar>
        </Backdrop>
      )}

      <DetailsTitle>
        <div>
          <AdaptiveText
            xl={{ size: 36, height: 46 }}
            xs={{ size: 28, height: 39 }}
            fontFamily="Greenwich SemBd"
            weight={600}
          >
            Booking details confirmation
          </AdaptiveText>
        </div>
        <div>
          <AdaptiveText
            xl={{ size: 20, height: 23 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
            weight={700}
            color={colors.blu4}
          >
            You are about to request booking at the salon. Please ensure the
            following data is correct.
          </AdaptiveText>
        </div>
      </DetailsTitle>
      <LocationTitle>
        <AdaptiveText
          xl={{ size: 26, height: 33 }}
          xs={{ size: 20, height: 20 }}
          fontFamily="Greenwich SemBd"
          weight={600}
        >
          Booking at&nbsp;
          <AdaptiveText
            as="span"
            xl={{ size: 26, height: 33 }}
            xs={{ size: 20, height: 20 }}
            fontFamily="Greenwich SemBd"
            weight={600}
            color={colors.blu4}
          >
            {salonData?.name}
          </AdaptiveText>
        </AdaptiveText>
        <SalonLocation>
          <Location />
          <AdaptiveText
            xl={{ size: 18, height: 21 }}
            xs={{ size: 16, height: 19 }}
            fontFamily="Greenwich"
          >
            {`${salonData?.address?.street} ${salonData?.address?.number}, ${salonData?.address?.postCode} ${salonData?.city}`}
          </AdaptiveText>
        </SalonLocation>
      </LocationTitle>
      <Divider />
      <BookingDetails>
        <div>
          <AdaptiveText
            xl={{ size: 20, height: 20 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
          >
            Selected workplace type:
          </AdaptiveText>
        </div>
        <div>
          <AdaptiveText
            xl={{ size: 20, height: 23 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
            weight={700}
          >
            {
              salonData?.workStations?.find(
                (workStation) =>
                  workStation.workstationId === bookingData.workstation
              )?.name
            }
          </AdaptiveText>
        </div>
      </BookingDetails>
      <BookingDetails>
        <div>
          <AdaptiveText
            xl={{ size: 20, height: 20 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
          >
            Selected dates for coworking:
          </AdaptiveText>
        </div>
        <DatesBlock>
          {bookingData.days.map((day, idx) => {
            return (
              <AdaptiveText
                key={day.scheduleDay}
                xl={{ size: 20, height: 23 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
                weight={700}
              >
                {`${moment(day.scheduleDay).format("DD/MM/YYYY")}${
                  idx === bookingData.days?.length - 1 ? "" : ";"
                }`}
              </AdaptiveText>
            );
          })}
        </DatesBlock>
        <RiskBlock>
          <Allow />
          <AdaptiveText
            xl={{ size: 20, height: 20 }}
            xs={{ size: 18, height: 21 }}
            fontFamily="Greenwich"
          >
            Risk free: You can cancel your booking up to 48 hours, so lock in
            this great spot now!
          </AdaptiveText>
        </RiskBlock>
      </BookingDetails>
      {loading ? null : (
        <TotalBlock>
          <Summary>
            <AdaptiveText
              xl={{ size: 20, height: 23 }}
              xs={{ size: 18, height: 21 }}
              fontFamily="Greenwich"
              weight={700}
              color={colors.blu4}
            >
              Your rental price summary
            </AdaptiveText>
            <Descriptions>
              <Row>
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                >
                  Full day rate, excl. VAT
                </AdaptiveText>
                <AdaptiveText
                  xl={{ size: 20, height: 23 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                  weight={700}
                >
                  {total?.pricePerDay}
                </AdaptiveText>
              </Row>
              <Row>
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                >
                  Number of coworking days
                </AdaptiveText>
                <AdaptiveText
                  xl={{ size: 20, height: 23 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                  weight={700}
                >
                  {total?.days?.length}
                </AdaptiveText>
              </Row>
              <Row>
                <AdaptiveText
                  xl={{ size: 20, height: 20 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                >
                  Total price, excl. VAT
                </AdaptiveText>
                <AdaptiveText
                  xl={{ size: 20, height: 23 }}
                  xs={{ size: 18, height: 21 }}
                  fontFamily="Greenwich"
                  weight={700}
                >
                  {total.price}
                </AdaptiveText>
              </Row>
              {/*<Row>*/}
              {/*  <AdaptiveText*/}
              {/*    xl={{ size: 16, height: 19 }}*/}
              {/*    xs={{ size: 14, height: 16 }}*/}
              {/*    fontFamily="Greenwich"*/}
              {/*    color={colors.grey3}*/}
              {/*  >*/}
              {/*    incl. service fee 10%*/}
              {/*  </AdaptiveText>*/}
              {/*  <AdaptiveText*/}
              {/*    xl={{ size: 16, height: 19 }}*/}
              {/*    xs={{ size: 14, height: 16 }}*/}
              {/*    fontFamily="Greenwich"*/}
              {/*    weight={700}*/}
              {/*    color={colors.grey3}*/}
              {/*  >*/}
              {/*    {total.fees}*/}
              {/*  </AdaptiveText>*/}
              {/*</Row>*/}
              {/*<Row>*/}
              {/*  <AdaptiveText*/}
              {/*    xl={{ size: 20, height: 20 }}*/}
              {/*    xs={{ size: 18, height: 21 }}*/}
              {/*    fontFamily="Greenwich"*/}
              {/*    color={colors.pink4}*/}
              {/*  >*/}
              {/*    Discount 10%*/}
              {/*  </AdaptiveText>*/}
              {/*  <AdaptiveText*/}
              {/*    xl={{ size: 20, height: 23 }}*/}
              {/*    xs={{ size: 18, height: 21 }}*/}
              {/*    fontFamily="Greenwich"*/}
              {/*    weight={700}*/}
              {/*    color={colors.pink4}*/}
              {/*  >*/}
              {/*    {total.discount}*/}
              {/*  </AdaptiveText>*/}
              {/*</Row>*/}
            </Descriptions>
          </Summary>
          <Total>
            <Row>
              <AdaptiveText
                xl={{ size: 20, height: 20 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
              >
                Total price to be paid, incl. VAT
              </AdaptiveText>
              <AdaptiveText
                xl={{ size: 20, height: 23 }}
                xs={{ size: 18, height: 21 }}
                fontFamily="Greenwich"
                weight={700}
              >
                {total.priceVat}
              </AdaptiveText>
            </Row>
            <Row>
              <AdaptiveText
                xl={{ size: 16, height: 19 }}
                xs={{ size: 14, height: 16 }}
                fontFamily="Greenwich"
                color={colors.grey3}
              >
                VAT, 21 %
              </AdaptiveText>
              <AdaptiveText
                xl={{ size: 16, height: 19 }}
                xs={{ size: 14, height: 16 }}
                fontFamily="Greenwich"
                weight={700}
                color={colors.grey3}
              >
                {total.vat}
              </AdaptiveText>
            </Row>
          </Total>
        </TotalBlock>
      )}

      <AdaptiveText
        xl={{ size: 20, height: 23 }}
        xs={{ size: 18, height: 21 }}
        fontFamily="Greenwich"
        color={colors.blu4}
        weight={700}
      >
        You will now be redirected to a secure payment system site to finish the
        booking process
      </AdaptiveText>
      <Controls>
        <BackButton
          variant="outlined"
          onClick={() => {
            setTab(1);
          }}
          disabled={submitLoading}
        >
          Back
        </BackButton>
        <PayButton
          variant="contained"
          onClick={bookSalon}
          disabled={loading || submitLoading}
        >
          {submitLoading ? <CircularProgress size={30} /> : "Confirm and pay"}
        </PayButton>
      </Controls>
    </div>
  );
};

export default Details;
