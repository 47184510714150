import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { ReactComponent as Show } from "../../assets/icons/show_psw.svg";
import { ReactComponent as Hide } from "../../assets/icons/hide_psw.svg";
import styled from "@emotion/styled";
import { colors, vw } from "../../styles";
import { ExplanationIcon } from "@components";
import { PropTypes } from "./duck/types";
import { ErrorText } from "./duck/style";
import { useController, useFormContext } from "react-hook-form";

const StyledInput = styled(FormControl)(({ theme }) => ({
  // marginTop: "32px",
  // marginBottom: "6px",

  ["@media (max-width: 480px)"]: {
    marginTop: vw(32),
    marginBottom: vw(6),
  },

  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "inherit",
    },
  },

  "&.inputError": {
    "& .MuiInputLabel-root": {
      color: colors.red,
    },

    "& .MuiOutlinedInput-root fieldset": {
      border: `1px solid ${colors.red}`,
      borderWidth: 1,

      ["@media (max-width: 480px)"]: {
        borderWidth: vw(1),
      },
    },
  },
  label: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    top: "-30px",
    fontSize: "18px",
    lineHeight: "21px",
    fontFamily: "Greenwich",
    transform: "none",

    ["@media (max-width: 480px)"]: {
      top: vw(-30),
      fontSize: vw(18),
      lineHeight: vw(21),
    },
  },

  "& .MuiOutlinedInput-root": {
    height: "60px",
    position: "relative",
    backgroundColor: colors.white,
    border: "none",
    borderRadius: 10,
    fontSize: 20,
    lineHeight: 1,
    width: "auto",
    fontFamily: "Greenwich",
    transition: (theme as any).transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&.MuiInputBase-sizeSmall": {
      height: "40px",
    },

    ["@media (max-width: 480px)"]: {
      height: vw(60),
      borderRadius: vw(10),
      fontSize: vw(20),

      "&.MuiInputBase-sizeSmall": {
        height: vw(40),
      },
    },

    "&.Mui-focused": {
      "& fieldset": {
        borderWidth: 1,

        ["@media (max-width: 480px)"]: {
          borderWidth: vw(1),
        },
      },
    },

    "& input": {
      outline: "none",

      "&::placeholder": {
        fontSize: 18,
        lineHeight: 1,

        ["@media (max-width: 480px)"]: {
          fontSize: vw(18),
        },
      },
    },

    "& fieldset": {
      top: 0,
      borderWidth: 0,

      legend: {
        display: "none",
      },
    },
  },
  "& .MuiInputBase-input": {
    border: "none",
  },
}));

const PasswordInput: React.FC<PropTypes> = ({
  name,
  label,
  placeholder,
  tooltip,
  required,
  defaultValue,
  size,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });
  const [show, setShow] = React.useState(false);

  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledInput
      required={required}
      fullWidth
      className={errors[fieldName]?.message ? "inputError" : ""}
    >
      <InputLabel shrink htmlFor={name}>
        <Stack direction="row" gap="8px">
          {!!label ? label : ""}
          {tooltip && <ExplanationIcon text={tooltip} />}
        </Stack>
      </InputLabel>
      <OutlinedInput
        name={fieldName}
        type={show ? "text" : "password"}
        value={value}
        onChange={onChange}
        {...(size && { size: size })}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              disableRipple
              edge="end"
            >
              {show ? <Hide /> : <Show />}
            </IconButton>
          </InputAdornment>
        }
        placeholder={placeholder}
        id={name}
      />
      {errors[fieldName]?.message && (
        <ErrorText
          xs={{ size: 18, height: 21 }}
          sm={{ size: 18, height: 21 }}
          color={errors[fieldName]?.message ? colors.red : colors.accent}
        >
          {errors[fieldName]?.message}
        </ErrorText>
      )}
    </StyledInput>
  );
};

export default PasswordInput;
