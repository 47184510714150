import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid, Snackbar, Alert } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { ButtonsWrapper, FormButton, Row } from "./duck/styles";
import { Text } from "../../../../../components/text";
import { PasswordInput, TextInput } from "../../../../../form-units";
import { yupResolver } from "@hookform/resolvers/yup";
import { getValidation, validationSchema } from "./duck/utils";
import { colors } from "../../../../../styles";
import { updateMasterPassword } from "../../../../../services/masterApi";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/reducers/rootReducer";

export const ChangePasswordForm = () => {
  const { auth } = useSelector((state: AppState) => state);
  const navigate = useNavigate();
  const { userId, salonId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [submitted, setSubmitted] = useState({
    message: "",
    error: "",
  });
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: getValidation().defaultValues,
    mode: "onBlur",
  });

  const watchFields = formProviderProps.watch(["confirmPassword", "password"]);

  useEffect(() => {
    const formErrors = formProviderProps.formState.errors as any;
    const values = formProviderProps.getValues() as any;

    setTimeout(() => {
      if (
        !formErrors.confirmPassword &&
        values.password === values.confirmPassword
      ) {
        formProviderProps.clearErrors("password");
      } else if (
        !formErrors.password &&
        values.password === values.confirmPassword
      ) {
        formProviderProps.clearErrors("confirmPassword");
      }
    }, 0);
  }, [...watchFields]);

  useEffect(() => {
    if (auth?.userId !== userId && auth?.salonId !== salonId) {
      navigate("/");
    }
  }, []);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = async () => {
    try {
      await updateMasterPassword({
        ...formProviderProps.getValues(),
        userId: auth.userId,
      });

      formProviderProps.reset();

      setSubmitted({
        message: "Password updated successfully",
        error: "",
      });
      openAlert();
    } catch (err) {
      setSubmitted({
        message: "",
        error: err.response.data.message,
      });
      openAlert();
    }
  };

  const openAlert = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <FormProvider {...formProviderProps}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={submitted.error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {submitted.error?.length ? submitted.error : submitted.message}
        </Alert>
      </Snackbar>
      <form onSubmit={formProviderProps.handleSubmit(onSubmit)} noValidate>
        <Grid container flexDirection="column" spacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12}>
            <Text
              xs={{ size: 28, height: 40 }}
              sm={{ size: 36, height: 46 }}
              fontWeight={600}
            >
              Change password
            </Text>
          </Grid>
          <Grid
            item
            container
            flexDirection="column"
            spacing={{ xs: 2, sm: 3 }}
          >
            <Row item>
              <TextInput
                name={"currentPassword"}
                type={"text"}
                required
                placeholder=""
                label="Current password"
              />
            </Row>
            <Row item>
              <PasswordInput
                name="password"
                label="New password"
                placeholder="Password should be at least 8 characters"
                required
              />
            </Row>
            <Row item>
              <PasswordInput
                name="confirmPassword"
                label="Confirm password"
                placeholder="Passwords must match"
                required
              />
            </Row>
            <Grid item xs={12}>
              <Link to="/forgot_password_modal">
                <Text
                  xs={{ size: 16, height: 16 }}
                  sm={{ size: 16, height: 16 }}
                  fontWeight={700}
                  color={colors.pink4}
                >
                  Forgot your password?
                </Text>
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ButtonsWrapper>
              <FormButton
                variant="outlined"
                disableTouchRipple
                disableFocusRipple
                onClick={onBack}
              >
                Cancel
              </FormButton>
              <FormButton
                type="submit"
                variant="contained"
                disableTouchRipple
                disableFocusRipple
              >
                Submit
              </FormButton>
            </ButtonsWrapper>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
