import axios from "axios";
import { authHeader, BASE_API_URL } from "./authApi";

export const getCityList = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/dictionary/city`);

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getWorkstationBookings = async (
  params,
  lang = "EN"
): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/workstation/rent/lang/${lang}`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSalonBookings =
  (salonId) =>
  async (params): Promise<any> => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/workstation/rent/salon/${salonId}`,
        {
          headers: authHeader(),
          params,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

export const getMaserBookings =
  (masterId) =>
  async (params): Promise<any> => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/workstation/rent/master/${masterId}`,
        {
          headers: authHeader(),
          params,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

export const updateBookingRequest = async (body): Promise<any> => {
  try {
    const response = await axios.put(`${BASE_API_URL}/workstation/rent`, body, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateBookingDay = async (
  bookingSchedId,
  status
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BASE_API_URL}/workstation/rent/schedule/${bookingSchedId}/status/${status}`,
      {},
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUsers = async (params): Promise<any> => {
  try {
    const response = await axios.get(`${BASE_API_URL}/user`, {
      headers: authHeader(),
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBookingStatuses = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/dictionary/rent/statuses`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const geBookingDayStatuses = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/dictionary/bookingDay/statuses`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getReportTypes = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/dictionary/report/types`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getReport = async (body): Promise<any> => {
  try {
    const response = await axios.post(`${BASE_API_URL}/report`, body, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPayments = async (params): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/accountant/salon/payment`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRefunds = async (params): Promise<any> => {
  try {
    const response = await axios.get(
      `${BASE_API_URL}/accountant/master/refund`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserInfo = async (): Promise<any> => {
  try {
    const response = await axios.get(
        `${BASE_API_URL}/user/info`,
        { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserGrantInfo = async (): Promise<any> => {
  try {
    const response = await axios.get(
        `${BASE_API_URL}/user/get-grant-application`,
        { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const applyForGrant = async (): Promise<any> => {
  try {
    const response = await axios.post(
        `${BASE_API_URL}/user/apply-for-grant`,
        null,
        { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
