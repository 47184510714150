import { NavDropdown } from "react-bootstrap";
import { Link} from "react-router-dom";
import LogOutModal from '../../pages/home/components/logoutModal/logoutModal';
import HamburgerButton from "../header/components/hamburgerMenu";
import React, { useEffect, useState } from "react";
import { ROLES } from "../../constants/common.consts";
import { MasterNavDesktopList } from "./components/Desktop/MasterNavDesktopList";
import { HostNavDesktopList } from "./components/Desktop/HostNavDesktopList";
import { MasterNavMobileList } from "./components/Mobile/MasterNavMobileList";
import { HostNavMobileList } from "./components/Mobile/HostNavMobileList";
import { SuperAdminNavDesktopList } from "./components/Desktop/SuperAdminNavDesktopList";
import { SuperAdminNavMobileList } from "./components/Mobile/SuperAdminNavMobileList";
import { useSelector } from "react-redux";
import { AppState } from "@redux/reducers/rootReducer";

const accountIcon = (
  <svg
    className="account_icon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9998 25.5998C12.6665 25.5998 9.71984 23.8932 7.99984 21.3332C8.03984 18.6665 13.3332 17.1998 15.9998 17.1998C18.6665 17.1998 23.9598 18.6665 23.9998 21.3332C22.2798 23.8932 19.3332 25.5998 15.9998 25.5998ZM15.9998 6.6665C17.0607 6.6665 18.0781 7.08793 18.8283 7.83808C19.5784 8.58822 19.9998 9.60564 19.9998 10.6665C19.9998 11.7274 19.5784 12.7448 18.8283 13.4949C18.0781 14.2451 17.0607 14.6665 15.9998 14.6665C14.939 14.6665 13.9216 14.2451 13.1714 13.4949C12.4213 12.7448 11.9998 11.7274 11.9998 10.6665C11.9998 9.60564 12.4213 8.58822 13.1714 7.83808C13.9216 7.08793 14.939 6.6665 15.9998 6.6665ZM15.9998 2.6665C14.2489 2.6665 12.5151 3.01138 10.8974 3.68144C9.27972 4.35151 7.80986 5.33363 6.57175 6.57175C4.07126 9.07223 2.6665 12.4636 2.6665 15.9998C2.6665 19.5361 4.07126 22.9274 6.57175 25.4279C7.80986 26.666 9.27972 27.6482 10.8974 28.3182C12.5151 28.9883 14.2489 29.3332 15.9998 29.3332C19.5361 29.3332 22.9274 27.9284 25.4279 25.4279C27.9284 22.9274 29.3332 19.5361 29.3332 15.9998C29.3332 8.6265 23.3332 2.6665 15.9998 2.6665Z"
      fill="currentColor"
    />
  </svg>
);

export function DesktopNavDropdown() {
  const { auth } = useSelector((state: AppState) => state);
  const [isOpen, setIsOpen] = useState(true);

  const onToggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
    const hide = () => {
      setIsOpen(false);
    };

    window.addEventListener("scroll", hide);

    return () => {
      window.removeEventListener("scroll", hide);
    };
  }, []);

  return (
    <NavDropdown
      className="nav_dropdown show"
      title={accountIcon}
      show={isOpen}
      onToggle={onToggleHandler}
    >
      <div className="list_wrapper">
        {auth?.role === ROLES.MASTER && <MasterNavDesktopList />}
        {auth?.role === ROLES.SALON_ADMIN && <HostNavDesktopList />}
        {auth?.role === ROLES.SUPER_ADMIN && <SuperAdminNavDesktopList />}
        <LogOutModal />
      </div>
    </NavDropdown>
  );
}

export function MobileLogOutNavDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const { auth } = useSelector((state: AppState) => state);

  const onToggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hide = () => {
      setIsOpen(false);
    };

    window.addEventListener("scroll", hide);

    return () => {
      window.removeEventListener("scroll", hide);
    };
  }, []);

  return (
    <NavDropdown
      className="nav_dropdown"
      title={<HamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />}
      show={isOpen}
      onToggle={onToggleHandler}
    >
      <div className="list_wrapper">
        {!auth && <Link to="for-salons"><NavDropdown.Item as="button">For Salons</NavDropdown.Item></Link>}
        {!auth && <Link to="beauty_artists"><NavDropdown.Item as="button">For Beauty artists</NavDropdown.Item></Link>}
        {auth?.role === ROLES.MASTER && <MasterNavMobileList />}
        {auth?.role === ROLES.SALON_ADMIN && <HostNavMobileList />}
        {auth?.role === ROLES.SUPER_ADMIN && <SuperAdminNavMobileList />}
        {/* TODO: out of scope */}
        {/* <NavDropdown.Item as="button">
          <div className="vv_language_selector">
            <ul className="languages_list ">
              <li className="list_item active">en</li>
              <li className="list_item">nl</li>
            </ul>
          </div>
        </NavDropdown.Item> */}
      </div>
    </NavDropdown>
  );
}

export function MobileLogInNavDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const onToggleHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavDropdown
      className="nav_dropdown"
      title={<HamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />}
      show={isOpen}
      onToggle={onToggleHandler}
    >
      <div className="list_wrapper">
        <NavDropdown.Item as="button">
          <Link to="grants_for_beauty_artists">Apply for grant</Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="button">
          <Link to="for-salons">For Salons</Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="button">
          <Link to="beauty_artists">For Beauty Artists</Link>
        </NavDropdown.Item>
        {/*<NavDropdown.Item as="button">*/}
        {/*  <Link to="salon_catalog">View salons</Link>*/}
        {/*</NavDropdown.Item>*/}
        <NavDropdown.Item as="button">
          <Link to="login_modal">Log in</Link>
        </NavDropdown.Item>
        {/* TODO: out of scope */}
        {/*
        <NavDropdown.Item as="button">
          <div className="vv_language_selector">
            <ul className="languages_list ">
              <li className="list_item active">en</li>
              <li className="list_item">nl</li>
            </ul>
          </div>
        </NavDropdown.Item> */}
      </div>
    </NavDropdown>
  );
}
