import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { PropTypes } from "./duck/types";
import { CustomPhoneInput } from "../../components/phoneInput/phoneInput";

const PhoneInput: React.FC<PropTypes> = ({
  name,
  label,
  tooltip,
  required,
  defaultValue,
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  return (
    <CustomPhoneInput
      text={label}
      value={value || ""}
      onChange={onChange}
      require={required}
      info={tooltip}
      err={errors[fieldName]?.message.toString()}
    />
  );
};

export default PhoneInput;
