import React from "react";
import logo from "../../assets/icons/vervvey_logo.svg";
import linkedinIcon from "../../assets/icons/Icon_Linkedin.svg";
import instagramIcon from "../../assets/icons/Icon_Insta.svg";
import facebookIcon from "../../assets/icons/Icon_Facebook.svg";
import rightArrow from "../../assets/icons/chevron-right.svg";
import { Link } from "react-router-dom";
import "./footer.scss";
import { BASE_API_URL } from "../../services/authApi";

const arrowRightIcon = (
  <svg
    className="arrow_right_icon"
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43 27.4546H11"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 11.4546L43 27.4546L27 43.4546"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function Footer() {
  return (
    <footer className="vv_footer">
      <div className="with_links">
        <div className="links_list">
          {/* TODO: out of scope */}
          {/* <div className="list_item">
            <Link to="edit_salons">Add your salon</Link>
          </div> */}
          {/* <div className="list_item">
            <Link to="salons"> About VERVVEY</Link>
          </div> */}
          {/* <div className="list_item">
            <Link to="salons">Find your artist</Link>
          </div> */}
        </div>
        <div className="links_wrapper">
          <div className="social_lins_wrapper">
            <div>
              <a
                href="https://www.linkedin.com/company/vervvey"
                target="_blank"
              >
                <img src={linkedinIcon} alt="link" className="social_link" />
              </a>
            </div>
            <div>
              <a href="https://instagram.com/vervvey" target="_blank">
                <img src={instagramIcon} alt="link" className="social_link" />
              </a>
            </div>
            <div>
              <a href="https://www.facebook.com/vervvey.beauty" target="_blank">
                <img src={facebookIcon} alt="link" className="social_link" />
              </a>
            </div>
          </div>
          <div className="email_link">
            <a href="mailto:hello@vervvey.com">hello@vervvey.com</a>
          </div>
          <div className="phone_number">
            <a href="tel:+31208905527">+31 20 890 5527</a>
          </div>
        </div>
        <div className="image_wrapper">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="bottom">
        <div className="terms">
          <a
            target="_blank"
            href={`${BASE_API_URL}/document/Terms%20of%20use.pdf`}
          >
            Terms of use
          </a>
          <a
            target="_blank"
            href={`${BASE_API_URL}/document/Privacy%20statement%20VERVVEY-EN.pdf`}
          >
            Privacy Statement
          </a>
        </div>
        {/* <div className="learn_about">
          <a href="mailto:hello@vervvey.com">
            Learn about our COVID-19 Health & Safety Measures {arrowRightIcon}
          </a>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
