import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getValidation } from "./duck/utils";
import { FormProvider, useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { authService } from "../../services/authApi";
import { PasswordInput, TextInput } from "../../form-units";
import CustomButton from "../../components/customButton/customButton";
import { AuthActions } from "../../redux/actions/authActions";

import {
  Wrapper,
  PaddedBox,
  LoginBlock,
  StyledDialog,
  StyledDialogContent,
} from "./reset-password-styles";
import { AdaptiveText } from "../../components";
import { IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const authDispatch = useDispatch<Dispatch<AuthActions>>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { validationSchema, defaultValues } = getValidation();
  const restoreToken = searchParams.get("token");

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const pass = formProviderProps.watch(["password", "confirmPassword"]);

  useEffect(() => {
    if (formProviderProps.formState.isSubmitted) {
      formProviderProps.trigger();
    }
  }, [pass]);

  const close = () => {
    navigate("/login_modal");
  };

  async function handleSubmit() {
    try {
      setLoading(true);

      const data = await authService.resetPassword({
        ...formProviderProps.getValues(),
        restoreToken,
      });

      if (data) {
        navigate("/login_modal?passwordChanged=true");
      }

      return;
    } catch {
      setError("Failed to change password");
    }

    setLoading(false);
  }

  return (
    <StyledDialog open onClose={() => {}} scroll="body" fullWidth maxWidth="md">
      <StyledDialogContent>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Wrapper>
          <AdaptiveText
            xl={{ size: 26, height: 33 }}
            xs={{ size: 18, height: 18 }}
            weight={700}
            fontFamily="Greenwich"
            uppercase
          >
            Password reset
          </AdaptiveText>
          <PaddedBox>
            <AdaptiveText
              xl={{ size: 16, height: 19 }}
              xs={{ size: 12, height: 12 }}
              fontFamily="Greenwich"
            >
              Please enter your e-mail and set a new password
            </AdaptiveText>
          </PaddedBox>

          {error && <Alert variant="danger">{error}</Alert>}
          <FormProvider {...formProviderProps}>
            <form
              onSubmit={formProviderProps.handleSubmit(handleSubmit)}
              noValidate
            >
              <TextInput
                name="email"
                type="email"
                required
                label="Your e-mail address"
              />
              <PasswordInput
                name="password"
                label="New password"
                required
              ></PasswordInput>
              <PasswordInput
                name="confirmPassword"
                label="Confirm password"
                required
              ></PasswordInput>

              <LoginBlock>
                <CustomButton
                  disabled={loading}
                  className="w-100"
                  type="submit"
                  variant="primary"
                >
                  Reset password
                </CustomButton>
              </LoginBlock>
            </form>
          </FormProvider>
        </Wrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
}
