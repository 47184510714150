import logoIcon from "../../assets/icons/vervvey_logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./header.scss";

import {
  DesktopNavDropdown,
  MobileLogInNavDropdown,
  MobileLogOutNavDropdown,
} from "../customNavDropdown/customNavDropdown";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import classes from "../../pages/GrantsForBeautyArtists/GrantsForBeautyArtists.module.scss";
import React from "react";
import {ROLES, STATUSES} from "../../constants/common.consts";
import NavDropdown from "react-bootstrap/NavDropdown";

const accountIcon = (
  <svg
    className="account_icon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9998 25.5998C12.6665 25.5998 9.71984 23.8932 7.99984 21.3332C8.03984 18.6665 13.3332 17.1998 15.9998 17.1998C18.6665 17.1998 23.9598 18.6665 23.9998 21.3332C22.2798 23.8932 19.3332 25.5998 15.9998 25.5998ZM15.9998 6.6665C17.0607 6.6665 18.0781 7.08793 18.8283 7.83808C19.5784 8.58822 19.9998 9.60564 19.9998 10.6665C19.9998 11.7274 19.5784 12.7448 18.8283 13.4949C18.0781 14.2451 17.0607 14.6665 15.9998 14.6665C14.939 14.6665 13.9216 14.2451 13.1714 13.4949C12.4213 12.7448 11.9998 11.7274 11.9998 10.6665C11.9998 9.60564 12.4213 8.58822 13.1714 7.83808C13.9216 7.08793 14.939 6.6665 15.9998 6.6665ZM15.9998 2.6665C14.2489 2.6665 12.5151 3.01138 10.8974 3.68144C9.27972 4.35151 7.80986 5.33363 6.57175 6.57175C4.07126 9.07223 2.6665 12.4636 2.6665 15.9998C2.6665 19.5361 4.07126 22.9274 6.57175 25.4279C7.80986 26.666 9.27972 27.6482 10.8974 28.3182C12.5151 28.9883 14.2489 29.3332 15.9998 29.3332C19.5361 29.3332 22.9274 27.9284 25.4279 25.4279C27.9284 22.9274 29.3332 19.5361 29.3332 15.9998C29.3332 8.6265 23.3332 2.6665 15.9998 2.6665Z"
      fill="currentColor"
    />
  </svg>
);

function DesktopHeaderNavigation() {
  const { auth } = useSelector((state: AppState) => state);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  let menu;

  if (isDesktop) {
    menu =
        <>
          <li>
            <NavLink to="for-salons">For Salons</NavLink>
          </li>
          <li>
            <NavLink to="beauty_artists">For Beauty Artists</NavLink>
          </li>
          <li>
            <NavLink to="grants_for_beauty_artists">Talent grant</NavLink>
          </li>
        </>;

    if (auth?.role === ROLES.MASTER){
      menu = <>
        <li>
          <NavLink to="salon_catalog">View salons</NavLink>
        </li>
        <li>
          <NavLink to="bookings_list">My bookings</NavLink>
        </li>
        <li>
          <NavLink to="grants_for_beauty_artists">Talent grant</NavLink>
        </li>
      </>;
    }
    else if (auth?.role === ROLES.SALON_ADMIN) {

      if (auth?.salonId) {
        menu = <>
          <li>
            <NavLink to="schedules/workstations_rentals">Edit schedule</NavLink>
          </li>
          <li>
            <NavLink to="bookings_list">Bookings</NavLink>
          </li>
        </>;
      }
      else {
        const isHostInfoFilled = !!(auth.name && auth.surname);
        menu = <>
          <li>
            <Link
                to={
                  !isHostInfoFilled
                      ? "host_information"
                      : "edit_salons"
                }
            >
              Edit profile
            </Link>
          </li>
        </>;
      }
    }

  }

  return (
    <div className="nav_wrapper">
      <nav>
        <ul>

          {menu}

          {auth?.role ? (
            <li className="log_out">
              <DesktopNavDropdown />
            </li>
          ) : (
            <li className="log_in">
              {accountIcon}
              <NavLink to="login_modal">Log in</NavLink>
            </li>
          )}
        </ul>
      </nav>
      {/* TODO: out of scope */}
      {/* <div className="vv_language_selector">
        <ul className="languages_list ">
          <li className="list_item active">en</li>
          <li className="list_item">nl</li>
        </ul>
      </div> */}
    </div>
  );
}

function MobileHeaderNavigation() {
  const navigate = useNavigate();
  const { auth } = useSelector((state: AppState) => state);

  return (
    <div className="nav_wrapper">
      <nav>
        <ul>
          {auth.userId ? (
            <>
              <li className="log_out">{accountIcon}</li>
              <li className="burger_menu">
                <MobileLogOutNavDropdown />
              </li>
            </>
          ) : (
            <>
              <li
                className="log_in"
                onClick={() => {
                  navigate("login_modal");
                }}
              >
                {accountIcon}
              </li>
              <li className="burger_menu">
                <MobileLogInNavDropdown />
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}

function Header() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { auth } = useSelector((state: AppState) => state);

  let complete_profile_message;

  if (auth?.role === 'master' && auth?.status === STATUSES.APPROVEMENT){
    if (auth?.name){
      complete_profile_message = <div className="notificaton_message">
        <div className={classes.titles_wrapper}>
            <Typography variant="h6">
              Hey there! We are verifying your profile, thank you for your patience
            </Typography>
        </div>
      </div>;
    }
    else {
      complete_profile_message = <div className="notificaton_message">
        <div className={classes.titles_wrapper}>
          <Link
              to={
                auth?.name
                    ? `/profile_edit/professional_profile/${auth.userId}`
                    : "/eligibility_check"
              }
          >
            <Typography variant="h6">
              Hey there! Please, complete your profile to be verified as a member
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.3142 4.57549C12.1406 4.40192 11.8592 4.40192 11.6856 4.57549C11.5121 4.74905 11.5121 5.03046 11.6856 5.20403L18.0376 11.556L4.88878 11.556C4.64332 11.556 4.44434 11.755 4.44434 12.0004C4.44434 12.2459 4.64332 12.4449 4.88878 12.4449H18.0385L11.6856 18.7977C11.5121 18.9713 11.5121 19.2527 11.6856 19.4262C11.8592 19.5998 12.1406 19.5998 12.3142 19.4262L19.4253 12.3151C19.5117 12.2287 19.5551 12.1154 19.5554 12.0021L19.5554 12.0004C19.5554 11.8717 19.5007 11.7558 19.4133 11.6747L12.3142 4.57549Z" fill="#ffffff"></path></svg>
            </Typography>
          </Link>
        </div>
      </div>;
    }
  }

  return (
  <>
    <header className="vv_header">
      <div className="logo_wrapper">
        <NavLink to="/">
          <img src={logoIcon} alt="logo" />
        </NavLink>
      </div>
      {isTablet ? <MobileHeaderNavigation /> : <DesktopHeaderNavigation />}
    </header>

    {complete_profile_message}
  </>
  );
}

export default Header;
